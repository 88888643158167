@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,100&display=swap');


:root {
  --color-main: #35962b;
  --color-primary: #35962b;
  --color-secondary: rgba(165, 182, 70, 0.185);
  --color-success: #F5F08D;
  --color-success-light: #f9f5ab2c;
  --color-blue4: #35962b;
  --color-blue5: #35962b;
  --color-blue6: #6640f4;
  --color-lightBlue: #15f1ff;
  --color-darkBlue: #010049;
  --color-gray: #eef4f8;
  --color-main-grad: linear-gradient(to right, #0e664d 0%, #A6B646 100%);
}

.fs-sm {
  font-size: 0.75rem !important;
}

.offcanvas-backdrop.show{
  opacity: 0.7 !important;
}

.btn {
  box-shadow: none !important;
}

.btn-primary {
  border: none !important;
}

.title_small {
  background-color: var(--color-success-light) !important;
}

.btn-primary {
  background-color: var(--color-main) !important;
}

.color-main {
  color: var(--color-main);
}

.border-main {
  border-color: var(--color-main) !important;
}

.bg-main {
  background-color: var(--color-main);
  border-color: var(--color-main);
}

.color-lightBlue {
  color: var(--color-lightBlue);
}

.border-lightBlue {
  border-color: var(--color-lightBlue) !important;
}

.bg-lightBlue {
  background-color: var(--color-lightBlue);
}

.color-darkBlue {
  color: var(--color-darkBlue);
}

.border-darkBlue {
  border-color: var(--color-darkBlue) !important;
}

.bg-darkBlue {
  background-color: var(--color-darkBlue);
}

.bg-gradient {
  background-image: var(--color-main-grad) !important;
}

.bg-gray {
  background: var(--color-gray) !important;
}

.color-blue2 {
  color: var(--color-blue2) !important;
}

.bg-blue2 {
  background-color: var(--color-blue2) !important;
}

.border-blue2 {
  border-color: var(--color-blue2) !important;
}

.color-blue4 {
  color: var(--color-main) !important;
}

.bg-blue4 {
  background-color: var(--color-main) !important;
}

.border-blue4 {
  border-color: var(--color-blue4) !important;
}

.color-blue5 {
  color: var(--color-blue5) !important;
}

.bg-blue5 {
  background-color: var(--color-blue5) !important;
}

.border-blue5 {
  border-color: var(--color-blue5) !important;
}

.color-blue6 {
  color: var(--color-blue6) !important;
}

.bg-blue6 {
  background-color: var(--color-blue6) !important;
}

.border-blue6 {
  border-color: var(--color-blue6) !important;
}


/* ===============================================================
              [ * global Section ] 
  ================================================================*/
/* --------------- global --------------- */
*,
* button:focus {
  outline: 0;
}

html::-webkit-scrollbar {
  height: 20px;
  width: 10px;
  background: #f1f1f1;
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb {
  background: #999;
  -webkit-border-radius: 0;
  border-radius: 10px;
}

html::-webkit-scrollbar-corner {
  background: #999;
  border-radius: 10px;
}

body {
  font-family: 'Roboto', sans-serif !important;
  text-decoration: none;
  font-size: 0.9em;
  overflow-x: hidden;
  position: relative;
  font-weight: 400;
  line-height: 1.5;
}

body.style-6 {
  padding: 30px 30px 0;
}

.container,
.container-fluid {
  position: relative;
  z-index: 5;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 1170px;
  padding-right: 15px;
  padding-left: 15px;
}

.row:not(.gx-0):not(.gx-1):not(.gx-2):not(.gx-3):not(.gx-4):not(.gx-5) {
  margin-right: -15px;
  margin-left: -15px;
}

.row:not(.gx-0):not(.gx-1):not(.gx-2):not(.gx-3):not(.gx-4):not(.gx-5)>* {
  padding-right: 15px;
  padding-left: 15px;
}

body a:hover {
  text-decoration: none;
}

body a:focus {
  outline: 0;
}

body ul {
  list-style-type: none;
  margin: 0;
}

.btn-default:active,
.btn-default:active:focus,
.btn-default:active:hover,
.btn-default:focus,
.btn-default:hover,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  -webkit-appearance: none;
}

.btn:active,
.btn:active:focus,
.btn:active:hover,
.btn:focus,
.btn:hover,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  outline: 0;
}

a,
span,
img {
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: inherit;
  text-decoration: none !important;
}

ul {
  padding: 0;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.bi {
  display: inline-block;
}

.text {
  font-size: 14px;
  /* line-height: 2; */
}

.small,
small {
  font-size: 13px;
  line-height: 1.7;
  display: inline-block;
}

small.text {
  font-size: 12px;
}

.text-gray {
  color: #666;
}

.bg-gray5 {
  background-color: #f3f7fe;
}

.section-padding {
  padding: 100px 0;
}

.swiper-pagination {
  width: 100%;
  bottom: 0;
}

.pagination_circle .swiper-pagination-bullet {
  margin: 0 20px;
  position: relative;
}

.pagination_circle .swiper-pagination-bullet::before {
  position: absolute;
  content: "";
  left: -10px;
  top: -10px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid transparent;
}

.pagination_circle .swiper-pagination-bullet.swiper-pagination-bullet-active::before {
  border: 1px solid var(--color-lightBlue);
}

.pagi_dark .swiper-pagination-bullet {
  background-color: #000;
}

.pagi_white .swiper-pagination-bullet {
  background-color: #fff;
}

.swiper-pagination-bullet {
  margin: 0 5px;
}

.swiper-pagination-bullet-active {
  background-color: var(--color-main);
  border-radius: 5px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: var(--color-main);
  font-size: 15px;
  font-weight: bold;
}

.swiper-button-next,
.swiper-button-prev {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: #fff;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background: var(--color-main-grad);
}

.swiper-button-next:hover::after,
.swiper-button-prev:hover::after {
  color: #fff;
}

.slider-style-6 {
  position: relative;
}

.slider-style-6 .swiper-pagination-bullet-active {
  background-color: var(--color-blue6);
}

.slider-style-6 .swiper-button-next,
.slider-style-6 .swiper-button-prev {
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.slider-style-6 .swiper-button-next:hover,
.slider-style-6 .swiper-button-prev:hover {
  background: var(--color-blue6);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.slider-style-6 .swiper-button-next,
.slider-style-6 .swiper-container-rtl .swiper-button-prev {
  right: -60px;
}

.slider-style-6 .swiper-button-prev,
.slider-style-6 .swiper-container-rtl .swiper-button-next {
  left: -60px;
}

/* --------------- dropdown --------------- */
.dropdown-toggle::after {
  position: relative;
  top: 3px;
  opacity: 0.8;
  content: "\f078";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  border: 0;
  font-size: 80%;
}

.dropdown-menu, .goog-te-combo {
  min-width: 170px;
  padding: 10px 0;
  border-radius: 15px;
  border: 0;
  -webkit-box-shadow: 0 0 20px #0001;
  box-shadow: 0 0 20px #0001;
  top: 100%;
}

.dropdown-menu .dropdown-item {
  padding: 5px 20px;
  font-size: 12px;
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-switch .form-check-input {
  margin: 0 15px !important;
  width: 2.5em !important;
  height: 1.3em;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
}

.form-switch .form-check-input.color-lightBlue:checked {
  background-color: var(--color-lightBlue);
  border-color: var(--color-lightBlue);
  /* background-image: url(../img/icons/toggle_dot.svg);  */
}


.loading {
  position: fixed;
  left: 50%;
  top: calc(50% - 40px);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  font-weight: 300;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 10px;
  z-index: 9999999999;
}

.loading.isdone {
  top: 50%;
  opacity: 0;
  -webkit-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.loading span {
  -webkit-animation: loading 1.4s infinite alternate;
  animation: loading 1.4s infinite alternate;
}

.loading span:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.loading span:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.loading span:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.loading span:nth-child(4) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.loading span:nth-child(5) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.loading span:nth-child(6) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.loading span:nth-child(7) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@-webkit-keyframes loading {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes loading {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* --------------- End Preloader --------------- */
/* --------------- to_top button --------------- */
.to_top {
  position: fixed;
  z-index: 9999;
  bottom: 30px;
  right: -50px;
  width: 35px;
  height: 35px;
  border-radius: 7px;
  background: var(--color-main-grad);
  color: #fff;
  font-size: 11px;
  text-align: center;
  padding: 7px;
  line-height: 1;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-box-shadow: 0 5px 10px #0002;
  box-shadow: 0 5px 10px #0002;
}

.to_top small {
  display: block;
  text-transform: uppercase;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  font-size: 8px;
}

.to_top i {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.to_top.show {
  right: 30px;
  opacity: 1;
}

/* --------------- pagination style --------------- */
.pagination.style-5 a {
  width: 30px;
  height: 30px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  border: 1px solid #9993;
  border-radius: 8px;
  margin-right: 5px;
  font-size: 11px;
  color: #666;
}

.pagination.style-5 a .text {
  padding: 3px 10px;
  font-size: 11px;
}

.pagination.color-5 a:hover {
  color: var(--color-blue5) !important;
  border-color: var(--color-blue5);
}

.pagination.color-5 a.active {
  color: #fff;
  border-color: var(--color-blue5);
  background-color: var(--color-blue5);
}

.pagination.color-4 a:hover {
  color: var(--color-blue4) !important;
  border-color: var(--color-blue4);
}

.pagination.color-4 a.active {
  color: #fff;
  border-color: var(--color-blue4);
  background-color: var(--color-blue4);
}

/**
   * ----------------------------------------
   * animation rotate-center
   * ----------------------------------------
  */
.rotate-center {
  -webkit-animation: rotate-center 100s linear infinite both;
  animation: rotate-center 100s linear infinite both;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/**
   * ----------------------------------------
   * animation slide_up_down
   * ----------------------------------------
  */
.slide_up_down {
  -webkit-animation: slide_up_down 1.7s ease-in-out infinite alternate both;
  animation: slide_up_down 1.7s ease-in-out infinite alternate both;
}

/* ------- */
@-webkit-keyframes slide_up_down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

@keyframes slide_up_down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

/**
   * ----------------------------------------
   * animation wave
   * ----------------------------------------
  */
.wave {
  -webkit-animation: wave 2s ease-in-out infinite alternate both;
  animation: wave 2s ease-in-out infinite alternate both;
}

/* ------- */
@-webkit-keyframes wave {
  0% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg);
  }

  100% {
    -webkit-transform: rotate(-1deg);
    transform: rotate(-1deg);
  }
}

@keyframes wave {
  0% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg);
  }

  100% {
    -webkit-transform: rotate(-1deg);
    transform: rotate(-1deg);
  }
}

/**
   * ----------------------------------------
   * animation scale_up_down
   * ----------------------------------------
  */
.scale_up_down {
  -webkit-animation: scale_up_down 1s ease-in-out infinite alternate both;
  animation: scale_up_down 1s ease-in-out infinite alternate both;
}

/* ------- */
@-webkit-keyframes scale_up_down {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale_up_down {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* ===============================================================
              [ * buttons Section ] 
  ================================================================*/
/* --------------- helper classes --------------- */
/* --------------- radius classes --------------- */
.radius-1 {
  border-radius: 3px !important;
}

.radius-2 {
  border-radius: 5px !important;
}

.radius-3 {
  border-radius: 8px !important;
}

.radius-4 {
  border-radius: 10px !important;
}

.radius-5 {
  border-radius: 12px !important;
}

.radius-6 {
  border-radius: 15px !important;
}

.radius-7 {
  border-radius: 20px !important;
}

.radius-8 {
  border-radius: 25px !important;
}

.radius-9 {
  border-radius: 30px !important;
}

.radius-10 {
  border-radius: 50px !important;
}

/* --------------- margin classes --------------- */
.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

.mt-200 {
  margin-top: 200px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-150 {
  margin-bottom: 150px !important;
}

.mb-200 {
  margin-bottom: 200px !important;
}

/* --------------- padding classes --------------- */
.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pt-150 {
  padding-top: 150px !important;
}

.pt-200 {
  padding-top: 200px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pb-150 {
  padding-bottom: 150px !important;
}

.pb-200 {
  padding-bottom: 200px !important;
}

/* --------------- opacity classes --------------- */
.op-1 {
  opacity: 0.1;
}

.op-2 {
  opacity: 0.2;
}

.op-3 {
  opacity: 0.3;
}

.op-4 {
  opacity: 0.4;
}

.op-5 {
  opacity: 0.5;
}

.op-6 {
  opacity: 0.6;
}

.op-7 {
  opacity: 0.7;
}

.op-8 {
  opacity: 0.8;
}

.op-9 {
  opacity: 0.9;
}

.op-10 {
  opacity: 1;
}

/* --------------- lineHeight classes --------------- */
.lh-1 {
  line-height: 1;
}

.lh-2 {
  line-height: 1.2;
}

.lh-3 {
  line-height: 1.4;
}

.lh-4 {
  line-height: 1.6;
}

.lh-5 {
  line-height: 1.8;
}

.lh-6 {
  line-height: 2;
}

.lh-7 {
  line-height: 2.5;
}

.lh-8 {
  line-height: 3;
}

/* --------------- border color classes --------------- */
.brd-light {
  border-color: #fff4 !important;
}

.brd-gray {
  border-color: #0002 !important;
}

/* ---------------  color classes --------------- */
.color-000 {
  color: #000;
}

.color-111 {
  color: #111;
}

.color-222 {
  color: #222;
}

.color-333 {
  color: #333;
}

.color-444 {
  color: #444;
}

.color-555 {
  color: #555;
}

.color-666 {
  color: #666;
}

.color-777 {
  color: #777;
}

.color-888 {
  color: #888;
}

.color-999 {
  color: #999;
}

/* --------------- icons size --------------- */
.icon-10 {
  width: 10px;
  height: 10px;
}

.icon-15 {
  width: 15px;
  height: 15px;
}

.icon-20 {
  width: 20px;
  height: 20px;
}

.icon-25 {
  width: 25px;
  height: 25px;
}

.icon-30 {
  width: 30px;
  height: 30px;
}

.icon-35 {
  width: 35px;
  height: 35px;
}

.icon-40 {
  width: 40px;
  height: 40px;
}

.icon-45 {
  width: 45px;
  height: 45px;
}

.icon-50 {
  width: 50px;
  height: 50px;
}

.icon-55 {
  width: 55px;
  height: 55px;
}

.icon-60 {
  width: 60px;
  height: 60px;
}

.icon-65 {
  width: 65px;
  height: 65px;
}

.icon-70 {
  width: 70px;
  height: 70px;
}

.icon-75 {
  width: 75px;
  height: 75px;
}

.icon-80 {
  width: 80px;
  height: 80px;
}

/* --------------- img classes --------------- */
.img-cover {
  display: block;
}

.img-cover img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.img-contain {
  display: block;
}

.img-contain img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.imgrotate-scale-hover {
  overflow: hidden;
  display: block;
}

.imgrotate-scale-hover img {
  -webkit-transition: all 2s ease;
  -o-transition: all 2s ease;
  transition: all 2s ease;
}

.imgrotate-scale-hover:hover img {
  -webkit-transform: rotate(-10deg) scale(1.2);
  -ms-transform: rotate(-10deg) scale(1.2);
  transform: rotate(-10deg) scale(1.2);
}

/* --------------- letter spacing classes --------------- */
.ltspc-1 {
  letter-spacing: 1px;
}

.ltspc-2 {
  letter-spacing: 2px;
}

.ltspc-3 {
  letter-spacing: 3px;
}

.ltspc-4 {
  letter-spacing: 4px;
}

.ltspc-5 {
  letter-spacing: 5px;
}

.ltspc-8 {
  letter-spacing: 8px;
}

.ltspc-10 {
  letter-spacing: 10px;
}

.ltspc-12 {
  letter-spacing: 12px;
}

.ltspc-15 {
  letter-spacing: 15px;
}

.ltspc-17 {
  letter-spacing: 17px;
}

.ltspc-20 {
  letter-spacing: 20px;
}

.fs-10px {
  font-size: 10px !important;
}

.fs-12px {
  font-size: 12px !important;
}

.fs-14px {
  font-size: 14px !important;
}

/* ===============================================================
              [ * buttons Section ] 
  ================================================================*/
/* --------------- buttons styles --------------- */
.butn,
.btn {
  padding: 12px 30px;
  position: relative;
  overflow: hidden;
  text-align: center;
  font-weight: 500;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.butn span,
.btn span {
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: capitalize;
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.butn small,
.btn small {
  font-size: 12px;
  position: relative;
  z-index: 2;
  text-transform: capitalize;
}

.butn.butn-gard::before,
.btn.butn-gard::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 150%;
  height: 100%;
  z-index: 1;
  background: -webkit-gradient(linear, left top, right top, color-stop(10%, #0c3df4), color-stop(45%, #02b5ff), color-stop(#02b5ff), to(#0c3df4));
  background: -webkit-linear-gradient(left, #0c3df4 10%, #02b5ff 45%, #02b5ff, #0c3df4);
  background: -o-linear-gradient(left, #0c3df4 10%, #02b5ff 45%, #02b5ff, #0c3df4);
  background: linear-gradient(to right, #0c3df4 10%, #02b5ff 45%, #02b5ff, #0c3df4);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.butn.butn-gard:hover::before,
.btn.butn-gard:hover::before {
  left: -50%;
}

.butn.dark-butn,
.btn.dark-butn {
  background: #000;
}

.butn.dark-butn span,
.butn.dark-butn small,
.btn.dark-butn span,
.btn.dark-butn small {
  color: #fff;
}

.butn.butn-blue6,
.btn.butn-blue6 {
  background: var(--color-blue6);
}

.butn.butn-blue6 span,
.butn.butn-blue6 small,
.btn.butn-blue6 span,
.btn.butn-blue6 small {
  color: #fff;
}

.butn.blue5-3Dbutn,
.btn.blue5-3Dbutn {
  background: var(--color-blue5);
  -webkit-box-shadow: 0px 4px 0px 0px var(--color-blue4);
  box-shadow: 0px 4px 0px 0px var(--color-blue4);
  -webkit-filter: drop-shadow(0 13px 20px #07397235);
  filter: drop-shadow(0 13px 20px #07397235);
  font-size: 12px;
}

.butn.blue5-3Dbutn span,
.butn.blue5-3Dbutn small,
.btn.blue5-3Dbutn span,
.btn.blue5-3Dbutn small {
  color: #fff;
}

.butn.sm-butn,
.btn.sm-butn {
  padding: 10px 25px;
}

.butn.sm-butn span,
.btn.sm-butn span {
  font-size: 12px;
}

.butn:hover,
.btn:hover {
  background: var(--color-main);
  border-color: transparent !important;
}

.butn:hover span,
.btn:hover span {
  color: #fff;
}

.butn.hover-darkBlue:hover,
.btn.hover-darkBlue:hover {
  background: var(--color-darkBlue);
  border-color: transparent !important;
}

.butn.hover-darkBlue:hover span,
.butn.hover-darkBlue:hover small,
.btn.hover-darkBlue:hover span,
.btn.hover-darkBlue:hover small {
  color: #fff;
}

.butn.hover-blue2:hover,
.btn.hover-blue2:hover {
  background: var(--color-blue2);
  border-color: transparent !important;
}

.butn.hover-blue2:hover span,
.butn.hover-blue2:hover small,
.btn.hover-blue2:hover span,
.btn.hover-blue2:hover small {
  color: #fff;
}

.butn.hover-blue4:hover,
.btn.hover-blue4:hover {
  background: var(--color-blue4);
  border-color: transparent !important;
}

.butn.hover-blue4:hover span,
.butn.hover-blue4:hover small,
.btn.hover-blue4:hover span,
.btn.hover-blue4:hover small {
  color: #fff;
}

.butn.hover-blue5:hover,
.btn.hover-blue5:hover {
  background: var(--color-blue5);
  border-color: transparent !important;
}

.butn.hover-blue5:hover span,
.butn.hover-blue5:hover small,
.btn.hover-blue5:hover span,
.btn.hover-blue5:hover small {
  color: #fff;
}

.butn.hover-lightBlue:hover,
.btn.hover-lightBlue:hover {
  background: var(--color-lightBlue);
  border-color: transparent !important;
}

.butn.hover-lightBlue:hover span,
.butn.hover-lightBlue:hover small,
.btn.hover-lightBlue:hover span,
.btn.hover-lightBlue:hover small {
  color: var(--color-darkBlue);
}

/* ===============================================================
              [ * section title ] 
  ================================================================*/
/* --------------- section head styles --------------- */
.section-head h6 {
  font-size: 13px;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.section-head.style-3 {
  margin-bottom: 50px;
}

.section-head.style-3 h3 {
  font-size: 27px;
  text-transform: capitalize;
}

.section-head.style-3 h3 span {
  font-weight: 400;
  color: var(--color-blue2);
  font-style: italic;
  position: relative;
}

.section-head.style-3 h3 span::after {
  position: absolute;
  content: "";
  /* background-image: url(../img/section_head/title_shap1.png); */
  left: 0;
  bottom: -15px;
  width: calc(100% + 40px);
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
}

.section-head.style-3.long-shape h3 span::after {
  /* background-image: url(../img/section_head/title_shap2.png); */
  bottom: -15px;
}

/* --------------- section style-4 --------------- */
.section-head.style-4 .title_small {
  font-size: 12px;
  padding: 5px 10px;
  background-color: #f7f4ff;
  border-radius: 5px;
  color: var(--color-blue4);
  margin-bottom: 20px;
}

.section-head.style-4 h2 {
  font-size: 40px;
  line-height: 1.3;
  color: #000;
}

.section-head.style-4 h2 span {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: var(--color-main-grad);
}

/* --------------- section style-5 --------------- */
.section-head.style-5 h2 {
  font-size: 45px;
}

.section-head.style-5 h2 span {
  color: var(--color-blue5);
  position: relative;
  display: inline-block;
  padding: 0 5px;
  margin: 0 5px;
}

.section-head.style-5 h2 span::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 40%;
  background-color: var(--color-blue5);
  opacity: 8%;
}

.section-head.style-5 p {
  color: #666;
  font-size: 15px;
}

/* --------------- section style-6 --------------- */
.section-head.style-6 h2 {
  color: #000;
  font-size: 45px;
}

.section-head.style-6 h2 span {
  position: relative;
  padding: 10px 20px;
}

.section-head.style-6 h2 span small {
  position: relative;
  z-index: 2;
  font-size: 45px;
  color: #fff;
}

.section-head.style-6 h2 span::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  /* background-image: url(../img/shap_style_6.png); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

/* ===============================================================
              [ * top navbar Section ] 
  ================================================================*/
/* --------------- top navbar styles --------------- */
.top-navbar.style-1 {
  background: var(--color-main-grad);
  position: relative;
  z-index: 9;
}

.top-navbar.style-1 .top-links .text {
  font-size: 11px;
}

.top-navbar.style-1 .top-links .text strong {
  margin: 0 10px;
}

.top-navbar.style-1 .top-links .text .fa-bullhorn {
  background-color: #002ec1;
  padding: 15px;
}

.top-navbar.style-1 .r-side {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.top-navbar.style-1 .r-side .socail-icons {
  border-right: 1px solid #fff4;
  padding-right: 10px;
  margin-right: 14px;
}

.top-navbar.style-1 .r-side .socail-icons a {
  color: #fff;
  margin: 0 5px;
  font-size: 12px;
}

.top-navbar.style-1 .r-side .dropdown {
  color: #fff;
  font-size: 11px;
}

.top-navbar.style-1 .r-side .dropdown img {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

/* --------------- top navbar style-4 --------------- */
.top-navbar.style-4 {
  background-image: url(../../../public/images/top_nav_s4.png);
  background-size: cover;
  background-position: top;
  padding: 10px 15px 50px;
  text-align: center;
}

/* --------------- top navbar style-5 --------------- */
.top-navbar.style-5 {
  padding: 10px 15px;
  text-align: center;
  background-color: var(--color-blue5);
}

/* --------------- top navbar styles --------------- */
/* --------------- top navbar styles --------------- */
/* ===============================================================
              [ * navbar Section ] 
  ================================================================*/
/* --------------- navbar styles --------------- */

/* .modal-backdrop {
  display: none !important;
} */

.modal-content {
  border: none !important;
  box-shadow: 0px 20px 20px 10px rgb(81 85 134 / 20%);
}

/* .navbar {
    z-index: 999 !important; 
  } */
.navbar .nav-link {
  cursor: pointer;
}

.navbar.nav-scroll {
  background: #fff !important;
  -webkit-box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.048);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.048);
  padding: 0;
  position: fixed !important;
  top: -100px !important;
  left: 0;
  width: 100%;
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s;
  -o-transition: transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  border-radius: 0 !important;
  margin: 0 !important;
}

.navbar.style-1 {
  padding: 30px 0;
}

.navbar.style-1 .navbar-brand {
  margin-right: 40px;
}

.navbar.style-1 .navbar-nav .nav-item .nav-link {
  font-size: 12px;
  color: #000;
  font-weight: 700;
  line-height: 2;
  margin: 0 7px;
  text-transform: capitalize;
}

.navbar.style-1 .navbar-nav .nav-item .nav-link.active {
  color: var(--color-main);
}

.navbar.style-1 .nav-side {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar.style-1 .nav-side .hotline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid #9993;
}

.navbar.style-1 .nav-side .hotline .icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: var(--color-main-grad);
  color: #fff;
  font-size: 13px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.navbar.style-1 .nav-side .hotline .cont h6 {
  color: #000;
  font-size: 15px;
  font-weight: bold;
}

.navbar.style-1 .qoute-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar.style-1 .qoute-nav a .cart-num {
  font-size: 9px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #fff;
  background: var(--color-main-grad);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.navbar.style-2.nav-scroll {
  background-color: #010049;
}

.navbar.style-2 .navbar-brand img {
  width: 155px;
  -o-object-fit: contain;
  object-fit: contain;
}

.navbar.style-2 .navbar-nav .nav-item .nav-link {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  line-height: 2;
  margin: 0;
  padding: 30px 30px;
  border-bottom: 2px solid transparent;
  position: relative;
}

.navbar.style-2 .navbar-nav .nav-item .nav-link::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0%;
  background-color: #fff2;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.navbar.style-2 .navbar-nav .nav-item .nav-link.active,
.navbar.style-2 .navbar-nav .nav-item .nav-link:hover {
  border-bottom: 2px solid var(--color-lightBlue);
}

.navbar.style-2 .navbar-nav .nav-item .nav-link.active::before,
.navbar.style-2 .navbar-nav .nav-item .nav-link:hover::before {
  height: 100%;
}

.navbar.style-2 .qoute-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar.style-2 .qoute-nav a {
  color: #fff;
}

.navbar.style-2 .qoute-nav a .cart-num {
  font-size: 9px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #000;
  background: var(--color-lightBlue);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.navbar.style-2 .dropdown-menu {
  background: #348CB2;
  border-radius: 0;
}

.navbar.style-2 .dropdown-menu .dropdown-item {
  color: #fff;
  padding: 8px 15px;
}

.navbar.style-2 .dropdown-menu .dropdown-item.active,
.navbar.style-2 .dropdown-menu .dropdown-item:active,
.navbar.style-2 .dropdown-menu .dropdown-item:hover {
  background-color: #010049;
}

.navbar.style-3 {
  padding: 20px 0;
}

.navbar.style-3.nav-scroll {
  background-color: #4A00E1;
}

.navbar.style-3 .navbar-brand img {
  width: 155px;
  -o-object-fit: contain;
  object-fit: contain;
}

.navbar.style-3 .navbar-nav .nav-item .nav-link {
  font-size: 11px;
  color: #fff;
  margin: 0 5px;
  padding: 10px 20px;
  border-radius: 30px;
}

.navbar.style-3 .navbar-nav .nav-item .nav-link.active,
.navbar.style-3 .navbar-nav .nav-item .nav-link:hover {
  background-color: #0002;
}

.navbar.style-3 .nav-side .search-icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #fff6;
  color: #fff;
  font-size: 12px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.navbar.style-4 {
  position: relative;
  z-index: 6;
  padding: 8px 15px;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: -38px;
}

.navbar.style-4 .navbar-brand {
  width: 70px;
  -o-object-fit: contain;
  object-fit: contain;
}

.navbar.style-4 .navbar-nav .nav-item .nav-link {
  position: relative;
  color: #000;
  font-size: 12px;
  font-weight: bold;
  margin: 0 5px;
}

.navbar.style-4 .navbar-nav .nav-item .nav-link::before {
  position: absolute;
  content: "";
  top: -30px;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-20px);
  -ms-transform: translateX(-50%) translateY(-20px);
  transform: translateX(-50%) translateY(-20px);
  width: 55px;
  height: 30px;
  /* background-image: url(../img/icons/nav_icon/active_s4.png); */
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.navbar.style-4 .navbar-nav .nav-item .nav-link:hover,
.navbar.style-4 .navbar-nav .nav-item .nav-link.active {
  color: var(--color-blue4);
}

.navbar.style-4 .navbar-nav .nav-item .nav-link:hover::before,
.navbar.style-4 .navbar-nav .nav-item .nav-link.active::before {
  opacity: 1;
  -webkit-transform: translateX(-50%) translateY(0);
  -ms-transform: translateX(-50%) translateY(0);
  transform: translateX(-50%) translateY(0);
}

.navbar.style-4 .navbar-nav .nav-item .nav-link .hot {
  position: absolute;
  font-size: 8px;
  padding: 2px 3px;
  border-radius: 3px;
  top: -10px;
  right: 0;
  text-transform: uppercase;
}

.navbar.style-4 .nav-side .search-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #0002;
  color: #000;
  font-size: 16px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.navbar.style-5 {
  position: relative;
  z-index: 99;
  padding: 20px 0;
  background-color: #e7f1ff;
}

.navbar.style-5 .container-fluid {
  padding: 0 3vw;
}

.navbar.style-5 .navbar-brand img {
  max-width: 150px;
  -o-object-fit: contain;
  object-fit: contain;
}

.navbar.style-5 .nav-item .nav-link {
  position: relative;
  color: #000;
  font-size: 12px;
  font-weight: bold;
  margin: 0 15px;
  text-transform: capitalize;
}

.navbar.style-5 .nav-item .nav-link:hover,
.navbar.style-5 .nav-item .nav-link.active {
  color: var(--color-blue5);
}

.navbar.style-5 .nav-item .nav-link .bi {
  position: relative;
  bottom: -5px;
}

.navbar.style-5 .nav-side .search-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #0002;
  color: #000;
  font-size: 16px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.navbar.style-6 {
  position: absolute;
  z-index: 99;
  padding: 20px 0;
  background-color: #dee0f2;
  width: 100%;
  border-radius: 30px;
  top: 0;
  left: 0;
}

.navbar.style-6 .container-fluid {
  padding: 0 3vw;
}

.navbar.style-6 .navbar-brand img {
  max-width: 150px;
  -o-object-fit: contain;
  object-fit: contain;
}

.navbar.style-6 .nav-item .nav-link {
  position: relative;
  color: #000;
  font-size: 12px;
  font-weight: bold;
  margin: 0 15px;
  text-transform: capitalize;
}

.navbar.style-6 .nav-item .nav-link::after {
  position: absolute;
  content: "";
  right: -16px;
  top: 16px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #bebccd;
}

.navbar.style-6 .nav-item .nav-link:hover,
.navbar.style-6 .nav-item .nav-link.active {
  color: var(--color-blue6);
}

.navbar.style-6 .nav-item:last-of-type .nav-link::after {
  display: none;
}

/* ===============================================================
              [ * header Section ] 
  ================================================================*/
/* --------------- header styles --------------- */
header.style-1 {
  position: relative;
}

header.style-1 .head-shape-r {
  position: absolute;
  right: 0;
  top: 8%;
  width: 55%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: right;
  object-position: right;
}

header.style-1 .head-shape-l {
  position: absolute;
  left: 0;
  top: 25%;
  height: 40%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: left;
  object-position: left;
}

header.style-1 .content {
  position: relative;
  z-index: 10;
}

header.style-1 .info .section-head h2 {
  font-size: 55px;
}

header.style-1 .info .text {
  font-size: 14px;
  color: #666666;
  width: 80%;
}

header.style-1 .info .bttns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

header.style-1 .info .bttns .vid-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 30px;
}

header.style-1 .info .bttns .vid-btn i {
  width: 35px;
  height: 35px;
  border-radius: 30px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: var(--color-main-grad);
  color: #fff;
  margin-right: 15px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 22px;
  padding-left: 2px;
  outline: 6px solid #157aa620;
}

header.style-1 .info .bttns .vid-btn span {
  color: var(--color-main);
  font-size: 12px;
  font-weight: bold;
}

header.style-2 {
  position: relative;
  min-height: 100vh;
  padding: 185px 0 100px;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(-40%, #062063), color-stop(40%, #157aa6));
  background: -webkit-linear-gradient(bottom, #062063 -40%, #157aa6 40%);
  background: -o-linear-gradient(bottom, #062063 -40%, #157aa6 40%);
  background: linear-gradient(to top, #062063 -40%, #157aa6 40%);
}

header.style-2 .head_shape2 {
  position: absolute;
  bottom: -5%;
  left: -5%;
  width: 110%;
  max-width: unset;
  height: 115%;
  max-height: none;
}

header.style-2 .content {
  position: relative;
  z-index: 5;
}

header.style-2 .content h1 {
  font-size: 123px;
  letter-spacing: 75px;
  display: inline-block;
  padding-left: 75px;
}

header.style-2 .content h4 {
  font-weight: 600;
}

header.style-2 .content p {
  font-size: 20px;
}

header.style-2 .content p.d-block {
  font-size: 16px;
}

header.style-2 .content .vid-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 100px;
}

header.style-2 .content .vid-btn i {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: var(--color-lightBlue);
  color: #fff;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 22px;
  padding-left: 2px;
}

header.style-2 .content .brands {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  z-index: 5;
}

header.style-3 {
  background-color: var(--color-blue2);
  position: relative;
  padding: 100px 0 30px;
}

header.style-3::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 60%;
  /* background-image: url(../img/0011.png); */
  background-size: 80%;
  background-repeat: repeat;
  background-position: bottom;
  opacity: 0.08;
  -webkit-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(1003%) contrast(103%);
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(1003%) contrast(103%);
}

header.style-3 .main-img {
  position: absolute;
  right: 0;
  top: 200px;
  width: 55%;
  height: calc(100% - 300px);
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: right;
  object-position: right;
}

header.style-3 .main-img .pattern {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

header.style-3 .main-img .circle {
  position: absolute;
  width: 55%;
  top: 10.5%;
  right: 20%;
  -webkit-animation: rotate-center 100s linear infinite both;
  animation: rotate-center 100s linear infinite both;
}

header.style-3 .main-img .logo_shap {
  position: absolute;
  top: 37%;
  right: 39%;
  width: 17%;
}

header.style-3 .content .info .h1 {
  font-size: 55px;
  font-weight: 700;
  color: #fff;
  line-height: 1.2;
}

header.style-3 .content .info .h1 span {
  font-weight: 400;
  position: relative;
  font-style: italic;
}

header.style-3 .content .info .h1 span::before {
  position: absolute;
  content: "";
  left: 0;
  top: 100%;
  width: 160px;
  height: 30px;
  /* background-image: url(../img/header/info_h1_line.png); */
  background-size: 160px;
  background-repeat: no-repeat;
  background-position: left;
}

header.style-3 .content .info .p {
  color: #ccccff;
  margin-top: 40px;
}

header.style-3 .content .info .h5 {
  color: #fffefe;
  font-size: 16px;
  font-weight: bold;
  margin-top: 80px;
  position: relative;
}

header.style-3 .content .info .h5::before {
  position: absolute;
  content: "";
  left: -65px;
  bottom: -10px;
  width: 50px;
  height: 100px;
  /* background-image: url(../img/header/head3_arrow.png); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

header.style-3 .content .info .form-group .form-control,
header.style-3 .content .info .form-group .form-select {
  padding: 12px 20px;
  font-size: 12px;
  border: 0;
  border-radius: 30px;
}

header.style-3 .content .info .form-group.input-with-icon {
  position: relative;
}

header.style-3 .content .info .form-group.input-with-icon .form-control {
  padding: 12px 20px 12px 40px;
}

header.style-3 .content .info .form-group.input-with-icon .input-icon {
  position: absolute;
  left: 20px;
  bottom: 10px;
  color: #999;
}

/* ------------ animation -------- */
header.style-4 {
  position: relative;
  padding: 60px 0;
}

header.style-4 .content {
  position: relative;
  overflow: hidden;
}

header.style-4 .content .info .title_small {
  font-size: 12px;
  padding: 5px 10px;
  background-color: #f7f4ff;
  border-radius: 5px;
  color: var(--color-blue4);
}

header.style-4 .content .info h1 {
  font-size: 52px;
  line-height: 60px;
  color: #000;
}

header.style-4 .content .info h1 span {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: -webkit-gradient(linear, left top, right top, from(#501e9c), color-stop(30%, #8169f1), color-stop(30%, #8169f1), color-stop(73%, #a44cee), to(#ff847f));
  background-image: -webkit-linear-gradient(left, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);
  background-image: -o-linear-gradient(left, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);
  background-image: linear-gradient(to right, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);
}

header.style-4 .content .info .text {
  color: #666;
  font-size: 15px;
}

header.style-4 .content .info .play-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

header.style-4 .content .info .play-btn .icon {
  width: 42px;
  height: 42px;
  border: 1px solid #5842bc99;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
}

header.style-4 .content .info .play-btn .icon i {
  color: var(--color-blue4);
  font-size: 16px;
}

header.style-4 .content .bubble {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-animation: rotate-center 100s linear infinite both;
  animation: rotate-center 100s linear infinite both;
}

header.style-4 .wave {
  position: absolute;
  left: -3%;
  bottom: -10px;
  width: 106%;
  max-width: unset;
  height: 240px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top;
  object-position: top;
  z-index: 20;
}

header.style-5 {
  position: relative;
  padding: 60px 0 80px;
  overflow: hidden;
  background: -moz-linear-gradient(bottom, #ffffff 0%, #edf4fe 74%, #e7f1ff 100%);
  background: -webkit-linear-gradient(bottom, #ffffff 0%, #edf4fe 74%, #e7f1ff 100%);
  background: -webkit-gradient(linear, left bottom, left top, from(#ffffff), color-stop(74%, #edf4fe), to(#e7f1ff));
  background: -o-linear-gradient(bottom, #ffffff 0%, #edf4fe 74%, #e7f1ff 100%);
  background: linear-gradient(to top, #ffffff 0%, #edf4fe 74%, #e7f1ff 100%);
}

header.style-5 .info {
  text-align: center;
}

header.style-5 .info h1 {
  font-size: 60px;
  color: #000;
  line-height: 1.2;
  letter-spacing: -2px;
}

header.style-5 .info h1 span {
  position: relative;
}

header.style-5 .info h1 span .head-line {
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
}

header.style-5 .info h1 span .head-pen {
  position: absolute;
  left: 102%;
  bottom: -5px;
}

header.style-5 .info p {
  font-size: 15px;
  color: #666;
  margin-top: 30px;
}

header.style-5 .info .form {
  text-align: center;
}

header.style-5 .info .form .form-group {
  position: relative;
  max-width: 500px;
  margin: 40px auto;
}

header.style-5 .info .form .form-group .icon {
  position: absolute;
  left: 25px;
  bottom: 13px;
  font-size: 18px;
}

header.style-5 .info .form .form-group input {
  width: 100%;
  padding: 16px 180px 16px 60px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 30px;
}

header.style-5 .info .form .form-group .btn {
  position: absolute;
  right: 8px;
  top: 7px;
}

header.style-5 .main-img {
  margin-top: 85px;
  position: relative;
  z-index: 10;
}

header.style-5 .main-img .page-img {
  -webkit-box-shadow: 0px -13px 124px 0px rgba(75, 83, 97, 0.15);
  box-shadow: 0px -13px 124px 0px rgba(75, 83, 97, 0.15);
  border-radius: 15px;
}

header.style-5 .main-img .linechart-img {
  position: absolute;
  top: 8%;
  right: 7%;
  width: 50%;
  -webkit-animation: scale_up_down 1.5s ease-in-out infinite alternate both;
  animation: scale_up_down 1.5s ease-in-out infinite alternate both;
}

header.style-5 .main-img .piechart-img {
  position: absolute;
  bottom: 10%;
  right: 10%;
  width: 15%;
  -webkit-filter: drop-shadow(0 20px 60px #0005);
  filter: drop-shadow(0 20px 60px #0005);
  -webkit-animation: rotate-center 50s linear infinite both;
  animation: rotate-center 50s linear infinite both;
}

header.style-5 .handl-img {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30%;
  z-index: 15;
  -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
  animation: slide_up_down 2s ease-in-out infinite alternate both;
}

header.style-5 .handr-img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40%;
  z-index: 0;
  -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
  animation: slide_up_down 2s ease-in-out infinite alternate both;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

header.style-6 {
  position: relative;
  padding: 30px 30px 0;
  overflow: hidden;
}

header.style-6 .content {
  background-color: #dee0f2;
  position: relative;
  padding: 150px 0 100px;
  border-radius: 30px;
}

header.style-6 .info {
  text-align: center;
  position: relative;
  z-index: 5;
}

header.style-6 .info h6 {
  color: #666;
  font-size: 18px;
  letter-spacing: 5px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

header.style-6 .info h1 {
  font-size: 85px;
  color: #000;
  line-height: 1.1;
}

header.style-6 .info h1 span {
  color: #fff;
  position: relative;
  padding: 10px 40px;
}

header.style-6 .info h1 span small {
  color: #fff;
  position: relative;
  z-index: 2;
  font-size: 85px;
}

header.style-6 .info h1 span::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 10px 30px;
  /* background-image: url(../img/shap_style_6.png); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

header.style-6 .info .text {
  font-size: 14px;
  color: #666;
  margin-top: 50px;
}

header.style-6 .info .form {
  background-color: #fff;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 10px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 70px;
}

header.style-6 .info .form .form-group {
  width: 38%;
  border-right: 1px solid #9994;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 15px;
}

header.style-6 .info .form .form-group:last-of-type {
  border: 0;
}

header.style-6 .info .form .form-group input {
  background-color: #fff;
  border: 0;
  color: #000;
  font-size: 12px;
  width: 100%;
}

header.style-6 .info .form .form-group input::-webkit-input-placeholder {
  opacity: 0.7;
}

header.style-6 .info .form .form-group input:-ms-input-placeholder {
  opacity: 0.7;
}

header.style-6 .info .form .form-group input::-ms-input-placeholder {
  opacity: 0.7;
}

header.style-6 .info .form .form-group input::placeholder {
  opacity: 0.7;
}

header.style-6 .hand-mega {
  position: absolute;
  width: 20%;
  top: 20%;
  left: 0;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: left;
  object-position: left;
  z-index: 2;
}

header.style-6 .head6-rating {
  position: absolute;
  width: 18%;
  bottom: 27%;
  left: 15%;
  -o-object-fit: contain;
  object-fit: contain;
  z-index: 2;
}

header.style-6 .target-3d {
  position: absolute;
  width: 20%;
  bottom: -120px;
  left: 35%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: left;
  object-position: left;
  z-index: 2;
}

header.style-6 .head6-charts {
  position: absolute;
  width: 14%;
  top: 20%;
  right: 15%;
  -o-object-fit: contain;
  object-fit: contain;
  z-index: 2;
}

header.style-6 .head6-rocket {
  position: absolute;
  width: 30%;
  bottom: 5%;
  right: -30px;
  z-index: 2;
}

/* ===============================================================
              [ * about Section ] 
  ================================================================*/
/* --------------- about styles --------------- */
.about.style-1 {
  position: relative;
}

.about.style-1 .content {
  padding: 65px 120px;
  background: var(--color-main-grad);
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  z-index: 5;
}

.about.style-1 .content .about_shap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 30%;
  pointer-events: none;
}

.about.style-1 .content .about_logos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 40px;
  border-bottom: 1px solid #fff4;
  position: relative;
  z-index: 5;
}

.about.style-1 .content .about-info {
  padding: 60px 0;
  position: relative;
  z-index: 5;
}

.about.style-1 .content .about-info .title h3 {
  color: #fff;
  font-weight: 600;
  font-size: 30px;
}

.about.style-1 .content .about-info .title small {
  color: #fff;
  text-transform: uppercase;
  font-size: 11px;
  margin-top: 35px;
  display: block;
}

.about.style-1 .content .about-info .info h6 {
  color: #fff;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 25px;
}

.about.style-1 .content .about-info .info p {
  font-size: 14px;
  color: #c7e9ff;
}

.about.style-1 .content .about-info .info .butn {
  margin-top: 50px;
}

.about.style-1 .content .about-numbers {
  position: relative;
  z-index: 5;
}

.about.style-1 .content .about-numbers .num-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about.style-1 .content .about-numbers .num-item .num {
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 15px;
  min-width: 70px;
  text-align: center;
}

.about.style-1 .content .about-numbers .num-item .num i {
  font-size: 21px;
}

.about.style-1 .content .about-numbers .num-item .inf {
  color: #fff;
}

.about.style-3 {
  position: relative;
}

.about.style-3 .top-content {
  min-height: 510px;
  margin-bottom: 100px;
  padding-top: 20px;
}

.about.style-3 .info h5 {
  font-size: 19px;
  line-height: 28px;
  font-style: italic;
  margin-bottom: 25px;
}

.about.style-3 .info .text {
  color: #666;
}

.about.style-3 .info ul li {
  font-size: 14px;
  font-weight: 600;
  margin: 15px 0;
}

.about.style-3 .info ul li i {
  color: var(--color-blue2);
}

.about.style-3 .img-left {
  position: absolute;
  left: 0;
  top: 190px;
  max-width: 50%;
}

.about.style-3 .img-left .info-circle {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -40%);
  -ms-transform: translate(-50%, -40%);
  transform: translate(-50%, -40%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width: 230px;
  height: 230px;
  background-color: #4a00e1;
  color: #fff;
  border-radius: 50%;
  overflow: hidden;
  outline: 10px solid #fff;
}

.about.style-3 .img-left .info-circle h2 {
  font-size: 56px;
}

.about.style-3 .img-left .info-circle small {
  font-size: 11px;
  text-transform: uppercase;
}

.about.style-3 .img-left .info-circle:nth-of-type(2) {
  width: 200px;
  height: 200px;
  left: auto;
  right: 0;
  top: 50%;
  -webkit-transform: translate(-14%, -57%);
  -ms-transform: translate(-14%, -57%);
  transform: translate(-14%, -57%);
  z-index: 5;
}

.about.style-3 .img-left .info-circle:nth-of-type(2) h2 {
  font-size: 50px;
}

.about.style-3 .img-left .info-circle:nth-of-type(3) {
  width: 150px;
  height: 150px;
  left: auto;
  right: 0;
  top: 50%;
  -webkit-transform: translate(-88%, 32%);
  -ms-transform: translate(-88%, 32%);
  transform: translate(-88%, 32%);
  background-color: #8d3dfa;
  z-index: 0;
}

.about.style-3 .img-left .info-circle:nth-of-type(3) h2 {
  font-size: 37px;
}

.about.style-3 .img-left .info-circle:nth-of-type(4) {
  width: 180px;
  height: 180px;
  left: 0;
  top: 50%;
  -webkit-transform: translate(20%, -20%);
  -ms-transform: translate(20%, -20%);
  transform: translate(20%, -20%);
  background-color: #00bcf2;
  z-index: 0;
}

.about.style-3 .img-left .info-circle:nth-of-type(4) h2 {
  font-size: 46px;
}

.about.style-3 .btm-content {
  position: relative;
  min-height: 510px;
  padding-top: 50px;
}

.about.style-3 .btm-content .img-right {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 60%;
}

.about.style-4 {
  overflow: hidden;
  position: relative;
}

.about.style-4 .top-wave {
  position: absolute;
  top: -2px;
  width: 100%;
}

.about.style-4 .bottom-wave {
  position: absolute;
  bottom: -2px;
  width: 100%;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.about.style-4 .content {
  position: relative;
  padding: 60px 0;
}

.about.style-4 .content ul li h6 {
  font-size: 15px;
}

.about.style-4 .content.frs-content .lines,
.about.style-4 .content.trd-content .lines {
  position: absolute;
  left: 0;
  top: -80px;
  width: 40%;
  max-width: unset;
  max-height: unset;
}

.about.style-4 .content.frs-content .bubble,
.about.style-4 .content.trd-content .bubble {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  max-width: unset;
  max-height: unset;
  -webkit-animation: rotate-center 100s linear infinite both;
  animation: rotate-center 100s linear infinite both;
}

.about.style-4 .content.sec-content .bubble2 {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  max-width: unset;
  max-height: unset;
  -webkit-animation: rotate-center 100s linear infinite both;
  animation: rotate-center 100s linear infinite both;
}

.about.style-4 .btn-img {
  text-align: initial;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background-color: #f4f2fb;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about.style-4 .btn-img .icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid #0001;
  width: 45px;
}

.about.style-4 .btn-img .inf {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.about.style-4 .btn-img .inf small {
  font-size: 10px;
  color: #666;
}

.about.style-4 .btn-img .inf h6 {
  font-size: 12px;
  color: #000;
  font-weight: bold;
  line-height: 1.5;
}

.about.style-4 .integration {
  position: relative;
}

.about.style-4 .integration .intg-back {
  position: absolute;
  left: 0;
  bottom: 19%;
  width: 100%;
  max-height: unset;
  -o-object-fit: cover;
  object-fit: cover;
}

.about.style-4 .integration .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}

.about.style-4 .integration .content .img {
  -webkit-animation: slide_up_down 1.7s ease-in-out infinite alternate both;
  animation: slide_up_down 1.7s ease-in-out infinite alternate both;
}

.about.style-4 .integration .content .img:nth-of-type(1) {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.about.style-4 .integration .content .img:nth-of-type(2) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.about.style-4 .integration .content .img:nth-of-type(3) {
  -webkit-animation-delay: -1.5s;
  animation-delay: -1.5s;
}

.about.style-4 .integration .content .img:nth-of-type(4) {
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.about.style-4 .integration .content .img:nth-of-type(5) {
  -webkit-animation-delay: -2.5s;
  animation-delay: -2.5s;
}

.about.style-5 .content {
  padding: 50px 0;
}

.about.style-5 .content p {
  font-size: 12.5px;
  color: #777;
  line-height: 2;
}

.about.style-5 .content .line-links {
  margin-top: 35px;
}

.about.style-5 .content .line-links a {
  position: relative;
  color: #000;
  font-size: 15px;
  font-weight: bold;
  border-bottom: 1px solid #9993;
  padding: 13px 0;
  display: block;
  width: 100%;
}

.about.style-5 .content .line-links a:last-of-type {
  border-bottom: 0;
}

.about.style-5 .content .line-links a::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 1px;
  width: 0%;
  background-color: var(--color-blue5);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.about.style-5 .content .line-links a:hover::after {
  width: 100%;
}

.about.style-5 .content .list-icon {
  margin-top: 40px;
}

.about.style-5 .content .list-icon li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
}

.about.style-5 .content .list-icon li .icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--color-blue5);
  color: #fff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 15px;
}

.about.style-5 .content .list-icon li h6 {
  font-size: 15px;
  font-weight: 700;
}

.about.style-5 .content .img {
  position: relative;
  text-align: right;
}

.about.style-5 .content .img.main-img1 .img-body {
  position: relative;
  z-index: 2;
  top: 50px;
  -webkit-animation: slide_up_down 3s ease-in-out infinite alternate both;
  animation: slide_up_down 3s ease-in-out infinite alternate both;
}

.about.style-5 .content .img.main-img1 img {
  position: absolute;
  -o-object-fit: contain;
  object-fit: contain;
}

.about.style-5 .content .img.main-img1 img.sm-circle {
  right: -50px;
  top: 20%;
  z-index: 1;
  -webkit-animation: rotate-center 20s linear infinite both reverse;
  animation: rotate-center 20s linear infinite both reverse;
}

.about.style-5 .content .img.main-img1 img.lg-circle {
  left: 0;
  top: 0;
  z-index: 1;
  -webkit-animation: rotate-center 50s linear infinite both;
  animation: rotate-center 50s linear infinite both;
}

.about.style-5 .content .img.main-img1 img.card1 {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 3;
  -webkit-animation: scale_up_down 10s ease-in-out infinite alternate both;
  animation: scale_up_down 10s ease-in-out infinite alternate both;
}

.about.style-5 .content .img.main-img1 img.card2 {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  -webkit-animation: scale_up_down 7s ease-in-out infinite alternate both;
  animation: scale_up_down 7s ease-in-out infinite alternate both;
  -webkit-transition-delay: -5s;
  -o-transition-delay: -5s;
  transition-delay: -5s;
}

.about.style-5 .content .img.main-img2 .img-body {
  position: relative;
  z-index: 2;
  -webkit-animation: slide_up_down 3s ease-in-out infinite alternate both;
  animation: slide_up_down 3s ease-in-out infinite alternate both;
}

.about.style-5 .content .img.main-img2 img {
  position: absolute;
}

.about.style-5 .content .img.main-img2 img:nth-of-type(1) {
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  -webkit-animation: rotate-center 70s linear infinite both;
  animation: rotate-center 70s linear infinite both;
}

.about.style-5 .content .img.main-img3 {
  position: relative;
}

.about.style-5 .content .img.main-img3 .img-body {
  position: relative;
  z-index: 1;
  max-height: 500px;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-animation: rotate-center 100s linear infinite both;
  animation: rotate-center 100s linear infinite both;
}

.about.style-5 .content .img.main-img3 img {
  position: absolute;
  right: 0;
  top: 20%;
  width: 100%;
}

.about.style-5 .content .img.main-img3 img:nth-of-type(2) {
  z-index: 3;
  -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
  animation: slide_up_down 2s ease-in-out infinite alternate both;
}

.about.style-5 .content .img.main-img3 img:nth-of-type(3) {
  z-index: 1;
  -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
  animation: slide_up_down 2s ease-in-out infinite alternate both;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.about.style-5 .content .img.main-img3 img:nth-of-type(4) {
  z-index: 2;
  -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
  animation: slide_up_down 2s ease-in-out infinite alternate both;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.about.style-5 .content .img.main-img3 img:nth-of-type(5) {
  z-index: 4;
  -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
  animation: slide_up_down 2s ease-in-out infinite alternate both;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.about.style-6 {
  position: relative;
}

.about.style-6 .content {
  border-bottom: 1px solid #9994;
}

.about.style-6 .content .info .text {
  color: #666;
  font-size: 15px;
}

.about.style-6 .content .info .vid-btn {
  font-size: 11px;
  font-weight: bold;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about.style-6 .content .info .vid-btn i {
  width: 40px;
  height: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--color-blue6);
  color: var(--color-blue6);
  font-size: 21px;
  margin-right: 5px;
}

.about.style-6 .bubbles {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50%;
  -o-object-fit: contain;
  object-fit: contain;
}

/* ===============================================================
              [ * services Section ] 
  ================================================================*/
/* --------------- services styles --------------- */
.services.style-1 {
  position: relative;
}

.services.style-1 .ser_shap_l {
  position: absolute;
  left: 0;
  bottom: 30%;
  z-index: -1;
  -webkit-transition: all 5s ease;
  -o-transition: all 5s ease;
  transition: all 5s ease;
}

.services.style-1 .ser_shap_r {
  position: absolute;
  right: 0;
  top: 25%;
}

.services.style-1 .service-box {
  position: relative;
  z-index: 5;
  padding: 30px;
  border: 1px solid #9993;
  border-radius: 8px;
  display: block;
  background-color: #fff;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.services.style-1 .service-box h5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 35px;
}

.services.style-1 .service-box h5 a {
  color: #000;
  font-weight: bold;
}

.services.style-1 .service-box h5 .num {
  color: var(--color-main);
  font-size: 12px;
}

.services.style-1 .service-box .icon {
  margin-bottom: 35px;
}

.services.style-1 .service-box .icon img {
  width: 60px;
  height: 60px;
  -o-object-fit: cover;
  object-fit: cover;
}

.services.style-1 .service-box .info .text {
  color: #666;
  margin-bottom: 20px;
  font-size: 13px;
}

.services.style-1 .service-box .info .tags a {
  font-size: 11px;
  padding: 3px 7px;
  border-radius: 4px;
  background-color: #eef4f8;
  margin-top: 10px;
}

.services.style-1 .service-box:hover {
  border-color: transparent;
  -webkit-box-shadow: 0px 54px 115px 0px rgba(52, 60, 66, 0.25);
  box-shadow: 0px 54px 115px 0px rgba(52, 60, 66, 0.25);
}

.services.style-1 .service-box:hover h5 a {
  color: var(--color-main);
}

.tags.style-2 a {
  font-size: 11px;
  padding: 3px 7px;
  border-radius: 4px;
  background-color: #131172;
  margin: 3px;
}

.services_projects {
  /* background-image: url(../img/services_work.png); */
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.services_projects .swiper-button-next,
.services_projects .swiper-button-prev {
  width: 50px;
  height: 50px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.services_projects .swiper-button-next:hover,
.services_projects .swiper-button-prev:hover {
  background: var(--color-lightBlue) !important;
}

.swiper-slide-duplicate-prev .service-box {
  border: 0 !important;
}

.services.style-3 {
  background-color: #f0eff5;
}

.services.style-3 .service-card.style-3 {
  padding: 30px;
  background-color: #fff;
  border-radius: 12px;
  height: 100%;
}

.services.style-3 .service-card.style-3 .icon {
  margin-bottom: 60px;
}

.services.style-3 .service-card.style-3 .icon img {
  width: 125px;
  height: 120px;
  -o-object-fit: contain;
  object-fit: contain;
}

.services.style-3 .service-card.style-3 .info .title {
  font-size: 18px;
  color: #000;
  text-transform: capitalize;
  margin-bottom: 20px;
  font-weight: bold;
}

.services.style-3 .service-card.style-3 .info .text {
  font-size: 13px;
  color: #666;
}

.services.style-3 .service-card.style-3 .info a {
  margin-top: 30px;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
}

.services.style-3 .service-card.style-3 .info a:hover {
  color: var(--color-blue2);
}

.services-slider.style-6 .swiper-container {
  padding-bottom: 100px;
}

.services-slider.style-6 .swiper-slide {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  top: 0;
}

.services-slider.style-6 .swiper-slide.swiper-slide-nth-next-2 {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  top: 100px;
  padding: 0 9px;
}

.services-slider.style-6 .swiper-slide.swiper-slide-next {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  top: 50px;
  padding: 0 12px;
}

.services-slider.style-6 .swiper-slide.swiper-slide-active {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  padding: 0 20px;
  -webkit-filter: drop-shadow(5px 50px 30px #07397211);
  filter: drop-shadow(5px 50px 30px #07397211);
}

.services-slider.style-6 .swiper-slide.swiper-slide-prev {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  top: 50px;
  padding: 0 12px;
}

.services-slider.style-6 .swiper-slide.swiper-slide-nth-prev-2 {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  top: 100px;
  padding: 0 9px;
}

.service-card.style-6 {
  text-align: center;
  padding: 30px 8%;
  background-color: #f1f2fa;
  border-radius: 30px;
  overflow: hidden;
  margin: 20px 0;
  display: block;
  color: #000;
}

.service-card.style-6 .icon {
  -webkit-filter: drop-shadow(5px 15px 20px #07397255);
  filter: drop-shadow(5px 15px 20px #07397255);
  margin-bottom: 25px;
}

.service-card.style-6 .icon img {
  max-height: 100px;
  max-width: 100px;
  -o-object-fit: contain;
  object-fit: contain;
}

.service-card.style-6 .info h5 {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 15px;
}

.service-card.style-6 .info .text {
  font-size: 11px;
  color: #666;
}

/* ===============================================================
              [ * choose-us Section ] 
  ================================================================*/
/* --------------- choose-usstyles --------------- */
.choose-us.style-1 {
  position: relative;
}

.choose-us.style-1 .choose-us-img {
  position: absolute;
  left: -65px;
  bottom: -150px;
  width: 60%;
  height: 120%;
  max-height: none;
  -webkit-animation: rotate-center 100s linear infinite both;
  animation: rotate-center 100s linear infinite both;
}

.choose-us.style-1 .info .text {
  color: #666;
  margin-bottom: 40px;
}

.choose-us.style-1 .info ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}

.choose-us.style-1 .info ul li .icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--color-main-grad);
  color: #fff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 15px;
}

.choose-us.style-1 .info ul li h6 {
  font-size: 15px;
  font-weight: 600;
}

.choose-us.style-1 .info .btn {
  margin-top: 60px;
}

.choose-us.style-1 .choose-us-brands {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
  animation: slide_up_down 2s ease-in-out infinite alternate both;
  z-index: 5;
}

.choose-us.style-1 .choose-us-bubbles {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 45%;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
  animation: slide_up_down 2s ease-in-out infinite alternate both;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  z-index: 3;
}

.choose-us.style-2 .img img {
  -webkit-animation: rotate-center 100s linear infinite both;
  animation: rotate-center 100s linear infinite both;
}

.choose-us.style-2 .info h5 {
  font-weight: 600;
}

.choose-us.style-2 .choose-numbers {
  position: relative;
  z-index: 5;
}

.choose-us.style-2 .choose-numbers .num-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.choose-us.style-2 .choose-numbers .num-item .num {
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 20px;
  min-width: 70px;
  text-align: center;
}

.choose-us.style-2 .choose-numbers .num-item .num i {
  font-size: 21px;
}

.choose-us.style-2 .choose-numbers .num-item .inf {
  color: #ccc;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1.7;
}

/* --------------- choose-us style-6 --------------- */
.choose-us.style-6 {
  position: relative;
}

.choose-us.style-6 .section-head.style-6 h2 {
  font-size: 40px;
}

.choose-us.style-6 .info ul li .inf h5 {
  font-size: 18px;
  font-weight: bold;
  color: #000;
  text-transform: capitalize;
}

.choose-us.style-6 .img {
  padding-top: 50px;
  margin-left: -200px;
}

.choose-us.style-6 .bubbles {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 200px);
}

/* ===============================================================
              [ * portfolio Section ] 
  ================================================================*/
/* --------------- portfolio styles --------------- */
.portfolio.style-1 {
  position: relative;
}

.portfolio.style-1 .shap_r {
  position: absolute;
  right: 0;
  top: 30px;
  width: 30%;
  opacity: 0.3;
  pointer-events: none;
}

.portfolio.style-1 .shap_l {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 40%;
  opacity: 1;
  pointer-events: none;
}

.portfolio.style-1 .portfolio-slider {
  position: relative;
  padding-bottom: 60px;
}

.portfolio.style-1 .portfolio-slider .swiper-button-next,
.portfolio.style-1 .portfolio-slider .swiper-button-prev {
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.portfolio.style-1 .portfolio-slider .swiper-button-next,
.portfolio.style-1 .portfolio-slider .swiper-container-rtl .swiper-button-prev {
  right: -70px;
}

.portfolio.style-1 .portfolio-slider .swiper-button-prev,
.portfolio.style-1 .portfolio-slider .swiper-container-rtl .swiper-button-next {
  left: -70px;
}

.portfolio.style-1 .portfolio-card {
  background-color: #fff;
  border-radius: 7px;
  overflow: hidden;
}

.portfolio.style-1 .portfolio-card .img {
  height: 240px;
  overflow: hidden;
}

.portfolio.style-1 .portfolio-card .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.portfolio.style-1 .portfolio-card .info {
  padding: 30px;
}

.portfolio.style-1 .portfolio-card .info h5 a {
  color: #000;
  font-weight: bold;
}

.portfolio.style-1 .portfolio-card .info small {
  font-size: 10px;
  letter-spacing: 1px;
  margin-top: 5px;
}

.portfolio.style-1 .portfolio-card .info .text {
  color: #666;
  margin: 15px 0 20px;
  font-size: 13px;
}

.portfolio.style-1 .portfolio-card .info .tags a {
  font-size: 11px;
  padding: 3px 7px;
  margin-top: 10px;
  border-radius: 4px;
  background-color: #eef4f8;
}

.portfolio.style-1 .portfolio-card:hover h5 a {
  color: var(--color-main);
}

.projects.style-3 {
  background-color: #f0eff5;
}

.project-card.style-3 {
  position: relative;
  overflow: hidden;
}

.project-card.style-3 .img {
  height: 360px;
  border-radius: 15px;
  overflow: hidden;
}

.project-card.style-3 .img img {
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.project-card.style-3 .info {
  position: absolute;
  z-index: 5;
  bottom: 20px;
  left: 20px;
  background-color: #fff;
  border-radius: 15px;
  display: inline-block;
  padding: 20px 25px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: translateY(150%);
  -ms-transform: translateY(150%);
  transform: translateY(150%);
  opacity: 0;
}

.project-card.style-3 .info .h5 {
  font-weight: bold;
  font-size: 19px;
  margin-bottom: 0;
  color: #000;
}

.project-card.style-3 .info .small {
  font-size: 11px;
  color: #999;
}

.project-card.style-3:hover .img img {
  -webkit-transform: scale(1.1) rotate(-5deg);
  -ms-transform: scale(1.1) rotate(-5deg);
  transform: scale(1.1) rotate(-5deg);
}

.project-card.style-3:hover .info {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

/* --------------- projects style-6 --------------- */
.projects.style-6 {
  padding: 0 30px;
}

.projects.style-6 .content {
  background-color: #f1f2fa;
  position: relative;
  padding: 100px 0;
  border-radius: 30px;
}

.project-card.style-6 {
  border-radius: 8px;
  overflow: hidden;
  display: block;
  margin: 0 15px;
}

.project-card.style-6:hover {
  -webkit-box-shadow: 0px 40px 35px 0px #51558622;
  box-shadow: 0px 40px 35px 0px #51558622;
}

.project-card.style-6 a:hover {
  color: var(--color-blue6);
}

.project-card.style-6 .img {
  height: 240px;
  overflow: hidden;
}

.project-card.style-6 .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.project-card.style-6 .info {
  background-color: #fff;
  padding: 40px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.project-card.style-6 .info .title {
  color: #000;
  font-size: 21px;
}

.project-card.style-6 .info .title:hover {
  color: var(--color-blue6);
}

.project-card.style-6 .info .title:hover a {
  color: var(--color-blue6);
}

.project-card.style-6 .info small {
  font-size: 11px;
  color: var(--color-blue6);
  text-transform: uppercase;
  margin-bottom: 10px;
}

.project-card.style-6 .info .text {
  font-size: 12px;
  color: #666;
  margin-bottom: 25px;
}

.project-card.style-6 .info .tags span {
  color: #010101;
  font-size: 10px;
  padding: 3px 7px;
  border-radius: 4px;
  background-color: #f1f2fa;
}

/* ===============================================================
              [ * testimonials Section ] 
  ================================================================*/
/* --------------- testimonials styles --------------- */
.testimonials.style-1 .content {
  position: relative;
  z-index: 5;
}

.testimonials.style-1 .content .vid_img {
  position: relative;
  height: 400px;
  min-height: 100%;
}

.testimonials.style-1 .content .vid_img::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left bottom, left top, from(#0007), to(#0001));
  background: -webkit-linear-gradient(bottom, #0007, #0001);
  background: -o-linear-gradient(bottom, #0007, #0001);
  background: linear-gradient(to top, #0007, #0001);
}

.testimonials.style-1 .content .vid_img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.testimonials.style-1 .content .vid_img .play_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--color-main-grad);
  z-index: 5;
}

.testimonials.style-1 .content .vid_img .play_icon i {
  color: #fff;
  font-size: 28px;
  margin-left: 3px;
}

.testimonials.style-1 .content .vid_img .img_info {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 30px;
  width: 100%;
  z-index: 5;
}

.testimonials.style-1 .content .vid_img .img_info h4 {
  color: #fff;
  font-weight: bold;
}

.testimonials.style-1 .content .vid_img .img_info small {
  color: #fff;
  display: block;
  font-size: 11px;
}

.testimonials.style-1 .content .info {
  position: relative;
  padding-left: 75px;
  min-height: 100%;
}

.testimonials.style-1 .content .info::after {
  position: absolute;
  content: "";
  left: 25px;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #9991;
}

.testimonials.style-1 .content .info .client_card {
  border: 1px solid #9994;
  border-radius: 5px;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
}

.testimonials.style-1 .content .info .client_card .user_img {
  margin-right: 25px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.testimonials.style-1 .content .info .client_card .user_img img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

.testimonials.style-1 .content .info .client_card .inf_content .rate_stars {
  margin-bottom: 5px;
}

.testimonials.style-1 .content .info .client_card .inf_content .rate_stars i {
  color: #ffb400;
  font-size: 10px;
}

.testimonials.style-1 .content .info .client_card .inf_content h6 {
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 15px;
}

.testimonials.style-1 .content .info .client_card .inf_content p {
  font-size: 12px;
  color: #000;
  text-transform: uppercase;
}

.testimonials.style-1 .content .info .client_card .inf_content p span {
  text-transform: capitalize;
}

.reviews.style-2 {
  position: relative;
}

.reviews.style-2 .rev_l {
  position: absolute;
  left: 0;
  top: 30%;
  width: 20%;
  -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
  animation: slide_up_down 2s ease-in-out infinite alternate both;
}

.reviews.style-2 .rev_r {
  position: absolute;
  right: 0;
  top: 30%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20%;
  -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
  animation: slide_up_down 2s ease-in-out infinite alternate both;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.swiper-slide .reviews_card.style-2 {
  opacity: 0;
  -webkit-transform: scale(0.8) translateY(20px);
  -ms-transform: scale(0.8) translateY(20px);
  transform: scale(0.8) translateY(20px);
  -webkit-transition: all 1.5s ease;
  -o-transition: all 1.5s ease;
  transition: all 1.5s ease;
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.swiper-slide.swiper-slide-active .reviews_card.style-2 {
  opacity: 1;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}

.reviews_card.style-2 .rev_user {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  outline: 1px solid var(--color-lightBlue);
  outline-offset: 5px;
  margin: 40px auto 25px;
}

.reviews_card.style-2 .rev_user img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.reviews_card.style-2 .rev_stars i {
  color: greenyellow;
}

.testimonials.style-3 {
  background-color: #f0eff5;
  margin-top: -30px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
  z-index: 5;
}

.testimonials.style-3 .testi_lines {
  position: absolute;
  bottom: -10px;
}

.testimonials.style-3 .testimonial-card.style-3 .text {
  font-size: 18px;
  line-height: 28px;
  min-height: 112px;
}

.testimonials.style-3 .testimonial-card.style-3 p {
  font-size: 13px;
  line-height: 1.1;
}

.testimonials.style-3 .testimonial-card.style-3 small {
  font-size: 11px;
}

.testimonials.style-3 .testimonial-slider.style-3 {
  position: relative;
}

.testimonials.style-3 .testimonial-slider.style-3 .swiper-button-next {
  top: -65px;
  right: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.testimonials.style-3 .testimonial-slider.style-3 .swiper-button-next::after {
  font-size: 12px;
}

.testimonials.style-3 .testimonial-slider.style-3 .swiper-button-next:hover {
  background: var(--color-blue2);
}

.testimonials.style-3 .testimonial-slider.style-3 .swiper-button-prev {
  top: -65px;
  left: auto;
  right: 40px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.testimonials.style-3 .testimonial-slider.style-3 .swiper-button-prev::after {
  font-size: 12px;
}

.testimonials.style-3 .testimonial-slider.style-3 .swiper-button-prev:hover {
  background: var(--color-blue2);
}

.testimonials.style-4 .content {
  border-bottom: 1px solid #9993;
  padding-bottom: 80px;
}

@media (max-width: 600px) {
  .testimonials.style-4 .content{
    padding-bottom: 10px !important;
  }
}


.testimonials.style-4 .content .numbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.testimonials.style-4 .content .numbs .num-card .icon {
  height: 55px;
  display: inline-block;
  margin-bottom: 15px;
}

.testimonials.style-4 .content .numbs .num-card h2 {
  color: var(--color-blue4);
}

.testimonials.style-4 .content .numbs .num-card p {
  font-size: 11px;
  color: #666;
  margin-top: 5px;
}

.testimonials.style-4 .content .numbs .num-card:not(:last-of-type) {
  padding-right: 50px;
  margin-right: 50px;
  border-right: 1px solid #9993;
}

.testimonials.style-4 .content .play-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonials.style-4 .content .play-btn .icon {
  width: 42px;
  height: 42px;
  border: 1px solid #5842bc99;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
}

.testimonials.style-4 .content .play-btn .icon i {
  color: var(--color-blue4);
  font-size: 16px;
}

.testimonials.style-4 .content .stars {
  font-size: 10px;
  color: #fdb900;
  margin-bottom: 5px;
}

.testimonials.style-4 .content .testi-cards {
  position: relative;
  padding: 0 5vw;
}

.testimonials.style-4 .content .testi-cards .client_card {
  position: relative;
  z-index: 10;
  border-radius: 15px;
  padding: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  -webkit-box-shadow: -4.104px 11.276px 38px 0px rgba(45, 42, 61, 0.1);
  box-shadow: -4.104px 11.276px 38px 0px rgba(45, 42, 61, 0.1);
  margin: 20px;
}

.testimonials.style-4 .content .testi-cards .client_card .user_img {
  margin-right: 25px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.testimonials.style-4 .content .testi-cards .client_card .user_img img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

.testimonials.style-4 .content .testi-cards .client_card .inf_content .rate_stars {
  margin-bottom: 5px;
}

.testimonials.style-4 .content .testi-cards .client_card .inf_content .rate_stars i {
  color: #ffb400;
  font-size: 10px;
}

.testimonials.style-4 .content .testi-cards .client_card .inf_content h6 {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 15px;
}

.testimonials.style-4 .content .testi-cards .client_card .inf_content p {
  font-size: 10px;
  color: #000;
  text-transform: uppercase;
}

.testimonials.style-4 .content .testi-cards .client_card .inf_content p span {
  text-transform: capitalize;
  color: #2E99F5;
}

.testimonials.style-4 .content .testi-cards .client_card:nth-of-type(2) {
  right: -5vw;
}

.testimonials.style-4 .content .testi-cards .testi-globe {
  position: absolute;
  top: -80px;
  left: 0;
  width: 110%;
  max-height: unset;
  max-width: unset;
  opacity: 0.2;
  -webkit-animation: rotate-center 100s linear infinite both;
  animation: rotate-center 100s linear infinite both;
}

.testi-card.style-5 {
  display: block;
  padding: 40px 30px;
  background-color: #fff;
  border-radius: 15px;
  text-align: center;
  margin: 0 15px;
}

.testi-card.style-5:hover {
  -webkit-box-shadow: 0px 30px 60px 0px #0a2b5326;
  box-shadow: 0px 30px 60px 0px #0a2b5326;
}

.testi-card.style-5:hover .card-title {
  color: var(--color-blue5);
}

.testi-card.style-5 .stars i {
  color: #27a700;
  font-size: 15px;
}

.testi-card.style-5 .text {
  font-size: 19px;
  line-height: 1.6;
  color: #000;
  margin-top: 15px;
}

.testi-card.style-5 .user h6 {
  margin: 15px auto 0;
  font-size: 14px;
  font-weight: bold;
}

.testi-card.style-5 .user small {
  font-size: 11px;
  color: #666;
}

.testimonials.style-6 {
  padding: 50px 0 120px;
}

.testimonials.style-6 .testi-slider.style-6 .icon {
  width: 95px;
  height: 95px;
  background-color: var(--color-blue6);
  color: #fff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 60% 40% 40% 40%;
  font-size: 35px;
}

.testimonials.style-6 .testi-slider.style-6 .testi-card .text {
  color: #000;
  font-size: 23px;
  line-height: 1.4;
}

.testimonials.style-6 .testi-slider.style-6 .swiper-button-next,
.testimonials.style-6 .testi-slider.style-6 .swiper-button-prev {
  background-color: #f1f2fa;
  width: 40px;
  height: 40px;
  top: 100%;
  margin-top: 40px;
}

.testimonials.style-6 .testi-slider.style-6 .swiper-button-next:hover,
.testimonials.style-6 .testi-slider.style-6 .swiper-button-prev:hover {
  background-color: var(--color-blue6);
}

.testimonials.style-6 .testi-slider.style-6 .swiper-button-next {
  right: auto;
  left: 50px;
}

.testimonials.style-6 .testi-slider.style-6 .swiper-button-prev {
  left: 0;
}

.testimonials.style-6 .img {
  position: relative;
  margin-top: 50px;
}

.testimonials.style-6 .img img {
  position: relative;
  z-index: 5;
}

.testimonials.style-6 .img .bubbls {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  z-index: 0;
}

/* ===============================================================
              [ * testimonials Section ] 
  ================================================================*/
/* --------------- pricing styles --------------- */
.pricing.style-2 {
  background: -webkit-gradient(linear, left top, left bottom, from(#010049), to(#157aa6));
  background: -webkit-linear-gradient(top, #010049, #157aa6);
  background: -o-linear-gradient(top, #010049, #157aa6);
  background: linear-gradient(to bottom, #010049, #157aa6);
  position: relative;
}

.pricing.style-2 .shap_l {
  position: absolute;
  left: 0;
  top: 20%;
  max-width: 30%;
}

.pricing.style-2 .shap_r {
  position: absolute;
  right: 0;
  top: 10%;
  height: 80%;
  max-width: 30%;
}

.pricing.style-2 .toggle_switch .form-check-label {
  pointer-events: none;
}

.pricing.style-2 .yearly_price,
.pricing.style-2 .monthly_price {
  display: none;
}

.pricing.style-2 .yearly_price.show,
.pricing.style-2 .monthly_price.show {
  display: block;
}

.pricing_card.style-2 {
  position: relative;
  text-align: center;
  color: #fff;
  padding: 85px 50px 50px;
  border: 1px solid #3d9dd1;
}

.pricing_card.style-2 p {
  text-transform: uppercase;
  margin: 30px 0 15px;
  font-size: 16px;
}

.pricing_card.style-2 .price .h2 small {
  font-size: 13px;
  position: relative;
  top: -12px;
}

.pricing_card.style-2 ul {
  min-height: 164px;
}

.pricing_card.style-2 ul li {
  border-color: #3d9dd181 !important;
}

.pricing_card.style-2 .hint {
  position: absolute;
  top: 25px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 11px;
  color: #fff;
  padding: 5px 8px;
  border-radius: 3px;
  background-color: #1f3f85;
  text-transform: capitalize;
}

.pricing_card.style-2.recommended::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 10px;
  background: var(--color-lightBlue);
}

.pricing.style-3 {
  position: relative;
}

.pricing.style-3 .testi_lines {
  position: absolute;
  bottom: -10px;
}

.pricing-card.style-3 {
  padding: 30px;
  border-radius: 20px;
  background-color: #f3f2f7;
}

.pricing-card.style-3 .card-head {
  border-bottom: 1px solid #9993;
  padding-bottom: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pricing-card.style-3 .card-head h4 {
  font-size: 19px;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pricing-card.style-3 .card-head h4 small {
  font-size: 10px;
  font-weight: 400;
  padding: 3px 5px;
  border-radius: 3px;
  color: #fff !important;
  background-color: #6e27ff;
  margin: 0 5px;
}

.pricing-card.style-3 .card-head small {
  font-size: 11px;
  color: #999999;
}

.pricing-card.style-3 .card-head .price {
  text-align: right;
}

.pricing-card.style-3 .card-head .price h5 {
  font-size: 20px;
  color: var(--color-blue2);
  font-weight: bold;
}

.pricing-card.style-3 .card-body {
  min-height: 180px;
  padding: 20px 0;
}

.pricing-card.style-3 .card-body li {
  line-height: 1.2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pricing-card.style-3 .card-body li small {
  font-size: 11px;
  margin: 5px 0;
}

.pricing-card.style-3 .card-body li i {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  color: var(--color-blue2);
  font-size: 20px;
  margin-right: 10px;
}

.pricing-card.style-3.dark-card {
  background-color: var(--color-blue2);
}

.pricing-card.style-3.dark-card .card-head {
  border-bottom: 1px solid #9999ff;
}

.pricing-card.style-3.dark-card .card-head h4,
.pricing-card.style-3.dark-card .card-head h5 {
  color: #fff;
}

.pricing-card.style-3.dark-card .card-head small {
  color: #9999ff;
}

.pricing-card.style-3.dark-card .card-body li {
  color: #fff;
}

.pricing-card.style-3.dark-card .card-body li i {
  color: #9999ff;
}

.pricing-card.style-3.dark-card .btn {
  background-color: #fff !important;
}

.pricing-card.style-3.dark-card .btn span {
  color: #000;
}

.pricing.style-4 .form-check-input.bg-blue4 {
  background-color: var(--color-blue4);
  border-color: var(--color-blue4);
}

.pricing.style-4 .content .row .col-lg-6:nth-of-type(1) .price-card {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  margin-right: -1px;
}

.pricing.style-4 .content .row .col-lg-6:nth-of-type(2) .price-card {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #f8f7fd;
}

.pricing.style-4 .content .price-card {
  position: relative;
  border: 1px solid #e9e8ee;
  border-radius: 15px;
  padding: 40px;
}

.pricing.style-4 .content .price-card .off {
  position: absolute;
  width: 85px;
  height: 85px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  /* background-image: url(../img/pricing/off.png); */
  background-size: contain;
  background-repeat: no-repeat;
  top: -20px;
  right: 45px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.pricing.style-4 .content .price-card .price-header {
  border-bottom: 1px solid #e9e8ee;
}

.pricing.style-4 .content .price-card .price-header h6 {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.pricing.style-4 .content .price-card .price-header h6 .icon {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.pricing.style-4 .content .price-card .price-header h2 {
  font-size: 37px;
  color: #000;
  margin: 12px 0 5px;
}

.pricing.style-4 .content .price-card .price-header h2 small {
  font-size: 15px;
  font-weight: 300;
}

.pricing.style-4 .content .price-card .price-header p {
  font-size: 12px;
  color: #666;
}

.pricing.style-5 .pricing-tabsHead .price-radios {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background-color: #edf2fa;
  padding: 7px;
  border-radius: 50px;
}

.pricing.style-5 .pricing-tabsHead .price-radios .form-check {
  margin: 0;
  padding: 0;
}

.pricing.style-5 .pricing-tabsHead .price-radios .form-check .form-check-label {
  color: #000;
  background-color: transparent;
  font-size: 12px;
  font-weight: bold;
  border-radius: 30px;
  padding: 10px 15px;
  text-transform: capitalize;
  cursor: pointer;
}

.pricing.style-5 .pricing-tabsHead .price-radios .form-check .form-check-label small {
  padding: 1px 5px;
  font-size: 10px;
}

.pricing.style-5 .pricing-tabsHead .price-radios .form-check .form-check-input {
  display: none;
}

.pricing.style-5 .pricing-tabsHead .price-radios .form-check .form-check-input:checked~.form-check-label {
  background-color: #fff;
  -webkit-box-shadow: 0 5px 5px #0001;
  box-shadow: 0 5px 5px #0001;
}

.pricing.style-5 .content {
  min-width: 800px;
  margin-top: 50px;
}

.pricing.style-5 .content .price-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #7f7f7f;
}

.pricing.style-5 .content .price-head .price-headTitle {
  width: 34%;
}

.pricing.style-5 .content .price-head .price-headItem {
  position: relative;
  width: 22%;
  text-align: center;
  padding: 30px 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.pricing.style-5 .content .price-head .price-headItem .label {
  position: absolute;
  top: -30px;
  right: 15px;
  z-index: 5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 60px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-size: 9px;
  font-weight: bold;
  /* background-image: url(../img/pricing/label_success.png); */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
}

.pricing.style-5 .content .price-head .price-headItem h6 {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.pricing.style-5 .content .price-head .price-headItem h2 {
  font-size: 45px;
  margin-bottom: 5px;
}

.pricing.style-5 .content .price-head .price-headItem h2 span {
  font-size: 18px;
  color: #999;
  font-weight: 400;
}

.pricing.style-5 .content .price-head .price-headItem small {
  font-size: 11px;
  color: #666;
  display: block;
  line-height: 1.5;
}

.pricing.style-5 .content .price-body .price-bodyItems {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #f2f2f2;
}

.pricing.style-5 .content .price-body .price-bodyItems:last-of-type {
  border: 0;
}

.pricing.style-5 .content .price-body .price-bodyItems .price-bodyTitle {
  width: 34%;
  padding: 15px 0;
  font-size: 14px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: -webkit-max-content;
  min-height: -moz-max-content;
  min-height: max-content;
  position: relative;
}

.pricing.style-5 .content .price-body .price-bodyItems .price-bodyTitle .pop-info {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.pricing.style-5 .content .price-body .price-bodyItems .price-bodyTitle .pop-info i.bttn-info {
  font-size: 11px;
  color: #999;
  margin: 0 3px;
}

.pricing.style-5 .content .price-body .price-bodyItems .price-bodyTitle .pop-info i.bttn-info:hover {
  color: var(--color-blue5);
}

.pricing.style-5 .content .price-body .price-bodyItems .price-bodyTitle .pop-info .hidden_content {
  position: absolute;
  bottom: 15px;
  left: 15px;
  padding: 20px;
  border-radius: 10px;
  background-color: #f7f7f7;
  text-align: center;
  width: 250px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  opacity: 0;
  visibility: hidden;
}

.pricing.style-5 .content .price-body .price-bodyItems .price-bodyTitle .pop-info .hidden_content .title {
  color: var(--color-blue5);
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 500;
}

.pricing.style-5 .content .price-body .price-bodyItems .price-bodyTitle .pop-info:hover .hidden_content {
  opacity: 1;
  visibility: visible;
}

.pricing.style-5 .content .price-body .price-bodyItems .price-item {
  width: 22%;
  padding: 15px 0;
  text-align: center;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: -webkit-max-content;
  min-height: -moz-max-content;
  min-height: max-content;
}

.pricing.style-5 .content .price-body .price-bodyItems .price-item i {
  color: var(--color-blue5);
  font-size: 20px;
}

.pricing.style-5 .content .price-body .price-bodyItems .price-item span {
  color: #000;
  font-size: 14px;
}

.pricing.style-5 .content .price-foot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pricing.style-5 .content .price-foot .price-footTitle {
  width: 34%;
}

.pricing.style-5 .content .price-foot .price-footItem {
  width: 22%;
  text-align: center;
  padding: 30px 0 50px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.pricing.style-5 .monthly_price {
  display: none;
}

/* ===============================================================
              [ * team Section ] 
  ================================================================*/
/* --------------- team styles --------------- */
.team.style-1 {
  position: relative;
}

.team.style-1 .team_shap {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: none;
  z-index: -1;
}

.team.style-1 .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.team.style-1 .content .team_box {
  width: 18%;
  text-align: center;
  margin-bottom: 20px;
}

.team.style-1 .content .team_box .avatar {
  width: 135px;
  height: 135px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 15px;
}

.team.style-1 .content .team_box .avatar img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.team.style-1 .content .team_box .info h6 {
  font-weight: bold;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.team.style-1 .content .team_box .info small {
  display: block;
  font-size: 13px;
  color: #888;
}

.team.style-1 .content .team_box .info .social_icons {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.team.style-1 .content .team_box .info .social_icons a {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #eef4f8;
  color: #666;
  margin: 5px;
}

.team.style-1 .content .team_box .info .social_icons a:hover {
  background: var(--color-main-grad);
  color: #fff;
}

.team.style-1 .content .team_box:hover .info h6 {
  color: var(--color-main);
}

.team.style-1.team-blue2 .content .team_box .social_icons a:hover {
  background: var(--color-blue2);
  color: #fff;
}

.team.style-1.team-blue2 .content .team_box:hover .info h6 {
  color: var(--color-blue2);
}

/* --------------- team style-6 --------------- */
.team.style-6 {
  padding: 0 30px;
}

.team.style-6 .content {
  background-color: #f1f2fa;
  position: relative;
  padding: 100px 0;
  border-radius: 30px;
}

.team-card.style-6 {
  text-align: center;
  padding: 15px;
  background-color: #fff;
  border-radius: 20px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.team-card.style-6 .img {
  position: relative;
  border-radius: 15px;
  height: 200px;
  overflow: hidden;
}

.team-card.style-6 .img img{
  object-fit: cover;
}

.team-card.style-6 .img .social-icons {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px;
  z-index: 5;
}

.team-card.style-6 .img .social-icons a {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  color: #666;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 11px;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.team-card.style-6 .img .social-icons a:hover {
  background-color: var(--color-blue6);
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transition-delay: 0 !important;
  -o-transition-delay: 0 !important;
  transition-delay: 0 !important;
}

.team-card.style-6 .img .social-icons a:nth-of-type(1) {
  -webkit-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.team-card.style-6 .img .social-icons a:nth-of-type(2) {
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.team-card.style-6 .img .social-icons a:nth-of-type(3) {
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.team-card.style-6 .img .social-icons a:nth-of-type(4) {
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.team-card.style-6 .info {
  padding: 25px 0 10px;
}

.team-card.style-6 .info h6 {
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

.team-card.style-6 .info small {
  color: #999;
  font-size: 13px;
}

.team-card.style-6:hover {
  -webkit-box-shadow: 0px 54px 92px 0px rgba(81, 85, 134, 0.2);
  box-shadow: 0px 54px 92px 0px rgba(81, 85, 134, 0.2);
}

.team-card.style-6:hover .img .social-icons a {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.team-card.style-6:hover .info h6 {
  color: var(--color-blue6);
}

/* ===============================================================
              [ * blog Section ] 
  ================================================================*/
/* --------------- blog styles --------------- */
.blog.style-1 .content .blog_slider {
  position: relative;
}

.blog.style-1 .content .blog_slider .swiper-button-next,
.blog.style-1 .content .blog_slider .swiper-button-prev {
  width: 35px;
  height: 35px;
}

.blog.style-1 .content .blog_slider .swiper-button-next,
.blog.style-1 .content .blog_slider .swiper-container-rtl .swiper-button-prev {
  right: 120px;
  top: -63px;
}

.blog.style-1 .content .blog_slider .swiper-button-prev,
.blog.style-1 .content .blog_slider .swiper-container-rtl .swiper-button-next {
  right: 160px;
  left: auto;
  top: -63px;
}

.blog.style-1 .content .blog_box {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.blog.style-1 .content .blog_box .img {
  height: 350px;
  position: relative;
}

.blog.style-1 .content .blog_box .img::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#000), to(transparent));
  background-image: -webkit-linear-gradient(bottom, #000, transparent);
  background-image: -o-linear-gradient(bottom, #000, transparent);
  background-image: linear-gradient(to top, #000, transparent);
}

.blog.style-1 .content .blog_box .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.blog.style-1 .content .blog_box .tags {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
}

.blog.style-1 .content .blog_box .tags a {
  font-size: 11px;
  padding: 3px 7px;
  border-radius: 4px;
  background: var(--color-main-grad);
  color: #fff;
  text-transform: uppercase;
}

.blog.style-1 .content .blog_box .info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  z-index: 5;
}

.blog.style-1 .content .blog_box .info h6 {
  color: #fff;
  margin-bottom: 10px;
  border-bottom: 2px solid transparent;
  display: inline-block;
  line-height: 1.3;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.blog.style-1 .content .blog_box .info h6:hover a {
  color: var(--color-main);
}

.blog.style-1 .content .blog_box .info .auther {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.blog.style-1 .content .blog_box .info .auther span {
  padding: 0 10px;
  color: #fff;
  font-size: 12px;
}

.blog.style-1 .content .blog_box .info .auther span:first-of-type {
  padding-left: 0;
  border-right: 1px solid #9993;
}

.blog.style-1 .content .blog_box .info .auther span .auther-img {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 3px;
}

.blog.style-1 .content .blog_box .info .auther span i {
  font-size: 11px;
  margin-right: 4px;
}

.blog.style-1 .content .blog_box .info .text {
  margin-top: 20px;
  font-size: 11px;
  color: #fff;
  display: none;
}

.blog.style-1 .content .blog_box:hover .img img {
  -webkit-transform: scale(1.3) rotate(-10deg);
  -ms-transform: scale(1.3) rotate(-10deg);
  transform: scale(1.3) rotate(-10deg);
}

.blog.style-1 .content .blog_box:hover .info h6 {
  border-color: #fff9;
}

.blog.style-2 {
  /* background-image: url(../img/num_back.png); */
  background-size: cover;
  position: relative;
}

.blog.style-2 .card-img-top {
  height: 300px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 6px;
}

.blog.style-3 {
  background-color: #f0eff5;
  position: relative;
}

.blog.style-3 .v_lines {
  position: absolute;
  top: 100px;
  left: 50%;
  height: calc(100% - 340px);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.blog.style-3 .card .img {
  height: 120px;
}

.blog.style-3 .card .card-body .date span {
  font-size: 11px;
  letter-spacing: 1px;
}

.blog.style-3 .card .card-body .card-title {
  font-size: 18px;
  font-weight: bold;
  max-width: 85%;
  margin-top: 10px;
  min-height: 45px;
}

.blog.style-3 .card .card-body .small {
  font-size: 10px;
}

.blog.style-3 .client-logos .img img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.6;
}

.blog.style-3 .client-logos .img:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}

.blog.style-6 .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.blog.style-6 .content .blog-card.style-6 {
  width: 30%;
  position: relative;
}

.blog.style-6 .content .blog-card.style-6.card-center::after {
  position: absolute;
  content: "";
  right: -28px;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #9994;
}

.blog.style-6 .content .blog-card.style-6.card-center::before {
  position: absolute;
  content: "";
  left: -28px;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #9994;
}

.blog.style-6 a:hover {
  color: var(--color-blue6);
}

.blog-card.style-6 {
  position: relative;
  height: 100%;
}

.blog-card.style-6 .img {
  height: 230px;
  border-radius: 20px;
  overflow: hidden;
}

.blog-card.style-6 .info {
  padding: 30px 0;
}

.blog-card.style-6 .info .blog-title {
  color: #000;
  font-size: 22px;
  margin: 10px 0 10px;
}

.blog-card.style-6 .info .text {
  color: #666;
  font-size: 13px;
}

/* ===============================================================
              [ * faq Section ] 
  ================================================================*/
/* --------------- faq styles --------------- */
.faq.style-3 .accordion-item {
  border: 0;
  border-bottom: 1px solid #9995;
  background: transparent;
}

.faq.style-3 .accordion-item .accordion-button {
  font-weight: bold;
  font-size: 14px;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-transform: capitalize;
  padding: 25px 15px;
}

.faq.style-3 .accordion-item .accordion-button::after {
  background-size: 15px;
}

.faq.style-3 .accordion-item .accordion-button:not(.collapsed) {
  background-color: #fff;
  color: var(--color-blue2);
  padding-top: 30px;
}

.faq.style-3 .accordion-item .accordion-collapse {
  background-color: #fff;
}

.faq.style-3 .accordion-item .accordion-collapse .accordion-body {
  padding-top: 0;
  padding-bottom: 30px;
  font-size: 10px;
  color: #666;
}

.faq.style-3.style-4 .accordion-item {
  border: 0;
  border-radius: 7px;
  overflow: hidden;
}

.faq.style-3.style-4 .accordion-item .accordion-button {
  padding: 15px;
  font-size: 15px;
}

.faq.style-3.style-4 .accordion-item .accordion-button:not(.collapsed) {
  background-color: #f4f2fb;
  color: var(--color-blue4);
  padding-bottom: 10px;
}

.faq.style-3.style-4 .accordion-item .accordion-collapse {
  background-color: #f4f2fb;
}

.faq.style-3.style-4 .accordion-item .accordion-collapse .accordion-body {
  font-size: 13px;
}

/* ===============================================================
              [ * community Section ] 
  ================================================================*/
/* --------------- community style-4 --------------- */
.community.style-4 .content {
  border: 1px solid #9993;
  border-radius: 15px;
  padding: 30px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.community.style-4 .content .commun-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 33%;
  border-right: 1px solid #9993;
  padding: 20px 0;
}

.community.style-4 .content .commun-card:last-of-type {
  border: 0;
}

.community.style-4 .content .commun-card .icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 25px;
}

.community.style-4 .content .commun-card .icon i {
  font-size: 60px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: -webkit-gradient(linear, left top, right top, from(#501e9c), color-stop(30%, #8169f1), color-stop(30%, #8169f1), color-stop(73%, #a44cee), to(#ff847f));
  background-image: -webkit-linear-gradient(left, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);
  background-image: -o-linear-gradient(left, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);
  background-image: linear-gradient(to right, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);
}

.community.style-4 .content .commun-card .inf h5 {
  font-size: 23px;
  color: #000;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 1.1;
}

.community.style-4 .content .commun-card .inf p {
  font-size: 12px;
  color: #666;
}

/* --------------- community style-5 --------------- */
.community.style-5 .content {
  border: 1px solid #9993;
  border-radius: 15px;
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.community.style-5 .content .commun-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 33%;
  border-right: 1px solid #9993;
  padding: 10px 0;
}

.community.style-5 .content .commun-card:last-of-type {
  border: 0;
}

.community.style-5 .content .commun-card .icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 25px;
}

.community.style-5 .content .commun-card .icon img {
  -webkit-filter: drop-shadow(-10px 10px 20px #084cba77);
  filter: drop-shadow(-10px 10px 20px #084cba77);
  -webkit-animation: scale_up_down 1s ease-in-out infinite alternate both;
  animation: scale_up_down 1s ease-in-out infinite alternate both;
}

.community.style-5 .content .commun-card .inf h5 {
  font-size: 20px;
  color: #000;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 1.1;
}

/* ===============================================================
              [ * faq screenshots ] 
  ================================================================*/
/* --------------- screenshots style-4 --------------- */
.screenshots.style-4 {
  background-color: #f0eff5;
  overflow: hidden;
  padding-top: 100px;
  position: relative;
  height: 700px;
}

.screenshots.style-4::after {
  position: absolute;
  content: "";
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 30px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: #fff;
  z-index: 10;
}

.screenshots.style-4 .screenshots-slider {
  position: absolute;
  top: 110px;
  width: calc(100% + 120px);
  left: -60px;
}

.screenshots.style-4 .screenshots-slider .img {
  margin: 0 auto;
  height: 420px;
  width: 190px;
}

.screenshots.style-4 .screenshots-slider .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 20px;
}

.screenshots.style-4 .mob-hand {
  pointer-events: none;
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-33%);
  -ms-transform: translateX(-33%);
  transform: translateX(-33%);
  height: 600px;
  z-index: 10;
}

/* ===============================================================
              [ * features Section ] 
  ================================================================*/
/* --------------- features style-4 --------------- */
.features.style-4 {
  background-color: #f0eff5;
  position: relative;
  overflow: hidden;
}

.features.style-4 .img-circle {
  position: absolute;
  top: 40px;
  width: 76%;
  left: 12%;
  max-height: unset;
  -webkit-animation: rotate-center 100s linear infinite both;
  animation: rotate-center 100s linear infinite both;
}

.features.style-4 .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  z-index: 5;
}

.features.style-4 .content .features-card {
  text-align: center;
}

.features.style-4 .content .features-card .icon {
  width: 125px;
  height: 125px;
  display: block;
  margin: 0 auto 30px;
  position: relative;
}

.features.style-4 .content .features-card .icon .label {
  position: absolute;
  top: 0;
  right: -5px;
  z-index: 2;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 10px;
}

.features.style-4 .content .features-card h6 {
  font-weight: bold;
  font-size: 15px;
  line-height: 23px;
}

/* --------------- features style-5 --------------- */
.features-card.style-5 {
  display: block;
  padding: 40px;
  background-color: #fff;
  border-radius: 15px;
  text-align: center;
}

.features-card.style-5:hover {
  -webkit-box-shadow: 0px 46px 77px 0px #0a2b5326;
  box-shadow: 0px 46px 77px 0px #0a2b5326;
}

.features-card.style-5:hover .card-title {
  color: var(--color-blue5);
}

.features-card.style-5 .icon {
  margin-bottom: 40px;
}

.features-card.style-5 .icon img {
  width: 80px;
  height: 80px;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-filter: drop-shadow(0 25px 20px #07397235);
  filter: drop-shadow(0 25px 20px #07397235);
}

.features-card.style-5 .card-title {
  color: #000;
  font-size: 17.5px;
  font-weight: bold;
  margin-bottom: 15px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.features-card.style-5 .text {
  font-size: 12.5px;
  line-height: 1.8;
  color: #777;
}

/* ===============================================================
              [ * numbers Section ] 
  ================================================================*/
/* --------------- numbers style-6 --------------- */
.number-card.style-6 {
  border-right: 1px solid #9993;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 10px;
}

.number-card.style-6 h2 {
  font-size: 45px;
  color: var(--color-blue6);
}

.number-card.style-6 .text {
  font-size: 15px;
  color: #000;
  line-height: 1.5;
  text-transform: capitalize;
}

/* ===============================================================
              [ * clients Section ] 
  ================================================================*/
/* --------------- clients style-4 --------------- */
.clients.style-4 {
  background-color: #f0eff5;
  position: relative;
  z-index: 25;
}

.clients.style-4 .client-logos {
  border-bottom: 1px solid #9994;
}

.clients.style-4 .client-logos .img img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.6;
}

.clients.style-4 .client-logos .img:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}

/* --------------- clients style 5 --------------- */
.clients.style-5 .content .img {
  padding: 30px;
  text-align: center;
  border-radius: 20px;
  border: 1px solid #9994;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 5px;
  height: 150px;
}

.clients.style-5 .content .img.img-card {
  width: 19%;
}

.clients.style-5 .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
  position: relative;
}

/* --------------- clients styles --------------- */
/* ===============================================================
              [ * chat-banner Section ] 
  ================================================================*/
/* --------------- chat-banner styles --------------- */
.chat-banner.style-3 {
  background-color: var(--color-blue2);
  position: relative;
}

.chat-banner.style-3::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 60%;
  /* background-image: url(../img/0011.png); */
  background-size: 80%;
  background-repeat: repeat;
  background-position: bottom;
  opacity: 0.08;
  -webkit-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(1003%) contrast(103%);
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(1003%) contrast(103%);
}

.chat-banner.style-3 .info h3 {
  font-size: 27px;
  color: #fff;
  text-transform: capitalize;
}

.chat-banner.style-3 .info h3 span {
  font-weight: 400;
  font-style: italic;
  position: relative;
}

.chat-banner.style-3 .info h3 span::before {
  position: absolute;
  content: "";
  left: 0;
  top: 100%;
  width: 240px;
  height: 35px;
  /* background-image: url(../img/header/info_h1_line1.png); */
  background-size: 240px;
  background-repeat: no-repeat;
  background-position: left;
}

/* ===============================================================
              [ * contact Section ] 
  ================================================================*/
/* --------------- contact style-1 --------------- */
.contact.style-1 {
  position: relative;
  overflow: hidden;
}

.contact.style-1 .container {
  position: relative;
  z-index: 5;
}

.contact.style-1 .content .contact_info p {
  color: #fff;
  font-size: 13px;
}

.contact.style-1 .content .contact_info ul {
  margin: 50px 0;
}

.contact.style-1 .content .contact_info ul li {
  color: #fff;
  margin: 15px 0;
}

.contact.style-1 .content .contact_info ul li strong {
  margin-right: 5px;
}

.contact.style-1 .content .contact_info a {
  color: #fff;
  text-decoration: underline !important;
  text-transform: uppercase;
  font-size: 13px;
}

.contact.style-1 .content .contact_form .form-control,
.contact.style-1 .content .contact_form .form-select {
  border: 0;
  font-size: 13px;
  min-height: 50px;
}

.contact.style-1 .content .contact_form textarea {
  min-height: 125px !important;
}

.contact.style-1 .contact_globe {
  position: absolute;
  left: 0;
  top: -10%;
  width: 47%;
  height: 120%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: right;
  object-position: right;
  max-height: none;
  -webkit-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
  opacity: 0.3;
  -webkit-animation: rotate-center 100s linear infinite both;
  animation: rotate-center 100s linear infinite both;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* --------------- contact style-2 --------------- */
.contact.style-2 {
  position: relative;
  overflow: hidden;
  padding-bottom: 250px;
}

.contact.style-2 .global_2 {
  position: absolute;
  left: 25%;
  bottom: -500px;
  width: 55%;
  z-index: 2;
  opacity: 0.7;
  -webkit-animation: rotate-center 100s linear infinite both;
  animation: rotate-center 100s linear infinite both;
}

/* --------------- contact style-6 --------------- */
.contact.style-6 .content {
  position: relative;
  border-top: 10px solid var(--color-blue6);
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  padding: 100px 15px;
}

.contact.style-6 .content::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(45deg, transparent, #def1fa, transparent, #e8e8f3, transparent);
  background: -o-linear-gradient(45deg, transparent, #def1fa, transparent, #e8e8f3, transparent);
  background: linear-gradient(45deg, transparent, #def1fa, transparent, #e8e8f3, transparent);
  background-size: 400% 400%;
  -webkit-animation: gradient 20s linear infinite;
  animation: gradient 20s linear infinite;
  border-radius: 30px;
  overflow: hidden;
}

.contact.style-6 .content .contact_a {
  position: absolute;
  left: 0;
  bottom: 0;
  border-bottom-left-radius: 30px;
  pointer-events: none;
  max-width: 20%;
}

.contact.style-6 .content .contact_message {
  position: absolute;
  right: -150px;
  top: 100px;
  border-bottom-left-radius: 30px;
  pointer-events: none;
  max-width: 30%;
}

.contact.style-6 .content .form {
  position: relative;
  z-index: 5;
}

.contact.style-6 .form-group .form-control,
.contact.style-6 .form-group .form-select {
  border: 1px solid #9994;
  border-radius: 30px;
  min-height: 50px;
  font-size: 12px;
  padding: 10px 25px;
}

.contact.style-6 .form-group .form-control:focus,
.contact.style-6 .form-group .form-select:focus {
  border-color: var(--color-blue6);
  -webkit-box-shadow: none;
  box-shadow: none;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

/* ===============================================================
              [ * download Section ] 
  ================================================================*/
/* --------------- download style-5 --------------- */
.download.style-5 .content h2 {
  font-size: 50px;
}

.download.style-5 .content h2 span {
  position: relative;
}

.download.style-5 .content h2 span .head-line {
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
}

.download.style-5 .content h2 span .head-pen {
  position: absolute;
  right: 102%;
  bottom: -5px;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

/* ===============================================================
              [ * footer Section ] 
  ================================================================*/
/* --------------- footer styles --------------- */
footer.style-1 {
  background-color: #000;
  position: relative;
}

footer.style-1 .foot_l {
  position: absolute;
  left: 0;
  bottom: 0;
  pointer-events: none;
}

footer.style-1 .foot_r {
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;
}

footer.style-1 .content {
  padding: 90px 0 70px;
  position: relative;
  z-index: 5;
}

footer.style-1 p,
footer.style-1 a,
footer.style-1 li {
  font-size: 13px;
  color: #aaa7a7;
}

footer.style-1 a:hover {
  color: var(--color-main);
}

footer.style-1 .text {
  color: #fff;
  font-size: 14px;
}

footer.style-1 .foot_info li {
  margin: 20px 0;
}

footer.style-1 .foot_info li i {
  color: var(--color-main);
  font-size: 16px;
}

footer.style-1 .social_icons {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

footer.style-1 .social_icons a {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #2c2c2c;
  color: #aaa7a7;
  margin: 5px;
}

footer.style-1 .social_icons a:hover {
  background: var(--color-main-grad);
  color: #fff;
}

footer.style-1 .links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

footer.style-1 .links li {
  margin: 8px 0;
}

footer.style-1 .link_title {
  color: #fff;
  margin: 10px 0 15px;
}

footer.style-1 .foot_subscribe input {
  background: #1f1f1f;
  color: #fff;
  font-size: 12px;
  border: 0;
}

footer.style-1 .foot_subscribe input::-webkit-input-placeholder {
  color: #fff;
}

footer.style-1 .foot_subscribe input:-ms-input-placeholder {
  color: #fff;
}

footer.style-1 .foot_subscribe input::-ms-input-placeholder {
  color: #fff;
}

footer.style-1 .foot_subscribe input::placeholder {
  color: #fff;
}

footer.style-1 .foot {
  position: relative;
  z-index: 5;
  text-align: center;
  padding: 30px 0;
  border-top: 1px solid #fff3;
}

footer.style-2 {
  background-color: #010040;
}

footer.style-3 {
  position: relative;
  padding-top: 100px;
  overflow: hidden;
}

footer.style-3 .items .title {
  font-weight: bold;
  margin-bottom: 30px;
}

footer.style-3 .items .socail-icons {
  margin-top: 30px;
}

footer.style-3 .items .socail-icons a:hover {
  background-color: var(--color-blue2) !important;
  color: #fff !important;
}

footer.style-3 .items ul li {
  margin: 5px 0;
}

footer.style-3 .items ul li a {
  font-size: 12px;
}

footer.style-3 .items ul li a:hover {
  color: var(--color-blue2);
}

footer.style-3 .foot {
  padding: 35px 0 30px;
  position: relative;
  margin-top: 80px;
}

footer.style-3 .foot .logo {
  width: 120px;
}

footer.style-3 .foot .testi_lines {
  position: absolute;
  top: -10px;
}

footer.style-3 .contact_globe {
  position: absolute;
  height: 150%;
  max-height: none;
  width: 60%;
  top: -25%;
  left: 20%;
  opacity: 0.15;
  -webkit-animation: rotate-center 100s linear infinite both;
  animation: rotate-center 100s linear infinite both;
}

footer.style-4 {
  position: relative;
  background-color: #f0eff5;
  /* margin-top: 200px; */
}

footer.style-4 .wave {
  position: absolute;
  left: -3%;
  bottom: 95%;
  width: 106%;
  max-width: unset;
  height: 240px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top;
  object-position: top;
  z-index: 20;
}

footer.style-4 .container {
  position: relative;
  z-index: 25;
}

footer.style-4 .foot {
  border-bottom: 1px solid #9999;
  padding: 30px 0;
}

footer.style-4 .foot .links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

footer.style-4 .foot .links li a {
  font-size: 12px;
  margin: 0 15px;
  font-weight: bold;
}

footer.style-4 .foot .links li a:hover,
footer.style-4 .foot .links li a.active {
  color: var(--color-blue4);
}

footer.style-4 .copywrite {
  padding: 35px 0;
}

footer.style-5 {
  position: relative;
  padding-top: 100px;
  overflow: hidden;
}

footer.style-5 .items .title {
  font-weight: bold;
  margin-bottom: 30px;
  color: #000;
  line-height: 1;
}

footer.style-5 .items .socail-icons {
  margin-top: 30px;
}

footer.style-5 .items .socail-icons a:hover {
  background-color: var(--color-blue5) !important;
  color: #fff !important;
}

footer.style-5 .items ul li {
  margin: 5px 0;
}

footer.style-5 .items ul li a {
  font-size: 12px;
}

footer.style-5 .items ul li a:hover {
  color: var(--color-blue5);
}

footer.style-5 .foot {
  padding: 35px 0 30px;
  position: relative;
  margin-top: 80px;
}

footer.style-5 .foot .logo {
  width: 120px;
}

footer.style-6 {
  position: relative;
  padding-top: 100px;
  overflow: hidden;
}

footer.style-6 .items .title {
  font-weight: bold;
  margin-bottom: 30px;
  color: #000;
}

footer.style-6 .items .socail-icons {
  margin-top: 30px;
}

footer.style-6 .items .socail-icons a:hover {
  background-color: var(--color-blue6) !important;
  color: #fff !important;
}

footer.style-6 .items ul li {
  margin: 5px 0;
}

footer.style-6 .items ul li a {
  font-size: 12px;
}

footer.style-6 .items ul li a:hover {
  color: var(--color-blue6);
}

footer.style-6 .form .form-group {
  position: relative;
}

footer.style-6 .form .form-group .icon {
  position: absolute;
  top: 12px;
  left: 15px;
}

footer.style-6 .form .form-group input {
  width: 100%;
  border: 1px solid #9993;
  border-radius: 30px;
  min-height: 45px;
  font-size: 12px;
  padding: 0 40px;
}

footer.style-6 .form .form-group button {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: var(--color-blue6);
  border: 0;
  color: #fff;
}

footer.style-6 .foot {
  padding: 35px 0 30px;
  position: relative;
  margin-top: 80px;
}

footer.style-6 .foot .logo {
  width: 120px;
}

/* ===============================================================
              [ * portfolio_page Style ] 
  ================================================================*/
/* --------------- portfolio-page style-1 --------------- */
.portfolio-page.style-1 .portfolio-projects.style-1 {
  background-color: #e7f1ff;
}

.portfolio-page.style-1 .portfolio-projects.style-1 .controls {
  text-align: center;
  margin-bottom: 50px;
}

.portfolio-page.style-1 .portfolio-projects.style-1 .controls button {
  border: 0;
  background: transparent;
  font-weight: bold;
  text-transform: capitalize;
  margin: 10px;
}

.portfolio-page.style-1 .portfolio-projects.style-1 .controls button.mixitup-control-active {
  color: var(--color-blue5);
}

.portfolio-page.style-1 .download.style-5 {
  position: relative;
  overflow: hidden;
}

.portfolio-page.style-1 .download.style-5 .content h2 span .head-pen {
  right: auto;
  left: 102%;
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}

.portfolio-page.style-1 .download.style-5 .contact_globe {
  position: absolute;
  height: 150%;
  max-height: none;
  width: 60%;
  top: -25%;
  left: 20%;
  opacity: 0.15;
  -webkit-animation: rotate-center 100s linear infinite both;
  animation: rotate-center 100s linear infinite both;
}

/* ===============================================================
              [ * single_project Style ] 
  ================================================================*/
/* --------------- single-project style-5 --------------- */
.single-project.style-5 .about-app.style-5 h1 {
  font-size: 55px;
}

.single-project.style-5 .about-app.style-5 .about-cards .about-card {
  text-align: center;
  padding: 15px;
}

.single-project.style-5 .about-app.style-5 .about-cards .about-card small {
  font-size: 11px;
  color: #999;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.single-project.style-5 .about-app.style-5 .about-cards .about-card h6 {
  color: #000;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.6;
}

.single-project.style-5 .about-app.style-5 .img-content .img {
  position: relative;
}

.single-project.style-5 .about-app.style-5 .img-content .img .main-img {
  position: relative;
  z-index: 5;
}

.single-project.style-5 .about-app.style-5 .img-content .img .circle {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 15%;
  height: 70%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  -webkit-animation: rotate-center 100s linear infinite both reverse;
  animation: rotate-center 100s linear infinite both reverse;
}

.single-project.style-5 .about-app.style-5 .img-content .img .bubbls {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  -webkit-animation: rotate-center 100s linear infinite both;
  animation: rotate-center 100s linear infinite both;
}

.single-project.style-5 .screenshots {
  position: relative;
}

.single-project.style-5 .screenshots::after {
  position: absolute;
  content: "";
  /* background-image: url(../img/about/about_s4_wave.png); */
  background-repeat: repeat-x;
  width: 100%;
  height: 30px;
  top: -1px;
  left: 0;
  z-index: 10;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.single-project.style-5 .share .social-icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #f3f7fe;
  color: #666;
  font-size: 12px;
  margin: 20px 3px 0;
}

.single-project.style-5 .share .social-icon:hover {
  background-color: var(--color-blue5);
  color: #fff;
}

.single-project.style-5 .share .social-icon.hover-blue4:hover {
  background-color: var(--color-blue4);
  color: #fff;
}

.single-project.style-5 .projects .project-card.style-6:hover .info .title {
  color: var(--color-blue5);
}

.single-project.style-5 .projects .project-card.style-6 .info small {
  color: var(--color-blue5);
}

.single-project.style-5 .slider-style-6 .swiper-button-next:hover,
.single-project.style-5 .slider-style-6 .swiper-button-prev:hover {
  background-color: var(--color-blue5);
}

/* ===============================================================
              [ * services page Style ] 
  ================================================================*/
/* --------------- services page styles --------------- */
.services-page .features {
  background-color: #e7f1ff;
}

/* ===============================================================
              [ * services page Style ] 
  ================================================================*/
/* --------------- services page styles --------------- */
.about-page header.style-5 {
  position: relative;
}

.about-page header.style-5 .info h1 {
  font-size: 55px;
}

.about-page header.style-5 .main-vid {
  position: relative;
  height: 570px;
  border-radius: 20px;
  overflow: hidden;
  border: 10px solid #ffffff01;
  margin-top: 150px;
  -webkit-box-shadow: 0px 30px 40px 0px rgba(109, 121, 141, 0.3);
  box-shadow: 0px 30px 40px 0px rgba(109, 121, 141, 0.3);
}

.about-page header.style-5 .main-vid img {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.about-page header.style-5 .main-vid .play-icon {
  position: absolute;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  width: 100px;
  height: 100px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-size: 25px;
  background-color: var(--color-blue5);
  -webkit-box-shadow: 0px 5px 0px 0px var(--color-blue4);
  box-shadow: 0px 5px 0px 0px var(--color-blue4);
  -webkit-filter: drop-shadow(0 13px 20px #0008);
  filter: drop-shadow(0 13px 20px #0008);
  -webkit-animation: scale_up_down 1s ease-in-out infinite alternate both;
  animation: scale_up_down 1s ease-in-out infinite alternate both;
}

.about-page header.style-5 .hand-mega {
  position: absolute;
  width: 17%;
  top: 9%;
  left: 0;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: left;
  object-position: left;
  z-index: 2;
}

.about-page header.style-5 .head6-rating {
  position: absolute;
  width: 18%;
  top: 27%;
  left: 15%;
  -o-object-fit: contain;
  object-fit: contain;
  z-index: 2;
}

.about-page header.style-5 .head6-charts {
  position: absolute;
  width: 14%;
  top: 7%;
  right: 10%;
  -o-object-fit: contain;
  object-fit: contain;
  z-index: 2;
}

.about-page header.style-5 .head6-rocket {
  position: absolute;
  width: 20%;
  top: 25%;
  right: -30px;
  z-index: 2;
}

.about-page .contact .content {
  border-top: 10px solid var(--color-blue5);
}

.about-page .contact.style-6 .form-group .form-control:focus,
.about-page .contact.style-6 .form-group .form-select:focus {
  border-color: var(--color-blue5);
}

.culture.style-5 .culture-card {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  max-height: 500px;
}

.culture.style-5 .culture-card::after {
  position: absolute;
  content: "";
  height: 50px;
  width: 3px;
  background-color: #fff;
  top: 0%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10;
  border-radius: 3px;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.culture.style-5 .culture-card::before {
  position: absolute;
  content: "";
  height: 3px;
  width: 50px;
  background-color: #fff;
  top: 50%;
  left: 0%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10;
  border-radius: 3px;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.culture.style-5 .culture-card .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
}

.culture.style-5 .culture-card:hover .overlay {
  opacity: 0.3;
}

.culture.style-5 .culture-card:hover::after {
  top: 50%;
  opacity: 1;
}

.culture.style-5 .culture-card:hover::before {
  left: 50%;
  opacity: 1;
}

/* ===============================================================
              [ * contact page Style ] 
  ================================================================*/
/* --------------- contact page styles --------------- */
.contact-page.style-5 .contact.style-6 .form-group .form-control:focus,
.contact-page.style-5 .contact.style-6 .form-group .form-select:focus {
  border-color: var(--color-blue5);
}

.contact-page.style-5 .map iframe {
  width: 100%;
  margin-bottom: -6px;
}

/* ===============================================================
              [ * blog page Style ] 
  ================================================================*/
/* --------------- blog-page style-1 --------------- */
.blog-page.style-5 .blog-details-slider {
  position: relative;
  overflow: hidden;
}

.blog-page.style-5 .blog-details-slider .content-card .img {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
}

.blog-page.style-5 .blog-details-slider .content-card .img.overlay::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#000000e1), color-stop(#0005), to(transparent));
  background-image: -webkit-linear-gradient(bottom, #000000e1, #0005, transparent);
  background-image: -o-linear-gradient(bottom, #000000e1, #0005, transparent);
  background-image: linear-gradient(to top, #000000e1, #0005, transparent);
}

.blog-page.style-5 .blog-details-slider .content-card .img img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 500px;
}

.blog-page.style-5 .blog-details-slider .content-card .info {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 4vw;
  color: #fff;
}

.blog-page.style-5 .blog-details-slider .content-card .info .date {
  opacity: 0;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.blog-page.style-5 .blog-details-slider .content-card .info .title {
  opacity: 0;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  -webkit-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.blog-page.style-5 .blog-details-slider .content-card .info .text-info {
  opacity: 0;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.blog-page.style-5 .blog-details-slider .content-card a:hover {
  color: var(--color-blue5);
}

.blog-page.style-5 .blog-details-slider .swiper-slide-active .content-card .info small,
.blog-page.style-5 .blog-details-slider .swiper-slide-active .content-card .info h2,
.blog-page.style-5 .blog-details-slider .swiper-slide-active .content-card .info p {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.blog-page.style-5 .blog-details-slider .swiper-pagination {
  width: 100%;
  bottom: 0;
  text-align: right;
  padding: 30px 4vw;
}

.blog-page.style-5 .blog-details-slider .swiper-pagination .swiper-pagination-bullet {
  background-color: #fff;
  opacity: 1;
}

.blog-page.style-5 .blog-details-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--color-blue5);
}

.blog-page.style-5 .blog-details-slider .swiper-button-next,
.blog-page.style-5 .blog-details-slider .swiper-button-prev {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 15px;
  width: 50px;
  height: 50px;
}

.blog-page.style-5 .blog-details-slider .swiper-button-next::after,
.blog-page.style-5 .blog-details-slider .swiper-button-prev::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.blog-page.style-5 .blog-details-slider .swiper-button-next,
.blog-page.style-5 .blog-details-slider .swiper-container-rtl .swiper-button-prev {
  right: -35px;
}

.blog-page.style-5 .blog-details-slider .swiper-button-next::after,
.blog-page.style-5 .blog-details-slider .swiper-container-rtl .swiper-button-prev::after {
  font-size: 14px;
  margin-right: 20px;
  margin-top: 23px;
}

.blog-page.style-5 .blog-details-slider .swiper-button-prev,
.blog-page.style-5 .blog-details-slider .swiper-container-rtl .swiper-button-next {
  left: -35px;
}

.blog-page.style-5 .blog-details-slider .swiper-button-prev::after,
.blog-page.style-5 .blog-details-slider .swiper-container-rtl .swiper-button-next::after {
  font-size: 14px;
  margin-left: 25px;
  margin-bottom: 22px;
}

.blog-page.style-5 .popular-posts .post-sc-title {
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 20px;
}

.blog-page.style-5 .popular-posts .card {
  padding: 0 15px;
}

.blog-page.style-5 .popular-posts .card .img {
  height: 200px;
  overflow: hidden;
}

.blog-page.style-5 .popular-posts .card .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.blog-page.style-5 .popular-posts .card .title a:hover {
  color: var(--color-blue5);
}

.blog-page.style-5 .popular-posts .card a:hover {
  color: var(--color-blue5);
}

.blog-page.style-5 .all-news .card .img {
  height: 200px;
}

.blog-page.style-5 .all-news .card .card-body .card-title {
  min-height: unset;
  max-width: unset;
}

.blog-page.style-5 .all-news .card .card-body {
  position: relative;
  padding-bottom: 40px !important;
  height: 100%;
}

.blog-page.style-5 .all-news .card .card-body .auther-comments {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
}

.blog-page.style-5 .all-news .card a:hover {
  color: var(--color-blue5);
}

.blog-page.style-5 .side-blog {
  position: -webkit-sticky;
  position: sticky;
  top: 30px;
}

.blog-page.style-5 .side-blog .title {
  font-size: 16px;
  font-weight: 600 !important;
  letter-spacing: 2px;
}

.blog-page.style-5 .side-blog .search-form .form-group .form-control {
  min-height: 50px;
  font-size: 12px;
  padding: 10px 50px 10px 20px;
}

.blog-page.style-5 .side-blog .search-form .form-group .search-btn {
  position: absolute;
  right: 10px;
  bottom: 12px;
}

.blog-page.style-5 .side-blog .side-recent-post .post-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.blog-page.style-5 .side-blog .side-recent-post .post-card .img {
  height: 60px;
  width: 30%;
  border-radius: 10px;
  overflow: hidden;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.blog-page.style-5 .side-blog .side-recent-post .post-card .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.blog-page.style-5 .side-blog .side-recent-post .post-card .inf h6 {
  color: #000;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
}

.blog-page.style-5 .side-blog .side-recent-post .post-card .inf p {
  font-size: 10px;
  color: #666;
}

.blog-page.style-5 .side-blog .side-recent-post .post-card:hover .inf h6 {
  color: var(--color-blue5);
}

.blog-page.style-5 .side-blog .side-categories .cat-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #666;
  font-size: 11px;
  text-transform: uppercase;
  padding: 10px 0;
  border-bottom: 1px solid #9995;
}

.blog-page.style-5 .side-blog .side-categories .cat-item:hover {
  color: var(--color-blue5);
  font-weight: bold;
}

.blog-page.style-5 .side-blog .side-newsletter {
  background-color: #eaeef2;
  padding: 50px 30px;
  border-radius: 20px;
}

.blog-page.style-5 .side-blog .side-newsletter .text {
  font-size: 11px;
  color: #666;
  line-height: 1.5;
}

.blog-page.style-5 .side-blog .side-share .social-icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #f3f7fe;
  color: #666;
  font-size: 12px;
  margin: 0 3px;
}

.blog-page.style-5 .side-blog .side-share .social-icon:hover {
  background-color: var(--color-blue5);
  color: #fff;
}

.blog-page.style-5 .side-blog .side-insta .insta-img {
  height: 80px;
  width: 31%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
}

.blog-page.style-5 .side-blog .side-insta .insta-img::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #000;
  opacity: 0;
  z-index: 2;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.blog-page.style-5 .side-blog .side-insta .insta-img .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 3;
  color: #fff;
  margin-top: 15px;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.blog-page.style-5 .side-blog .side-insta .insta-img:hover::after {
  opacity: 0.3;
}

.blog-page.style-5 .side-blog .side-insta .insta-img:hover .icon {
  opacity: 1;
  margin: 0;
}

.blog-page.style-5 .blog-content-info .info-imgs .img img {
  height: 300px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 20px;
}

.blog-page.style-5 .blog-content-info .twitter-info .twitter-card {
  padding: 5vw;
  border-top: 2px solid #000;
}

.blog-page.style-5 .blog-content-info .twitter-info .twitter-card .twitter-header .twitter-icon {
  font-size: 25px;
  color: #00ccff;
}

.blog-page.style-5 .side-tags a {
  font-size: 11px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #eef4f8;
  margin-bottom: 4px;
}

.blog-page.style-5 .side-tags a:hover {
  background-color: var(--color-blue5);
  color: #fff;
}

.blog-page.style-5 .blog-share .share-icons a i {
  width: 25px;
  height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  -webkit-margin-end: 5px;
  margin-inline-end: 5px;
  border-radius: 50%;
}

.blog-page.style-5 .blog-share .share-icons a i.fa-facebook-f {
  background-color: #3b5999;
}

.blog-page.style-5 .blog-share .share-icons a i.fa-twitter {
  background-color: #55acee;
}

.blog-page.style-5 .blog-share .share-icons a i.fa-tumblr {
  background-color: #2b4b6a;
}

.blog-page.style-5 .blog-share .share-icons a i.fa-rss {
  background-color: #fb7000;
}

.blog-page.style-5 .blog-comments .comment-card {
  background-color: #f4f8fc;
}

.blog-page.style-5 .blog-comments .comment-card .social-icons a {
  background-color: #dce1e5;
}

.blog-page.style-5 .blog-comments .comment-card .social-icons a:hover {
  background-color: var(--color-blue5);
  color: #fff;
}

.blog-page.style-5 .related-postes-slider .swiper-slide-prev {
  position: relative;
}

.blog-page.style-5 .related-postes-slider .swiper-slide-prev::after {
  position: absolute;
  content: "";
  right: -40px;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #9994;
}

.blog-page.style-5 .related-postes-slider .swiper-slide-active::after {
  position: absolute;
  content: "";
  right: -40px;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #9994;
}

.blog-page.style-5 .related-postes-slider .swiper-button-next,
.blog-page.style-5 .related-postes-slider .swiper-button-prev {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #fff;
}

.blog-page.style-5 .related-postes-slider .swiper-button-next:hover,
.blog-page.style-5 .related-postes-slider .swiper-button-prev:hover {
  background-color: var(--color-blue5);
  color: #fff;
}

.blog-page.style-5 .related-postes-slider .swiper-button-next,
.blog-page.style-5 .related-postes-slider .swiper-container-rtl .swiper-button-prev {
  right: 0;
  top: -60px;
}

.blog-page.style-5 .related-postes-slider .swiper-button-prev,
.blog-page.style-5 .related-postes-slider .swiper-container-rtl .swiper-button-next {
  left: auto;
  right: 50px;
  top: -60px;
}

.blog-page.style-5 .related-postes-slider .swiper-button-next::after,
.blog-page.style-5 .related-postes-slider .swiper-button-prev::after {
  font-size: 13px;
}

.blog-page.style-5.color-4 .side-tags a:hover,
.blog-page.style-5.color-4 .blog-page.style-5 .blog-comments .comment-card .social-icons a:hover,
.blog-page.style-5.color-4 .side-blog .side-share .social-icon:hover,
.blog-page.style-5.color-4 .related-postes-slider .swiper-button-next:hover,
.blog-page.style-5.color-4 .related-postes-slider .swiper-button-prev:hover,
.blog-page.style-5.color-4 .blog-details-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--color-blue4);
}

.blog-page.style-5.color-4 .side-blog .side-recent-post .post-card:hover .inf h6,
.blog-page.style-5.color-4 .side-blog .side-categories .cat-item:hover,
.blog-page.style-5.color-4 .popular-posts .card a:hover,
.blog-page.style-5.color-4 .blog-details-slider .content-card a:hover {
  color: var(--color-blue4);
}

.blog-page.style-5.color-4 .bg-main {
  background-color: var(--color-blue4) !important;
}

/* ===============================================================
              [ * shop page Style ] 
  ================================================================*/
/* --------------- services page styles --------------- */
.shop-page .filter .filter-card {
  padding: 20px 15px;
  border-radius: 10px;
  background-color: #f0f4f8;
  text-transform: capitalize;
}

.shop-page .filter .filter-card .card-title {
  font-size: 15px;
  color: #000;
  font-weight: 800;
  margin-bottom: 15px;
}

.shop-page .filter .filter-card .form-check-label {
  cursor: pointer;
}

.shop-page .filter .filter-card .cat-link {
  color: #000;
  font-size: 11px;
  font-weight: 600;
}

.shop-page .filter .filter-card .category-checkRadio {
  padding: 0;
  line-height: 1;
}

.shop-page .filter .filter-card .category-checkRadio .form-check-input {
  display: none;
}

.shop-page .filter .filter-card .category-checkRadio .form-check-input:checked~.form-check-label {
  color: var(--color-blue5);
}

.shop-page .filter .filter-card .accordion .accordion-button {
  color: #000;
  font-size: 11px;
  font-weight: 600;
  padding: 0;
  background: transparent;
  margin-bottom: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.shop-page .filter .filter-card .accordion .accordion-button::after {
  background-size: 0.8rem;
  background-position: center;
}

.shop-page .filter .filter-card .accordion .accordion-button:not(.collapsed) {
  color: var(--color-blue5);
}

.shop-page .filter .filter-card .filter-card-item {
  padding-bottom: 15px;
  border-bottom: 1px solid #9994;
  margin-bottom: 20px;
}

.shop-page .filter .filter-card .filter-card-item .sub-tilte {
  font-size: 11px;
  color: #000;
  font-weight: 800;
  position: relative;
  cursor: pointer;
  margin-bottom: 15px;
}

.shop-page .filter .filter-card .filter-card-item .filter-card-body .filter-card-scroll {
  max-height: 130px;
  overflow-x: hidden;
  overflow-y: auto;
}

.shop-page .filter .filter-card .filter-card-item .filter-card-body .filter-card-scroll::-webkit-scrollbar {
  height: 10px;
  width: 5px;
  background: #dfdfdf;
  border-radius: 10px;
}

.shop-page .filter .filter-card .filter-card-item .filter-card-body .filter-card-scroll::-webkit-scrollbar-thumb {
  background: var(--color-blue5);
  -webkit-border-radius: 0;
  border-radius: 10px;
}

.shop-page .filter .filter-card .filter-card-item .filter-card-body .filter-card-scroll::-webkit-scrollbar-corner {
  background: var(--color-blue5);
  border-radius: 10px;
}

.shop-page .filter .filter-card .filter-card-item .search-group {
  position: relative;
  margin-bottom: 25px;
}

.shop-page .filter .filter-card .filter-card-item .search-group input {
  width: 100%;
  background-color: #fff;
  border: 0;
  border-radius: 5px;
  padding: 10px;
  font-size: 11px;
  min-height: 40px;
  -webkit-padding-end: 20px;
  padding-inline-end: 20px;
}

.shop-page .filter .filter-card .filter-card-item .search-group button {
  position: absolute;
  right: 8px;
  top: 10px;
  font-size: 13px;
  border: 0;
  background: transparent;
}

.shop-page .filter .filter-card .category-checkBox {
  font-size: 11px;
  color: #666;
  text-transform: capitalize;
}

.shop-page .filter .filter-card .slider-range-content .amount-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.shop-page .filter .filter-card .slider-range-content .amount-input .amount {
  width: 35%;
  -webkit-margin-end: 5%;
  margin-inline-end: 5%;
  border-radius: 7px;
  background-color: #fff;
  font-size: 12px;
  overflow: hidden;
  padding: 6px 10px;
}

.shop-page .filter .filter-card .slider-range-content .amount-input .amount small {
  font-size: 10px;
  color: #999999;
}

.shop-page .filter .filter-card .slider-range-content .amount-input .amount input {
  border: 0;
  width: 100%;
}

.shop-page .filter .filter-card .slider-range-content .amount-input button {
  width: 50px;
  height: 50px;
  background-color: var(--color-blue5);
  border: 0;
  color: #fff;
  border-radius: 7px;
  text-transform: capitalize;
}

.shop-page .filter .filter-card .slider-range-content .ui-slider-horizontal {
  margin-top: 25px;
  height: 6px;
  border: 0;
  background-color: #dfdfdf;
}

.shop-page .filter .filter-card .slider-range-content .ui-slider-horizontal .ui-slider-range {
  background-color: var(--color-blue5);
}

.shop-page .filter .filter-card .slider-range-content .ui-slider-horizontal .ui-state-default,
.shop-page .filter .filter-card .slider-range-content .ui-slider-horizontal .ui-widget-content .ui-state-default,
.shop-page .filter .filter-card .slider-range-content .ui-slider-horizontal .ui-widget-header .ui-state-default,
.shop-page .filter .filter-card .slider-range-content .ui-slider-horizontal .ui-button,
.shop-page .filter .filter-card .slider-range-content .ui-slider-horizontal html .ui-button.ui-state-disabled:hover,
.shop-page .filter .filter-card .slider-range-content .ui-slider-horizontal html .ui-button.ui-state-disabled:active {
  border: 0;
  background: var(--color-blue5);
  border-radius: 50%;
  width: 1em;
  height: 1em;
}

.shop-page .products-content .top-filter {
  padding: 15px 0;
}

.shop-page .products-content .top-filter .r-side .form .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 11px;
}

.shop-page .products-content .top-filter .r-side .form .form-group label {
  color: #999;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-margin-end: 15px;
  margin-inline-end: 15px;
}

.shop-page .products-content .top-filter .r-side .form .form-group .form-select {
  border: 0;
  background-color: #f0f4f8;
  font-size: 11px;
  min-height: 35px;
  border-radius: 12px;
}

.shop-page .products-content .top-filter .r-side .grid-list-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.shop-page .products-content .top-filter .r-side .grid-list-btns .bttn {
  width: 35px;
  height: 35px;
  font-size: 17px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #f0f4f8;
  color: #000;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  cursor: pointer;
}

.shop-page .products-content .top-filter .r-side .grid-list-btns .bttn:hover,
.shop-page .products-content .top-filter .r-side .grid-list-btns .bttn.active {
  color: #fff;
  background-color: var(--color-blue5);
}

.shop-page .products-content .products .product-card {
  border: 1px solid #ededed;
  padding: 30px 15px 15px;
  position: relative;
  border-radius: 12px;
  padding-bottom: 15px;
  overflow: hidden;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-bottom: 24px;
}

.shop-page .products-content .products .product-card:hover {
  padding-bottom: 60px;
}

.shop-page .products-content .products .product-card:hover .img img {
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
}

.shop-page .products-content .products .product-card:hover .info {
  margin-top: -25px;
}

.shop-page .products-content .products .product-card:hover .btn {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.shop-page .products-content .products .product-card .img {
  height: 130px;
}

.shop-page .products-content .products .product-card .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
}

.shop-page .products-content .products .product-card .info {
  margin-top: 20px;
  min-height: 77px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.shop-page .products-content .products .product-card .info .category {
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 7px;
}

.shop-page .products-content .products .product-card .info .title {
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 400;
  margin-bottom: 0;
}

.shop-page .products-content .products .product-card .info .stars {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 9px;
  color: #d0d0d0;
}

.shop-page .products-content .products .product-card .info .stars .active {
  color: #ffba00;
}

.shop-page .products-content .products .product-card .info .rev {
  color: #999;
  font-size: 9px;
}

.shop-page .products-content .products .product-card .price {
  margin-top: 15px;
  font-size: 15px;
  font-weight: bold;
}

.shop-page .products-content .products .product-card .price .price-sale {
  color: #f73312;
}

.shop-page .products-content .products .product-card .price .old-price {
  font-size: 11px;
  color: #999999;
  text-decoration: line-through !important;
  -webkit-margin-start: 5px;
  margin-inline-start: 5px;
}

.shop-page .products-content .products .product-card .btn {
  position: absolute;
  bottom: 15px;
  left: 15px;
  width: calc(100% - 30px);
  background-color: var(--color-blue5);
  padding: 7px 15px;
  -webkit-transform: translateY(70px);
  -ms-transform: translateY(70px);
  transform: translateY(70px);
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.shop-page .products-content .products .product-card .btn span {
  font-size: 11px;
  color: #fff;
  font-weight: bold;
}

.shop-page .products-content .products .product-card .btn:hover {
  background-color: #000;
}

.shop-page .products-content .products .product-card .label {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 9px;
  border-radius: 4px;
  background-color: #f0f0f0;
  color: #000;
  padding: 3px 10px;
  text-transform: uppercase;
}

.shop-page .products-content .products .product-card .label.new {
  background-color: #ffb500;
}

.shop-page .products-content .products .product-card .label.sale-off {
  background-color: #f73312;
  color: #fff;
}

.shop-page .products-content .products .product-card .fav-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 14px;
  color: #ccc;
  cursor: pointer;
}

.shop-page .products-content .products .product-card .fav-btn.active {
  color: #f73312;
}

.shop-page .products-content .products.list-view .card-width {
  width: 50%;
}

.shop-page .products-content .products.list-view .product-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.shop-page .products-content .products.list-view .product-card .img {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 35%;
  margin-right: 10px;
}

.shop-page .products-content .products.list-view .product-card:hover {
  padding-top: 12px;
  padding-bottom: 35px;
}

.shop-page .products-content .products.list-view .product-card:hover .img img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.shop-page .products-content .products.list-view .product-card:hover .info {
  margin-top: 25px;
}

.shop-page.style-grad .filter .filter-card .category-checkRadio .form-check-input:checked~.form-check-label {
  color: var(--color-blue4);
}

.shop-page.style-grad .filter .filter-card .accordion .accordion-button:not(.collapsed) {
  color: var(--color-blue4);
}

.shop-page.style-grad .filter .filter-card .filter-card-item .filter-card-body .filter-card-scroll::-webkit-scrollbar-thumb {
  background: var(--color-blue4);
}

.shop-page.style-grad .filter .filter-card .filter-card-item .filter-card-body .filter-card-scroll::-webkit-scrollbar-corner {
  background: var(--color-blue4);
}

.shop-page.style-grad .filter .filter-card .slider-range-content .amount-input button {
  background-color: var(--color-blue4);
}

.shop-page.style-grad .filter .filter-card .slider-range-content .ui-slider-horizontal .ui-slider-range {
  background-color: var(--color-blue4);
}

.shop-page.style-grad .filter .filter-card .slider-range-content .ui-slider-horizontal .ui-state-default,
.shop-page.style-grad .filter .filter-card .slider-range-content .ui-slider-horizontal .ui-widget-content .ui-state-default,
.shop-page.style-grad .filter .filter-card .slider-range-content .ui-slider-horizontal .ui-widget-header .ui-state-default,
.shop-page.style-grad .filter .filter-card .slider-range-content .ui-slider-horizontal .ui-button,
.shop-page.style-grad .filter .filter-card .slider-range-content .ui-slider-horizontal html .ui-button.ui-state-disabled:hover,
.shop-page.style-grad .filter .filter-card .slider-range-content .ui-slider-horizontal html .ui-button.ui-state-disabled:active {
  background: var(--color-blue4);
}

.shop-page.style-grad .products-content .top-filter .r-side .grid-list-btns .bttn:hover,
.shop-page.style-grad .products-content .top-filter .r-side .grid-list-btns .bttn.active {
  background-color: var(--color-blue4);
}

.shop-page.style-grad .products-content .products .product-card .btn {
  background-color: var(--color-blue4);
}

/* ===============================================================
              [ * shop product Style ] 
  ================================================================*/
/* --------------- services product styles --------------- */
.product-page .product-slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.product-page .product-slider .gallery-thumbs {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-margin-end: 20px;
  margin-inline-end: 20px;
  height: 500px;
}

.product-page .product-slider .gallery-thumbs .swiper-slide {
  min-height: 75px;
  cursor: pointer;
}

.product-page .product-slider .gallery-thumbs .swiper-slide .img {
  width: 80px;
  height: 75px;
  border: 1px solid #9993;
  border-radius: 7px;
  padding: 8px;
}

.product-page .product-slider .gallery-thumbs .swiper-slide .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.product-page .product-slider .gallery-thumbs .swiper-slide.swiper-slide-thumb-active .img {
  border-color: var(--color-blue5);
}

.product-page .product-slider .gallery-top .swiper-slide .img {
  height: 500px;
  padding: 3vw;
  border: 1px solid #9993;
  border-radius: 7px;
}

.product-page .product-slider .gallery-top .swiper-slide .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.product-page .product-info .category {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 7px;
}

.product-page .product-info .title {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 400;
  margin-bottom: 0;
}

.product-page .product-info .stars {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 10px;
  color: #d0d0d0;
}

.product-page .product-info .stars .active {
  color: #ffba00;
}

.product-page .product-info .rev {
  color: #999;
  font-size: 10px;
}

.product-page .product-info .price {
  font-size: 25px;
  color: #000;
  font-weight: bold;
  margin-top: 30px;
}

.product-page .product-info .info-text {
  font-size: 14px;
  color: #666;
  margin-top: 20px;
}

.product-page .product-info .info-list {
  margin-top: 15px;
  font-size: 14px;
  color: #666;
}

.product-page .product-info .info-list li {
  margin-bottom: 5px;
}

.product-page .product-info .color-quantity {
  padding: 20px 0;
  border-top: 1px solid #9993;
  margin-top: 25px;
}

.product-page .product-info .color-quantity .select-color {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-page .product-info .color-quantity .select-color .colors-content .form-check {
  padding: 0;
  margin-bottom: 0;
}

.product-page .product-info .color-quantity .select-color .colors-content .form-check .form-check-input {
  display: none;
}

.product-page .product-info .color-quantity .select-color .colors-content .form-check .form-check-label .color-circle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 3px solid #fff;
}

.product-page .product-info .color-quantity .select-color .colors-content .form-check .form-check-label .color-circle.gray {
  background-color: #e3e3e3;
}

.product-page .product-info .color-quantity .select-color .colors-content .form-check .form-check-label .color-circle.black {
  background-color: #000;
}

.product-page .product-info .color-quantity .select-color .colors-content .form-check .form-check-label .color-circle.blue {
  background-color: #1941c4;
}

.product-page .product-info .color-quantity .select-color .colors-content .form-check .form-check-label .color-circle.green {
  background-color: #9ac419;
}

.product-page .product-info .color-quantity .select-color .colors-content .form-check .form-check-input:checked~.form-check-label .color-circle {
  outline: 1px solid var(--color-blue5);
}

.product-page .product-info .qyt-addCart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-page .product-info .qyt-addCart .add-more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  padding: 10px 15px;
  border: 1px solid #9993;
  width: 30%;
  border-radius: 30px;
}

.product-page .product-info .qyt-addCart .add-more .qt-minus,
.product-page .product-info .qyt-addCart .add-more .qt-plus {
  cursor: pointer;
}

.product-page .product-info .qyt-addCart .add-more .qt {
  font-size: 14px;
}

.product-page .product-info .qyt-addCart .btn {
  background-color: var(--color-blue5);
  padding: 10px 30px;
  width: 40%;
}

.product-page .product-info .qyt-addCart .btn span {
  font-size: 12px;
  color: #fff;
  font-weight: bold;
}

.product-page .product-info .qyt-addCart .btn:hover {
  background-color: #000;
}

.product-page .product-info .fav-btn {
  width: 45px;
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #0002;
  font-size: 15px;
  color: #ccc;
  cursor: pointer;
}

.product-page .product-info .fav-btn.active {
  color: #f73312;
}

.product-page .product-info .socail-icons {
  margin-top: 40px;
}

.product-page .product-info .socail-icons a:hover {
  background-color: var(--color-blue5) !important;
  color: #fff !important;
}

.product-page .product-details .nav-pills {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-bottom: 1px solid #0002;
  padding-bottom: 10px;
}

.product-page .product-details .nav-pills .nav-link {
  border: 0;
  background: transparent;
  font-size: 25px;
  color: #666;
  text-transform: capitalize;
}

.product-page .product-details .nav-pills .nav-link.active {
  color: #000;
  font-weight: bold;
}

.product-page .product-details .content-info {
  padding: 50px 8vw;
}

.product-page .product-details .content-info .text {
  color: #666;
}

.product-page .product-details .additional-info ul {
  border: 1px solid #0002;
}

.product-page .product-details .additional-info ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 25px;
  font-style: 14px;
  color: #666;
  border-bottom: 1px solid #0002;
}

.product-page .product-details .additional-info ul li:last-of-type {
  border: 0;
}

.product-page .product-details .stars {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 12px;
  color: #d0d0d0;
}

.product-page .product-details .stars .active {
  color: #ffba00;
}

.product-page .product-details .rate-stars {
  display: block;
  position: relative;
  text-align: end;
  margin-bottom: 30px;
  direction: rtl;
}

.product-page .product-details .rate-stars input {
  border-radius: 0;
  visibility: hidden;
  width: 20px;
  cursor: pointer;
}

.product-page .product-details .rate-stars input:after {
  content: "\f005";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 20px;
  color: #eee;
  visibility: visible;
  display: inline-block;
  width: 20px;
  height: 20px;
  -webkit-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s;
  -webkit-transition: -webkit-transform 0.6s;
  transition: -webkit-transform 0.6s;
  -o-transition: transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
}

.product-page .product-details .rate-stars input:hover:after {
  opacity: 0.8;
}

.product-page .product-details .rate-stars input:checked:after {
  color: #fc0;
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}

.product-page .product-details .rate-stars input:checked~input:after {
  color: #fc0;
}

.product-page .product-details .rate-stars input:not(:checked):after {
  color: #eee;
}

.product-page .related-products .title {
  font-size: 25px;
  margin-bottom: 35px;
}

.product-page .related-products .related-products-slider .swiper-button-next,
.product-page .related-products .related-products-slider .swiper-button-prev {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #d8dee4;
}

.product-page .related-products .related-products-slider .swiper-button-next:hover,
.product-page .related-products .related-products-slider .swiper-button-prev:hover {
  background: var(--color-blue5);
}

.product-page .related-products .related-products-slider .swiper-button-next,
.product-page .related-products .related-products-slider .swiper-container-rtl .swiper-button-prev {
  right: -50px;
}

.product-page .related-products .related-products-slider .swiper-button-prev,
.product-page .related-products .related-products-slider .swiper-container-rtl .swiper-button-next {
  left: -50px;
}

.product-page .related-products .related-products-slider .swiper-button-next:after,
.product-page .related-products .related-products-slider .swiper-button-prev:after {
  font-size: 10px;
}

.product-page .product-card {
  border: 1px solid #ededed;
  padding: 30px 15px 15px;
  position: relative;
  border-radius: 12px;
  padding-bottom: 15px;
  overflow: hidden;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-bottom: 24px;
}

.product-page .product-card:hover {
  padding-bottom: 60px;
}

.product-page .product-card:hover .img img {
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
}

.product-page .product-card:hover .info {
  margin-top: -25px;
}

.product-page .product-card:hover .btn {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.product-page .product-card .img {
  height: 130px;
}

.product-page .product-card .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
}

.product-page .product-card .info {
  margin-top: 20px;
  min-height: 77px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.product-page .product-card .info .category {
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 7px;
}

.product-page .product-card .info .title {
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 400;
  margin-bottom: 0;
}

.product-page .product-card .info .stars {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 9px;
  color: #d0d0d0;
}

.product-page .product-card .info .stars .active {
  color: #ffba00;
}

.product-page .product-card .info .rev {
  color: #999;
  font-size: 9px;
}

.product-page .product-card .price {
  margin-top: 15px;
  font-size: 15px;
  font-weight: bold;
}

.product-page .product-card .price .price-sale {
  color: #f73312;
}

.product-page .product-card .price .old-price {
  font-size: 11px;
  color: #999999;
  text-decoration: line-through !important;
  -webkit-margin-start: 5px;
  margin-inline-start: 5px;
}

.product-page .product-card .btn {
  position: absolute;
  bottom: 15px;
  left: 15px;
  width: calc(100% - 30px);
  background-color: var(--color-blue5);
  padding: 7px 15px;
  -webkit-transform: translateY(70px);
  -ms-transform: translateY(70px);
  transform: translateY(70px);
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.product-page .product-card .btn span {
  font-size: 11px;
  color: #fff;
  font-weight: bold;
}

.product-page .product-card .btn:hover {
  background-color: #000;
}

.product-page .product-card .label {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 9px;
  border-radius: 4px;
  background-color: #f0f0f0;
  color: #000;
  padding: 3px 10px;
  text-transform: uppercase;
}

.product-page .product-card .label.new {
  background-color: #ffb500;
}

.product-page .product-card .label.sale-off {
  background-color: #f73312;
  color: #fff;
}

.product-page .product-card .fav-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 14px;
  color: #ccc;
  cursor: pointer;
}

.product-page .product-card .fav-btn.active {
  color: #f73312;
}

/* ===============================================================
              [ * responsive style ] 
  ================================================================*/
/* -------------------- responsive style ------------------------- */
@media screen and (max-width: 1100px) {
  .screenshots.style-4 .screenshots-slider .img {
    margin: 0 auto;
    width: 190px;
  }
}

@media screen and (max-width: 991px) {
  .section-padding {
    padding: 70px 0;
  }

  .section-head h2 {
    font-size: 25px;
  }

  .top-navbar.style-1 .top-links {
    display: none;
  }

  .top-navbar.style-1 .r-side {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 0;
  }

  .navbar.style-1 {
    border-bottom: 1px solid #9993;
  }

  .navbar.style-1 .nav-side {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .navbar.style-1 .nav-side .hotline {
    width: 100%;
    margin-bottom: 10px;
    border: 0;
  }

  .portfolio.style-1 .portfolio-slider .swiper-button-next,
  .portfolio.style-1 .portfolio-slider .swiper-button-prev {
    display: none;
  }

  header.style-1 .info {
    margin-bottom: 30px;
  }

  header.style-1 .info .text {
    width: 100%;
  }

  .about.style-1 .content {
    padding: 30px;
  }

  .about.style-1 .content .num-item {
    padding: 15px;
    border: 1px solid #fff5;
    margin: 5px 0;
  }

  .testimonials.style-1 .content .vid_img {
    min-height: auto;
  }

  .testimonials.style-1 .content .info {
    padding: 0;
  }

  .testimonials.style-1 .content .info::after {
    display: none;
  }

  .choose_us.style-1 .choose-us-img,
  .choose_us.style-1 .animated_logos,
  .choose_us.style-1 .choose-us-brands,
  .choose_us.style-1 .choose-us-bubbles {
    display: none;
  }

  .choose_us.style-1 .info .btn {
    margin-top: 30px;
  }

  .team.style-1 .content .team_box {
    width: 100%;
  }

  .blog.style-1 .blog_slider .swiper-button-next,
  .blog.style-1 .blog_slider .swiper-button-prev {
    position: static;
    margin-top: 40px !important;
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .contact.style-1 .content .contact_info ul {
    margin: 15px 0;
  }

  .contact.style-1 .content .contact_form {
    margin-top: 30px;
  }

  footer.style-1 .links {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar.style-2 {
    padding: 25px 0 !important;
    position: relative !important;
    background: var(--color-main-grad);
  }

  .navbar.style-2 .navbar-collapse {
    margin-top: 30px;
  }

  .navbar.style-2 .container {
    border: 0;
  }

  .navbar.style-2 .qoute-nav .btn {
    margin-left: auto;
    margin-right: 0;
  }

  header.style-2 br {
    display: none;
  }

  .section-head h2 {
    letter-spacing: 3px;
    margin-bottom: 30px;
  }

  .choose_us.style-2 {
    overflow: hidden;
  }

  .choose_us.style-2 .choose-numbers {
    margin-top: 50px;
  }

  .choose_us.style-2 .choose-numbers .num-item {
    width: 100%;
    border: 1px solid #fff3;
    margin: 5px 0;
    padding: 15px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .choose_us.style-2 .choose-numbers .num-item br {
    display: none;
  }

  .choose_us.style-2 .choose-numbers .num-item .num {
    width: 100%;
    text-align: initial;
  }

  .reviews_card.style-2 br {
    display: none;
  }

  .pricing.style-2 {
    overflow: hidden;
  }

  .pricing.style-2 .pricing_card.style-2 {
    border: 1px solid var(--color-lightBlue) !important;
    margin: 10px 0 !important;
    border-radius: 0 !important;
  }

  .pricing.style-2 .mt-50 {
    margin-top: 30px;
  }

  .services_projects .service-box {
    border: 0 !important;
  }

  .blog.style-2 {
    overflow: hidden;
  }

  .blog.style-2 .card {
    padding: 10px;
    border: 1px solid #fff3 !important;
  }

  .blog.style-2 .card h4 {
    font-size: 1rem !important;
  }

  .blog.style-2 .mt-80 {
    margin-top: 40px;
  }

  .contact.style-2 {
    padding-bottom: 150px;
  }

  .contact.style-2 h2 {
    letter-spacing: 1px;
  }

  .contact.style-2 .global_2 {
    bottom: -120px;
  }

  .contact.style-2 .mb-70 {
    margin-bottom: 30px;
  }

  .contact.style-2 .mb-50 {
    margin-bottom: 20px;
  }

  footer.style-2 .foot_links {
    text-align: initial !important;
    margin: 30px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  footer.style-2 .foot_links a {
    width: 50%;
    margin: 10px 0 !important;
  }

  footer.style-2 .text-end,
  footer.style-2 .text-center {
    text-align: initial !important;
  }

  .navbar.style-3 {
    position: relative !important;
    background-color: var(--color-blue2);
    padding: 20px 0;
  }

  .navbar.style-3 .navbar-collapse {
    margin-top: 15px;
  }

  header.style-3 {
    padding: 0 0 30px;
  }

  header.style-3 .main-img {
    display: none;
  }

  header.style-3 .content .info .h1 {
    font-size: 28px;
  }

  header.style-3 .content .info .h1 span:before {
    width: 100px;
    background-size: 100px;
  }

  header.style-3 .content .info .h5 {
    margin-top: 40px;
  }

  .style-3 .testi_lines {
    width: calc(100% - 30px) !important;
  }

  .about.style-3 .img-left {
    position: relative;
    top: 0;
    max-width: 100%;
    margin: 50px 0;
  }

  .about.style-3 .img-left .info-circle {
    width: 150px;
    height: 150px;
    outline: 7px solid #fff;
  }

  .about.style-3 .img-left .info-circle h2 {
    font-size: 35px;
  }

  .about.style-3 .img-left .info-circle small {
    font-size: 10px;
    text-transform: uppercase;
  }

  .about.style-3 .img-left .info-circle:nth-of-type(2) {
    width: 120px;
    height: 120px;
  }

  .about.style-3 .img-left .info-circle:nth-of-type(2) h2 {
    font-size: 30px;
  }

  .about.style-3 .img-left .info-circle:nth-of-type(3) {
    width: 100px;
    height: 100px;
  }

  .about.style-3 .img-left .info-circle:nth-of-type(3) h2 {
    font-size: 25px;
  }

  .about.style-3 .img-left .info-circle:nth-of-type(4) {
    width: 110px;
    height: 110px;
  }

  .about.style-3 .img-left .info-circle:nth-of-type(4) h2 {
    font-size: 20px;
  }

  .about.style-3 .info .mb-70 {
    margin-bottom: 30px !important;
  }

  .about.style-3 .btm-content {
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content;
  }

  .about.style-3 .btm-content .mt-60 {
    margin-top: 30px !important;
  }

  .about.style-3 .btm-content .img-right {
    display: none;
  }

  .about.style-3 .top-content {
    margin-bottom: 30px;
  }

  .pricing.style-3 .info {
    margin-bottom: 50px;
  }

  .pricing.style-3 .text.mb-30 {
    margin-bottom: 15px !important;
  }

  .pricing.style-3 .mt-60 {
    margin-top: 30px !important;
  }

  .section-head.style-3 h3 {
    font-size: 21px;
  }

  .blog.style-3 .card .img {
    height: 150px;
    margin-bottom: 5px;
  }

  .blog.style-3 .v_lines {
    display: none;
  }

  .blog.style-3 .client-logos {
    margin-top: 30px !important;
  }

  .blog.style-3 .client-logos .img {
    margin: 20px 0;
  }

  .chat-banner.style-3 .bttns {
    margin-top: 60px;
    text-align: start !important;
  }

  footer.style-3 .items {
    margin-bottom: 50px;
  }

  footer.style-3 .items .title {
    margin-bottom: 20px;
  }

  footer.style-3 .foot {
    margin-top: 0;
  }

  footer.style-3 .foot .logo {
    margin-bottom: 20px;
  }

  .fs-10px {
    font-size: 8px !important;
  }

  .navbar.style-4 .navbar-nav .nav-item .nav-link:before {
    display: none;
  }

  .navbar.style-4 {
    /* background-color: #f0eff5; */
    /* padding: 20px 0;  */
  }

  .navbar.style-4 .navbar-collapse {
    margin-top: 30px;
  }

  .style-4 .mb-60 {
    margin-bottom: 30px !important;
  }

  .style-4 .mb-70 {
    margin-bottom: 40px !important;
  }

  .style-4 br {
    display: none;
  }

  header.style-4 {
    overflow: hidden;
  }

  header.style-4 .content .info {
    margin-bottom: 50px;
  }

  header.style-4 .content .info h1 {
    font-size: 25px;
    line-height: 40px;
    margin-bottom: 15px !important;
  }

  header.style-4 .content .info .title_small {
    margin-bottom: 20px !important;
  }

  header.style-4 .content .info .mt-100 {
    margin-top: 50px !important;
  }

  header.style-4 .content .info .mt-50 {
    margin-top: 30px !important;
  }

  header.style-4 .content .info br {
    display: none;
  }

  header.style-4 .content .info .small,
  header.style-4 .content .info small,
  header.style-4 .content .info .btn {
    font-size: 10px;
  }

  .clients.style-4 .client-logos .img {
    margin: 20px 0;
    text-align: center;
  }

  .features.style-4 .content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .features.style-4 .content .features-card {
    margin: 15px 0;
  }

  .about.style-4 .content {
    padding: 40px 0;
  }

  .testimonials.style-4 {
    overflow: hidden;
  }

  .testimonials.style-4 .content .testi-cards .client_card {
    right: 0 !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 20px 0 !important;
  }

  .testimonials.style-4 .content .testi-cards .client_card .user_img {
    margin-bottom: 30px;
  }

  .testimonials.style-4 .content .testi-cards .client_card .inf_content {
    text-align: center;
  }

  .testimonials.style-4 .content .testi-cards .client_card .inf_content br {
    display: none;
  }

  .pricing.style-4 .content .row .col-lg-6:first-of-type .price-card {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border: 1px solid #e9e8ee;
    margin-right: 0;
    margin-bottom: 30px;
  }

  .pricing.style-4 .content .row .col-lg-6:nth-of-type(2) .price-card {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .faq.style-3.style-4 {
    overflow: hidden;
  }

  .faq.style-3.style-4 .col-lg-6:nth-of-type(1) .accordion-item.border-0 {
    border-bottom: 1px solid #9995 !important;
  }

  .community.style-4 .content,
  .community.style-5 .content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 20px !important;
  }

  .community.style-4 .content .commun-card,
  .community.style-5 .content .commun-card {
    width: 100%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    padding: 30px !important;
  }

  .section-head.style-4 h2 {
    font-size: 25px;
  }

  footer.style-4 {
    margin-top: 30px;
  }

  footer.style-4 .d-flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    /* -ms-flex-direction: column; */
    /* flex-direction: column;  */
  }

  footer.style-4 .d-flex .btn {
    width: 100%;
    margin: 10px 0;
  }

  footer.style-4 .d-flex .btn.me-4 {
    margin-right: 0 !important;
  }

  footer.style-4 .foot .links {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 30px 0;
  }

  footer.style-4 .foot .links li {
    width: 50%;
    margin: 5px 0;
  }

  footer.style-4 .foot .links li a {
    margin-left: 0;
  }

  footer.style-4 .wave {
    left: 0;
    width: 100%;
  }

  .screenshots.style-4 .screenshots-slider {
    width: 100%;
    left: 0;
  }

  .screenshots.style-4 .screenshots-slider .img {
    margin: 0 auto;
    width: 190px;
  }

  footer.style-4 .foot .logo {
    width: 160px;
  }

  .navbar.style-5 {
    border-bottom: 1px solid #9999;
  }

  .navbar.style-5 .navbar-collapse {
    margin-top: 20px;
    padding: 15px 0;
    background: #fff9;
  }

  .navbar.style-5 .nav-item .nav-link {
    padding-left: 0;
  }

  .section-head.style-5 h2 {
    font-size: 30px;
  }

  .about.style-5 .content .img {
    overflow: hidden;
    margin-bottom: 30px;
    padding-bottom: 50px;
  }

  .about.style-5 .content .img.main-img3 .img-body {
    max-height: 300px;
  }

  .download.style-5 .content h2 {
    font-size: 28px;
  }

  .download.style-5 .content h2 span {
    padding-bottom: 10px;
  }

  .download.style-5 .btn {
    margin: 10px 0;
  }

  .download.style-5 .btn.sm-butn span {
    font-size: 10px;
  }

  footer.style-5 .items {
    margin-bottom: 50px;
  }

  footer.style-5 .items .title {
    margin-bottom: 20px;
  }

  footer.style-5 .foot {
    margin-top: 0;
  }

  footer.style-5 .foot .logo {
    margin-bottom: 20px;
  }

  .style-6 br {
    display: none;
  }

  .navbar.style-6 .container-fluid {
    padding: 0 20px;
  }

  .navbar.style-6 .container-fluid .navbar-collapse {
    margin-top: 20px;
  }

  header.style-6 .content {
    padding: 100px 0 20px;
  }

  header.style-6 .info h1,
  header.style-6 .info h1 span small {
    font-size: 30px;
    line-height: 1.5;
  }

  header.style-6 .info .text {
    margin-top: 20px;
  }

  header.style-6 .info .text br {
    display: none;
  }

  header.style-6 .info .form {
    border-radius: 15px;
  }

  header.style-6 .info .form .form-group {
    width: 100%;
    margin: 8px 0;
  }

  header.style-6 .info .form .btn {
    width: 100%;
    margin-top: 15px;
  }

  .navbar.style-6 .nav-item .nav-link:after {
    display: inline-block !important;
  }

  header.style-6 {
    padding: 0;
  }

  header.style-6 .content {
    border-radius: 0;
  }

  .section-head.style-6 h2,
  .section-head.style-6 h2 span small {
    font-size: 30px;
  }

  .about.style-6 {
    overflow: hidden;
  }

  .about.style-6 .info {
    margin-bottom: 30px;
  }

  .service-card.style-6 {
    margin: 15px;
  }

  .section-head.d-flex {
    display: block !important;
  }

  .projects.style-6 {
    overflow: hidden;
    padding: 0;
  }

  .projects.style-6 .content {
    border-radius: 0;
  }

  .projects.style-6 .content .project-card.style-6 {
    margin: 0;
  }

  .testimonials.style-6 {
    overflow: hidden;
  }

  .choose-us.style-6 {
    overflow: hidden;
  }

  .choose-us.style-6 .info {
    padding: 0 15px;
    margin-top: 30px;
  }

  .testimonials.style-6 .img {
    margin-top: 150px;
  }

  .clients.style-5 .content .img.img-card {
    width: 47%;
  }

  .number-card.style-6 {
    margin: 15px 0;
    border: 0 !important;
  }

  .team.style-6 {
    padding: 0;
  }

  .team.style-6 .content {
    border-radius: 0;
    padding: 70px 0;
  }

  .team.style-6 .content .text-end {
    text-align: left !important;
    margin-top: 10px;
  }

  .team-card.style-6 .img .social-icons a {
    -webkit-transform: translateY(0) !important;
    -ms-transform: translateY(0) !important;
    transform: translateY(0) !important;
    opacity: 1;
  }

  .blog.style-6 .content {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .blog.style-6 .content .blog-card {
    width: 100% !important;
    margin-bottom: 30px;
  }

  .blog.style-6 .content .blog-card:last-of-type {
    margin: 0;
  }

  .blog.style-6 .content .blog-card::after,
  .blog.style-6 .content .blog-card::before {
    display: none;
  }

  footer.style-6 {
    padding-top: 40px;
  }

  footer.style-6 .items {
    margin-top: 30px;
  }

  footer.style-6 .items .title {
    margin-bottom: 15px;
  }

  footer.style-6 .foot {
    padding-top: 30px;
    line-height: 1.8;
  }

  footer.style-6 .foot .logo {
    margin-bottom: 20px;
  }

  .blog-page.style-1 .popular-posts .border-end {
    border: 0 !important;
  }

  .about-page header.style-5 .info h1 {
    font-size: 30px;
  }

  .about-page header.style-5 .main-vid {
    height: 350px;
  }

  .about-page .choose-us.style-6 .info {
    padding: 30px 15px;
  }

  .about-page .clients .section-head {
    padding: 0 15px;
  }

  .about-page .culture {
    padding: 70px 15px;
  }

  .contact.style-6 .content .contact_message {
    right: 0;
    top: 30px;
  }

  .contact-page.style-5 .contact .content .contact_message {
    right: 0;
    top: 30px;
  }

  .contact-page.style-5 .section-head {
    margin-bottom: 50px !important;
  }

  .contact-page.style-5 .mb-100 {
    margin-bottom: 70px !important;
  }

  .contact-page.style-5 .ltspc-20 {
    letter-spacing: 3px !important;
    margin-bottom: 30px !important;
  }

  .contact-page.style-5 h4 {
    font-size: 18px;
  }

  .shop-page .filter {
    margin-bottom: 30px;
  }

  .shop-page .products-content .products.list-view .card-width {
    width: 100%;
  }

  .product-page .related-products .related-products-slider .swiper-button-next,
  .product-page .related-products .related-products-slider .swiper-container-rtl .swiper-button-prev {
    right: 5px;
  }

  .product-page .related-products .related-products-slider .swiper-button-prev,
  .product-page .related-products .related-products-slider .swiper-container-rtl .swiper-button-next {
    left: 5px;
  }

  .product-page .product-slider {
    overflow: hidden;
  }

  .product-page .product-slider {
    margin-bottom: 50px;
  }

  .product-page .product-slider .gallery-top .swiper-slide .img,
  .product-page .product-slider .gallery-thumbs {
    height: 370px;
  }

  .product-page .product-details .nav-pills .nav-link {
    font-size: 18px;
  }

  .product-page .product-info .qyt-addCart .add-more {
    width: 25%;
  }
}

/* ======== */
/* ======= */