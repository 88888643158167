:root {
    --secondary: #5b586e;
    --light: #f4f5f7;
    --dark: #222032;
    --white: #fff;
    --gray-200: #dde1ec;
    --gray-300: #b3b1c3;
    --black: #000
}

/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
    --bs-blue: #395aff;
    --bs-indigo: #6610f2;
    --bs-purple: #a659ff;
    --bs-pink: #e05fc4;
    --bs-red: #ff3838;
    --bs-orange: #ff7e1b;
    --bs-yellow: #ffc21d;
    --bs-green: #3ECA76;
    --bs-teal: #20c997;
    --bs-cyan: #20bbd3;
    --bs-white: #fff;
    --bs-gray: #5b586e;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f4f5f7;
    --bs-gray-200: #dde1ec;
    --bs-gray-300: #b3b1c3;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #5b586e;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #222032;
    --bs-primary: #395aff;
    --bs-secondary: #5b586e;
    --bs-success: #3ECA76;
    --bs-info: #20bbd3;
    --bs-warning: #ffc21d;
    --bs-danger: #ff3838;
    --bs-light: #f4f5f7;
    --bs-dark: #222032;
    --bs-primary-rgb: 57, 90, 255;
    --bs-secondary-rgb: 91, 88, 110;
    --bs-success-rgb: 62, 202, 118;
    --bs-info-rgb: 32, 187, 211;
    --bs-warning-rgb: 255, 194, 29;
    --bs-danger-rgb: 255, 56, 56;
    --bs-light-rgb: 244, 245, 247;
    --bs-dark-rgb: 34, 32, 50;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 34, 32, 50;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #222032;
    --bs-body-bg: #fff
}

*,
*::before,
*::after {
    box-sizing: border-box
}

@media(prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth
    }
}

body {
    margin: 0;
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: .25
}

hr:not([size]) {
    height: 1px
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2
}

h1,
.h1 {
    font-size: calc(1.375rem + 1.5vw)
}

@media(min-width: 1200px) {

    h1,
    .h1 {
        font-size: 2.5rem
    }
}

h2,
.h2 {
    font-size: calc(1.325rem + 0.9vw)
}

@media(min-width: 1200px) {

    h2,
    .h2 {
        font-size: 2rem
    }
}

h3,
.h3 {
    font-size: calc(1.3rem + 0.6vw)
}

@media(min-width: 1200px) {

    h3,
    .h3 {
        font-size: 1.75rem
    }
}

h4,
.h4 {
    font-size: calc(1.275rem + 0.3vw)
}

@media(min-width: 1200px) {

    h4,
    .h4 {
        font-size: 1.5rem
    }
}

h5,
.h5 {
    font-size: 1.25rem
}

h6,
.h6 {
    font-size: 1rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[title],
abbr[data-bs-original-title] {
    text-decoration: underline dotted;
    cursor: help;
    text-decoration-skip-ink: none
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

ol,
ul {
    padding-left: 2rem
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,
strong {
    font-weight: bolder
}

small,
.small {
    font-size: 0.875em
}

mark,
.mark {
    padding: .2em;
    background-color: #fcf8e3
}

sub,
sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -0.25em
}

sup {
    top: -0.5em
}

a {
    color: #395aff;
    text-decoration: underline
}

a:hover {
    color: #2e48cc
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
}

pre,
code,
kbd,
samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em;
    direction: ltr
        /* rtl:ignore */
    ;
    unicode-bidi: bidi-override
}

pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 0.875em
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

code {
    font-size: 0.875em;
    color: #e05fc4;
    word-wrap: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: 0.875em;
    color: #fff;
    background-color: #222032;
    border-radius: .2rem
}

kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700
}

figure {
    margin: 0 0 1rem
}

img,
svg {
    vertical-align: middle
}

table {
    caption-side: bottom;
    border-collapse: collapse
}

caption {
    padding-top: .5rem;
    padding-bottom: .5rem;
    color: #5b586e;
    text-align: left
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent
}

thead,
tbody,
tfoot,
tr,
td,
th {
    border-color: inherit;
    border-style: solid;
    border-width: 0
}

label {
    display: inline-block
}

button {
    border-radius: 0
}

button:focus:not(:focus-visible) {
    outline: 0
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
select {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

select:disabled {
    opacity: 1
}

[list]::-webkit-calendar-picker-indicator {
    display: none
}

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
    cursor: pointer
}

::-moz-focus-inner {
    padding: 0;
    border-style: none
}

textarea {
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit
}

@media(min-width: 1200px) {
    legend {
        font-size: 1.5rem
    }
}

legend+* {
    clear: left
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
    padding: 0
}

::-webkit-inner-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: textfield
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
    padding: 0
}

::file-selector-button {
    font: inherit
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

iframe {
    border: 0
}

summary {
    display: list-item;
    cursor: pointer
}

progress {
    vertical-align: baseline
}

[hidden] {
    display: none !important
}

.lead {
    font-size: 1.25rem;
    font-weight: 300
}

.display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 300;
    line-height: 1.2
}

@media(min-width: 1200px) {
    .display-1 {
        font-size: 5rem
    }
}

.display-2 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 300;
    line-height: 1.2
}

@media(min-width: 1200px) {
    .display-2 {
        font-size: 4.5rem
    }
}

.display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 300;
    line-height: 1.2
}

@media(min-width: 1200px) {
    .display-3 {
        font-size: 4rem
    }
}

.display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 300;
    line-height: 1.2
}

@media(min-width: 1200px) {
    .display-4 {
        font-size: 3.5rem
    }
}

.display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 300;
    line-height: 1.2
}

@media(min-width: 1200px) {
    .display-5 {
        font-size: 3rem
    }
}

.display-6 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 300;
    line-height: 1.2
}

@media(min-width: 1200px) {
    .display-6 {
        font-size: 2.5rem
    }
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: 0.875em;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem
}

.blockquote>:last-child {
    margin-bottom: 0
}

.blockquote-footer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: 0.875em;
    color: #5b586e
}

.blockquote-footer::before {
    content: "— "
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #b3b1c3;
    border-radius: .25rem;
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: 0.875em;
    color: #5b586e
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto
}

@media(min-width: 576px) {

    .container-sm,
    .container {
        max-width: 540px
    }
}

@media(min-width: 768px) {

    .container-md,
    .container-sm,
    .container {
        max-width: 720px
    }
}

@media(min-width: 992px) {

    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 960px
    }
}

@media(min-width: 1200px) {

    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1140px
    }
}

@media(min-width: 1400px) {

    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1320px
    }
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1*var(--bs-gutter-y));
    margin-right: calc(-0.5*var(--bs-gutter-x));
    margin-left: calc(-0.5*var(--bs-gutter-x))
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)*.5);
    padding-left: calc(var(--bs-gutter-x)*.5);
    margin-top: var(--bs-gutter-y)
}

.col {
    flex: 1 0 0%
}

.row-cols-auto>* {
    flex: 0 0 auto;
    width: auto
}

.row-cols-1>* {
    flex: 0 0 auto;
    width: 100%
}

.row-cols-2>* {
    flex: 0 0 auto;
    width: 50%
}

.row-cols-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
}

.row-cols-4>* {
    flex: 0 0 auto;
    width: 25%
}

.row-cols-5>* {
    flex: 0 0 auto;
    width: 20%
}

.row-cols-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
}

.col-auto {
    flex: 0 0 auto;
    width: auto
}

.col-1 {
    flex: 0 0 auto;
    width: 8.33333333%
}

.col-2 {
    flex: 0 0 auto;
    width: 16.66666667%
}

.col-3 {
    flex: 0 0 auto;
    width: 25%
}

.col-4 {
    flex: 0 0 auto;
    width: 33.33333333%
}

.col-5 {
    flex: 0 0 auto;
    width: 41.66666667%
}

.col-6 {
    flex: 0 0 auto;
    width: 50%
}

.col-7 {
    flex: 0 0 auto;
    width: 58.33333333%
}

.col-8 {
    flex: 0 0 auto;
    width: 66.66666667%
}

.col-9 {
    flex: 0 0 auto;
    width: 75%
}

.col-10 {
    flex: 0 0 auto;
    width: 83.33333333%
}

.col-11 {
    flex: 0 0 auto;
    width: 91.66666667%
}

.col-12 {
    flex: 0 0 auto;
    width: 100%
}

.offset-1 {
    margin-left: 8.33333333%
}

.offset-2 {
    margin-left: 16.66666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333333%
}

.offset-5 {
    margin-left: 41.66666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333333%
}

.offset-8 {
    margin-left: 66.66666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333333%
}

.offset-11 {
    margin-left: 91.66666667%
}

.g-0,
.gx-0 {
    --bs-gutter-x: 0
}

.g-0,
.gy-0 {
    --bs-gutter-y: 0
}

.g-1,
.gx-1 {
    --bs-gutter-x: 0.25rem
}

.g-1,
.gy-1 {
    --bs-gutter-y: 0.25rem
}

.g-2,
.gx-2 {
    --bs-gutter-x: 0.5rem
}

.g-2,
.gy-2 {
    --bs-gutter-y: 0.5rem
}

.g-3,
.gx-3 {
    --bs-gutter-x: 1rem
}

.g-3,
.gy-3 {
    --bs-gutter-y: 1rem
}

.g-4,
.gx-4 {
    --bs-gutter-x: 1.5rem
}

.g-4,
.gy-4 {
    --bs-gutter-y: 1.5rem
}

.g-5,
.gx-5 {
    --bs-gutter-x: 3rem
}

.g-5,
.gy-5 {
    --bs-gutter-y: 3rem
}

@media(min-width: 576px) {
    .col-sm {
        flex: 1 0 0%
    }

    .row-cols-sm-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-sm-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-sm-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-sm-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-sm-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-sm-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-sm-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-sm-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333333%
    }

    .offset-sm-2 {
        margin-left: 16.66666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333333%
    }

    .offset-sm-5 {
        margin-left: 41.66666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333333%
    }

    .offset-sm-8 {
        margin-left: 66.66666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333333%
    }

    .offset-sm-11 {
        margin-left: 91.66666667%
    }

    .g-sm-0,
    .gx-sm-0 {
        --bs-gutter-x: 0
    }

    .g-sm-0,
    .gy-sm-0 {
        --bs-gutter-y: 0
    }

    .g-sm-1,
    .gx-sm-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-sm-1,
    .gy-sm-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-sm-2,
    .gx-sm-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-sm-2,
    .gy-sm-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-sm-3,
    .gx-sm-3 {
        --bs-gutter-x: 1rem
    }

    .g-sm-3,
    .gy-sm-3 {
        --bs-gutter-y: 1rem
    }

    .g-sm-4,
    .gx-sm-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-sm-4,
    .gy-sm-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-sm-5,
    .gx-sm-5 {
        --bs-gutter-x: 3rem
    }

    .g-sm-5,
    .gy-sm-5 {
        --bs-gutter-y: 3rem
    }
}

@media(min-width: 768px) {
    .col-md {
        flex: 1 0 0%
    }

    .row-cols-md-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-md-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-md-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-md-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-md-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-md-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-md-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-md-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-md-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-md-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-md-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-md-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-md-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-md-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-md-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-md-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-md-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-md-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333333%
    }

    .offset-md-2 {
        margin-left: 16.66666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333333%
    }

    .offset-md-5 {
        margin-left: 41.66666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333333%
    }

    .offset-md-8 {
        margin-left: 66.66666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333333%
    }

    .offset-md-11 {
        margin-left: 91.66666667%
    }

    .g-md-0,
    .gx-md-0 {
        --bs-gutter-x: 0
    }

    .g-md-0,
    .gy-md-0 {
        --bs-gutter-y: 0
    }

    .g-md-1,
    .gx-md-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-md-1,
    .gy-md-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-md-2,
    .gx-md-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-md-2,
    .gy-md-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-md-3,
    .gx-md-3 {
        --bs-gutter-x: 1rem
    }

    .g-md-3,
    .gy-md-3 {
        --bs-gutter-y: 1rem
    }

    .g-md-4,
    .gx-md-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-md-4,
    .gy-md-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-md-5,
    .gx-md-5 {
        --bs-gutter-x: 3rem
    }

    .g-md-5,
    .gy-md-5 {
        --bs-gutter-y: 3rem
    }
}

@media(min-width: 992px) {
    .col-lg {
        flex: 1 0 0%
    }

    .row-cols-lg-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-lg-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-lg-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-lg-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-lg-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-lg-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-lg-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-lg-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-lg-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-lg-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-lg-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-lg-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-lg-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333333%
    }

    .offset-lg-2 {
        margin-left: 16.66666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333333%
    }

    .offset-lg-5 {
        margin-left: 41.66666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333333%
    }

    .offset-lg-8 {
        margin-left: 66.66666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333333%
    }

    .offset-lg-11 {
        margin-left: 91.66666667%
    }

    .g-lg-0,
    .gx-lg-0 {
        --bs-gutter-x: 0
    }

    .g-lg-0,
    .gy-lg-0 {
        --bs-gutter-y: 0
    }

    .g-lg-1,
    .gx-lg-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-lg-1,
    .gy-lg-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-lg-2,
    .gx-lg-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-lg-2,
    .gy-lg-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-lg-3,
    .gx-lg-3 {
        --bs-gutter-x: 1rem
    }

    .g-lg-3,
    .gy-lg-3 {
        --bs-gutter-y: 1rem
    }

    .g-lg-4,
    .gx-lg-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-lg-4,
    .gy-lg-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-lg-5,
    .gx-lg-5 {
        --bs-gutter-x: 3rem
    }

    .g-lg-5,
    .gy-lg-5 {
        --bs-gutter-y: 3rem
    }
}

@media(min-width: 1200px) {
    .col-xl {
        flex: 1 0 0%
    }

    .row-cols-xl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xl-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xl-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333333%
    }

    .offset-xl-2 {
        margin-left: 16.66666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333333%
    }

    .offset-xl-5 {
        margin-left: 41.66666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333333%
    }

    .offset-xl-8 {
        margin-left: 66.66666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333333%
    }

    .offset-xl-11 {
        margin-left: 91.66666667%
    }

    .g-xl-0,
    .gx-xl-0 {
        --bs-gutter-x: 0
    }

    .g-xl-0,
    .gy-xl-0 {
        --bs-gutter-y: 0
    }

    .g-xl-1,
    .gx-xl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xl-1,
    .gy-xl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xl-2,
    .gx-xl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xl-2,
    .gy-xl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xl-3,
    .gx-xl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xl-3,
    .gy-xl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xl-4,
    .gx-xl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xl-4,
    .gy-xl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xl-5,
    .gx-xl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xl-5,
    .gy-xl-5 {
        --bs-gutter-y: 3rem
    }
}

@media(min-width: 1400px) {
    .col-xxl {
        flex: 1 0 0%
    }

    .row-cols-xxl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xxl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xxl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xxl-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xxl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xxl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xxl-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xxl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xxl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xxl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xxl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xxl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xxl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xxl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xxl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xxl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xxl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xxl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xxl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xxl-0 {
        margin-left: 0
    }

    .offset-xxl-1 {
        margin-left: 8.33333333%
    }

    .offset-xxl-2 {
        margin-left: 16.66666667%
    }

    .offset-xxl-3 {
        margin-left: 25%
    }

    .offset-xxl-4 {
        margin-left: 33.33333333%
    }

    .offset-xxl-5 {
        margin-left: 41.66666667%
    }

    .offset-xxl-6 {
        margin-left: 50%
    }

    .offset-xxl-7 {
        margin-left: 58.33333333%
    }

    .offset-xxl-8 {
        margin-left: 66.66666667%
    }

    .offset-xxl-9 {
        margin-left: 75%
    }

    .offset-xxl-10 {
        margin-left: 83.33333333%
    }

    .offset-xxl-11 {
        margin-left: 91.66666667%
    }

    .g-xxl-0,
    .gx-xxl-0 {
        --bs-gutter-x: 0
    }

    .g-xxl-0,
    .gy-xxl-0 {
        --bs-gutter-y: 0
    }

    .g-xxl-1,
    .gx-xxl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xxl-1,
    .gy-xxl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xxl-2,
    .gx-xxl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xxl-2,
    .gy-xxl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xxl-3,
    .gx-xxl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xxl-3,
    .gy-xxl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xxl-4,
    .gx-xxl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xxl-4,
    .gy-xxl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xxl-5,
    .gx-xxl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xxl-5,
    .gy-xxl-5 {
        --bs-gutter-y: 3rem
    }
}

.table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #222032;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: #222032;
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: #222032;
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: #222032;
    vertical-align: top;
    border-color: #b3b1c3
}

.table>:not(caption)>*>* {
    padding: .5rem .5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
}

.table>tbody {
    vertical-align: inherit
}

.table>thead {
    vertical-align: bottom
}

.table>:not(:first-child) {
    border-top: 2px solid currentColor
}

.caption-top {
    caption-side: top
}

.table-sm>:not(caption)>*>* {
    padding: .25rem .25rem
}

.table-bordered>:not(caption)>* {
    border-width: 1px 0
}

.table-bordered>:not(caption)>*>* {
    border-width: 0 1px
}

.table-borderless>:not(caption)>*>* {
    border-bottom-width: 0
}

.table-borderless>:not(:first-child) {
    border-top-width: 0
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color)
}

.table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color)
}

.table-hover>tbody>tr:hover>* {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color)
}

.table-primary {
    --bs-table-bg: #d7deff;
    --bs-table-striped-bg: #ccd3f2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #c2c8e6;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #c7cdec;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #c2c8e6
}

.table-secondary {
    --bs-table-bg: #dedee2;
    --bs-table-striped-bg: #d3d3d7;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #c8c8cb;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #cdcdd1;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #c8c8cb
}

.table-success {
    --bs-table-bg: #d8f4e4;
    --bs-table-striped-bg: #cde8d9;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #c2dccd;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #c8e2d3;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #c2dccd
}

.table-info {
    --bs-table-bg: #d2f1f6;
    --bs-table-striped-bg: #c8e5ea;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bdd9dd;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #c2dfe4;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #bdd9dd
}

.table-warning {
    --bs-table-bg: #fff3d2;
    --bs-table-striped-bg: #f2e7c8;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e6dbbd;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #ece1c2;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #e6dbbd
}

.table-danger {
    --bs-table-bg: #ffd7d7;
    --bs-table-striped-bg: #f2cccc;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e6c2c2;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #ecc7c7;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #e6c2c2
}

.table-light {
    --bs-table-bg: #f4f5f7;
    --bs-table-striped-bg: #e8e9eb;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dcddde;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e2e3e4;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #dcddde
}

.table-dark {
    --bs-table-bg: #222032;
    --bs-table-striped-bg: #2d2b3c;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #383647;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #333141;
    --bs-table-hover-color: #fff;
    color: #fff;
    border-color: #383647
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

@media(max-width: 575.98px) {
    .table-responsive-sm {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media(max-width: 767.98px) {
    .table-responsive-md {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media(max-width: 991.98px) {
    .table-responsive-lg {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media(max-width: 1199.98px) {
    .table-responsive-xl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media(max-width: 1399.98px) {
    .table-responsive-xxl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

.form-label {
    margin-bottom: .5rem
}

.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem
}

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem
}

.form-text {
    margin-top: .25rem;
    font-size: 0.875em;
    color: #5b586e
}

.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #222032;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .form-control {
        transition: none
    }
}

.form-control[type=file] {
    overflow: hidden
}

.form-control:focus {
    color: #222032;
    background-color: #fff;
    border-color: #9cadff;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(57, 90, 255, .25)
}

.form-control::-webkit-date-and-time-value {
    height: 1.5em
}

.form-control::placeholder {
    color: #5b586e;
    opacity: 1
}

.form-control::file-selector-button {
    padding: .375rem .75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: .75rem;
    color: #222032;
    background-color: #dde1ec;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .form-control::file-selector-button {
        transition: none
    }
}

.form-control::-webkit-file-upload-button {
    padding: .375rem .75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: .75rem;
    color: #222032;
    background-color: #dde1ec;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .form-control::-webkit-file-upload-button {
        transition: none
    }
}


.form-control-plaintext {
    display: block;
    width: 100%;
    padding: .375rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #222032;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    min-height: calc(1.5em + 0.5rem + 2px);
    padding: .25rem .5rem;
    font-size: 0.875rem;
    border-radius: .2rem
}

.form-control-sm::file-selector-button {
    padding: .25rem .5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: .5rem
}

.form-control-sm::-webkit-file-upload-button {
    padding: .25rem .5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: .5rem
}

.form-control-lg {
    min-height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.form-control-lg::file-selector-button {
    padding: .5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem
}

.form-control-lg::-webkit-file-upload-button {
    padding: .5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem
}

textarea.form-control {
    min-height: calc(1.5em + 0.75rem + 2px)
}

textarea.form-control-sm {
    min-height: calc(1.5em + 0.5rem + 2px)
}

textarea.form-control-lg {
    min-height: calc(1.5em + 1rem + 2px)
}

.form-control-color {
    width: 3rem;
    height: auto;
    padding: .375rem
}

.form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: .25rem
}

.form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: .25rem
}

.form-select {
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #222032;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none
}

@media(prefers-reduced-motion: reduce) {
    .form-select {
        transition: none
    }
}

.form-select:focus {
    border-color: #9cadff;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(57, 90, 255, .25)
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
    padding-right: .75rem;
    background-image: none
}

.form-select:disabled {
    background-color: #dde1ec
}

.form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #222032
}

.form-select-sm {
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: .5rem;
    font-size: 0.875rem;
    border-radius: .2rem
}

.form-select-lg {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: .125rem
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.5em
}

.form-check-input {
    width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    appearance: none;
    print-color-adjust: exact
}

.form-check-input[type=checkbox] {
    border-radius: .25em
}

.form-check-input[type=radio] {
    border-radius: 50%
}

.form-check-input:active {
    filter: brightness(90%)
}

.form-check-input:focus {
    border-color: #9cadff;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(57, 90, 255, .25)
}

.form-check-input:checked {
    background-color: #395aff;
    border-color: #395aff
}

.form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check-input[type=checkbox]:indeterminate {
    background-color: #395aff;
    border-color: #395aff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
}

.form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: .5
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
    opacity: .5
}

.form-switch {
    padding-left: 2.5em
}

.form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position .15s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .form-switch .form-check-input {
        transition: none
    }
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%239cadff'/%3e%3c/svg%3e")
}

.form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check-inline {
    display: inline-block;
    margin-right: 1rem
}

.btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.btn-check[disabled]+.btn,
.btn-check:disabled+.btn {
    pointer-events: none;
    filter: none;
    opacity: .65
}

.form-range {
    width: 100%;
    height: 1.5rem;
    padding: 0;
    background-color: transparent;
    appearance: none
}

.form-range:focus {
    outline: 0
}

.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(57, 90, 255, .25)
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(57, 90, 255, .25)
}

.form-range::-moz-focus-outer {
    border: 0
}

.form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #395aff;
    border: 0;
    border-radius: 1rem;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none
}

@media(prefers-reduced-motion: reduce) {
    .form-range::-webkit-slider-thumb {
        transition: none
    }
}

.form-range::-webkit-slider-thumb:active {
    background-color: #c4ceff
}

.form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #b3b1c3;
    border-color: transparent;
    border-radius: 1rem
}

.form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #395aff;
    border: 0;
    border-radius: 1rem;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none
}

@media(prefers-reduced-motion: reduce) {
    .form-range::-moz-range-thumb {
        transition: none
    }
}

.form-range::-moz-range-thumb:active {
    background-color: #c4ceff
}

.form-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #b3b1c3;
    border-color: transparent;
    border-radius: 1rem
}

.form-range:disabled {
    pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd
}

.form-range:disabled::-moz-range-thumb {
    background-color: #adb5bd
}

.form-floating {
    position: relative
}

.form-floating>.form-control,
.form-floating>.form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25
}

.form-floating>label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem .75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .form-floating>label {
        transition: none
    }
}

.form-floating>.form-control {
    padding: 1rem .75rem
}

.form-floating>.form-control::placeholder {
    color: transparent
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-select {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    opacity: .65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem)
}

.form-floating>.form-control:-webkit-autofill~label {
    opacity: .65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem)
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.input-group>.form-control,
.input-group>.form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0
}

.input-group>.form-control:focus,
.input-group>.form-select:focus {
    z-index: 3
}

.input-group .btn {
    position: relative;
    z-index: 2
}

.input-group .btn:focus {
    z-index: 3
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #222032;
    text-align: center;
    white-space: nowrap;
    background-color: #dde1ec;
    border: 1px solid #ced4da;
    border-radius: .25rem
}

.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text,
.input-group-lg>.btn {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text,
.input-group-sm>.btn {
    padding: .25rem .5rem;
    font-size: 0.875rem;
    border-radius: .2rem
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
    padding-right: 3rem
}

.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 0.875em;
    color: #3eca76
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    color: #000;
    background-color: rgba(62, 202, 118, .9);
    border-radius: .25rem
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
    display: block
}

.was-validated .form-control:valid,
.form-control.is-valid {
    border-color: #3eca76;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233ECA76' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
    border-color: #3eca76;
    box-shadow: 0 0 0 .25rem rgba(62, 202, 118, .25)
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem)
}

.was-validated .form-select:valid,
.form-select.is-valid {
    border-color: #3eca76
}

.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233ECA76' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right .75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
    border-color: #3eca76;
    box-shadow: 0 0 0 .25rem rgba(62, 202, 118, .25)
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
    border-color: #3eca76
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
    background-color: #3eca76
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
    box-shadow: 0 0 0 .25rem rgba(62, 202, 118, .25)
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
    color: #3eca76
}

.form-check-inline .form-check-input~.valid-feedback {
    margin-left: .5em
}

.was-validated .input-group .form-control:valid,
.input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
    z-index: 1
}

.was-validated .input-group .form-control:valid:focus,
.input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
    z-index: 3
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 0.875em;
    color: #ff3838
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    color: #000;
    background-color: rgba(255, 56, 56, .9);
    border-radius: .25rem
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
    display: block
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: #ff3838;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff3838'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff3838' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
    border-color: #ff3838;
    box-shadow: 0 0 0 .25rem rgba(255, 56, 56, .25)
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem)
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
    border-color: #ff3838
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff3838'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff3838' stroke='none'/%3e%3c/svg%3e");
    background-position: right .75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
    border-color: #ff3838;
    box-shadow: 0 0 0 .25rem rgba(255, 56, 56, .25)
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
    border-color: #ff3838
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
    background-color: #ff3838
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 .25rem rgba(255, 56, 56, .25)
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
    color: #ff3838
}

.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: .5em
}

.was-validated .input-group .form-control:invalid,
.input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
    z-index: 2
}

.was-validated .input-group .form-control:invalid:focus,
.input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
    z-index: 3
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #222032;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: #222032
}

.btn-check:focus+.btn,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(57, 90, 255, .25)
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
    pointer-events: none;
    opacity: .65
}

.btn-primary {
    color: #fff;
    background-color: #395aff;
    border-color: #395aff
}

.btn-primary:hover {
    color: #fff;
    background-color: #304dd9;
    border-color: #2e48cc
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
    color: #fff;
    background-color: #304dd9;
    border-color: #2e48cc;
    box-shadow: 0 0 0 .25rem rgba(87, 115, 255, .5)
}

.btn-check:checked+.btn-primary,
.btn-check:active+.btn-primary,
.btn-primary:active,
.btn-primary.active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #2e48cc;
    border-color: #2b44bf
}

.btn-check:checked+.btn-primary:focus,
.btn-check:active+.btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(87, 115, 255, .5)
}

.btn-primary:disabled,
.btn-primary.disabled {
    color: #fff;
    background-color: #395aff;
    border-color: #395aff
}

.btn-secondary {
    color: #fff;
    background-color: #5b586e;
    border-color: #5b586e
}

.btn-secondary:hover {
    color: #fff;
    background-color: #4d4b5e;
    border-color: #494658
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
    color: #fff;
    background-color: #4d4b5e;
    border-color: #494658;
    box-shadow: 0 0 0 .25rem rgba(116, 113, 132, .5)
}

.btn-check:checked+.btn-secondary,
.btn-check:active+.btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #494658;
    border-color: #444253
}

.btn-check:checked+.btn-secondary:focus,
.btn-check:active+.btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(116, 113, 132, .5)
}

.btn-secondary:disabled,
.btn-secondary.disabled {
    color: #fff;
    background-color: #5b586e;
    border-color: #5b586e
}

.btn-success {
    color: #000;
    background-color: #3eca76;
    border-color: #3eca76
}

.btn-success:hover {
    color: #000;
    background-color: #5bd28b;
    border-color: #51cf84
}

.btn-check:focus+.btn-success,
.btn-success:focus {
    color: #000;
    background-color: #5bd28b;
    border-color: #51cf84;
    box-shadow: 0 0 0 .25rem rgba(53, 172, 100, .5)
}

.btn-check:checked+.btn-success,
.btn-check:active+.btn-success,
.btn-success:active,
.btn-success.active,
.show>.btn-success.dropdown-toggle {
    color: #000;
    background-color: #65d591;
    border-color: #51cf84
}

.btn-check:checked+.btn-success:focus,
.btn-check:active+.btn-success:focus,
.btn-success:active:focus,
.btn-success.active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(53, 172, 100, .5)
}

.btn-success:disabled,
.btn-success.disabled {
    color: #000;
    background-color: #3eca76;
    border-color: #3eca76
}

.btn-info {
    color: #000;
    background-color: #20bbd3;
    border-color: #20bbd3
}

.btn-info:hover {
    color: #000;
    background-color: #41c5da;
    border-color: #36c2d7
}

.btn-check:focus+.btn-info,
.btn-info:focus {
    color: #000;
    background-color: #41c5da;
    border-color: #36c2d7;
    box-shadow: 0 0 0 .25rem rgba(27, 159, 179, .5)
}

.btn-check:checked+.btn-info,
.btn-check:active+.btn-info,
.btn-info:active,
.btn-info.active,
.show>.btn-info.dropdown-toggle {
    color: #000;
    background-color: #4dc9dc;
    border-color: #36c2d7
}

.btn-check:checked+.btn-info:focus,
.btn-check:active+.btn-info:focus,
.btn-info:active:focus,
.btn-info.active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(27, 159, 179, .5)
}

.btn-info:disabled,
.btn-info.disabled {
    color: #000;
    background-color: #20bbd3;
    border-color: #20bbd3
}

.btn-warning {
    color: #000;
    background-color: #ffc21d;
    border-color: #ffc21d
}

.btn-warning:hover {
    color: #000;
    background-color: #ffcb3f;
    border-color: #ffc834
}

.btn-check:focus+.btn-warning,
.btn-warning:focus {
    color: #000;
    background-color: #ffcb3f;
    border-color: #ffc834;
    box-shadow: 0 0 0 .25rem rgba(217, 165, 25, .5)
}

.btn-check:checked+.btn-warning,
.btn-check:active+.btn-warning,
.btn-warning:active,
.btn-warning.active,
.show>.btn-warning.dropdown-toggle {
    color: #000;
    background-color: #ffce4a;
    border-color: #ffc834
}

.btn-check:checked+.btn-warning:focus,
.btn-check:active+.btn-warning:focus,
.btn-warning:active:focus,
.btn-warning.active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(217, 165, 25, .5)
}

.btn-warning:disabled,
.btn-warning.disabled {
    color: #000;
    background-color: #ffc21d;
    border-color: #ffc21d
}

.btn-danger {
    color: #000;
    background-color: #ff3838;
    border-color: #ff3838
}

.btn-danger:hover {
    color: #000;
    background-color: #ff5656;
    border-color: #ff4c4c
}

.btn-check:focus+.btn-danger,
.btn-danger:focus {
    color: #000;
    background-color: #ff5656;
    border-color: #ff4c4c;
    box-shadow: 0 0 0 .25rem rgba(217, 48, 48, .5)
}

.btn-check:checked+.btn-danger,
.btn-check:active+.btn-danger,
.btn-danger:active,
.btn-danger.active,
.show>.btn-danger.dropdown-toggle {
    color: #000;
    background-color: #ff6060;
    border-color: #ff4c4c
}

.btn-check:checked+.btn-danger:focus,
.btn-check:active+.btn-danger:focus,
.btn-danger:active:focus,
.btn-danger.active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(217, 48, 48, .5)
}

.btn-danger:disabled,
.btn-danger.disabled {
    color: #000;
    background-color: #ff3838;
    border-color: #ff3838
}

.btn-light {
    color: #000;
    background-color: #f4f5f7;
    border-color: #f4f5f7
}

.btn-light:hover {
    color: #000;
    background-color: #f6f7f8;
    border-color: #f5f6f8
}

.btn-check:focus+.btn-light,
.btn-light:focus {
    color: #000;
    background-color: #f6f7f8;
    border-color: #f5f6f8;
    box-shadow: 0 0 0 .25rem rgba(207, 208, 210, .5)
}

.btn-check:checked+.btn-light,
.btn-check:active+.btn-light,
.btn-light:active,
.btn-light.active,
.show>.btn-light.dropdown-toggle {
    color: #000;
    background-color: #f6f7f9;
    border-color: #f5f6f8
}

.btn-check:checked+.btn-light:focus,
.btn-check:active+.btn-light:focus,
.btn-light:active:focus,
.btn-light.active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(207, 208, 210, .5)
}

.btn-light:disabled,
.btn-light.disabled {
    color: #000;
    background-color: #f4f5f7;
    border-color: #f4f5f7
}

.btn-dark {
    color: #fff;
    background-color: #222032;
    border-color: #222032
}

.btn-dark:hover {
    color: #fff;
    background-color: #1d1b2b;
    border-color: #1b1a28
}

.btn-check:focus+.btn-dark,
.btn-dark:focus {
    color: #fff;
    background-color: #1d1b2b;
    border-color: #1b1a28;
    box-shadow: 0 0 0 .25rem rgba(67, 65, 81, .5)
}

.btn-check:checked+.btn-dark,
.btn-check:active+.btn-dark,
.btn-dark:active,
.btn-dark.active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1b1a28;
    border-color: #1a1826
}

.btn-check:checked+.btn-dark:focus,
.btn-check:active+.btn-dark:focus,
.btn-dark:active:focus,
.btn-dark.active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(67, 65, 81, .5)
}

.btn-dark:disabled,
.btn-dark.disabled {
    color: #fff;
    background-color: #222032;
    border-color: #222032
}

.btn-outline-primary {
    color: #395aff;
    border-color: #395aff
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #395aff;
    border-color: #395aff
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 .25rem rgba(57, 90, 255, .5)
}

.btn-check:checked+.btn-outline-primary,
.btn-check:active+.btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: #395aff;
    border-color: #395aff
}

.btn-check:checked+.btn-outline-primary:focus,
.btn-check:active+.btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(57, 90, 255, .5)
}

.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
    color: #395aff;
    background-color: transparent
}

.btn-outline-secondary {
    color: #5b586e;
    border-color: #5b586e
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #5b586e;
    border-color: #5b586e
}

.btn-check:focus+.btn-outline-secondary,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 .25rem rgba(91, 88, 110, .5)
}

.btn-check:checked+.btn-outline-secondary,
.btn-check:active+.btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
    color: #fff;
    background-color: #5b586e;
    border-color: #5b586e
}

.btn-check:checked+.btn-outline-secondary:focus,
.btn-check:active+.btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(91, 88, 110, .5)
}

.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
    color: #5b586e;
    background-color: transparent
}

.btn-outline-success {
    color: #3eca76;
    border-color: #3eca76
}

.btn-outline-success:hover {
    color: #000;
    background-color: #3eca76;
    border-color: #3eca76
}

.btn-check:focus+.btn-outline-success,
.btn-outline-success:focus {
    box-shadow: 0 0 0 .25rem rgba(62, 202, 118, .5)
}

.btn-check:checked+.btn-outline-success,
.btn-check:active+.btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
    color: #000;
    background-color: #3eca76;
    border-color: #3eca76
}

.btn-check:checked+.btn-outline-success:focus,
.btn-check:active+.btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(62, 202, 118, .5)
}

.btn-outline-success:disabled,
.btn-outline-success.disabled {
    color: #3eca76;
    background-color: transparent
}

.btn-outline-info {
    color: #20bbd3;
    border-color: #20bbd3
}

.btn-outline-info:hover {
    color: #000;
    background-color: #20bbd3;
    border-color: #20bbd3
}

.btn-check:focus+.btn-outline-info,
.btn-outline-info:focus {
    box-shadow: 0 0 0 .25rem rgba(32, 187, 211, .5)
}

.btn-check:checked+.btn-outline-info,
.btn-check:active+.btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
    color: #000;
    background-color: #20bbd3;
    border-color: #20bbd3
}

.btn-check:checked+.btn-outline-info:focus,
.btn-check:active+.btn-outline-info:focus,
.btn-outline-info:active:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(32, 187, 211, .5)
}

.btn-outline-info:disabled,
.btn-outline-info.disabled {
    color: #20bbd3;
    background-color: transparent
}

.btn-outline-warning {
    color: #ffc21d;
    border-color: #ffc21d
}

.btn-outline-warning:hover {
    color: #000;
    background-color: #ffc21d;
    border-color: #ffc21d
}

.btn-check:focus+.btn-outline-warning,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 .25rem rgba(255, 194, 29, .5)
}

.btn-check:checked+.btn-outline-warning,
.btn-check:active+.btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
    color: #000;
    background-color: #ffc21d;
    border-color: #ffc21d
}

.btn-check:checked+.btn-outline-warning:focus,
.btn-check:active+.btn-outline-warning:focus,
.btn-outline-warning:active:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(255, 194, 29, .5)
}

.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
    color: #ffc21d;
    background-color: transparent
}

.btn-outline-danger {
    color: #ff3838;
    border-color: #ff3838
}

.btn-outline-danger:hover {
    color: #000;
    background-color: #ff3838;
    border-color: #ff3838
}

.btn-check:focus+.btn-outline-danger,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 .25rem rgba(255, 56, 56, .5)
}

.btn-check:checked+.btn-outline-danger,
.btn-check:active+.btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
    color: #000;
    background-color: #ff3838;
    border-color: #ff3838
}

.btn-check:checked+.btn-outline-danger:focus,
.btn-check:active+.btn-outline-danger:focus,
.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(255, 56, 56, .5)
}

.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
    color: #ff3838;
    background-color: transparent
}

.btn-outline-light {
    color: #f4f5f7;
    border-color: #f4f5f7
}

.btn-outline-light:hover {
    color: #000;
    background-color: #f4f5f7;
    border-color: #f4f5f7
}

.btn-check:focus+.btn-outline-light,
.btn-outline-light:focus {
    box-shadow: 0 0 0 .25rem rgba(244, 245, 247, .5)
}

.btn-check:checked+.btn-outline-light,
.btn-check:active+.btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
    color: #000;
    background-color: #f4f5f7;
    border-color: #f4f5f7
}

.btn-check:checked+.btn-outline-light:focus,
.btn-check:active+.btn-outline-light:focus,
.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(244, 245, 247, .5)
}

.btn-outline-light:disabled,
.btn-outline-light.disabled {
    color: #f4f5f7;
    background-color: transparent
}

.btn-outline-dark {
    color: #222032;
    border-color: #222032
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #222032;
    border-color: #222032
}

.btn-check:focus+.btn-outline-dark,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 .25rem rgba(34, 32, 50, .5)
}

.btn-check:checked+.btn-outline-dark,
.btn-check:active+.btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #222032;
    border-color: #222032
}

.btn-check:checked+.btn-outline-dark:focus,
.btn-check:active+.btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(34, 32, 50, .5)
}

.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
    color: #222032;
    background-color: transparent
}

.btn-link {
    font-weight: 400;
    color: #395aff;
    text-decoration: underline
}

.btn-link:hover {
    color: #2e48cc
}

.btn-link:disabled,
.btn-link.disabled {
    color: #5b586e
}

.btn-lg,
.btn-group-lg>.btn {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.btn-sm,
.btn-group-sm>.btn {
    padding: .25rem .5rem;
    font-size: 0.875rem;
    border-radius: .2rem
}

.fade {
    transition: opacity .15s linear
}

@media(prefers-reduced-motion: reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media(prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none
    }
}

.collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width .35s ease
}

@media(prefers-reduced-motion: reduce) {
    .collapsing.collapse-horizontal {
        transition: none
    }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #222032;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem
}

.dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: .125rem
}

.dropdown-menu-start {
    --bs-position: start
}

.dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0
}

.dropdown-menu-end {
    --bs-position: end
}

.dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto
}

@media(min-width: 576px) {
    .dropdown-menu-sm-start {
        --bs-position: start
    }

    .dropdown-menu-sm-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-sm-end {
        --bs-position: end
    }

    .dropdown-menu-sm-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media(min-width: 768px) {
    .dropdown-menu-md-start {
        --bs-position: start
    }

    .dropdown-menu-md-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-md-end {
        --bs-position: end
    }

    .dropdown-menu-md-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media(min-width: 992px) {
    .dropdown-menu-lg-start {
        --bs-position: start
    }

    .dropdown-menu-lg-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-lg-end {
        --bs-position: end
    }

    .dropdown-menu-lg-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media(min-width: 1200px) {
    .dropdown-menu-xl-start {
        --bs-position: start
    }

    .dropdown-menu-xl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xl-end {
        --bs-position: end
    }

    .dropdown-menu-xl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media(min-width: 1400px) {
    .dropdown-menu-xxl-start {
        --bs-position: start
    }

    .dropdown-menu-xxl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xxl-end {
        --bs-position: end
    }

    .dropdown-menu-xxl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: .125rem
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: .125rem
}

.dropend .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid
}

.dropend .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropend .dropdown-toggle::after {
    vertical-align: 0
}

.dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: .125rem
}

.dropstart .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: ""
}

.dropstart .dropdown-toggle::after {
    display: none
}

.dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent
}

.dropstart .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropstart .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid rgba(0, 0, 0, .15)
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #222032;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #1f1d2d;
    background-color: #dde1ec
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #395aff
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: .5rem 1rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #5b586e;
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: .25rem 1rem;
    color: #222032
}

.dropdown-menu-dark {
    color: #b3b1c3;
    background-color: #343a40;
    border-color: rgba(0, 0, 0, .15)
}

.dropdown-menu-dark .dropdown-item {
    color: #b3b1c3
}

.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
    color: #fff;
    background-color: rgba(255, 255, 255, .15)
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
    color: #fff;
    background-color: #395aff
}

.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
    color: #adb5bd
}

.dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, .15)
}

.dropdown-menu-dark .dropdown-item-text {
    color: #b3b1c3
}

.dropdown-menu-dark .dropdown-header {
    color: #adb5bd
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle
}

.btn-group>.btn,
.btn-group-vertical>.btn {
    position: relative;
    flex: 1 1 auto
}

.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn:hover,
.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn:hover,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
    z-index: 1
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
    margin-left: -1px
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn:nth-child(n+3),
.btn-group>:not(.btn-check)+.btn,
.btn-group>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: .5625rem;
    padding-left: .5625rem
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
    margin-left: 0
}

.dropstart .dropdown-toggle-split::before {
    margin-right: 0
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
    margin-top: -1px
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn~.btn,
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

@media(prefers-reduced-motion: reduce) {
    .nav-link {
        transition: none
    }
}

.nav-link:hover,
.nav-link:focus {
    color: #2e48cc
}

.nav-link.disabled {
    color: #5b586e;
    pointer-events: none;
    cursor: default
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-sm,
.navbar>.container-md,
.navbar>.container-lg,
.navbar>.container-xl,
.navbar>.container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between
}

.navbar-brand {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static
}

.navbar-text {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .navbar-toggler {
        transition: none
    }
}

.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 .25rem
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%
}

.navbar-nav-scroll {
    max-height: var(--bs-scroll-height, 75vh);
    overflow-y: auto
}

@media(min-width: 576px) {
    .navbar-expand-sm {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-sm .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }

    .navbar-expand-sm .offcanvas-header {
        display: none
    }

    .navbar-expand-sm .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none
    }

    .navbar-expand-sm .offcanvas-top,
    .navbar-expand-sm .offcanvas-bottom {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }

    .navbar-expand-sm .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media(min-width: 768px) {
    .navbar-expand-md {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-md .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }

    .navbar-expand-md .offcanvas-header {
        display: none
    }

    .navbar-expand-md .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none
    }

    .navbar-expand-md .offcanvas-top,
    .navbar-expand-md .offcanvas-bottom {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }

    .navbar-expand-md .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media(min-width: 992px) {
    .navbar-expand-lg {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }

    .navbar-expand-lg .offcanvas-header {
        display: none
    }

    .navbar-expand-lg .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none
    }

    .navbar-expand-lg .offcanvas-top,
    .navbar-expand-lg .offcanvas-bottom {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }

    .navbar-expand-lg .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media(min-width: 1200px) {
    .navbar-expand-xl {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }

    .navbar-expand-xl .offcanvas-header {
        display: none
    }

    .navbar-expand-xl .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none
    }

    .navbar-expand-xl .offcanvas-top,
    .navbar-expand-xl .offcanvas-bottom {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }

    .navbar-expand-xl .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media(min-width: 1400px) {
    .navbar-expand-xxl {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xxl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xxl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xxl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xxl .navbar-toggler {
        display: none
    }

    .navbar-expand-xxl .offcanvas-header {
        display: none
    }

    .navbar-expand-xxl .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none
    }

    .navbar-expand-xxl .offcanvas-top,
    .navbar-expand-xxl .offcanvas-bottom {
        height: auto;
        border-top: 0;
        border-bottom: 0
    }

    .navbar-expand-xxl .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

.navbar-expand {
    flex-wrap: nowrap;
    justify-content: flex-start
}

.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
}

.navbar-expand .navbar-nav-scroll {
    overflow: visible
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-expand .offcanvas-header {
    display: none
}

.navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none
}

.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0
}

.navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .55)
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, .7)
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, .3)
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, .55);
    border-color: rgba(0, 0, 0, .1)
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, .55)
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, .9)
}

.navbar-dark .navbar-brand {
    color: #fff
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .55)
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, .75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, .25)
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .nav-link.active {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, .55);
    border-color: rgba(255, 255, 255, .1)
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, .55)
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
    color: #fff
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group {
    border-top: inherit;
    border-bottom: inherit
}

.card>.list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px)
}

.card>.list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px)
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
    border-top: 0
}

.card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem
}

.card-title {
    margin-bottom: .5rem
}

.card-subtitle {
    margin-top: -0.25rem;
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link+.card-link {
    margin-left: 1rem
}

.card-header {
    padding: .5rem 1rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125)
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0
}

.card-footer {
    padding: .5rem 1rem;
    background-color: rgba(0, 0, 0, .03);
    border-top: 1px solid rgba(0, 0, 0, .125)
}

.card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px)
}

.card-header-tabs {
    margin-right: -0.5rem;
    margin-bottom: -0.5rem;
    margin-left: -0.5rem;
    border-bottom: 0
}

.card-header-pills {
    margin-right: -0.5rem;
    margin-left: -0.5rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-radius: calc(0.25rem - 1px)
}

.card-img,
.card-img-top,
.card-img-bottom {
    width: 100%
}

.card-img,
.card-img-top {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px)
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px)
}

.card-group>.card {
    margin-bottom: .75rem
}

@media(min-width: 576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap
    }

    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-img-top,
    .card-group>.card:not(:last-child) .card-header {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-img-bottom,
    .card-group>.card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-img-top,
    .card-group>.card:not(:first-child) .card-header {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-img-bottom,
    .card-group>.card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0
    }
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 1rem;
    list-style: none
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: .5rem;
    color: #5b586e;
    content: var(--bs-breadcrumb-divider, "https://orinostudio.com/")
        /* rtl: var(--bs-breadcrumb-divider, "/") */
}

.breadcrumb-item.active {
    color: #5b586e
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none
}

.page-link {
    position: relative;
    display: block;
    color: #395aff;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #b3b1c3;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .page-link {
        transition: none
    }
}

.page-link:hover {
    z-index: 2;
    color: #2e48cc;
    background-color: #dde1ec;
    border-color: #b3b1c3
}

.page-link:focus {
    z-index: 3;
    color: #2e48cc;
    background-color: #dde1ec;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(57, 90, 255, .25)
}

.page-item:not(:first-child) .page-link {
    margin-left: -1px
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #395aff;
    border-color: #395aff
}

.page-item.disabled .page-link {
    color: #5b586e;
    pointer-events: none;
    background-color: #fff;
    border-color: #b3b1c3
}

.page-link {
    padding: .375rem .75rem
}

.page-item:first-child .page-link {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1.25rem
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: 0.875rem
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
}

.badge {
    display: inline-block;
    padding: .35em .65em;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.alert {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 700
}

.alert-dismissible {
    padding-right: 3rem
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem
}

.alert-primary {
    color: #223699;
    background-color: #d7deff;
    border-color: #c4ceff
}

.alert-primary .alert-link {
    color: #1b2b7a
}

.alert-secondary {
    color: #373542;
    background-color: #dedee2;
    border-color: #cecdd4
}

.alert-secondary .alert-link {
    color: #2c2a35
}

.alert-success {
    color: #257947;
    background-color: #d8f4e4;
    border-color: #c5efd6
}

.alert-success .alert-link {
    color: #1e6139
}

.alert-info {
    color: #13707f;
    background-color: #d2f1f6;
    border-color: #bcebf2
}

.alert-info .alert-link {
    color: #0f5a66
}

.alert-warning {
    color: #664e0c;
    background-color: #fff3d2;
    border-color: #ffedbb
}

.alert-warning .alert-link {
    color: #523e0a
}

.alert-danger {
    color: #922;
    background-color: #ffd7d7;
    border-color: #ffc3c3
}

.alert-danger .alert-link {
    color: #7a1b1b
}

.alert-light {
    color: #626263;
    background-color: #fdfdfd;
    border-color: #fcfcfd
}

.alert-light .alert-link {
    color: #4e4e4f
}

.alert-dark {
    color: #14131e;
    background-color: #d3d2d6;
    border-color: #bdbcc2
}

.alert-dark .alert-link {
    color: #100f18
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #dde1ec;
    border-radius: .25rem
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #395aff;
    transition: width .6s ease
}

@media(prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem
}

.progress-bar-animated {
    animation: 1s linear infinite progress-bar-stripes
}

@media(prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        animation: none
    }
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: .25rem
}

.list-group-numbered {
    list-style-type: none;
    counter-reset: section
}

.list-group-numbered>li::before {
    content: counters(section, ".") ". ";
    counter-increment: section
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit
}

.list-group-item-action:hover,
.list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f4f5f7
}

.list-group-item-action:active {
    color: #222032;
    background-color: #dde1ec
}

.list-group-item {
    position: relative;
    display: block;
    padding: .5rem 1rem;
    color: #222032;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125)
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #5b586e;
    pointer-events: none;
    background-color: #fff
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #395aff;
    border-color: #395aff
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px
}

.list-group-horizontal {
    flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
}

@media(min-width: 576px) {
    .list-group-horizontal-sm {
        flex-direction: row
    }

    .list-group-horizontal-sm>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width: 768px) {
    .list-group-horizontal-md {
        flex-direction: row
    }

    .list-group-horizontal-md>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-md>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width: 992px) {
    .list-group-horizontal-lg {
        flex-direction: row
    }

    .list-group-horizontal-lg>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width: 1200px) {
    .list-group-horizontal-xl {
        flex-direction: row
    }

    .list-group-horizontal-xl>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width: 1400px) {
    .list-group-horizontal-xxl {
        flex-direction: row
    }

    .list-group-horizontal-xxl>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

.list-group-flush {
    border-radius: 0
}

.list-group-flush>.list-group-item {
    border-width: 0 0 1px
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-primary {
    color: #223699;
    background-color: #d7deff
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: #223699;
    background-color: #c2c8e6
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #223699;
    border-color: #223699
}

.list-group-item-secondary {
    color: #373542;
    background-color: #dedee2
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
    color: #373542;
    background-color: #c8c8cb
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #373542;
    border-color: #373542
}

.list-group-item-success {
    color: #257947;
    background-color: #d8f4e4
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
    color: #257947;
    background-color: #c2dccd
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #257947;
    border-color: #257947
}

.list-group-item-info {
    color: #13707f;
    background-color: #d2f1f6
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
    color: #13707f;
    background-color: #bdd9dd
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #13707f;
    border-color: #13707f
}

.list-group-item-warning {
    color: #664e0c;
    background-color: #fff3d2
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
    color: #664e0c;
    background-color: #e6dbbd
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #664e0c;
    border-color: #664e0c
}

.list-group-item-danger {
    color: #922;
    background-color: #ffd7d7
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
    color: #922;
    background-color: #e6c2c2
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #922;
    border-color: #922
}

.list-group-item-light {
    color: #626263;
    background-color: #fdfdfd
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
    color: #626263;
    background-color: #e4e4e4
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #626263;
    border-color: #626263
}

.list-group-item-dark {
    color: #14131e;
    background-color: #d3d2d6
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
    color: #14131e;
    background-color: #bebdc1
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #14131e;
    border-color: #14131e
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: .25rem;
    opacity: .5
}

.btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75
}

.btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(57, 90, 255, .25);
    opacity: 1
}

.btn-close:disabled,
.btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: .25
}

.btn-close-white {
    filter: invert(1) grayscale(100%) brightness(200%)
}

.toast {
    width: 350px;
    max-width: 100%;
    font-size: 0.875rem;
    pointer-events: auto;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    border-radius: .25rem
}

.toast.showing {
    opacity: 0
}

.toast:not(.show) {
    display: none
}

.toast-container {
    width: max-content;
    max-width: 100%;
    pointer-events: none
}

.toast-container>:not(:last-child) {
    margin-bottom: .75rem
}

.toast-header {
    display: flex;
    align-items: center;
    padding: .5rem .75rem;
    color: #5b586e;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px)
}

.toast-header .btn-close {
    margin-right: -0.375rem;
    margin-left: .75rem
}

.toast-body {
    padding: .75rem;
    word-wrap: break-word
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0, -50px)
}

@media(prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal.show .modal-dialog {
    transform: none
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem)
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #000
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .5
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #b3b1c3;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px)
}

.modal-header .btn-close {
    padding: .5rem .5rem;
    margin: -0.5rem -0.5rem -0.5rem auto
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid #b3b1c3;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px)
}

.modal-footer>* {
    margin: .25rem
}

@media(min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto
    }

    .modal-dialog-scrollable {
        height: calc(100% - 3.5rem)
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem)
    }

    .modal-sm {
        max-width: 300px
    }
}

@media(min-width: 992px) {

    .modal-lg,
    .modal-xl {
        max-width: 800px
    }
}

@media(min-width: 1200px) {
    .modal-xl {
        max-width: 1140px
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
}

.modal-fullscreen .modal-header {
    border-radius: 0
}

.modal-fullscreen .modal-body {
    overflow-y: auto
}

.modal-fullscreen .modal-footer {
    border-radius: 0
}

@media(max-width: 575.98px) {
    .modal-fullscreen-sm-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-sm-down .modal-footer {
        border-radius: 0
    }
}

@media(max-width: 767.98px) {
    .modal-fullscreen-md-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-md-down .modal-footer {
        border-radius: 0
    }
}

@media(max-width: 991.98px) {
    .modal-fullscreen-lg-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-lg-down .modal-footer {
        border-radius: 0
    }
}

@media(max-width: 1199.98px) {
    .modal-fullscreen-xl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-xl-down .modal-footer {
        border-radius: 0
    }
}

@media(max-width: 1399.98px) {
    .modal-fullscreen-xxl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-xxl-down .modal-footer {
        border-radius: 0
    }
}

.tooltip {
    position: absolute;
    z-index: 1080;
    display: block;
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: .9
}

.tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .tooltip-arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^=top] {
    padding: .4rem 0
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
    bottom: 0
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    top: -1px;
    border-width: .4rem .4rem 0;
    border-top-color: #000
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^=right] {
    padding: 0 .4rem
}

.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
    left: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
    right: -1px;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #000
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^=bottom] {
    padding: .4rem 0
}

.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
    top: 0
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #000
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^=left] {
    padding: 0 .4rem
}

.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
    right: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
    left: -1px;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #000
}

.tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: .25rem
}

.popover {
    position: absolute;
    top: 0;
    left: 0
        /* rtl:ignore */
    ;
    z-index: 1070;
    display: block;
    max-width: 276px;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem
}

.popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: .5rem
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-popover-top>.popover-arrow,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow {
    bottom: calc(-0.5rem - 1px)
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: rgba(0, 0, 0, .25)
}

.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after {
    bottom: 1px;
    border-width: .5rem .5rem 0;
    border-top-color: #fff
}

.bs-popover-end>.popover-arrow,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow {
    left: calc(-0.5rem - 1px);
    width: .5rem;
    height: 1rem
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: rgba(0, 0, 0, .25)
}

.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after {
    left: 1px;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff
}

.bs-popover-bottom>.popover-arrow,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow {
    top: calc(-0.5rem - 1px)
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before {
    top: 0;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: rgba(0, 0, 0, .25)
}

.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after {
    top: 1px;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: #fff
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f0f0f0
}

.bs-popover-start>.popover-arrow,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow {
    right: calc(-0.5rem - 1px);
    width: .5rem;
    height: 1rem
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: rgba(0, 0, 0, .25)
}

.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after {
    right: 1px;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff
}

.popover-header {
    padding: .5rem 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f0f0f0;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px)
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: 1rem 1rem;
    color: #222032
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: block
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
    transform: translateX(100%)
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media(prefers-reduced-motion: reduce) {

    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
        transition: none
    }
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: none;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease
}

@media(prefers-reduced-motion: reduce) {

    .carousel-control-prev,
    .carousel-control-next {
        transition: none
    }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease
}

@media(prefers-reduced-motion: reduce) {
    .carousel-indicators [data-bs-target] {
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
    filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #000
}

.carousel-dark .carousel-caption {
    color: #000
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg)
            /* rtl:ignore */
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: .75s linear infinite spinner-border
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: .2em
}

@keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1;
        transform: none
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: .75s linear infinite spinner-grow
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem
}

@media(prefers-reduced-motion: reduce) {

    .spinner-border,
    .spinner-grow {
        animation-duration: 1.5s
    }
}

.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    transition: transform .3s ease-in-out
}

@media(prefers-reduced-motion: reduce) {
    .offcanvas {
        transition: none
    }
}

.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000
}

.offcanvas-backdrop.fade {
    opacity: 0
}

.offcanvas-backdrop.show {
    opacity: .5
}

.offcanvas-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem
}

.offcanvas-header .btn-close {
    padding: .5rem .5rem;
    margin-top: -0.5rem;
    margin-right: -0.5rem;
    margin-bottom: -0.5rem
}

.offcanvas-title {
    margin-bottom: 0;
    line-height: 1.5
}

.offcanvas-body {
    flex-grow: 1;
    padding: 1rem 1rem;
    overflow-y: auto
}

.offcanvas-start {
    top: 0;
    left: 0;
    width: 400px;
    border-right: 1px solid rgba(0, 0, 0, .2);
    transform: translateX(-100%)
}

.offcanvas-end {
    top: 0;
    right: 0;
    width: 400px;
    border-left: 1px solid rgba(0, 0, 0, .2);
    transform: translateX(100%)
}

.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    transform: translateY(-100%)
}

.offcanvas-bottom {
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-top: 1px solid rgba(0, 0, 0, .2);
    transform: translateY(100%)
}

.offcanvas.show {
    transform: none
}

.placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentColor;
    opacity: .5
}

.placeholder.btn::before {
    display: inline-block;
    content: ""
}

.placeholder-xs {
    min-height: .6em
}

.placeholder-sm {
    min-height: .8em
}

.placeholder-lg {
    min-height: 1.2em
}

.placeholder-glow .placeholder {
    animation: placeholder-glow 2s ease-in-out infinite
}

@keyframes placeholder-glow {
    50% {
        opacity: .2
    }
}

.placeholder-wave {
    mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
    mask-size: 200% 100%;
    animation: placeholder-wave 2s linear infinite
}

@keyframes placeholder-wave {
    100% {
        mask-position: -200% 0%
    }
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.link-primary {
    color: #395aff
}

.link-primary:hover,
.link-primary:focus {
    color: #2e48cc
}

.link-secondary {
    color: #5b586e
}

.link-secondary:hover,
.link-secondary:focus {
    color: #494658
}

.link-success {
    color: #3eca76
}

.link-success:hover,
.link-success:focus {
    color: #65d591
}

.link-info {
    color: #20bbd3
}

.link-info:hover,
.link-info:focus {
    color: #4dc9dc
}

.link-warning {
    color: #ffc21d
}

.link-warning:hover,
.link-warning:focus {
    color: #ffce4a
}

.link-danger {
    color: #ff3838
}

.link-danger:hover,
.link-danger:focus {
    color: #ff6060
}

.link-light {
    color: #f4f5f7
}

.link-light:hover,
.link-light:focus {
    color: #f6f7f9
}

.link-dark {
    color: #222032
}

.link-dark:hover,
.link-dark:focus {
    color: #1b1a28
}

.ratio {
    position: relative;
    width: 100%
}

.ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""
}

.ratio>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.ratio-1x1 {
    --bs-aspect-ratio: 100%
}

.ratio-4x3 {
    --bs-aspect-ratio: 75%
}

.ratio-16x9 {
    --bs-aspect-ratio: 56.25%
}

.ratio-21x9 {
    --bs-aspect-ratio: 42.8571428571%
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020
}

@media(min-width: 576px) {
    .sticky-sm-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media(min-width: 768px) {
    .sticky-md-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media(min-width: 992px) {
    .sticky-lg-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media(min-width: 1200px) {
    .sticky-xl-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media(min-width: 1400px) {
    .sticky-xxl-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.hstack {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch
}

.vstack {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-self: stretch
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ""
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.vr {
    display: inline-block;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentColor;
    opacity: .25
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.float-start {
    float: left !important
}

.float-end {
    float: right !important
}

.float-none {
    float: none !important
}

.opacity-0 {
    opacity: 0 !important
}

.opacity-25 {
    opacity: .25 !important
}

.opacity-50 {
    opacity: .5 !important
}

.opacity-75 {
    opacity: .75 !important
}

.opacity-100 {
    opacity: 1 !important
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.overflow-visible {
    overflow: visible !important
}

.overflow-scroll {
    overflow: scroll !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-grid {
    display: grid !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

.d-none {
    display: none !important
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}

.shadow-none {
    box-shadow: none !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: sticky !important
}

.top-0 {
    top: 0 !important
}

.top-50 {
    top: 50% !important
}

.top-100 {
    top: 100% !important
}

.bottom-0 {
    bottom: 0 !important
}

.bottom-50 {
    bottom: 50% !important
}

.bottom-100 {
    bottom: 100% !important
}

.start-0 {
    left: 0 !important
}

.start-50 {
    left: 50% !important
}

.start-100 {
    left: 100% !important
}

.end-0 {
    right: 0 !important
}

.end-50 {
    right: 50% !important
}

.end-100 {
    right: 100% !important
}

.translate-middle {
    transform: translate(-50%, -50%) !important
}

.translate-middle-x {
    transform: translateX(-50%) !important
}

.translate-middle-y {
    transform: translateY(-50%) !important
}

.border {
    border: 1px solid #b3b1c3 !important
}

.border-0 {
    border: 0 !important
}

.border-top {
    border-top: 1px solid #b3b1c3 !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-end {
    border-right: 1px solid #b3b1c3 !important
}

.border-end-0 {
    border-right: 0 !important
}

.border-bottom {
    border-bottom: 1px solid #b3b1c3 !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-start {
    border-left: 1px solid #b3b1c3 !important
}

.border-start-0 {
    border-left: 0 !important
}

.border-primary {
    border-color: #395aff !important
}

.border-secondary {
    border-color: #5b586e !important
}

.border-success {
    border-color: #3eca76 !important
}

.border-info {
    border-color: #20bbd3 !important
}

.border-warning {
    border-color: #ffc21d !important
}

.border-danger {
    border-color: #ff3838 !important
}

.border-light {
    border-color: #f4f5f7 !important
}

.border-dark {
    border-color: #222032 !important
}

.border-white {
    border-color: #fff !important
}

.border-1 {
    border-width: 1px !important
}

.border-2 {
    border-width: 2px !important
}

.border-3 {
    border-width: 3px !important
}

.border-4 {
    border-width: 4px !important
}

.border-5 {
    border-width: 5px !important
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.mw-100 {
    max-width: 100% !important
}

.vw-100 {
    width: 100vw !important
}

.min-vw-100 {
    min-width: 100vw !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.mh-100 {
    max-height: 100% !important
}

.vh-100 {
    height: 100vh !important
}

.min-vh-100 {
    min-height: 100vh !important
}

.flex-fill {
    flex: 1 1 auto !important
}

.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow-1 {
    flex-grow: 1 !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    flex-shrink: 1 !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.gap-0 {
    gap: 0 !important
}

.gap-1 {
    gap: .25rem !important
}

.gap-2 {
    gap: .5rem !important
}

.gap-3 {
    gap: 1rem !important
}

.gap-4 {
    gap: 1.5rem !important
}

.gap-5 {
    gap: 3rem !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.justify-content-evenly {
    justify-content: space-evenly !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

.order-first {
    order: -1 !important
}

.order-0 {
    order: 0 !important
}

.order-1 {
    order: 1 !important
}

.order-2 {
    order: 2 !important
}

.order-3 {
    order: 3 !important
}

.order-4 {
    order: 4 !important
}

.order-5 {
    order: 5 !important
}

.order-last {
    order: 6 !important
}

.m-0 {
    margin: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.m-3 {
    margin: 1rem !important
}

.m-4 {
    margin: 1.5rem !important
}

.m-5 {
    margin: 3rem !important
}

.m-auto {
    margin: auto !important
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
}

.mx-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
}

.mx-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
}

.my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
}

.mt-0 {
    margin-top: 0 !important
}

.mt-1 {
    margin-top: .25rem !important
}

.mt-2 {
    margin-top: .5rem !important
}

.mt-3 {
    margin-top: 1rem !important
}

.mt-4 {
    margin-top: 1.5rem !important
}

.mt-5 {
    margin-top: 3rem !important
}

.mt-auto {
    margin-top: auto !important
}

.me-0 {
    margin-right: 0 !important
}

.me-1 {
    margin-right: .25rem !important
}

.me-2 {
    margin-right: .5rem !important
}

.me-3 {
    margin-right: 1rem !important
}

.me-4 {
    margin-right: 1.5rem !important
}

.me-5 {
    margin-right: 3rem !important
}

.me-auto {
    margin-right: auto !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.mb-1 {
    margin-bottom: .25rem !important
}

.mb-2 {
    margin-bottom: .5rem !important
}

.mb-3 {
    margin-bottom: 1rem !important
}

.mb-4 {
    margin-bottom: 1.5rem !important
}

/* .mb-5 {
    margin-bottom: 3rem !important
} */

.mb-auto {
    margin-bottom: auto !important
}

.ms-0 {
    margin-left: 0 !important
}

.ms-1 {
    margin-left: .25rem !important
}

.ms-2 {
    margin-left: .5rem !important
}

.ms-3 {
    margin-left: 1rem !important
}

.ms-4 {
    margin-left: 1.5rem !important
}

.ms-5 {
    margin-left: 3rem !important
}

.ms-auto {
    margin-left: auto !important
}

.p-0 {
    padding: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.p-5 {
    padding: 3rem !important
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
}

.px-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
}

.px-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
}

.py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
}

.pt-0 {
    padding-top: 0 !important
}

.pt-1 {
    padding-top: .25rem !important
}

.pt-2 {
    padding-top: .5rem !important
}

.pt-3 {
    padding-top: 1rem !important
}

.pt-4 {
    padding-top: 1.5rem !important
}

.pt-5 {
    padding-top: 3rem !important
}

.pe-0 {
    padding-right: 0 !important
}

.pe-1 {
    padding-right: .25rem !important
}

.pe-2 {
    padding-right: .5rem !important
}

.pe-3 {
    padding-right: 1rem !important
}

.pe-4 {
    padding-right: 1.5rem !important
}

.pe-5 {
    padding-right: 3rem !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pb-1 {
    padding-bottom: .25rem !important
}

.pb-2 {
    padding-bottom: .5rem !important
}

.pb-3 {
    padding-bottom: 1rem !important
}

.pb-4 {
    padding-bottom: 1.5rem !important
}

.pb-5 {
    padding-bottom: 3rem !important
}

.ps-0 {
    padding-left: 0 !important
}

.ps-1 {
    padding-left: .25rem !important
}

.ps-2 {
    padding-left: .5rem !important
}

.ps-3 {
    padding-left: 1rem !important
}

.ps-4 {
    padding-left: 1.5rem !important
}

.ps-5 {
    padding-left: 3rem !important
}

.font-monospace {
    font-family: var(--bs-font-monospace) !important
}

.fs-1 {
    font-size: calc(1.375rem + 1.5vw) !important
}

.fs-2 {
    font-size: calc(1.325rem + 0.9vw) !important
}

.fs-3 {
    font-size: calc(1.3rem + 0.6vw) !important
}

.fs-4 {
    font-size: calc(1.275rem + 0.3vw) !important
}

.fs-5 {
    font-size: 1.25rem !important
}

.fs-6 {
    font-size: 1rem !important
}

.fst-italic {
    font-style: italic !important
}

.fst-normal {
    font-style: normal !important
}

.fw-light {
    font-weight: 300 !important
}

.fw-lighter {
    font-weight: lighter !important
}

.fw-normal {
    font-weight: 400 !important
}

.fw-bold {
    font-weight: 700 !important
}

.fw-bolder {
    font-weight: bolder !important
}

.lh-1 {
    line-height: 1 !important
}

.lh-sm {
    line-height: 1.25 !important
}

.lh-base {
    line-height: 1.5 !important
}

.lh-lg {
    line-height: 2 !important
}

.text-start {
    text-align: left !important
}

.text-end {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

.text-decoration-none {
    text-decoration: none !important
}

.text-decoration-underline {
    text-decoration: underline !important
}

.text-decoration-line-through {
    text-decoration: line-through !important
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-break {
    word-wrap: break-word !important;
    word-break: break-word !important
}

.text-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important
}

.text-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important
}

.text-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important
}

.text-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important
}

.text-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important
}

.text-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important
}

.text-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important
}

.text-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important
}

.text-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important
}

.text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important
}

.text-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important
}

.text-muted {
    --bs-text-opacity: 1;
    color: #5b586e !important
}

.text-black-50 {
    --bs-text-opacity: 1;
    color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
    --bs-text-opacity: 1;
    color: rgba(255, 255, 255, .5) !important
}

.text-reset {
    --bs-text-opacity: 1;
    color: inherit !important
}

.text-opacity-25 {
    --bs-text-opacity: 0.25
}

.text-opacity-50 {
    --bs-text-opacity: 0.5
}

.text-opacity-75 {
    --bs-text-opacity: 0.75
}

.text-opacity-100 {
    --bs-text-opacity: 1
}

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important
}

.bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important
}

.bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important
}

.bg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important
}

.bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important
}

.bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important
}

.bg-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important
}

.bg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important
}

.bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important
}

.bg-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important
}

.bg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important
}

.bg-opacity-10 {
    --bs-bg-opacity: 0.1
}

.bg-opacity-25 {
    --bs-bg-opacity: 0.25
}

.bg-opacity-50 {
    --bs-bg-opacity: 0.5
}

.bg-opacity-75 {
    --bs-bg-opacity: 0.75
}

.bg-opacity-100 {
    --bs-bg-opacity: 1
}

.bg-gradient {
    background-image: var(--bs-gradient) !important
}

.user-select-all {
    user-select: all !important
}

.user-select-auto {
    user-select: auto !important
}

.user-select-none {
    user-select: none !important
}

.pe-none {
    pointer-events: none !important
}

.pe-auto {
    pointer-events: auto !important
}

.rounded {
    border-radius: .25rem !important
}

.rounded-0 {
    border-radius: 0 !important
}

.rounded-1 {
    border-radius: .2rem !important
}

.rounded-2 {
    border-radius: .25rem !important
}

.rounded-3 {
    border-radius: .3rem !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-pill {
    border-radius: 50rem !important
}

.rounded-top {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important
}

.rounded-end {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important
}

.rounded-bottom {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important
}

.rounded-start {
    border-bottom-left-radius: .25rem !important;
    border-top-left-radius: .25rem !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

@media(min-width: 576px) {
    .float-sm-start {
        float: left !important
    }

    .float-sm-end {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-grid {
        display: grid !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: flex !important
    }

    .d-sm-inline-flex {
        display: inline-flex !important
    }

    .d-sm-none {
        display: none !important
    }

    .flex-sm-fill {
        flex: 1 1 auto !important
    }

    .flex-sm-row {
        flex-direction: row !important
    }

    .flex-sm-column {
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-sm-0 {
        gap: 0 !important
    }

    .gap-sm-1 {
        gap: .25rem !important
    }

    .gap-sm-2 {
        gap: .5rem !important
    }

    .gap-sm-3 {
        gap: 1rem !important
    }

    .gap-sm-4 {
        gap: 1.5rem !important
    }

    .gap-sm-5 {
        gap: 3rem !important
    }

    .justify-content-sm-start {
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        justify-content: center !important
    }

    .justify-content-sm-between {
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        justify-content: space-around !important
    }

    .justify-content-sm-evenly {
        justify-content: space-evenly !important
    }

    .align-items-sm-start {
        align-items: flex-start !important
    }

    .align-items-sm-end {
        align-items: flex-end !important
    }

    .align-items-sm-center {
        align-items: center !important
    }

    .align-items-sm-baseline {
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        align-items: stretch !important
    }

    .align-content-sm-start {
        align-content: flex-start !important
    }

    .align-content-sm-end {
        align-content: flex-end !important
    }

    .align-content-sm-center {
        align-content: center !important
    }

    .align-content-sm-between {
        align-content: space-between !important
    }

    .align-content-sm-around {
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        align-content: stretch !important
    }

    .align-self-sm-auto {
        align-self: auto !important
    }

    .align-self-sm-start {
        align-self: flex-start !important
    }

    .align-self-sm-end {
        align-self: flex-end !important
    }

    .align-self-sm-center {
        align-self: center !important
    }

    .align-self-sm-baseline {
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        align-self: stretch !important
    }

    .order-sm-first {
        order: -1 !important
    }

    .order-sm-0 {
        order: 0 !important
    }

    .order-sm-1 {
        order: 1 !important
    }

    .order-sm-2 {
        order: 2 !important
    }

    .order-sm-3 {
        order: 3 !important
    }

    .order-sm-4 {
        order: 4 !important
    }

    .order-sm-5 {
        order: 5 !important
    }

    .order-sm-last {
        order: 6 !important
    }

    .m-sm-0 {
        margin: 0 !important
    }

    .m-sm-1 {
        margin: .25rem !important
    }

    .m-sm-2 {
        margin: .5rem !important
    }

    .m-sm-3 {
        margin: 1rem !important
    }

    .m-sm-4 {
        margin: 1.5rem !important
    }

    .m-sm-5 {
        margin: 3rem !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mx-sm-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-sm-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-sm-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-sm-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-sm-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-sm-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-sm-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-sm-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-sm-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-sm-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-sm-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-sm-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-sm-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-sm-0 {
        margin-top: 0 !important
    }

    .mt-sm-1 {
        margin-top: .25rem !important
    }

    .mt-sm-2 {
        margin-top: .5rem !important
    }

    .mt-sm-3 {
        margin-top: 1rem !important
    }

    .mt-sm-4 {
        margin-top: 1.5rem !important
    }

    .mt-sm-5 {
        margin-top: 3rem !important
    }

    .mt-sm-auto {
        margin-top: auto !important
    }

    .me-sm-0 {
        margin-right: 0 !important
    }

    .me-sm-1 {
        margin-right: .25rem !important
    }

    .me-sm-2 {
        margin-right: .5rem !important
    }

    .me-sm-3 {
        margin-right: 1rem !important
    }

    .me-sm-4 {
        margin-right: 1.5rem !important
    }

    .me-sm-5 {
        margin-right: 3rem !important
    }

    .me-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-0 {
        margin-bottom: 0 !important
    }

    .mb-sm-1 {
        margin-bottom: .25rem !important
    }

    .mb-sm-2 {
        margin-bottom: .5rem !important
    }

    .mb-sm-3 {
        margin-bottom: 1rem !important
    }

    .mb-sm-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-sm-5 {
        margin-bottom: 3rem !important
    }

    .mb-sm-auto {
        margin-bottom: auto !important
    }

    .ms-sm-0 {
        margin-left: 0 !important
    }

    .ms-sm-1 {
        margin-left: .25rem !important
    }

    .ms-sm-2 {
        margin-left: .5rem !important
    }

    .ms-sm-3 {
        margin-left: 1rem !important
    }

    .ms-sm-4 {
        margin-left: 1.5rem !important
    }

    .ms-sm-5 {
        margin-left: 3rem !important
    }

    .ms-sm-auto {
        margin-left: auto !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .p-sm-1 {
        padding: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem !important
    }

    .p-sm-3 {
        padding: 1rem !important
    }

    .p-sm-4 {
        padding: 1.5rem !important
    }

    .p-sm-5 {
        padding: 3rem !important
    }

    .px-sm-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-sm-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-sm-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-sm-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-sm-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-sm-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-sm-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-sm-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-sm-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-sm-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-sm-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-sm-0 {
        padding-top: 0 !important
    }

    .pt-sm-1 {
        padding-top: .25rem !important
    }

    .pt-sm-2 {
        padding-top: .5rem !important
    }

    .pt-sm-3 {
        padding-top: 1rem !important
    }

    .pt-sm-4 {
        padding-top: 1.5rem !important
    }

    .pt-sm-5 {
        padding-top: 3rem !important
    }

    .pe-sm-0 {
        padding-right: 0 !important
    }

    .pe-sm-1 {
        padding-right: .25rem !important
    }

    .pe-sm-2 {
        padding-right: .5rem !important
    }

    .pe-sm-3 {
        padding-right: 1rem !important
    }

    .pe-sm-4 {
        padding-right: 1.5rem !important
    }

    .pe-sm-5 {
        padding-right: 3rem !important
    }

    .pb-sm-0 {
        padding-bottom: 0 !important
    }

    .pb-sm-1 {
        padding-bottom: .25rem !important
    }

    .pb-sm-2 {
        padding-bottom: .5rem !important
    }

    .pb-sm-3 {
        padding-bottom: 1rem !important
    }

    .pb-sm-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-sm-5 {
        padding-bottom: 3rem !important
    }

    .ps-sm-0 {
        padding-left: 0 !important
    }

    .ps-sm-1 {
        padding-left: .25rem !important
    }

    .ps-sm-2 {
        padding-left: .5rem !important
    }

    .ps-sm-3 {
        padding-left: 1rem !important
    }

    .ps-sm-4 {
        padding-left: 1.5rem !important
    }

    .ps-sm-5 {
        padding-left: 3rem !important
    }

    .text-sm-start {
        text-align: left !important
    }

    .text-sm-end {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }
}

@media(min-width: 768px) {
    .float-md-start {
        float: left !important
    }

    .float-md-end {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-grid {
        display: grid !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: flex !important
    }

    .d-md-inline-flex {
        display: inline-flex !important
    }

    .d-md-none {
        display: none !important
    }

    .flex-md-fill {
        flex: 1 1 auto !important
    }

    .flex-md-row {
        flex-direction: row !important
    }

    .flex-md-column {
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-md-wrap {
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-md-0 {
        gap: 0 !important
    }

    .gap-md-1 {
        gap: .25rem !important
    }

    .gap-md-2 {
        gap: .5rem !important
    }

    .gap-md-3 {
        gap: 1rem !important
    }

    .gap-md-4 {
        gap: 1.5rem !important
    }

    .gap-md-5 {
        gap: 3rem !important
    }

    .justify-content-md-start {
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        justify-content: center !important
    }

    .justify-content-md-between {
        justify-content: space-between !important
    }

    .justify-content-md-around {
        justify-content: space-around !important
    }

    .justify-content-md-evenly {
        justify-content: space-evenly !important
    }

    .align-items-md-start {
        align-items: flex-start !important
    }

    .align-items-md-end {
        align-items: flex-end !important
    }

    .align-items-md-center {
        align-items: center !important
    }

    .align-items-md-baseline {
        align-items: baseline !important
    }

    .align-items-md-stretch {
        align-items: stretch !important
    }

    .align-content-md-start {
        align-content: flex-start !important
    }

    .align-content-md-end {
        align-content: flex-end !important
    }

    .align-content-md-center {
        align-content: center !important
    }

    .align-content-md-between {
        align-content: space-between !important
    }

    .align-content-md-around {
        align-content: space-around !important
    }

    .align-content-md-stretch {
        align-content: stretch !important
    }

    .align-self-md-auto {
        align-self: auto !important
    }

    .align-self-md-start {
        align-self: flex-start !important
    }

    .align-self-md-end {
        align-self: flex-end !important
    }

    .align-self-md-center {
        align-self: center !important
    }

    .align-self-md-baseline {
        align-self: baseline !important
    }

    .align-self-md-stretch {
        align-self: stretch !important
    }

    .order-md-first {
        order: -1 !important
    }

    .order-md-0 {
        order: 0 !important
    }

    .order-md-1 {
        order: 1 !important
    }

    .order-md-2 {
        order: 2 !important
    }

    .order-md-3 {
        order: 3 !important
    }

    .order-md-4 {
        order: 4 !important
    }

    .order-md-5 {
        order: 5 !important
    }

    .order-md-last {
        order: 6 !important
    }

    .m-md-0 {
        margin: 0 !important
    }

    .m-md-1 {
        margin: .25rem !important
    }

    .m-md-2 {
        margin: .5rem !important
    }

    .m-md-3 {
        margin: 1rem !important
    }

    .m-md-4 {
        margin: 1.5rem !important
    }

    .m-md-5 {
        margin: 3rem !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mx-md-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-md-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-md-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-md-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-md-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-md-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-md-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-md-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-md-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-md-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-md-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-md-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-md-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-md-0 {
        margin-top: 0 !important
    }

    .mt-md-1 {
        margin-top: .25rem !important
    }

    .mt-md-2 {
        margin-top: .5rem !important
    }

    .mt-md-3 {
        margin-top: 1rem !important
    }

    .mt-md-4 {
        margin-top: 1.5rem !important
    }

    .mt-md-5 {
        margin-top: 3rem !important
    }

    .mt-md-auto {
        margin-top: auto !important
    }

    .me-md-0 {
        margin-right: 0 !important
    }

    .me-md-1 {
        margin-right: .25rem !important
    }

    .me-md-2 {
        margin-right: .5rem !important
    }

    .me-md-3 {
        margin-right: 1rem !important
    }

    .me-md-4 {
        margin-right: 1.5rem !important
    }

    .me-md-5 {
        margin-right: 3rem !important
    }

    .me-md-auto {
        margin-right: auto !important
    }

    .mb-md-0 {
        margin-bottom: 0 !important
    }

    .mb-md-1 {
        margin-bottom: .25rem !important
    }

    .mb-md-2 {
        margin-bottom: .5rem !important
    }

    .mb-md-3 {
        margin-bottom: 1rem !important
    }

    .mb-md-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-md-5 {
        margin-bottom: 3rem !important
    }

    .mb-md-auto {
        margin-bottom: auto !important
    }

    .ms-md-0 {
        margin-left: 0 !important
    }

    .ms-md-1 {
        margin-left: .25rem !important
    }

    .ms-md-2 {
        margin-left: .5rem !important
    }

    .ms-md-3 {
        margin-left: 1rem !important
    }

    .ms-md-4 {
        margin-left: 1.5rem !important
    }

    .ms-md-5 {
        margin-left: 3rem !important
    }

    .ms-md-auto {
        margin-left: auto !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .p-md-1 {
        padding: .25rem !important
    }

    .p-md-2 {
        padding: .5rem !important
    }

    .p-md-3 {
        padding: 1rem !important
    }

    .p-md-4 {
        padding: 1.5rem !important
    }

    .p-md-5 {
        padding: 3rem !important
    }

    .px-md-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-md-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-md-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-md-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-md-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-md-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-md-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-md-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-md-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-md-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-md-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-md-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-md-0 {
        padding-top: 0 !important
    }

    .pt-md-1 {
        padding-top: .25rem !important
    }

    .pt-md-2 {
        padding-top: .5rem !important
    }

    .pt-md-3 {
        padding-top: 1rem !important
    }

    .pt-md-4 {
        padding-top: 1.5rem !important
    }

    .pt-md-5 {
        padding-top: 3rem !important
    }

    .pe-md-0 {
        padding-right: 0 !important
    }

    .pe-md-1 {
        padding-right: .25rem !important
    }

    .pe-md-2 {
        padding-right: .5rem !important
    }

    .pe-md-3 {
        padding-right: 1rem !important
    }

    .pe-md-4 {
        padding-right: 1.5rem !important
    }

    .pe-md-5 {
        padding-right: 3rem !important
    }

    .pb-md-0 {
        padding-bottom: 0 !important
    }

    .pb-md-1 {
        padding-bottom: .25rem !important
    }

    .pb-md-2 {
        padding-bottom: .5rem !important
    }

    .pb-md-3 {
        padding-bottom: 1rem !important
    }

    .pb-md-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-md-5 {
        padding-bottom: 3rem !important
    }

    .ps-md-0 {
        padding-left: 0 !important
    }

    .ps-md-1 {
        padding-left: .25rem !important
    }

    .ps-md-2 {
        padding-left: .5rem !important
    }

    .ps-md-3 {
        padding-left: 1rem !important
    }

    .ps-md-4 {
        padding-left: 1.5rem !important
    }

    .ps-md-5 {
        padding-left: 3rem !important
    }

    .text-md-start {
        text-align: left !important
    }

    .text-md-end {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }
}

@media(min-width: 992px) {
    .float-lg-start {
        float: left !important
    }

    .float-lg-end {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-grid {
        display: grid !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: flex !important
    }

    .d-lg-inline-flex {
        display: inline-flex !important
    }

    .d-lg-none {
        display: none !important
    }

    .flex-lg-fill {
        flex: 1 1 auto !important
    }

    .flex-lg-row {
        flex-direction: row !important
    }

    .flex-lg-column {
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-lg-0 {
        gap: 0 !important
    }

    .gap-lg-1 {
        gap: .25rem !important
    }

    .gap-lg-2 {
        gap: .5rem !important
    }

    .gap-lg-3 {
        gap: 1rem !important
    }

    .gap-lg-4 {
        gap: 1.5rem !important
    }

    .gap-lg-5 {
        gap: 3rem !important
    }

    .justify-content-lg-start {
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        justify-content: center !important
    }

    .justify-content-lg-between {
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        justify-content: space-around !important
    }

    .justify-content-lg-evenly {
        justify-content: space-evenly !important
    }

    .align-items-lg-start {
        align-items: flex-start !important
    }

    .align-items-lg-end {
        align-items: flex-end !important
    }

    .align-items-lg-center {
        align-items: center !important
    }

    .align-items-lg-baseline {
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        align-items: stretch !important
    }

    .align-content-lg-start {
        align-content: flex-start !important
    }

    .align-content-lg-end {
        align-content: flex-end !important
    }

    .align-content-lg-center {
        align-content: center !important
    }

    .align-content-lg-between {
        align-content: space-between !important
    }

    .align-content-lg-around {
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        align-content: stretch !important
    }

    .align-self-lg-auto {
        align-self: auto !important
    }

    .align-self-lg-start {
        align-self: flex-start !important
    }

    .align-self-lg-end {
        align-self: flex-end !important
    }

    .align-self-lg-center {
        align-self: center !important
    }

    .align-self-lg-baseline {
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        align-self: stretch !important
    }

    .order-lg-first {
        order: -1 !important
    }

    .order-lg-0 {
        order: 0 !important
    }

    .order-lg-1 {
        order: 1 !important
    }

    .order-lg-2 {
        order: 2 !important
    }

    .order-lg-3 {
        order: 3 !important
    }

    .order-lg-4 {
        order: 4 !important
    }

    .order-lg-5 {
        order: 5 !important
    }

    .order-lg-last {
        order: 6 !important
    }

    .m-lg-0 {
        margin: 0 !important
    }

    .m-lg-1 {
        margin: .25rem !important
    }

    .m-lg-2 {
        margin: .5rem !important
    }

    .m-lg-3 {
        margin: 1rem !important
    }

    .m-lg-4 {
        margin: 1.5rem !important
    }

    .m-lg-5 {
        margin: 3rem !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mx-lg-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-lg-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-lg-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-lg-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-lg-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-lg-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-lg-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-lg-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-lg-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-lg-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-lg-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-lg-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-lg-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-lg-0 {
        margin-top: 0 !important
    }

    .mt-lg-1 {
        margin-top: .25rem !important
    }

    .mt-lg-2 {
        margin-top: .5rem !important
    }

    .mt-lg-3 {
        margin-top: 1rem !important
    }

    .mt-lg-4 {
        margin-top: 1.5rem !important
    }

    .mt-lg-5 {
        margin-top: 3rem !important
    }

    .mt-lg-auto {
        margin-top: auto !important
    }

    .me-lg-0 {
        margin-right: 0 !important
    }

    .me-lg-1 {
        margin-right: .25rem !important
    }

    .me-lg-2 {
        margin-right: .5rem !important
    }

    .me-lg-3 {
        margin-right: 1rem !important
    }

    .me-lg-4 {
        margin-right: 1.5rem !important
    }

    .me-lg-5 {
        margin-right: 3rem !important
    }

    .me-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-0 {
        margin-bottom: 0 !important
    }

    .mb-lg-1 {
        margin-bottom: .25rem !important
    }

    .mb-lg-2 {
        margin-bottom: .5rem !important
    }

    .mb-lg-3 {
        margin-bottom: 1rem !important
    }

    .mb-lg-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-lg-5 {
        margin-bottom: 3rem !important
    }

    .mb-lg-auto {
        margin-bottom: auto !important
    }

    .ms-lg-0 {
        margin-left: 0 !important
    }

    .ms-lg-1 {
        margin-left: .25rem !important
    }

    .ms-lg-2 {
        margin-left: .5rem !important
    }

    .ms-lg-3 {
        margin-left: 1rem !important
    }

    .ms-lg-4 {
        margin-left: 1.5rem !important
    }

    .ms-lg-5 {
        margin-left: 3rem !important
    }

    .ms-lg-auto {
        margin-left: auto !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .p-lg-1 {
        padding: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem !important
    }

    .p-lg-3 {
        padding: 1rem !important
    }

    .p-lg-4 {
        padding: 1.5rem !important
    }

    .p-lg-5 {
        padding: 3rem !important
    }

    .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-lg-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-lg-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-lg-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-lg-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-lg-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-lg-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-lg-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-lg-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-lg-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-lg-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-lg-0 {
        padding-top: 0 !important
    }

    .pt-lg-1 {
        padding-top: .25rem !important
    }

    .pt-lg-2 {
        padding-top: .5rem !important
    }

    .pt-lg-3 {
        padding-top: 1rem !important
    }

    .pt-lg-4 {
        padding-top: 1.5rem !important
    }

    .pt-lg-5 {
        padding-top: 3rem !important
    }

    .pe-lg-0 {
        padding-right: 0 !important
    }

    .pe-lg-1 {
        padding-right: .25rem !important
    }

    .pe-lg-2 {
        padding-right: .5rem !important
    }

    .pe-lg-3 {
        padding-right: 1rem !important
    }

    .pe-lg-4 {
        padding-right: 1.5rem !important
    }

    .pe-lg-5 {
        padding-right: 3rem !important
    }

    .pb-lg-0 {
        padding-bottom: 0 !important
    }

    .pb-lg-1 {
        padding-bottom: .25rem !important
    }

    .pb-lg-2 {
        padding-bottom: .5rem !important
    }

    .pb-lg-3 {
        padding-bottom: 1rem !important
    }

    .pb-lg-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-lg-5 {
        padding-bottom: 3rem !important
    }

    .ps-lg-0 {
        padding-left: 0 !important
    }

    .ps-lg-1 {
        padding-left: .25rem !important
    }

    .ps-lg-2 {
        padding-left: .5rem !important
    }

    .ps-lg-3 {
        padding-left: 1rem !important
    }

    .ps-lg-4 {
        padding-left: 1.5rem !important
    }

    .ps-lg-5 {
        padding-left: 3rem !important
    }

    .text-lg-start {
        text-align: left !important
    }

    .text-lg-end {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }
}

@media(min-width: 1200px) {
    .float-xl-start {
        float: left !important
    }

    .float-xl-end {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-grid {
        display: grid !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: flex !important
    }

    .d-xl-inline-flex {
        display: inline-flex !important
    }

    .d-xl-none {
        display: none !important
    }

    .flex-xl-fill {
        flex: 1 1 auto !important
    }

    .flex-xl-row {
        flex-direction: row !important
    }

    .flex-xl-column {
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-xl-0 {
        gap: 0 !important
    }

    .gap-xl-1 {
        gap: .25rem !important
    }

    .gap-xl-2 {
        gap: .5rem !important
    }

    .gap-xl-3 {
        gap: 1rem !important
    }

    .gap-xl-4 {
        gap: 1.5rem !important
    }

    .gap-xl-5 {
        gap: 3rem !important
    }

    .justify-content-xl-start {
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        justify-content: center !important
    }

    .justify-content-xl-between {
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        justify-content: space-around !important
    }

    .justify-content-xl-evenly {
        justify-content: space-evenly !important
    }

    .align-items-xl-start {
        align-items: flex-start !important
    }

    .align-items-xl-end {
        align-items: flex-end !important
    }

    .align-items-xl-center {
        align-items: center !important
    }

    .align-items-xl-baseline {
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        align-items: stretch !important
    }

    .align-content-xl-start {
        align-content: flex-start !important
    }

    .align-content-xl-end {
        align-content: flex-end !important
    }

    .align-content-xl-center {
        align-content: center !important
    }

    .align-content-xl-between {
        align-content: space-between !important
    }

    .align-content-xl-around {
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        align-content: stretch !important
    }

    .align-self-xl-auto {
        align-self: auto !important
    }

    .align-self-xl-start {
        align-self: flex-start !important
    }

    .align-self-xl-end {
        align-self: flex-end !important
    }

    .align-self-xl-center {
        align-self: center !important
    }

    .align-self-xl-baseline {
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        align-self: stretch !important
    }

    .order-xl-first {
        order: -1 !important
    }

    .order-xl-0 {
        order: 0 !important
    }

    .order-xl-1 {
        order: 1 !important
    }

    .order-xl-2 {
        order: 2 !important
    }

    .order-xl-3 {
        order: 3 !important
    }

    .order-xl-4 {
        order: 4 !important
    }

    .order-xl-5 {
        order: 5 !important
    }

    .order-xl-last {
        order: 6 !important
    }

    .m-xl-0 {
        margin: 0 !important
    }

    .m-xl-1 {
        margin: .25rem !important
    }

    .m-xl-2 {
        margin: .5rem !important
    }

    .m-xl-3 {
        margin: 1rem !important
    }

    .m-xl-4 {
        margin: 1.5rem !important
    }

    .m-xl-5 {
        margin: 3rem !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mx-xl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-xl-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-xl-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-xl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-xl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-xl-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-xl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-xl-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-xl-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-xl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-xl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-xl-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-xl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-xl-0 {
        margin-top: 0 !important
    }

    .mt-xl-1 {
        margin-top: .25rem !important
    }

    .mt-xl-2 {
        margin-top: .5rem !important
    }

    .mt-xl-3 {
        margin-top: 1rem !important
    }

    .mt-xl-4 {
        margin-top: 1.5rem !important
    }

    .mt-xl-5 {
        margin-top: 3rem !important
    }

    .mt-xl-auto {
        margin-top: auto !important
    }

    .me-xl-0 {
        margin-right: 0 !important
    }

    .me-xl-1 {
        margin-right: .25rem !important
    }

    .me-xl-2 {
        margin-right: .5rem !important
    }

    .me-xl-3 {
        margin-right: 1rem !important
    }

    .me-xl-4 {
        margin-right: 1.5rem !important
    }

    .me-xl-5 {
        margin-right: 3rem !important
    }

    .me-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-0 {
        margin-bottom: 0 !important
    }

    .mb-xl-1 {
        margin-bottom: .25rem !important
    }

    .mb-xl-2 {
        margin-bottom: .5rem !important
    }

    .mb-xl-3 {
        margin-bottom: 1rem !important
    }

    .mb-xl-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-xl-5 {
        margin-bottom: 3rem !important
    }

    .mb-xl-auto {
        margin-bottom: auto !important
    }

    .ms-xl-0 {
        margin-left: 0 !important
    }

    .ms-xl-1 {
        margin-left: .25rem !important
    }

    .ms-xl-2 {
        margin-left: .5rem !important
    }

    .ms-xl-3 {
        margin-left: 1rem !important
    }

    .ms-xl-4 {
        margin-left: 1.5rem !important
    }

    .ms-xl-5 {
        margin-left: 3rem !important
    }

    .ms-xl-auto {
        margin-left: auto !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .p-xl-1 {
        padding: .25rem !important
    }

    .p-xl-2 {
        padding: .5rem !important
    }

    .p-xl-3 {
        padding: 1rem !important
    }

    .p-xl-4 {
        padding: 1.5rem !important
    }

    .p-xl-5 {
        padding: 3rem !important
    }

    .px-xl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-xl-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-xl-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-xl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-xl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-xl-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-xl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-xl-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-xl-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-xl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-xl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-xl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-xl-0 {
        padding-top: 0 !important
    }

    .pt-xl-1 {
        padding-top: .25rem !important
    }

    .pt-xl-2 {
        padding-top: .5rem !important
    }

    .pt-xl-3 {
        padding-top: 1rem !important
    }

    .pt-xl-4 {
        padding-top: 1.5rem !important
    }

    .pt-xl-5 {
        padding-top: 3rem !important
    }

    .pe-xl-0 {
        padding-right: 0 !important
    }

    .pe-xl-1 {
        padding-right: .25rem !important
    }

    .pe-xl-2 {
        padding-right: .5rem !important
    }

    .pe-xl-3 {
        padding-right: 1rem !important
    }

    .pe-xl-4 {
        padding-right: 1.5rem !important
    }

    .pe-xl-5 {
        padding-right: 3rem !important
    }

    .pb-xl-0 {
        padding-bottom: 0 !important
    }

    .pb-xl-1 {
        padding-bottom: .25rem !important
    }

    .pb-xl-2 {
        padding-bottom: .5rem !important
    }

    .pb-xl-3 {
        padding-bottom: 1rem !important
    }

    .pb-xl-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-xl-5 {
        padding-bottom: 3rem !important
    }

    .ps-xl-0 {
        padding-left: 0 !important
    }

    .ps-xl-1 {
        padding-left: .25rem !important
    }

    .ps-xl-2 {
        padding-left: .5rem !important
    }

    .ps-xl-3 {
        padding-left: 1rem !important
    }

    .ps-xl-4 {
        padding-left: 1.5rem !important
    }

    .ps-xl-5 {
        padding-left: 3rem !important
    }

    .text-xl-start {
        text-align: left !important
    }

    .text-xl-end {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }
}

@media(min-width: 1400px) {
    .float-xxl-start {
        float: left !important
    }

    .float-xxl-end {
        float: right !important
    }

    .float-xxl-none {
        float: none !important
    }

    .d-xxl-inline {
        display: inline !important
    }

    .d-xxl-inline-block {
        display: inline-block !important
    }

    .d-xxl-block {
        display: block !important
    }

    .d-xxl-grid {
        display: grid !important
    }

    .d-xxl-table {
        display: table !important
    }

    .d-xxl-table-row {
        display: table-row !important
    }

    .d-xxl-table-cell {
        display: table-cell !important
    }

    .d-xxl-flex {
        display: flex !important
    }

    .d-xxl-inline-flex {
        display: inline-flex !important
    }

    .d-xxl-none {
        display: none !important
    }

    .flex-xxl-fill {
        flex: 1 1 auto !important
    }

    .flex-xxl-row {
        flex-direction: row !important
    }

    .flex-xxl-column {
        flex-direction: column !important
    }

    .flex-xxl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xxl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xxl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xxl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xxl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xxl-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-xxl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xxl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xxl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-xxl-0 {
        gap: 0 !important
    }

    .gap-xxl-1 {
        gap: .25rem !important
    }

    .gap-xxl-2 {
        gap: .5rem !important
    }

    .gap-xxl-3 {
        gap: 1rem !important
    }

    .gap-xxl-4 {
        gap: 1.5rem !important
    }

    .gap-xxl-5 {
        gap: 3rem !important
    }

    .justify-content-xxl-start {
        justify-content: flex-start !important
    }

    .justify-content-xxl-end {
        justify-content: flex-end !important
    }

    .justify-content-xxl-center {
        justify-content: center !important
    }

    .justify-content-xxl-between {
        justify-content: space-between !important
    }

    .justify-content-xxl-around {
        justify-content: space-around !important
    }

    .justify-content-xxl-evenly {
        justify-content: space-evenly !important
    }

    .align-items-xxl-start {
        align-items: flex-start !important
    }

    .align-items-xxl-end {
        align-items: flex-end !important
    }

    .align-items-xxl-center {
        align-items: center !important
    }

    .align-items-xxl-baseline {
        align-items: baseline !important
    }

    .align-items-xxl-stretch {
        align-items: stretch !important
    }

    .align-content-xxl-start {
        align-content: flex-start !important
    }

    .align-content-xxl-end {
        align-content: flex-end !important
    }

    .align-content-xxl-center {
        align-content: center !important
    }

    .align-content-xxl-between {
        align-content: space-between !important
    }

    .align-content-xxl-around {
        align-content: space-around !important
    }

    .align-content-xxl-stretch {
        align-content: stretch !important
    }

    .align-self-xxl-auto {
        align-self: auto !important
    }

    .align-self-xxl-start {
        align-self: flex-start !important
    }

    .align-self-xxl-end {
        align-self: flex-end !important
    }

    .align-self-xxl-center {
        align-self: center !important
    }

    .align-self-xxl-baseline {
        align-self: baseline !important
    }

    .align-self-xxl-stretch {
        align-self: stretch !important
    }

    .order-xxl-first {
        order: -1 !important
    }

    .order-xxl-0 {
        order: 0 !important
    }

    .order-xxl-1 {
        order: 1 !important
    }

    .order-xxl-2 {
        order: 2 !important
    }

    .order-xxl-3 {
        order: 3 !important
    }

    .order-xxl-4 {
        order: 4 !important
    }

    .order-xxl-5 {
        order: 5 !important
    }

    .order-xxl-last {
        order: 6 !important
    }

    .m-xxl-0 {
        margin: 0 !important
    }

    .m-xxl-1 {
        margin: .25rem !important
    }

    .m-xxl-2 {
        margin: .5rem !important
    }

    .m-xxl-3 {
        margin: 1rem !important
    }

    .m-xxl-4 {
        margin: 1.5rem !important
    }

    .m-xxl-5 {
        margin: 3rem !important
    }

    .m-xxl-auto {
        margin: auto !important
    }

    .mx-xxl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-xxl-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-xxl-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-xxl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-xxl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-xxl-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-xxl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-xxl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-xxl-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-xxl-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-xxl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-xxl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-xxl-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-xxl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-xxl-0 {
        margin-top: 0 !important
    }

    .mt-xxl-1 {
        margin-top: .25rem !important
    }

    .mt-xxl-2 {
        margin-top: .5rem !important
    }

    .mt-xxl-3 {
        margin-top: 1rem !important
    }

    .mt-xxl-4 {
        margin-top: 1.5rem !important
    }

    .mt-xxl-5 {
        margin-top: 3rem !important
    }

    .mt-xxl-auto {
        margin-top: auto !important
    }

    .me-xxl-0 {
        margin-right: 0 !important
    }

    .me-xxl-1 {
        margin-right: .25rem !important
    }

    .me-xxl-2 {
        margin-right: .5rem !important
    }

    .me-xxl-3 {
        margin-right: 1rem !important
    }

    .me-xxl-4 {
        margin-right: 1.5rem !important
    }

    .me-xxl-5 {
        margin-right: 3rem !important
    }

    .me-xxl-auto {
        margin-right: auto !important
    }

    .mb-xxl-0 {
        margin-bottom: 0 !important
    }

    .mb-xxl-1 {
        margin-bottom: .25rem !important
    }

    .mb-xxl-2 {
        margin-bottom: .5rem !important
    }

    .mb-xxl-3 {
        margin-bottom: 1rem !important
    }

    .mb-xxl-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-xxl-5 {
        margin-bottom: 3rem !important
    }

    .mb-xxl-auto {
        margin-bottom: auto !important
    }

    .ms-xxl-0 {
        margin-left: 0 !important
    }

    .ms-xxl-1 {
        margin-left: .25rem !important
    }

    .ms-xxl-2 {
        margin-left: .5rem !important
    }

    .ms-xxl-3 {
        margin-left: 1rem !important
    }

    .ms-xxl-4 {
        margin-left: 1.5rem !important
    }

    .ms-xxl-5 {
        margin-left: 3rem !important
    }

    .ms-xxl-auto {
        margin-left: auto !important
    }

    .p-xxl-0 {
        padding: 0 !important
    }

    .p-xxl-1 {
        padding: .25rem !important
    }

    .p-xxl-2 {
        padding: .5rem !important
    }

    .p-xxl-3 {
        padding: 1rem !important
    }

    .p-xxl-4 {
        padding: 1.5rem !important
    }

    .p-xxl-5 {
        padding: 3rem !important
    }

    .px-xxl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-xxl-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-xxl-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-xxl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-xxl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-xxl-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-xxl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-xxl-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-xxl-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-xxl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-xxl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-xxl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-xxl-0 {
        padding-top: 0 !important
    }

    .pt-xxl-1 {
        padding-top: .25rem !important
    }

    .pt-xxl-2 {
        padding-top: .5rem !important
    }

    .pt-xxl-3 {
        padding-top: 1rem !important
    }

    .pt-xxl-4 {
        padding-top: 1.5rem !important
    }

    .pt-xxl-5 {
        padding-top: 3rem !important
    }

    .pe-xxl-0 {
        padding-right: 0 !important
    }

    .pe-xxl-1 {
        padding-right: .25rem !important
    }

    .pe-xxl-2 {
        padding-right: .5rem !important
    }

    .pe-xxl-3 {
        padding-right: 1rem !important
    }

    .pe-xxl-4 {
        padding-right: 1.5rem !important
    }

    .pe-xxl-5 {
        padding-right: 3rem !important
    }

    .pb-xxl-0 {
        padding-bottom: 0 !important
    }

    .pb-xxl-1 {
        padding-bottom: .25rem !important
    }

    .pb-xxl-2 {
        padding-bottom: .5rem !important
    }

    .pb-xxl-3 {
        padding-bottom: 1rem !important
    }

    .pb-xxl-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-xxl-5 {
        padding-bottom: 3rem !important
    }

    .ps-xxl-0 {
        padding-left: 0 !important
    }

    .ps-xxl-1 {
        padding-left: .25rem !important
    }

    .ps-xxl-2 {
        padding-left: .5rem !important
    }

    .ps-xxl-3 {
        padding-left: 1rem !important
    }

    .ps-xxl-4 {
        padding-left: 1.5rem !important
    }

    .ps-xxl-5 {
        padding-left: 3rem !important
    }

    .text-xxl-start {
        text-align: left !important
    }

    .text-xxl-end {
        text-align: right !important
    }

    .text-xxl-center {
        text-align: center !important
    }
}

@media(min-width: 1200px) {
    .fs-1 {
        font-size: 2.5rem !important
    }

    .fs-2 {
        font-size: 2rem !important
    }

    .fs-3 {
        font-size: 1.75rem !important
    }

    .fs-4 {
        font-size: 1.5rem !important
    }
}

@media print {
    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-grid {
        display: grid !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: flex !important
    }

    .d-print-inline-flex {
        display: inline-flex !important
    }

    .d-print-none {
        display: none !important
    }
}

@-webkit-keyframes floating-1 {
    0% {
        transform: translate(0%, -15%)
    }

    50% {
        transform: translate(15%, 0%)
    }

    100% {
        transform: translate(0%, -15%)
    }
}

@-moz-keyframes floating-1 {
    0% {
        transform: translate(0%, -15%)
    }

    50% {
        transform: translate(15%, 0%)
    }

    100% {
        transform: translate(0%, -15%)
    }
}

@keyframes floating-1 {
    0% {
        transform: translate(0%, -15%)
    }

    50% {
        transform: translate(15%, 0%)
    }

    100% {
        transform: translate(0%, -15%)
    }
}

@-webkit-keyframes floating-2 {
    0% {
        transform: translate(0%, 15%)
    }

    50% {
        transform: translate(15%, 0%)
    }

    100% {
        transform: translate(0%, 15%)
    }
}

@-moz-keyframes floating-2 {
    0% {
        transform: translate(0%, 15%)
    }

    50% {
        transform: translate(15%, 0%)
    }

    100% {
        transform: translate(0%, 15%)
    }
}

@keyframes floating-2 {
    0% {
        transform: translate(0%, 15%)
    }

    50% {
        transform: translate(15%, 0%)
    }

    100% {
        transform: translate(0%, 15%)
    }
}

@-webkit-keyframes fadeOut {
    100% {
        opacity: 0;
        visibility: hidden
    }
}

@-moz-keyframes fadeOut {
    100% {
        opacity: 0;
        visibility: hidden
    }
}

@keyframes fadeOut {
    100% {
        opacity: 0;
        visibility: hidden
    }
}

@-webkit-keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0)
    }

    100% {
        -webkit-transform: scale(1);
        opacity: 0
    }
}

@-moz-keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0)
    }

    100% {
        -webkit-transform: scale(1);
        opacity: 0
    }
}

@keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0)
    }

    100% {
        -webkit-transform: scale(1);
        opacity: 0
    }
}

@-webkit-keyframes animation_clickable {
    to {
        transform: scale(4);
        opacity: 0
    }
}

@-moz-keyframes animation_clickable {
    to {
        transform: scale(4);
        opacity: 0
    }
}

@keyframes animation_clickable {
    to {
        transform: scale(4);
        opacity: 0
    }
}

.lds-spinner {
    display: flex;
    justify-content: center;
    position: relative;
    width: calc(100% - 80px);
    height: 70px;
    margin-top: -20px
}

.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite
}

.lds-spinner div::after {
    content: " ";
    display: block;
    position: absolute;
    top: 27px;
    left: 39px;
    width: 2.3px;
    height: 7px;
    border-radius: 50px;
    background: var(--secondary)
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s
}

@-webkit-keyframes lds-spinner {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

@-moz-keyframes lds-spinner {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

@keyframes lds-spinner {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.btn-xs-size {
    min-width: 62px;
    height: 28px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 11px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center
}

.btn-xs-size.border-primary {
    border: 1px solid #395aff
}

.btn-sm-size {
    min-width: 86px;
    height: 36px;
    padding-left: 18px;
    padding-right: 18px;
    font-size: 14px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center
}

.btn-sm-size.border-primary {
    border: 1px solid #395aff
}

.btn-md-size {
    min-width: 95px;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    z-index: 1
}

.btn-md-size.border-primary {
    border: 1px solid #395aff
}

.effect-click {
    position: relative;
    overflow: hidden;
    z-index: 1
}

.effect-click span {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: animation_clickable 600ms linear;
    background-color: rgba(34, 32, 50, .2)
}

.btn-bid-items {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    min-width: 190px;
    height: 60px;
    border-radius: 50px;
    background-color: #395aff;
    padding: 10px 10px 10px 20px
}

.btn-bid-items p {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin: 0
}

.btn-bid-items .ico {
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #fff;
    background-color: rgba(255, 255, 255, .12);
    border-radius: 50%
}

.btn-box-check {
    position: relative;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0
}

.btn-box-check .icon-box {
    width: 35px;
    height: 35px;
    border-radius: 10px;
    background-color: #395aff;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: .25s;
    -moz-transition: .25s;
    -o-transition: .25s;
    -ms-transition: .25s;
    transition: .25s
}

.btn-box-check input {
    position: absolute;
    font-size: 40px;
    opacity: 0
}

.btn-box-check input:checked~.icon-box {
    background-color: var(--light);
    color: var(--dark)
}

.btn-box-check input:checked~.icon-box i::before {
    content: ""
}

.item-add-NFTs-plus {
    position: fixed;
    bottom: 40px
}

.item-add-NFTs-plus a {
    width: 56px;
    text-decoration: none;
    height: 56px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #395aff;
    color: #fff;
    font-weight: 600;
    font-size: 23px
}

.size-0 {
    font-size: 0px !important
}

.size-1 {
    font-size: 1px !important
}

.size-2 {
    font-size: 2px !important
}

.size-3 {
    font-size: 3px !important
}

.size-4 {
    font-size: 4px !important
}

.size-5 {
    font-size: 5px !important
}

.size-6 {
    font-size: 6px !important
}

.size-7 {
    font-size: 7px !important
}

.size-8 {
    font-size: 8px !important
}

.size-9 {
    font-size: 9px !important
}

.size-10 {
    font-size: 10px !important
}

.size-11 {
    font-size: 11px !important
}

.size-12 {
    font-size: 12px !important
}

.size-13 {
    font-size: 13px !important
}

.size-14 {
    font-size: 14px !important
}

.size-15 {
    font-size: 15px !important
}

.size-16 {
    font-size: 16px !important
}

.size-17 {
    font-size: 17px !important
}

.size-18 {
    font-size: 18px !important
}

.size-19 {
    font-size: 19px !important
}

.size-20 {
    font-size: 20px !important
}

.size-21 {
    font-size: 21px !important
}

.size-22 {
    font-size: 22px !important
}

.size-23 {
    font-size: 23px !important
}

.size-24 {
    font-size: 24px !important
}

.size-25 {
    font-size: 25px !important
}

.size-26 {
    font-size: 26px !important
}

.size-27 {
    font-size: 27px !important
}

.size-28 {
    font-size: 28px !important
}

.size-29 {
    font-size: 29px !important
}

.size-30 {
    font-size: 30px !important
}

.size-31 {
    font-size: 31px !important
}

.size-32 {
    font-size: 32px !important
}

.size-33 {
    font-size: 33px !important
}

.size-34 {
    font-size: 34px !important
}

.size-35 {
    font-size: 35px !important
}

.weight-100 {
    font-weight: 100 !important
}

.weight-200 {
    font-weight: 200 !important
}

.weight-300 {
    font-weight: 300 !important
}

.weight-400 {
    font-weight: 400 !important
}

.weight-500 {
    font-weight: 500 !important
}

.weight-600 {
    font-weight: 600 !important
}

.weight-700 {
    font-weight: 700 !important
}

.weight-800 {
    font-weight: 800 !important
}

.weight-900 {
    font-weight: 900 !important
}

.color-black {
    color: #000
}

.color-white {
    color: var(--white)
}

.color-primary {
    color: #395aff
}

.color-secondary {
    color: var(--dark)
}

.color-snow {
    color: var(--light)
}

.color-red {
    color: #ff3838
}

.color-orange {
    color: #ff7e1b
}

.color-blue {
    color: #395aff
}

.color-yellow {
    color: #ffc21d
}

.color-green {
    color: #3eca76
}

.color-text {
    color: var(--secondary)
}

.color-pink {
    color: #e05fc4
}

.color-cyan {
    color: #20bbd3
}

.color-purple {
    color: #a659ff
}

.bg-black {
    background-color: #000
}

.bg-white {
    background-color: var(--white)
}

.bg-primary {
    background-color: #395aff
}

.bg-secondary {
    background-color: var(--dark)
}

.bg-snow {
    background-color: var(--light)
}

.bg-red {
    background-color: #ff3838
}

.bg-orange {
    background-color: #ff7e1b
}

.bg-blue {
    background-color: #395aff
}

.bg-yellow {
    background-color: #ffc21d
}

.bg-green {
    background-color: #3eca76
}

.bg-text {
    background-color: var(--secondary)
}

.bg-pink {
    background-color: #e05fc4
}

.bg-cyan {
    background-color: #20bbd3
}

.bg-purple {
    background-color: #a659ff
}

.bg-black-1 {
    background-color: rgba(0, 0, 0, .1)
}

.bg-white-1 {
    background-color: rgba(var(--white), 0.1)
}

.bg-primary-1 {
    background-color: rgba(57, 90, 255, .1)
}

.bg-secondary-1 {
    background-color: rgba(var(--dark), 0.1)
}

.bg-snow-1 {
    background-color: rgba(var(--light), 0.1)
}

.bg-red-1 {
    background-color: rgba(255, 56, 56, .1)
}

.bg-orange-1 {
    background-color: rgba(255, 126, 27, .1)
}

.bg-blue-1 {
    background-color: rgba(57, 90, 255, .1)
}

.bg-yellow-1 {
    background-color: rgba(255, 194, 29, .1)
}

.bg-green-1 {
    background-color: rgba(62, 202, 118, .1)
}

.bg-text-1 {
    background-color: rgba(var(--secondary), 0.1)
}

.bg-pink-1 {
    background-color: rgba(224, 95, 196, .1)
}

.bg-cyan-1 {
    background-color: rgba(32, 187, 211, .1)
}

.bg-purple-1 {
    background-color: rgba(166, 89, 255, .1)
}

.switch_toggle {
    position: relative;
    width: 43px;
    height: 25px;
    border-radius: 50px;
    border: 0;
    display: inline-flex;
    align-items: center;
    margin-bottom: 0
}

.switch_toggle input {
    opacity: 0;
    width: 0;
    height: 0
}

.switch_toggle input:checked~.handle {
    background-color: #395aff
}

.switch_toggle input:checked~.handle::before {
    -webkit-transform: translateX(14px);
    -moz-transform: translateX(14px);
    -o-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px)
}

.switch_toggle .handle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d0d3dc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50px
}

.switch_toggle .handle::before {
    content: "";
    position: absolute;
    height: 14px;
    width: 14px;
    left: 7px;
    top: 5px;
    background-color: #fff;
    -webkit-transition: .26s;
    -moz-transition: .26s;
    -o-transition: .26s;
    -ms-transition: .26s;
    transition: .26s;
    border-radius: 50%;
    -webkit-box-shadow: 0px 1px 1px 0px rgba(62, 67, 119, .25);
    -moz-box-shadow: 0px 1px 1px 0px rgba(62, 67, 119, .25);
    -o-box-shadow: 0px 1px 1px 0px rgba(62, 67, 119, .25);
    -ms-box-shadow: 0px 1px 1px 0px rgba(62, 67, 119, .25);
    box-shadow: 0px 1px 1px 0px rgba(62, 67, 119, .25)
}

.switch_toggle.toggle_lg {
    width: 48px;
    height: 30px
}

.switch_toggle.toggle_lg .handle::before {
    width: 22px;
    height: 22px;
    left: 6px;
    top: 4px
}

.em_toggle .btn-toggle {
    position: relative;
    width: 34px;
    height: 23px;
    border-radius: 50px;
    border: 0;
    background-color: #e9eaec;
    -webkit-transition: .25s;
    -moz-transition: .25s;
    -o-transition: .25s;
    -ms-transition: .25s;
    transition: .25s
}

.em_toggle .btn-toggle .handle {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--white);
    top: 4px;
    left: 5px;
    -webkit-box-shadow: 0px 1px 1px 0px rgba(62, 67, 119, .25);
    -moz-box-shadow: 0px 1px 1px 0px rgba(62, 67, 119, .25);
    -o-box-shadow: 0px 1px 1px 0px rgba(62, 67, 119, .25);
    -ms-box-shadow: 0px 1px 1px 0px rgba(62, 67, 119, .25);
    box-shadow: 0px 1px 1px 0px rgba(62, 67, 119, .25);
    -webkit-transition: left .25s;
    -moz-transition: left .25s;
    -o-transition: left .25s;
    -ms-transition: left .25s;
    transition: left .25s
}

.em_toggle .btn-toggle.active {
    background-color: #3eca76
}

.em_toggle .btn-toggle.active>.handle {
    left: 15px;
    background-color: var(--white)
}

.em_toggle .btn-toggle.toggle_md {
    width: 39px;
    height: 26px
}

.em_toggle .btn-toggle.toggle_md .handle {
    top: 5px;
    height: 16px;
    width: 16px
}

.em_toggle .btn-toggle.toggle_md.active>.handle {
    left: 18px
}

.em_toggle .btn-toggle.toggle_lg {
    width: 48px;
    height: 30px
}

.em_toggle .btn-toggle.toggle_lg .handle {
    top: 4px;
    width: 22px;
    height: 22px
}

.em_toggle .btn-toggle.toggle_lg.active>.handle {
    left: 21px
}

.form-group {
    margin-bottom: 15px
}

.form-group label {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 500;
    color: var(--secondary)
}

.form-group .form-control {
    border-radius: 8px !important;
    width: 100% !important;
    background-color: var(--light);
    font-weight: 400 !important;
    font-size: 13px !important;
    color: var(--dark) !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    -webkit-transition: .35s;
    -moz-transition: .35s;
    -o-transition: .35s;
    -ms-transition: .35s;
    transition: .35s
}

.form-group .form-control::placeholder {
    font-size: 13px !important;
    color: var(--gray-300);
    font-weight: 500 !important
}

.form-group .form-control:-moz-placeholder {
    font-size: 13px;
    color: var(--gray-300);
    font-weight: 500
}

.form-group .form-control:-ms-input-placeholder {
    font-size: 13px;
    color: var(--gray-300);
    font-weight: 400
}

.form-group .form-control::-webkit-input-placeholder {
    font-size: 13px;
    color: var(--gray-300);
    font-weight: 400
}

.form-group .form-control:focus {
    border-color: #adb5bd !important
}

.form-group .form-control:hover,
.form-group .form-control:focus,
.form-group .form-control:valid {
    background-color: var(--white);
    border-color: var(--gray-200)
}

.form-group .form-control.is-invalid {
    border-color: #ff3838;
    background-color: var(--white);
    background-image: none
}

.form-group .form-control.is-invalid~.icon_password {
    opacity: 0;
    pointer-events: none
}

.form-group .form-control.is-valid {
    border-color: #3eca76;
    background-color: var(--white);
    background-image: none
}

.form-group .form-control.is-valid~.icon_password {
    opacity: 0;
    pointer-events: none
}

.form-group .custom-select {
    background-image: url("data:image/svg+xml,%3Csvg id='Group_8600' data-name='Group 8600' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath id='Path_1959' data-name='Path 1959' d='M0,0H24V24H0Z' fill='none'/%3E%3Cpath id='Path_1960' data-name='Path 1960' d='M12,15,7.757,10.757,9.172,9.343,12,12.172l2.828-2.829,1.415,1.414Z' fill='%237d7996'/%3E%3C/svg%3E%0A") !important;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-position-x: calc(100% - 12px);
    background-repeat: no-repeat;
    background-size: 25px;
    display: flex;
    align-items: center;
    width: 100%
}

.form-group .custom-select.is-invalid {
    border-color: #ff3838;
    background-color: var(--white)
}

.form-group textarea {
    height: auto !important;
    padding-top: 15px
}

.form-group textarea::-webkit-resizer {
    display: none
}

.form-group.with_icon .input_group {
    position: relative;
    display: flex;
    align-items: center
}

.form-group.with_icon .input_group .icon {
    position: absolute;
    left: 18px
}

.form-group.with_icon .input_group .icon svg {
    width: 22px;
    height: 22px
}

.form-group.with_icon .input_group .icon svg path,
.form-group.with_icon .input_group .icon svg circle {
    stroke: var(--secondary)
}

.form-group.with_icon .input_group .icon i {
    font-size: 20px;
    color: var(--gray-300)
}

.form-group.with_icon .input_group .form-control {
    padding-left: 50px
}

.form-group .input_group {
    position: relative;
    display: flex;
    align-items: center
}

.form-group .input_group .icon_password {
    position: absolute;
    right: 18px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0
}

.form-group .input_group .icon_password i {
    font-size: 20px;
    color: var(--secondary)
}

.form-group .invalid-feedback {
    font-size: 12px;
    color: #ff3838;
    display: block;
    margin-top: 5px
}

.form-group.input-lined {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 20px
}

.form-group.input-lined label {
    font-size: 12px;
    margin-bottom: 0;
    line-height: 5px;
    -webkit-transition: .35s;
    -moz-transition: .35s;
    -o-transition: .35s;
    -ms-transition: .35s;
    transition: .35s
}

.form-group.input-lined .form-control {
    padding-left: 0;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid var(--gray-200);
    background-color: transparent;
    height: 45px;
    -webkit-transition: .35s;
    -moz-transition: .35s;
    -o-transition: .35s;
    -ms-transition: .35s;
    transition: .35s
}

.form-group.input-lined .form-control:focus {
    border-bottom-color: var(--border-blue)
}

.form-group.input-lined .form-control:focus+label {
    color: #395aff !important
}

.form-group.input-lined .form-control::placeholder {
    font-size: 16px
}

.form-group.input-lined .form-control:-moz-placeholder {
    font-size: 16px
}

.form-group.input-lined .form-control:-ms-input-placeholder {
    font-size: 16px
}

.form-group.input-lined .form-control::-webkit-input-placeholder {
    font-size: 16px
}

.form-group.input-lined .form-control.is-valid {
    border-bottom-color: #3eca76;
    background-color: transparent
}

.form-group.input-lined .form-control.is-invalid {
    border-bottom-color: #ff3838;
    background-color: transparent
}

.form-group.input-lined .additional_txt {
    font-size: 12px;
    color: var(--secondary);
    margin-top: 5px
}

.form-group.input-lined .group_additional {
    display: flex;
    flex-direction: column-reverse
}

.form-group.input-lined .custom-select {
    background-position-x: calc(100% - 3px)
}

.form-group.input-lined:focus-within label {
    color: #395aff
}

.form-group.inputDefault-animated .group_anime {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%
}

.form-group.inputDefault-animated .group_anime label {
    position: absolute;
    z-index: 1;
    left: 20px;
    margin: 0;
    background-color: transparent;
    color: var(--secondary);
    padding: 0;
    -webkit-transition: .35s;
    -moz-transition: .35s;
    -o-transition: .35s;
    -ms-transition: .35s;
    transition: .35s
}

.form-group.inputDefault-animated .group_anime .form-control:focus~label,
.form-group.inputDefault-animated .group_anime .form-control:valid~label {
    -webkit-transform: scale(0.85) translateY(-30px);
    -moz-transform: scale(0.85) translateY(-30px);
    -o-transform: scale(0.85) translateY(-30px);
    -ms-transform: scale(0.85) translateY(-30px);
    transform: scale(0.85) translateY(-30px);
    background-color: var(--white);
    padding: 2px 6px;
    left: 10px
}

.form-group.inputDefault-animated .group_anime .form-control:focus.is-valid~label,
.form-group.inputDefault-animated .group_anime .form-control:valid.is-valid~label {
    color: #3eca76
}

.form-group.inputDefault-animated .group_anime .form-control:focus.is-invalid~label,
.form-group.inputDefault-animated .group_anime .form-control:valid.is-invalid~label {
    color: #ff3838
}

.form-group.inputDefault-animated .group_anime .form-control::placeholder {
    opacity: 0
}

.form-group.inputDefault-animated .group_anime .form-control:-moz-placeholder {
    opacity: 0
}

.form-group.inputDefault-animated .group_anime .form-control:-ms-input-placeholder {
    opacity: 0
}

.form-group.inputDefault-animated .group_anime .form-control::-webkit-input-placeholder {
    opacity: 0
}

.form-group.lined__iconed .input_group {
    position: relative;
    display: flex;
    align-items: center
}

.form-group.lined__iconed .input_group .icon {
    position: absolute;
    left: 0
}

.form-group.lined__iconed .input_group .icon svg {
    width: 22px;
    height: 22px
}

.form-group.lined__iconed .input_group .icon svg path,
.form-group.lined__iconed .input_group .icon svg circle {
    stroke: var(--secondary)
}

.form-group.lined__iconed .input_group .icon i {
    font-size: 20px;
    color: var(--secondary)
}

.form-group.lined__iconed .input_group .form-control {
    padding-left: 30px
}

.form-report .btn-report {
    width: 100%;
    min-height: 52px;
    border-radius: 10px;
    color: #fff;
    background-color: #395aff;
    font-size: 15px;
    font-weight: 500
}

.padding-0 {
    padding: 0px
}

.padding-t-0 {
    padding-top: 0px
}

.padding-b-0 {
    padding-bottom: 0px
}

.padding-r-0 {
    padding-right: 0px
}

.padding-l-0 {
    padding-left: 0px
}

.padding-y-0 {
    padding-top: 0px;
    padding-bottom: 0px
}

.padding-x-0 {
    padding-right: 0px;
    padding-left: 0px
}

.margin-0 {
    margin: 0px
}

.margin-t-0 {
    margin-top: 0px
}

.margin-b-0 {
    margin-bottom: 0px
}

.margin-r-0 {
    margin-right: 0px
}

.margin-l-0 {
    margin-left: 0px
}

.margin-y-0 {
    margin-top: 0px;
    margin-bottom: 0px
}

.margin-x-0 {
    margin-right: 0px;
    margin-left: 0px
}

.padding-1 {
    padding: 1px
}

.padding-t-1 {
    padding-top: 1px
}

.padding-b-1 {
    padding-bottom: 1px
}

.padding-r-1 {
    padding-right: 1px
}

.padding-l-1 {
    padding-left: 1px
}

.padding-y-1 {
    padding-top: 1px;
    padding-bottom: 1px
}

.padding-x-1 {
    padding-right: 1px;
    padding-left: 1px
}

.margin-1 {
    margin: 1px
}

.margin-t-1 {
    margin-top: 1px
}

.margin-b-1 {
    margin-bottom: 1px
}

.margin-r-1 {
    margin-right: 1px
}

.margin-l-1 {
    margin-left: 1px
}

.margin-y-1 {
    margin-top: 1px;
    margin-bottom: 1px
}

.margin-x-1 {
    margin-right: 1px;
    margin-left: 1px
}

.padding-2 {
    padding: 2px
}

.padding-t-2 {
    padding-top: 2px
}

.padding-b-2 {
    padding-bottom: 2px
}

.padding-r-2 {
    padding-right: 2px
}

.padding-l-2 {
    padding-left: 2px
}

.padding-y-2 {
    padding-top: 2px;
    padding-bottom: 2px
}

.padding-x-2 {
    padding-right: 2px;
    padding-left: 2px
}

.margin-2 {
    margin: 2px
}

.margin-t-2 {
    margin-top: 2px
}

.margin-b-2 {
    margin-bottom: 2px
}

.margin-r-2 {
    margin-right: 2px
}

.margin-l-2 {
    margin-left: 2px
}

.margin-y-2 {
    margin-top: 2px;
    margin-bottom: 2px
}

.margin-x-2 {
    margin-right: 2px;
    margin-left: 2px
}

.padding-3 {
    padding: 3px
}

.padding-t-3 {
    padding-top: 3px
}

.padding-b-3 {
    padding-bottom: 3px
}

.padding-r-3 {
    padding-right: 3px
}

.padding-l-3 {
    padding-left: 3px
}

.padding-y-3 {
    padding-top: 3px;
    padding-bottom: 3px
}

.padding-x-3 {
    padding-right: 3px;
    padding-left: 3px
}

.margin-3 {
    margin: 3px
}

.margin-t-3 {
    margin-top: 3px
}

.margin-b-3 {
    margin-bottom: 3px
}

.margin-r-3 {
    margin-right: 3px
}

.margin-l-3 {
    margin-left: 3px
}

.margin-y-3 {
    margin-top: 3px;
    margin-bottom: 3px
}

.margin-x-3 {
    margin-right: 3px;
    margin-left: 3px
}

.padding-4 {
    padding: 4px
}

.padding-t-4 {
    padding-top: 4px
}

.padding-b-4 {
    padding-bottom: 4px
}

.padding-r-4 {
    padding-right: 4px
}

.padding-l-4 {
    padding-left: 4px
}

.padding-y-4 {
    padding-top: 4px;
    padding-bottom: 4px
}

.padding-x-4 {
    padding-right: 4px;
    padding-left: 4px
}

.margin-4 {
    margin: 4px
}

.margin-t-4 {
    margin-top: 4px
}

.margin-b-4 {
    margin-bottom: 4px
}

.margin-r-4 {
    margin-right: 4px
}

.margin-l-4 {
    margin-left: 4px
}

.margin-y-4 {
    margin-top: 4px;
    margin-bottom: 4px
}

.margin-x-4 {
    margin-right: 4px;
    margin-left: 4px
}

.padding-5 {
    padding: 5px
}

.padding-t-5 {
    padding-top: 5px
}

.padding-b-5 {
    padding-bottom: 5px
}

.padding-r-5 {
    padding-right: 5px
}

.padding-l-5 {
    padding-left: 5px
}

.padding-y-5 {
    padding-top: 5px;
    padding-bottom: 5px
}

.padding-x-5 {
    padding-right: 5px;
    padding-left: 5px
}

.margin-5 {
    margin: 5px
}

.margin-t-5 {
    margin-top: 5px
}

.margin-b-5 {
    margin-bottom: 5px
}

.margin-r-5 {
    margin-right: 5px
}

.margin-l-5 {
    margin-left: 5px
}

.margin-y-5 {
    margin-top: 5px;
    margin-bottom: 5px
}

.margin-x-5 {
    margin-right: 5px;
    margin-left: 5px
}

.padding-6 {
    padding: 6px
}

.padding-t-6 {
    padding-top: 6px
}

.padding-b-6 {
    padding-bottom: 6px
}

.padding-r-6 {
    padding-right: 6px
}

.padding-l-6 {
    padding-left: 6px
}

.padding-y-6 {
    padding-top: 6px;
    padding-bottom: 6px
}

.padding-x-6 {
    padding-right: 6px;
    padding-left: 6px
}

.margin-6 {
    margin: 6px
}

.margin-t-6 {
    margin-top: 6px
}

.margin-b-6 {
    margin-bottom: 6px
}

.margin-r-6 {
    margin-right: 6px
}

.margin-l-6 {
    margin-left: 6px
}

.margin-y-6 {
    margin-top: 6px;
    margin-bottom: 6px
}

.margin-x-6 {
    margin-right: 6px;
    margin-left: 6px
}

.padding-7 {
    padding: 7px
}

.padding-t-7 {
    padding-top: 7px
}

.padding-b-7 {
    padding-bottom: 7px
}

.padding-r-7 {
    padding-right: 7px
}

.padding-l-7 {
    padding-left: 7px
}

.padding-y-7 {
    padding-top: 7px;
    padding-bottom: 7px
}

.padding-x-7 {
    padding-right: 7px;
    padding-left: 7px
}

.margin-7 {
    margin: 7px
}

.margin-t-7 {
    margin-top: 7px
}

.margin-b-7 {
    margin-bottom: 7px
}

.margin-r-7 {
    margin-right: 7px
}

.margin-l-7 {
    margin-left: 7px
}

.margin-y-7 {
    margin-top: 7px;
    margin-bottom: 7px
}

.margin-x-7 {
    margin-right: 7px;
    margin-left: 7px
}

.padding-8 {
    padding: 8px
}

.padding-t-8 {
    padding-top: 8px
}

.padding-b-8 {
    padding-bottom: 8px
}

.padding-r-8 {
    padding-right: 8px
}

.padding-l-8 {
    padding-left: 8px
}

.padding-y-8 {
    padding-top: 8px;
    padding-bottom: 8px
}

.padding-x-8 {
    padding-right: 8px;
    padding-left: 8px
}

.margin-8 {
    margin: 8px
}

.margin-t-8 {
    margin-top: 8px
}

.margin-b-8 {
    margin-bottom: 8px
}

.margin-r-8 {
    margin-right: 8px
}

.margin-l-8 {
    margin-left: 8px
}

.margin-y-8 {
    margin-top: 8px;
    margin-bottom: 8px
}

.margin-x-8 {
    margin-right: 8px;
    margin-left: 8px
}

.padding-9 {
    padding: 9px
}

.padding-t-9 {
    padding-top: 9px
}

.padding-b-9 {
    padding-bottom: 9px
}

.padding-r-9 {
    padding-right: 9px
}

.padding-l-9 {
    padding-left: 9px
}

.padding-y-9 {
    padding-top: 9px;
    padding-bottom: 9px
}

.padding-x-9 {
    padding-right: 9px;
    padding-left: 9px
}

.margin-9 {
    margin: 9px
}

.margin-t-9 {
    margin-top: 9px
}

.margin-b-9 {
    margin-bottom: 9px
}

.margin-r-9 {
    margin-right: 9px
}

.margin-l-9 {
    margin-left: 9px
}

.margin-y-9 {
    margin-top: 9px;
    margin-bottom: 9px
}

.margin-x-9 {
    margin-right: 9px;
    margin-left: 9px
}

.padding-10 {
    padding: 10px
}

.padding-t-10 {
    padding-top: 10px
}

.padding-b-10 {
    padding-bottom: 10px
}

.padding-r-10 {
    padding-right: 10px
}

.padding-l-10 {
    padding-left: 10px
}

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px
}

.padding-x-10 {
    padding-right: 10px;
    padding-left: 10px
}

.margin-10 {
    margin: 10px
}

.margin-t-10 {
    margin-top: 10px
}

.margin-b-10 {
    margin-bottom: 10px
}

.margin-r-10 {
    margin-right: 10px
}

.margin-l-10 {
    margin-left: 10px
}

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px
}

.margin-x-10 {
    margin-right: 10px;
    margin-left: 10px
}

.padding-11 {
    padding: 11px
}

.padding-t-11 {
    padding-top: 11px
}

.padding-b-11 {
    padding-bottom: 11px
}

.padding-r-11 {
    padding-right: 11px
}

.padding-l-11 {
    padding-left: 11px
}

.padding-y-11 {
    padding-top: 11px;
    padding-bottom: 11px
}

.padding-x-11 {
    padding-right: 11px;
    padding-left: 11px
}

.margin-11 {
    margin: 11px
}

.margin-t-11 {
    margin-top: 11px
}

.margin-b-11 {
    margin-bottom: 11px
}

.margin-r-11 {
    margin-right: 11px
}

.margin-l-11 {
    margin-left: 11px
}

.margin-y-11 {
    margin-top: 11px;
    margin-bottom: 11px
}

.margin-x-11 {
    margin-right: 11px;
    margin-left: 11px
}

.padding-12 {
    padding: 12px
}

.padding-t-12 {
    padding-top: 12px
}

.padding-b-12 {
    padding-bottom: 12px
}

.padding-r-12 {
    padding-right: 12px
}

.padding-l-12 {
    padding-left: 12px
}

.padding-y-12 {
    padding-top: 12px;
    padding-bottom: 12px
}

.padding-x-12 {
    padding-right: 12px;
    padding-left: 12px
}

.margin-12 {
    margin: 12px
}

.margin-t-12 {
    margin-top: 12px
}

.margin-b-12 {
    margin-bottom: 12px
}

.margin-r-12 {
    margin-right: 12px
}

.margin-l-12 {
    margin-left: 12px
}

.margin-y-12 {
    margin-top: 12px;
    margin-bottom: 12px
}

.margin-x-12 {
    margin-right: 12px;
    margin-left: 12px
}

.padding-13 {
    padding: 13px
}

.padding-t-13 {
    padding-top: 13px
}

.padding-b-13 {
    padding-bottom: 13px
}

.padding-r-13 {
    padding-right: 13px
}

.padding-l-13 {
    padding-left: 13px
}

.padding-y-13 {
    padding-top: 13px;
    padding-bottom: 13px
}

.padding-x-13 {
    padding-right: 13px;
    padding-left: 13px
}

.margin-13 {
    margin: 13px
}

.margin-t-13 {
    margin-top: 13px
}

.margin-b-13 {
    margin-bottom: 13px
}

.margin-r-13 {
    margin-right: 13px
}

.margin-l-13 {
    margin-left: 13px
}

.margin-y-13 {
    margin-top: 13px;
    margin-bottom: 13px
}

.margin-x-13 {
    margin-right: 13px;
    margin-left: 13px
}

.padding-14 {
    padding: 14px
}

.padding-t-14 {
    padding-top: 14px
}

.padding-b-14 {
    padding-bottom: 14px
}

.padding-r-14 {
    padding-right: 14px
}

.padding-l-14 {
    padding-left: 14px
}

.padding-y-14 {
    padding-top: 14px;
    padding-bottom: 14px
}

.padding-x-14 {
    padding-right: 14px;
    padding-left: 14px
}

.margin-14 {
    margin: 14px
}

.margin-t-14 {
    margin-top: 14px
}

.margin-b-14 {
    margin-bottom: 14px
}

.margin-r-14 {
    margin-right: 14px
}

.margin-l-14 {
    margin-left: 14px
}

.margin-y-14 {
    margin-top: 14px;
    margin-bottom: 14px
}

.margin-x-14 {
    margin-right: 14px;
    margin-left: 14px
}

.padding-15 {
    padding: 15px
}

.padding-t-15 {
    padding-top: 15px
}

.padding-b-15 {
    padding-bottom: 15px
}

.padding-r-15 {
    padding-right: 15px
}

.padding-l-15 {
    padding-left: 15px
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px
}

.padding-x-15 {
    padding-right: 15px;
    padding-left: 15px
}

.margin-15 {
    margin: 15px
}

.margin-t-15 {
    margin-top: 15px
}

.margin-b-15 {
    margin-bottom: 15px
}

.margin-r-15 {
    margin-right: 15px
}

.margin-l-15 {
    margin-left: 15px
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px
}

.margin-x-15 {
    margin-right: 15px;
    margin-left: 15px
}

.padding-16 {
    padding: 16px
}

.padding-t-16 {
    padding-top: 16px
}

.padding-b-16 {
    padding-bottom: 16px
}

.padding-r-16 {
    padding-right: 16px
}

.padding-l-16 {
    padding-left: 16px
}

.padding-y-16 {
    padding-top: 16px;
    padding-bottom: 16px
}

.padding-x-16 {
    padding-right: 16px;
    padding-left: 16px
}

.margin-16 {
    margin: 16px
}

.margin-t-16 {
    margin-top: 16px
}

.margin-b-16 {
    margin-bottom: 16px
}

.margin-r-16 {
    margin-right: 16px
}

.margin-l-16 {
    margin-left: 16px
}

.margin-y-16 {
    margin-top: 16px;
    margin-bottom: 16px
}

.margin-x-16 {
    margin-right: 16px;
    margin-left: 16px
}

.padding-17 {
    padding: 17px
}

.padding-t-17 {
    padding-top: 17px
}

.padding-b-17 {
    padding-bottom: 17px
}

.padding-r-17 {
    padding-right: 17px
}

.padding-l-17 {
    padding-left: 17px
}

.padding-y-17 {
    padding-top: 17px;
    padding-bottom: 17px
}

.padding-x-17 {
    padding-right: 17px;
    padding-left: 17px
}

.margin-17 {
    margin: 17px
}

.margin-t-17 {
    margin-top: 17px
}

.margin-b-17 {
    margin-bottom: 17px
}

.margin-r-17 {
    margin-right: 17px
}

.margin-l-17 {
    margin-left: 17px
}

.margin-y-17 {
    margin-top: 17px;
    margin-bottom: 17px
}

.margin-x-17 {
    margin-right: 17px;
    margin-left: 17px
}

.padding-18 {
    padding: 18px
}

.padding-t-18 {
    padding-top: 18px
}

.padding-b-18 {
    padding-bottom: 18px
}

.padding-r-18 {
    padding-right: 18px
}

.padding-l-18 {
    padding-left: 18px
}

.padding-y-18 {
    padding-top: 18px;
    padding-bottom: 18px
}

.padding-x-18 {
    padding-right: 18px;
    padding-left: 18px
}

.margin-18 {
    margin: 18px
}

.margin-t-18 {
    margin-top: 18px
}

.margin-b-18 {
    margin-bottom: 18px
}

.margin-r-18 {
    margin-right: 18px
}

.margin-l-18 {
    margin-left: 18px
}

.margin-y-18 {
    margin-top: 18px;
    margin-bottom: 18px
}

.margin-x-18 {
    margin-right: 18px;
    margin-left: 18px
}

.padding-19 {
    padding: 19px
}

.padding-t-19 {
    padding-top: 19px
}

.padding-b-19 {
    padding-bottom: 19px
}

.padding-r-19 {
    padding-right: 19px
}

.padding-l-19 {
    padding-left: 19px
}

.padding-y-19 {
    padding-top: 19px;
    padding-bottom: 19px
}

.padding-x-19 {
    padding-right: 19px;
    padding-left: 19px
}

.margin-19 {
    margin: 19px
}

.margin-t-19 {
    margin-top: 19px
}

.margin-b-19 {
    margin-bottom: 19px
}

.margin-r-19 {
    margin-right: 19px
}

.margin-l-19 {
    margin-left: 19px
}

.margin-y-19 {
    margin-top: 19px;
    margin-bottom: 19px
}

.margin-x-19 {
    margin-right: 19px;
    margin-left: 19px
}

.padding-20 {
    padding: 20px
}

.padding-t-20 {
    padding-top: 20px
}

.padding-b-20 {
    padding-bottom: 20px
}

.padding-r-20 {
    padding-right: 20px
}

.padding-l-20 {
    padding-left: 20px
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px
}

.padding-x-20 {
    padding-right: 20px;
    padding-left: 20px
}

.margin-20 {
    margin: 20px
}

.margin-t-20 {
    margin-top: 20px
}

.margin-b-20 {
    margin-bottom: 20px
}

.margin-r-20 {
    margin-right: 20px
}

.margin-l-20 {
    margin-left: 20px
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px
}

.margin-x-20 {
    margin-right: 20px;
    margin-left: 20px
}

.padding-21 {
    padding: 21px
}

.padding-t-21 {
    padding-top: 21px
}

.padding-b-21 {
    padding-bottom: 21px
}

.padding-r-21 {
    padding-right: 21px
}

.padding-l-21 {
    padding-left: 21px
}

.padding-y-21 {
    padding-top: 21px;
    padding-bottom: 21px
}

.padding-x-21 {
    padding-right: 21px;
    padding-left: 21px
}

.margin-21 {
    margin: 21px
}

.margin-t-21 {
    margin-top: 21px
}

.margin-b-21 {
    margin-bottom: 21px
}

.margin-r-21 {
    margin-right: 21px
}

.margin-l-21 {
    margin-left: 21px
}

.margin-y-21 {
    margin-top: 21px;
    margin-bottom: 21px
}

.margin-x-21 {
    margin-right: 21px;
    margin-left: 21px
}

.padding-22 {
    padding: 22px
}

.padding-t-22 {
    padding-top: 22px
}

.padding-b-22 {
    padding-bottom: 22px
}

.padding-r-22 {
    padding-right: 22px
}

.padding-l-22 {
    padding-left: 22px
}

.padding-y-22 {
    padding-top: 22px;
    padding-bottom: 22px
}

.padding-x-22 {
    padding-right: 22px;
    padding-left: 22px
}

.margin-22 {
    margin: 22px
}

.margin-t-22 {
    margin-top: 22px
}

.margin-b-22 {
    margin-bottom: 22px
}

.margin-r-22 {
    margin-right: 22px
}

.margin-l-22 {
    margin-left: 22px
}

.margin-y-22 {
    margin-top: 22px;
    margin-bottom: 22px
}

.margin-x-22 {
    margin-right: 22px;
    margin-left: 22px
}

.padding-23 {
    padding: 23px
}

.padding-t-23 {
    padding-top: 23px
}

.padding-b-23 {
    padding-bottom: 23px
}

.padding-r-23 {
    padding-right: 23px
}

.padding-l-23 {
    padding-left: 23px
}

.padding-y-23 {
    padding-top: 23px;
    padding-bottom: 23px
}

.padding-x-23 {
    padding-right: 23px;
    padding-left: 23px
}

.margin-23 {
    margin: 23px
}

.margin-t-23 {
    margin-top: 23px
}

.margin-b-23 {
    margin-bottom: 23px
}

.margin-r-23 {
    margin-right: 23px
}

.margin-l-23 {
    margin-left: 23px
}

.margin-y-23 {
    margin-top: 23px;
    margin-bottom: 23px
}

.margin-x-23 {
    margin-right: 23px;
    margin-left: 23px
}

.padding-24 {
    padding: 24px
}

.padding-t-24 {
    padding-top: 24px
}

.padding-b-24 {
    padding-bottom: 24px
}

.padding-r-24 {
    padding-right: 24px
}

.padding-l-24 {
    padding-left: 24px
}

.padding-y-24 {
    padding-top: 24px;
    padding-bottom: 24px
}

.padding-x-24 {
    padding-right: 24px;
    padding-left: 24px
}

.margin-24 {
    margin: 24px
}

.margin-t-24 {
    margin-top: 24px
}

.margin-b-24 {
    margin-bottom: 24px
}

.margin-r-24 {
    margin-right: 24px
}

.margin-l-24 {
    margin-left: 24px
}

.margin-y-24 {
    margin-top: 24px;
    margin-bottom: 24px
}

.margin-x-24 {
    margin-right: 24px;
    margin-left: 24px
}

.padding-25 {
    padding: 25px
}

.padding-t-25 {
    padding-top: 25px
}

.padding-b-25 {
    padding-bottom: 25px
}

.padding-r-25 {
    padding-right: 25px
}

.padding-l-25 {
    padding-left: 25px
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px
}

.padding-x-25 {
    padding-right: 25px;
    padding-left: 25px
}

.margin-25 {
    margin: 25px
}

.margin-t-25 {
    margin-top: 25px
}

.margin-b-25 {
    margin-bottom: 25px
}

.margin-r-25 {
    margin-right: 25px
}

.margin-l-25 {
    margin-left: 25px
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px
}

.margin-x-25 {
    margin-right: 25px;
    margin-left: 25px
}

.padding-26 {
    padding: 26px
}

.padding-t-26 {
    padding-top: 26px
}

.padding-b-26 {
    padding-bottom: 26px
}

.padding-r-26 {
    padding-right: 26px
}

.padding-l-26 {
    padding-left: 26px
}

.padding-y-26 {
    padding-top: 26px;
    padding-bottom: 26px
}

.padding-x-26 {
    padding-right: 26px;
    padding-left: 26px
}

.margin-26 {
    margin: 26px
}

.margin-t-26 {
    margin-top: 26px
}

.margin-b-26 {
    margin-bottom: 26px
}

.margin-r-26 {
    margin-right: 26px
}

.margin-l-26 {
    margin-left: 26px
}

.margin-y-26 {
    margin-top: 26px;
    margin-bottom: 26px
}

.margin-x-26 {
    margin-right: 26px;
    margin-left: 26px
}

.padding-27 {
    padding: 27px
}

.padding-t-27 {
    padding-top: 27px
}

.padding-b-27 {
    padding-bottom: 27px
}

.padding-r-27 {
    padding-right: 27px
}

.padding-l-27 {
    padding-left: 27px
}

.padding-y-27 {
    padding-top: 27px;
    padding-bottom: 27px
}

.padding-x-27 {
    padding-right: 27px;
    padding-left: 27px
}

.margin-27 {
    margin: 27px
}

.margin-t-27 {
    margin-top: 27px
}

.margin-b-27 {
    margin-bottom: 27px
}

.margin-r-27 {
    margin-right: 27px
}

.margin-l-27 {
    margin-left: 27px
}

.margin-y-27 {
    margin-top: 27px;
    margin-bottom: 27px
}

.margin-x-27 {
    margin-right: 27px;
    margin-left: 27px
}

.padding-28 {
    padding: 28px
}

.padding-t-28 {
    padding-top: 28px
}

.padding-b-28 {
    padding-bottom: 28px
}

.padding-r-28 {
    padding-right: 28px
}

.padding-l-28 {
    padding-left: 28px
}

.padding-y-28 {
    padding-top: 28px;
    padding-bottom: 28px
}

.padding-x-28 {
    padding-right: 28px;
    padding-left: 28px
}

.margin-28 {
    margin: 28px
}

.margin-t-28 {
    margin-top: 28px
}

.margin-b-28 {
    margin-bottom: 28px
}

.margin-r-28 {
    margin-right: 28px
}

.margin-l-28 {
    margin-left: 28px
}

.margin-y-28 {
    margin-top: 28px;
    margin-bottom: 28px
}

.margin-x-28 {
    margin-right: 28px;
    margin-left: 28px
}

.padding-29 {
    padding: 29px
}

.padding-t-29 {
    padding-top: 29px
}

.padding-b-29 {
    padding-bottom: 29px
}

.padding-r-29 {
    padding-right: 29px
}

.padding-l-29 {
    padding-left: 29px
}

.padding-y-29 {
    padding-top: 29px;
    padding-bottom: 29px
}

.padding-x-29 {
    padding-right: 29px;
    padding-left: 29px
}

.margin-29 {
    margin: 29px
}

.margin-t-29 {
    margin-top: 29px
}

.margin-b-29 {
    margin-bottom: 29px
}

.margin-r-29 {
    margin-right: 29px
}

.margin-l-29 {
    margin-left: 29px
}

.margin-y-29 {
    margin-top: 29px;
    margin-bottom: 29px
}

.margin-x-29 {
    margin-right: 29px;
    margin-left: 29px
}

.padding-30 {
    padding: 30px
}

.padding-t-30 {
    padding-top: 30px
}

.padding-b-30 {
    padding-bottom: 30px
}

.padding-r-30 {
    padding-right: 30px
}

.padding-l-30 {
    padding-left: 30px
}

.padding-y-30 {
    padding-top: 30px;
    padding-bottom: 30px
}

.padding-x-30 {
    padding-right: 30px;
    padding-left: 30px
}

.margin-30 {
    margin: 30px
}

.margin-t-30 {
    margin-top: 30px
}

.margin-b-30 {
    margin-bottom: 30px
}

.margin-r-30 {
    margin-right: 30px
}

.margin-l-30 {
    margin-left: 30px
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px
}

.margin-x-30 {
    margin-right: 30px;
    margin-left: 30px
}

.padding-31 {
    padding: 31px
}

.padding-t-31 {
    padding-top: 31px
}

.padding-b-31 {
    padding-bottom: 31px
}

.padding-r-31 {
    padding-right: 31px
}

.padding-l-31 {
    padding-left: 31px
}

.padding-y-31 {
    padding-top: 31px;
    padding-bottom: 31px
}

.padding-x-31 {
    padding-right: 31px;
    padding-left: 31px
}

.margin-31 {
    margin: 31px
}

.margin-t-31 {
    margin-top: 31px
}

.margin-b-31 {
    margin-bottom: 31px
}

.margin-r-31 {
    margin-right: 31px
}

.margin-l-31 {
    margin-left: 31px
}

.margin-y-31 {
    margin-top: 31px;
    margin-bottom: 31px
}

.margin-x-31 {
    margin-right: 31px;
    margin-left: 31px
}

.padding-32 {
    padding: 32px
}

.padding-t-32 {
    padding-top: 32px
}

.padding-b-32 {
    padding-bottom: 32px
}

.padding-r-32 {
    padding-right: 32px
}

.padding-l-32 {
    padding-left: 32px
}

.padding-y-32 {
    padding-top: 32px;
    padding-bottom: 32px
}

.padding-x-32 {
    padding-right: 32px;
    padding-left: 32px
}

.margin-32 {
    margin: 32px
}

.margin-t-32 {
    margin-top: 32px
}

.margin-b-32 {
    margin-bottom: 32px
}

.margin-r-32 {
    margin-right: 32px
}

.margin-l-32 {
    margin-left: 32px
}

.margin-y-32 {
    margin-top: 32px;
    margin-bottom: 32px
}

.margin-x-32 {
    margin-right: 32px;
    margin-left: 32px
}

.padding-33 {
    padding: 33px
}

.padding-t-33 {
    padding-top: 33px
}

.padding-b-33 {
    padding-bottom: 33px
}

.padding-r-33 {
    padding-right: 33px
}

.padding-l-33 {
    padding-left: 33px
}

.padding-y-33 {
    padding-top: 33px;
    padding-bottom: 33px
}

.padding-x-33 {
    padding-right: 33px;
    padding-left: 33px
}

.margin-33 {
    margin: 33px
}

.margin-t-33 {
    margin-top: 33px
}

.margin-b-33 {
    margin-bottom: 33px
}

.margin-r-33 {
    margin-right: 33px
}

.margin-l-33 {
    margin-left: 33px
}

.margin-y-33 {
    margin-top: 33px;
    margin-bottom: 33px
}

.margin-x-33 {
    margin-right: 33px;
    margin-left: 33px
}

.padding-34 {
    padding: 34px
}

.padding-t-34 {
    padding-top: 34px
}

.padding-b-34 {
    padding-bottom: 34px
}

.padding-r-34 {
    padding-right: 34px
}

.padding-l-34 {
    padding-left: 34px
}

.padding-y-34 {
    padding-top: 34px;
    padding-bottom: 34px
}

.padding-x-34 {
    padding-right: 34px;
    padding-left: 34px
}

.margin-34 {
    margin: 34px
}

.margin-t-34 {
    margin-top: 34px
}

.margin-b-34 {
    margin-bottom: 34px
}

.margin-r-34 {
    margin-right: 34px
}

.margin-l-34 {
    margin-left: 34px
}

.margin-y-34 {
    margin-top: 34px;
    margin-bottom: 34px
}

.margin-x-34 {
    margin-right: 34px;
    margin-left: 34px
}

.padding-35 {
    padding: 35px
}

.padding-t-35 {
    padding-top: 35px
}

.padding-b-35 {
    padding-bottom: 35px
}

.padding-r-35 {
    padding-right: 35px
}

.padding-l-35 {
    padding-left: 35px
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px
}

.padding-x-35 {
    padding-right: 35px;
    padding-left: 35px
}

.margin-35 {
    margin: 35px
}

.margin-t-35 {
    margin-top: 35px
}

.margin-b-35 {
    margin-bottom: 35px
}

.margin-r-35 {
    margin-right: 35px
}

.margin-l-35 {
    margin-left: 35px
}

.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px
}

.margin-x-35 {
    margin-right: 35px;
    margin-left: 35px
}

.padding-36 {
    padding: 36px
}

.padding-t-36 {
    padding-top: 36px
}

.padding-b-36 {
    padding-bottom: 36px
}

.padding-r-36 {
    padding-right: 36px
}

.padding-l-36 {
    padding-left: 36px
}

.padding-y-36 {
    padding-top: 36px;
    padding-bottom: 36px
}

.padding-x-36 {
    padding-right: 36px;
    padding-left: 36px
}

.margin-36 {
    margin: 36px
}

.margin-t-36 {
    margin-top: 36px
}

.margin-b-36 {
    margin-bottom: 36px
}

.margin-r-36 {
    margin-right: 36px
}

.margin-l-36 {
    margin-left: 36px
}

.margin-y-36 {
    margin-top: 36px;
    margin-bottom: 36px
}

.margin-x-36 {
    margin-right: 36px;
    margin-left: 36px
}

.padding-37 {
    padding: 37px
}

.padding-t-37 {
    padding-top: 37px
}

.padding-b-37 {
    padding-bottom: 37px
}

.padding-r-37 {
    padding-right: 37px
}

.padding-l-37 {
    padding-left: 37px
}

.padding-y-37 {
    padding-top: 37px;
    padding-bottom: 37px
}

.padding-x-37 {
    padding-right: 37px;
    padding-left: 37px
}

.margin-37 {
    margin: 37px
}

.margin-t-37 {
    margin-top: 37px
}

.margin-b-37 {
    margin-bottom: 37px
}

.margin-r-37 {
    margin-right: 37px
}

.margin-l-37 {
    margin-left: 37px
}

.margin-y-37 {
    margin-top: 37px;
    margin-bottom: 37px
}

.margin-x-37 {
    margin-right: 37px;
    margin-left: 37px
}

.padding-38 {
    padding: 38px
}

.padding-t-38 {
    padding-top: 38px
}

.padding-b-38 {
    padding-bottom: 38px
}

.padding-r-38 {
    padding-right: 38px
}

.padding-l-38 {
    padding-left: 38px
}

.padding-y-38 {
    padding-top: 38px;
    padding-bottom: 38px
}

.padding-x-38 {
    padding-right: 38px;
    padding-left: 38px
}

.margin-38 {
    margin: 38px
}

.margin-t-38 {
    margin-top: 38px
}

.margin-b-38 {
    margin-bottom: 38px
}

.margin-r-38 {
    margin-right: 38px
}

.margin-l-38 {
    margin-left: 38px
}

.margin-y-38 {
    margin-top: 38px;
    margin-bottom: 38px
}

.margin-x-38 {
    margin-right: 38px;
    margin-left: 38px
}

.padding-39 {
    padding: 39px
}

.padding-t-39 {
    padding-top: 39px
}

.padding-b-39 {
    padding-bottom: 39px
}

.padding-r-39 {
    padding-right: 39px
}

.padding-l-39 {
    padding-left: 39px
}

.padding-y-39 {
    padding-top: 39px;
    padding-bottom: 39px
}

.padding-x-39 {
    padding-right: 39px;
    padding-left: 39px
}

.margin-39 {
    margin: 39px
}

.margin-t-39 {
    margin-top: 39px
}

.margin-b-39 {
    margin-bottom: 39px
}

.margin-r-39 {
    margin-right: 39px
}

.margin-l-39 {
    margin-left: 39px
}

.margin-y-39 {
    margin-top: 39px;
    margin-bottom: 39px
}

.margin-x-39 {
    margin-right: 39px;
    margin-left: 39px
}

.padding-40 {
    padding: 40px
}

.padding-t-40 {
    padding-top: 40px
}

.padding-b-40 {
    padding-bottom: 40px
}

.padding-r-40 {
    padding-right: 40px
}

.padding-l-40 {
    padding-left: 40px
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px
}

.padding-x-40 {
    padding-right: 40px;
    padding-left: 40px
}

.margin-40 {
    margin: 40px
}

.margin-t-40 {
    margin-top: 40px
}

.margin-b-40 {
    margin-bottom: 40px
}

.margin-r-40 {
    margin-right: 40px
}

.margin-l-40 {
    margin-left: 40px
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px
}

.margin-x-40 {
    margin-right: 40px;
    margin-left: 40px
}

.padding-41 {
    padding: 41px
}

.padding-t-41 {
    padding-top: 41px
}

.padding-b-41 {
    padding-bottom: 41px
}

.padding-r-41 {
    padding-right: 41px
}

.padding-l-41 {
    padding-left: 41px
}

.padding-y-41 {
    padding-top: 41px;
    padding-bottom: 41px
}

.padding-x-41 {
    padding-right: 41px;
    padding-left: 41px
}

.margin-41 {
    margin: 41px
}

.margin-t-41 {
    margin-top: 41px
}

.margin-b-41 {
    margin-bottom: 41px
}

.margin-r-41 {
    margin-right: 41px
}

.margin-l-41 {
    margin-left: 41px
}

.margin-y-41 {
    margin-top: 41px;
    margin-bottom: 41px
}

.margin-x-41 {
    margin-right: 41px;
    margin-left: 41px
}

.padding-42 {
    padding: 42px
}

.padding-t-42 {
    padding-top: 42px
}

.padding-b-42 {
    padding-bottom: 42px
}

.padding-r-42 {
    padding-right: 42px
}

.padding-l-42 {
    padding-left: 42px
}

.padding-y-42 {
    padding-top: 42px;
    padding-bottom: 42px
}

.padding-x-42 {
    padding-right: 42px;
    padding-left: 42px
}

.margin-42 {
    margin: 42px
}

.margin-t-42 {
    margin-top: 42px
}

.margin-b-42 {
    margin-bottom: 42px
}

.margin-r-42 {
    margin-right: 42px
}

.margin-l-42 {
    margin-left: 42px
}

.margin-y-42 {
    margin-top: 42px;
    margin-bottom: 42px
}

.margin-x-42 {
    margin-right: 42px;
    margin-left: 42px
}

.padding-43 {
    padding: 43px
}

.padding-t-43 {
    padding-top: 43px
}

.padding-b-43 {
    padding-bottom: 43px
}

.padding-r-43 {
    padding-right: 43px
}

.padding-l-43 {
    padding-left: 43px
}

.padding-y-43 {
    padding-top: 43px;
    padding-bottom: 43px
}

.padding-x-43 {
    padding-right: 43px;
    padding-left: 43px
}

.margin-43 {
    margin: 43px
}

.margin-t-43 {
    margin-top: 43px
}

.margin-b-43 {
    margin-bottom: 43px
}

.margin-r-43 {
    margin-right: 43px
}

.margin-l-43 {
    margin-left: 43px
}

.margin-y-43 {
    margin-top: 43px;
    margin-bottom: 43px
}

.margin-x-43 {
    margin-right: 43px;
    margin-left: 43px
}

.padding-44 {
    padding: 44px
}

.padding-t-44 {
    padding-top: 44px
}

.padding-b-44 {
    padding-bottom: 44px
}

.padding-r-44 {
    padding-right: 44px
}

.padding-l-44 {
    padding-left: 44px
}

.padding-y-44 {
    padding-top: 44px;
    padding-bottom: 44px
}

.padding-x-44 {
    padding-right: 44px;
    padding-left: 44px
}

.margin-44 {
    margin: 44px
}

.margin-t-44 {
    margin-top: 44px
}

.margin-b-44 {
    margin-bottom: 44px
}

.margin-r-44 {
    margin-right: 44px
}

.margin-l-44 {
    margin-left: 44px
}

.margin-y-44 {
    margin-top: 44px;
    margin-bottom: 44px
}

.margin-x-44 {
    margin-right: 44px;
    margin-left: 44px
}

.padding-45 {
    padding: 45px
}

.padding-t-45 {
    padding-top: 45px
}

.padding-b-45 {
    padding-bottom: 45px
}

.padding-r-45 {
    padding-right: 45px
}

.padding-l-45 {
    padding-left: 45px
}

.padding-y-45 {
    padding-top: 45px;
    padding-bottom: 45px
}

.padding-x-45 {
    padding-right: 45px;
    padding-left: 45px
}

.margin-45 {
    margin: 45px
}

.margin-t-45 {
    margin-top: 45px
}

.margin-b-45 {
    margin-bottom: 45px
}

.margin-r-45 {
    margin-right: 45px
}

.margin-l-45 {
    margin-left: 45px
}

.margin-y-45 {
    margin-top: 45px;
    margin-bottom: 45px
}

.margin-x-45 {
    margin-right: 45px;
    margin-left: 45px
}

.padding-46 {
    padding: 46px
}

.padding-t-46 {
    padding-top: 46px
}

.padding-b-46 {
    padding-bottom: 46px
}

.padding-r-46 {
    padding-right: 46px
}

.padding-l-46 {
    padding-left: 46px
}

.padding-y-46 {
    padding-top: 46px;
    padding-bottom: 46px
}

.padding-x-46 {
    padding-right: 46px;
    padding-left: 46px
}

.margin-46 {
    margin: 46px
}

.margin-t-46 {
    margin-top: 46px
}

.margin-b-46 {
    margin-bottom: 46px
}

.margin-r-46 {
    margin-right: 46px
}

.margin-l-46 {
    margin-left: 46px
}

.margin-y-46 {
    margin-top: 46px;
    margin-bottom: 46px
}

.margin-x-46 {
    margin-right: 46px;
    margin-left: 46px
}

.padding-47 {
    padding: 47px
}

.padding-t-47 {
    padding-top: 47px
}

.padding-b-47 {
    padding-bottom: 47px
}

.padding-r-47 {
    padding-right: 47px
}

.padding-l-47 {
    padding-left: 47px
}

.padding-y-47 {
    padding-top: 47px;
    padding-bottom: 47px
}

.padding-x-47 {
    padding-right: 47px;
    padding-left: 47px
}

.margin-47 {
    margin: 47px
}

.margin-t-47 {
    margin-top: 47px
}

.margin-b-47 {
    margin-bottom: 47px
}

.margin-r-47 {
    margin-right: 47px
}

.margin-l-47 {
    margin-left: 47px
}

.margin-y-47 {
    margin-top: 47px;
    margin-bottom: 47px
}

.margin-x-47 {
    margin-right: 47px;
    margin-left: 47px
}

.padding-48 {
    padding: 48px
}

.padding-t-48 {
    padding-top: 48px
}

.padding-b-48 {
    padding-bottom: 48px
}

.padding-r-48 {
    padding-right: 48px
}

.padding-l-48 {
    padding-left: 48px
}

.padding-y-48 {
    padding-top: 48px;
    padding-bottom: 48px
}

.padding-x-48 {
    padding-right: 48px;
    padding-left: 48px
}

.margin-48 {
    margin: 48px
}

.margin-t-48 {
    margin-top: 48px
}

.margin-b-48 {
    margin-bottom: 48px
}

.margin-r-48 {
    margin-right: 48px
}

.margin-l-48 {
    margin-left: 48px
}

.margin-y-48 {
    margin-top: 48px;
    margin-bottom: 48px
}

.margin-x-48 {
    margin-right: 48px;
    margin-left: 48px
}

.padding-49 {
    padding: 49px
}

.padding-t-49 {
    padding-top: 49px
}

.padding-b-49 {
    padding-bottom: 49px
}

.padding-r-49 {
    padding-right: 49px
}

.padding-l-49 {
    padding-left: 49px
}

.padding-y-49 {
    padding-top: 49px;
    padding-bottom: 49px
}

.padding-x-49 {
    padding-right: 49px;
    padding-left: 49px
}

.margin-49 {
    margin: 49px
}

.margin-t-49 {
    margin-top: 49px
}

.margin-b-49 {
    margin-bottom: 49px
}

.margin-r-49 {
    margin-right: 49px
}

.margin-l-49 {
    margin-left: 49px
}

.margin-y-49 {
    margin-top: 49px;
    margin-bottom: 49px
}

.margin-x-49 {
    margin-right: 49px;
    margin-left: 49px
}

.padding-50 {
    padding: 50px
}

.padding-t-50 {
    padding-top: 50px
}

.padding-b-50 {
    padding-bottom: 50px
}

.padding-r-50 {
    padding-right: 50px
}

.padding-l-50 {
    padding-left: 50px
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px
}

.padding-x-50 {
    padding-right: 50px;
    padding-left: 50px
}

.margin-50 {
    margin: 50px
}

.margin-t-50 {
    margin-top: 50px
}

.margin-b-50 {
    margin-bottom: 50px
}

.margin-r-50 {
    margin-right: 50px
}

.margin-l-50 {
    margin-left: 50px
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px
}

.margin-x-50 {
    margin-right: 50px;
    margin-left: 50px
}

.rounded-0 {
    border-radius: 0px !important
}

.rounded-1 {
    border-radius: 1px !important
}

.rounded-2 {
    border-radius: 2px !important
}

.rounded-3 {
    border-radius: 3px !important
}

.rounded-4 {
    border-radius: 4px !important
}

.rounded-5 {
    border-radius: 5px !important
}

.rounded-6 {
    border-radius: 6px !important
}

.rounded-7 {
    border-radius: 7px !important
}

.rounded-8 {
    border-radius: 8px !important
}

.rounded-9 {
    border-radius: 9px !important
}

.rounded-10 {
    border-radius: 10px !important
}

.rounded-11 {
    border-radius: 11px !important
}

.rounded-12 {
    border-radius: 12px !important
}

.rounded-13 {
    border-radius: 13px !important
}

.rounded-14 {
    border-radius: 14px !important
}

.rounded-15 {
    border-radius: 15px !important
}

.rounded-16 {
    border-radius: 16px !important
}

.rounded-17 {
    border-radius: 17px !important
}

.rounded-18 {
    border-radius: 18px !important
}

.rounded-19 {
    border-radius: 19px !important
}

.rounded-20 {
    border-radius: 20px !important
}

.un-my-account .head {
    padding: 20px;
    border-bottom: 1px solid var(--gray-200)
}

.un-my-account .head .my-personal-account {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.un-my-account .head .my-personal-account .user {
    display: flex;
    align-items: center
}

.un-my-account .head .my-personal-account .user img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px
}

.un-my-account .head .my-personal-account .user .txt-user h1 {
    font-size: 18px;
    font-weight: 600;
    color: var(--dark);
    margin: 0;
    margin-bottom: 2px
}

.un-my-account .head .my-personal-account .user .txt-user p {
    font-size: 13px;
    color: var(--secondary);
    font-weight: 500;
    margin: 0
}

.un-my-account .head .form-address-copy .input_group {
    position: relative
}

.un-my-account .head .form-address-copy .input_group input {
    padding-right: 50px
}

.un-my-account .head .form-address-copy .input_group .btn-copy {
    padding: 0;
    margin: 0;
    position: absolute;
    width: 35px;
    height: 35px;
    color: #395aff;
    right: 10px
}

.un-my-account .body {
    text-align: center;
    padding: 20px
}

.un-my-account .body .img-coin-eth {
    margin-bottom: 20px
}

.un-my-account .body .img-coin-eth img {
    width: 150px;
    height: 150px;
    object-fit: contain
}

.un-my-account .body .my-balance-text h2 {
    font-size: 13px;
    color: var(--secondary);
    font-weight: 500;
    margin: 0;
    margin-bottom: 5px
}

.un-my-account .body .my-balance-text p {
    font-size: 30px;
    font-weight: 600;
    color: var(--dark);
    margin: 0;
    margin-bottom: 20px
}

.un-my-account .body .my-balance-text .btn.color-primary {
    color: #395aff !important
}

.log-table-simple {
    padding: 20px
}

.log-table-simple h3 {
    font-size: 13px;
    font-weight: 500;
    color: var(--secondary);
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 15px
}

.log-table-simple tbody tr:nth-of-type(odd) td {
    background-color: var(--light)
}

.log-table-simple tbody tr td {
    box-shadow: none !important;
    border: 0;
    border-radius: 10px;
    font-size: 13px;
    color: var(--secondary) !important;
    padding: 15px 20px;
    font-weight: 500
}

.un-activity-toggle {
    padding: 0 20px 30px 20px
}

.un-activity-toggle .nav .nav-link {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    border-radius: 15px;
    border: 1px solid var(--gray-200);
    margin-bottom: 15px
}

.un-activity-toggle .nav .nav-link .text h2 {
    font-size: 15px;
    font-weight: 500;
    color: var(--dark);
    margin-bottom: 5px
}

.un-activity-toggle .nav .nav-link .text p {
    font-size: 11px;
    font-weight: 500;
    color: var(--secondary);
    margin: 0;
    width: 60vw
}

.un-myItem-list {
    padding: 20px 20px 30px 20px
}

.un-myItem-list .nav-link {
    padding: 12px 15px;
    border-radius: 15px;
    border: 1px solid var(--gray-200);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px
}

.un-myItem-list .nav-link .cover_img {
    position: relative;
    display: flex;
    align-items: center
}

.un-myItem-list .nav-link .cover_img img {
    width: 60px;
    height: 50px;
    border-radius: 15px;
    object-fit: cover;
    margin-right: 15px
}

.un-myItem-list .nav-link .cover_img .txt h2 {
    font-size: 16px;
    color: var(--dark);
    font-weight: 500;
    margin: 0;
    margin-bottom: 3px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 45vw
}

.un-myItem-list .nav-link .cover_img .txt p {
    font-size: 13px;
    color: var(--secondary);
    font-weight: 500;
    margin: 0
}

.un-myItem-list .nav-link .other-side .out-link-edit {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background-color: rgba(57, 90, 255, .1);
    color: #395aff;
    text-decoration: none
}

.un-creator-ptofile .head picture {
    width: 100%
}

.un-creator-ptofile .head .cover-image {
    position: relative
}

.un-creator-ptofile .head .cover-image img {
    width: 100%;
    height: 155px;
    object-fit: cover
}

.un-creator-ptofile .head .cover-image.overlay {
    overflow: hidden
}

.un-creator-ptofile .head .cover-image.overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(34, 32, 50, .6)
}

.un-creator-ptofile .head .text-user-creator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px
}

.un-creator-ptofile .head .text-user-creator .user-img {
    position: relative;
    margin-right: 20px;
    margin-top: -30px
}

.un-creator-ptofile .head .text-user-creator .user-img img {
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid var(--white)
}

.un-creator-ptofile .head .text-user-creator .user-img i {
    position: absolute;
    font-size: 18px;
    color: #395aff;
    bottom: 0;
    right: 5px
}

.un-creator-ptofile .head .text-user-creator .user-img .btn-upload-icon {
    position: relative;
    overflow: hidden;
    width: 86px;
    height: 86px;
    border-radius: 50%;
    background-color: rgba(34, 32, 50, .3)
}

.un-creator-ptofile .head .text-user-creator .user-img .btn-upload-icon input {
    width: 100px;
    height: 100px;
    opacity: 0
}

.un-creator-ptofile .head .text-user-creator .user-img .btn-upload-icon .icon {
    position: absolute;
    bottom: 10%;
    left: 70%
}

.un-creator-ptofile .head .text-user-creator .user-img .btn-upload-icon .icon i {
    font-size: 24px;
    color: #fff
}

.un-creator-ptofile .head .text-user-creator .text {
    margin-top: 15px
}

.un-creator-ptofile .head .text-user-creator .text h3 {
    font-size: 18px;
    font-weight: 600;
    color: var(--dark);
    margin: 0;
    margin-bottom: 2px
}

.un-creator-ptofile .head .text-user-creator .text p {
    font-size: 13px;
    font-weight: 500;
    color: var(--secondary);
    margin: 0
}

.un-creator-ptofile .body .item-followers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px
}

.un-creator-ptofile .body .item-followers .users-text p {
    font-size: 14px;
    font-weight: 500;
    color: var(--secondary);
    margin-bottom: 10px
}

.un-creator-ptofile .body .item-followers .users-text .img-user picture:first-of-type img {
    margin-left: 0
}

.un-creator-ptofile .body .item-followers .users-text .img-user img {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid var(--white);
    display: inline-block;
    margin-left: -18px
}

.un-creator-ptofile .body .item-followers .users-text .img-user .link {
    text-decoration: none;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-color: var(--dark);
    border: 3px solid var(--white);
    font-size: 11px;
    font-weight: 500;
    color: var(--white);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: -18px
}

.un-creator-ptofile .body .item-followers .btn-edit-profile {
    background-color: var(--gray-300);
    color: #fff !important
}

.un-creator-ptofile .body .statistics {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    border: 1px solid var(--gray-200);
    padding: 15px 20px;
    margin: 0 20px
}

.un-creator-ptofile .body .statistics .text-grid h4 {
    font-size: 16px;
    color: var(--dark);
    font-weight: 600;
    margin-bottom: 3px
}

.un-creator-ptofile .body .statistics .text-grid p {
    font-size: 15px;
    color: var(--secondary);
    font-weight: 500;
    margin: 0
}

.un-creator-ptofile .body .description {
    padding: 20px
}

.un-creator-ptofile .body .description p {
    font-size: 14px;
    color: var(--secondary);
    margin: 0
}

.un-creator-ptofile .body .description p .read-more {
    color: var(--dark);
    font-size: 14px;
    font-weight: 500;
    text-decoration: none
}

.un-creator-ptofile .tab-creatore-profile {
    padding: 0 20px
}

.un-creator-ptofile .tab-creatore-profile .nav-pilled-tab {
    margin-bottom: 20px
}

.un-creator-ptofile .tab-creatore-profile .nav-pilled-tab .nav-item .nav-link {
    max-width: inherit;
    width: calc(25vw - 12px)
}

.un-creator-ptofile .tab-creatore-profile .item-card-gradual {
    margin-bottom: 15px
}

.empty-items {
    text-align: center;
    padding: 20px
}

.empty-items img {
    width: 120px;
    height: 120px;
    object-fit: contain;
    margin-bottom: 10px
}

.empty-items h4 {
    font-size: 15px;
    color: var(--dark);
    font-weight: 500;
    margin-bottom: 5px
}

.empty-items p {
    font-size: 13px;
    color: var(--secondary)
}

.empty-items .empty-dark {
    display: none
}

.btn-upload-text {
    position: relative;
    overflow: hidden
}

.btn-upload-text input {
    position: absolute;
    font-size: 32px;
    opacity: 0
}

.btn-upload-text span {
    font-size: 14px;
    color: var(--dark);
    font-weight: 500;
    margin: 0;
    padding: 10px 20px;
    display: inline-block;
    border-radius: 50px;
    background-color: var(--white)
}

.form-edit-profile .bk-social-networks h3 {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    color: var(--secondary);
    margin: 0
}

.form-group.with_icon .icon::before {
    content: "";
    width: 2px;
    height: 22px;
    background-color: var(--gray-200);
    display: block;
    position: absolute;
    right: -8px;
    top: 5px
}

.with_icon input {
    padding-left: 60px !important
}

.footer-pages-forms {
    padding: 15px 20px 20px 20px;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, .8);
    -webkit-backdrop-filter: saturate(1) blur(20px);
    -o-backdrop-filter: saturate(1) blur(20px);
    -ms-backdrop-filter: saturate(1) blur(20px);
    backdrop-filter: saturate(1) blur(20px)
}

.footer-pages-forms .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: env(safe-area-inset-bottom)
}

.footer-pages-forms .content .links-clear-data .link-clear {
    color: var(--secondary);
    text-decoration: none;
    display: inline-flex;
    align-items: center
}

.footer-pages-forms .content .links-clear-data .link-clear i {
    font-size: 20px;
    margin-right: 5px
}

.footer-pages-forms .content .links-clear-data .link-clear span {
    font-weight: 500;
    font-size: 15px
}

.footer-pages-forms .content .update-auto {
    position: relative
}

.footer-pages-forms .content .update-auto .auto-saving {
    color: var(--secondary);
    text-decoration: none;
    display: inline-flex;
    align-items: center
}

.footer-pages-forms .content .update-auto .auto-saving .loader-items {
    position: absolute;
    left: -30px;
    top: -10px
}

.footer-pages-forms .content .update-auto .auto-saving .loader-items div::after {
    top: 30px;
    left: 39px;
    width: 1.5px
}

.footer-pages-forms .content .update-auto .auto-saving span {
    font-weight: 500;
    font-size: 15px;
    margin-left: 30px
}

.space-sticky-footer {
    height: 90px
}

.un-create-collectibles {
    padding: 20px
}

.un-create-collectibles .upload-form h2 {
    font-size: 15px;
    font-weight: 600;
    color: var(--dark);
    margin: 0;
    margin-bottom: 5px
}

.un-create-collectibles .upload-form p {
    font-size: 13px;
    color: var(--secondary);
    margin: 0;
    margin-bottom: 15px
}

.un-create-collectibles .upload-form .uploaded-image {
    border-radius: 15px;
    border: 1px solid var(--gray-200);
    padding: 15px;
    position: relative
}

.un-create-collectibles .upload-form .uploaded-image img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover
}

.un-create-collectibles .upload-form .uploaded-image .icon-clear {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--white);
    font-size: 20px;
    position: absolute;
    top: 30px;
    right: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center
}

.upload-input-form {
    position: relative;
    overflow: hidden
}

.upload-input-form input {
    font-size: 70px;
    position: absolute;
    opacity: 0
}

.upload-input-form .content-input {
    border-radius: 15px;
    border: 1.5px dashed var(--gray-200);
    text-align: center;
    padding: 15px 20px
}

.upload-input-form .content-input .icon {
    font-size: 22px;
    color: var(--secondary);
    margin-bottom: 5px
}

.upload-input-form .content-input span {
    font-size: 11px;
    color: var(--secondary);
    font-weight: 500;
    display: block
}

.un-put-on-marketplace {
    padding: 20px
}

.un-put-on-marketplace .head {
    border-bottom: 1px solid var(--gray-200);
    padding-bottom: 30px;
    margin-bottom: 30px
}

.un-put-on-marketplace .head .item-NFTs {
    display: flex;
    align-items: center
}

.un-put-on-marketplace .head .item-NFTs img {
    width: 55px;
    height: 55px;
    border-radius: 15px;
    object-fit: cover;
    margin-right: 15px
}

.un-put-on-marketplace .head .item-NFTs .text h2 {
    font-size: 16px;
    color: var(--dark);
    font-weight: 500;
    margin: 0;
    margin-bottom: 5px
}

.un-put-on-marketplace .head .item-NFTs .text p {
    font-size: 13px;
    font-weight: 500;
    color: var(--secondary);
    margin: 0
}

.un-put-on-marketplace .body .title h2 {
    font-size: 15px;
    color: var(--dark);
    font-weight: 600;
    margin: 0;
    margin-bottom: 5px
}

.un-put-on-marketplace .body .title p {
    font-size: 13px;
    font-weight: 400;
    color: var(--secondary);
    margin: 0
}

.un-put-on-marketplace .body .nav-tab {
    display: flex;
    flex-direction: row;
    column-gap: 15px
}

.un-put-on-marketplace .body .nav-tab .nav-item {
    width: calc(33.3333333333% - 10px)
}

.un-put-on-marketplace .body .nav-tab .nav-item .nav-link {
    background-color: var(--white);
    border-radius: 10px;
    padding: 20px;
    border: 1.5px solid var(--gray-200);
    color: var(--dark);
    -webkit-transition: .35s;
    -moz-transition: .35s;
    -o-transition: .35s;
    -ms-transition: .35s;
    transition: .35s
}

.un-put-on-marketplace .body .nav-tab .nav-item .nav-link .icon {
    font-size: 24px;
    color: var(--dark);
    margin-bottom: 12px
}

.un-put-on-marketplace .body .nav-tab .nav-item .nav-link span {
    font-weight: 600;
    font-size: 14px
}

.un-put-on-marketplace .body .nav-tab .nav-item .nav-link.active {
    border-color: #395aff
}

.form-with-select .input_group {
    position: relative
}

.form-with-select .input_group .content {
    position: absolute;
    right: 0
}

.form-with-select .input_group .content::before {
    content: "";
    width: 1px;
    height: 22px;
    background-color: var(--gray-200);
    position: absolute;
    display: block;
    left: -10px;
    top: 20%
}

.form-with-select .input_group .content .form-select {
    border: 0;
    width: 100px;
    font-weight: 600;
    color: var(--dark);
    background-color: transparent;
    height: 50px;
    outline: none !important;
    box-shadow: none !important
}

.un-bottom-navigation {
    padding-top: 56px
}

@supports(-webkit-touch-callout: none) {
    .un-bottom-navigation {
        padding-top: 90px
    }
}

.un-bottom-navigation .em_body_navigation {
    background-color: var(--white);
    min-height: 56px;
    position: fixed;
    width: 100%;
    bottom: -1px;
    left: 0;
    right: 0;
    z-index: 99;
    border-top: 1px solid var(--gray-200);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: 20px;
    padding-right: 20px
}

@media(min-width: 576px) {
    .un-bottom-navigation .em_body_navigation {
        min-height: 60px
    }
}

.un-bottom-navigation .em_body_navigation .item_link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 56px
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink {
    width: 45px;
    height: 45px;
    display: inline-grid;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 0;
    justify-items: center
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink::after {
    content: "";
    background-color: var(--dark);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    bottom: 3px;
    opacity: 0;
    left: 45%
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .icon_active {
    display: none
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .icon_active path {
    fill: var(--dark)
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink svg {
    width: 24px;
    height: 24px
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink svg path {
    stroke: var(--dark)
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink i {
    font-size: 24px;
    color: var(--dark)
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .img_profile {
    width: 30px;
    height: 30px;
    border: 1px solid var(--white);
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .img_profile img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .items_basket {
    min-width: 51px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: #ff3838;
    color: var(--white);
    font-size: 11px;
    border-radius: 4px;
    top: -16px;
    z-index: 10;
    bottom: env(safe-area-inset-bottom);
    -webkit-box-shadow: 0px 7px 20px 0px rgba(255, 56, 56, .2);
    -moz-box-shadow: 0px 7px 20px 0px rgba(255, 56, 56, .2);
    -o-box-shadow: 0px 7px 20px 0px rgba(255, 56, 56, .2);
    -ms-box-shadow: 0px 7px 20px 0px rgba(255, 56, 56, .2);
    box-shadow: 0px 7px 20px 0px rgba(255, 56, 56, .2)
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .items_basket::before {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='72' height='67' viewBox='0 0 72 67'%3E%3Cdefs%3E%3Cfilter id='Polygon_2' x='0' y='0' width='72' height='67' filterUnits='userSpaceOnUse'%3E%3CfeOffset dy='7' input='SourceAlpha'/%3E%3CfeGaussianBlur stdDeviation='10' result='blur'/%3E%3CfeFlood flood-color='%23556fff' flood-opacity='0.2'/%3E%3CfeComposite operator='in' in2='blur'/%3E%3CfeComposite in='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cg transform='matrix(1, 0, 0, 1, 0, 0)' filter='url(%23Polygon_2)'%3E%3Cpath id='Polygon_2-2' data-name='Polygon 2' d='M5.241.886a1,1,0,0,1,1.519,0l3.826,4.463A1,1,0,0,1,9.826,7H2.174a1,1,0,0,1-.759-1.651Z' transform='translate(42 30) rotate(180)' fill='%23ff4040'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-size: 50px;
    width: 50px;
    height: 26px;
    position: absolute;
    bottom: -9px
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .items_basket_circle {
    position: absolute;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #ff3838;
    font-size: 11px;
    border: 2px solid var(--white);
    color: var(--white);
    top: 7px;
    margin-left: -5px
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle {
    position: relative;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #395aff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle::before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    border: 1px solid rgba(57, 90, 255, .3);
    border-radius: 50%
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle::after {
    content: "";
    position: absolute;
    width: 52px;
    height: 52px;
    border: 1px solid rgba(57, 90, 255, .1);
    border-radius: 50%
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle i {
    font-size: 17px;
    color: var(--white)
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle svg {
    width: 17px;
    height: 17px
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle svg path {
    stroke: var(--white)
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle.bg-white {
    background-color: var(--white)
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle.bg-white::before {
    border: 1px solid rgba(255, 56, 56, .3)
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle.bg-white::after {
    border: 1px solid rgba(255, 56, 56, .1)
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle.bg-white i {
    font-size: 17px;
    color: var(--dark)
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle.bg-white svg {
    width: 17px;
    height: 17px
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle.bg-white svg path {
    stroke: var(--dark)
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle_default {
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #3C9541;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle_default i {
    font-size: 21px;
    color: #fff
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle_default svg {
    width: 21px;
    height: 21px
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle_default svg path {
    stroke: #fff
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle_default._lg {
    width: 60px;
    height: 60px;
    margin-top: -22px
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle_default._lg i {
    font-size: 26px;
    color: #fff
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle_default._lg svg {
    width: 26px;
    height: 26px
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle_default._lg svg path {
    stroke: #fff
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle_default.bg-white {
    background-color: var(--white)
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle_default.bg-white::before {
    border: 1px solid rgba(255, 56, 56, .3)
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle_default.bg-white::after {
    border: 1px solid rgba(255, 56, 56, .1)
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle_default.bg-white i {
    font-size: 17px;
    color: var(--dark)
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle_default.bg-white svg {
    width: 17px;
    height: 17px
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink .btnCircle_default.bg-white svg path {
    stroke: var(--dark)
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink.btn_profile.active::after {
    opacity: 0
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink.active::after {
    opacity: 1
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink.active .icon_current {
    display: none
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink.active .icon_active {
    display: block
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink.active .img_profile {
    border: 1px solid var(--gray-200)
}

.un-bottom-navigation .em_body_navigation .item_link .btn_navLink.active .img_profile img {
    width: 20px;
    height: 20px
}

.un-bottom-navigation.with__text .em_body_navigation {
    min-height: 60px
}

.un-bottom-navigation.with__text .em_body_navigation .item_link {
    height: 60px
}

.un-bottom-navigation.with__text .em_body_navigation .item_link .btn_navLink {
    display: grid
}

.un-bottom-navigation.with__text .em_body_navigation .item_link .btn_navLink .items_basket_circle {
    top: 10px;
    margin-left: 0
}

.un-bottom-navigation.with__text .em_body_navigation .item_link .btn_navLink .txt__tile {
    font-size: 11px;
    font-weight: 400;
    display: block;
    padding-top: 3px;
    color: var(--dark)
}

.un-bottom-navigation.with__text .em_body_navigation .item_link .btn_navLink::after {
    margin-left: 12px;
    top: -1px
}

.un-bottom-navigation.with__text .em_body_navigation.-active_primary .item_link .btn_navLink.active .txt__tile {
    color: #395aff
}

.un-bottom-navigation.with__text .em_body_navigation.-active_primary .item_link .btn_navLink.active svg path {
    fill: #395aff;
    stroke: #395aff
}

.un-bottom-navigation.with__text .em_body_navigation.-active_primary .item_link .btn_navLink::after {
    background-color: #395aff
}

.un-bottom-navigation.rounded .em_body_navigation {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px
}

.un-bottom-navigation.just_color .em_body_navigation .item_link .btn_navLink::after {
    display: none
}

.un-bottom-navigation .em_body_navigation.bg-blue {
    background-color: #395aff !important
}

.un-bottom-navigation .em_body_navigation.bg-green {
    background-color: #3eca76 !important
}

.un-bottom-navigation .em_body_navigation.bg-yellow {
    background-color: #ffc21d !important
}

.un-bottom-navigation .em_body_navigation.bg-orange {
    background-color: #ff7e1b !important
}

.un-bottom-navigation .em_body_navigation.bg-black {
    background-color: var(--bg-black) !important
}

.un-bottom-navigation .em_body_navigation.bg-primary,
.un-bottom-navigation .em_body_navigation.bg-secondary,
.un-bottom-navigation .em_body_navigation.bg-blue,
.un-bottom-navigation .em_body_navigation.bg-orange,
.un-bottom-navigation .em_body_navigation.bg-yellow,
.un-bottom-navigation .em_body_navigation.bg-green,
.un-bottom-navigation .em_body_navigation.bg-black {
    border-top: 0
}

.un-bottom-navigation .em_body_navigation.bg-primary .item_link .btn_navLink .items_basket_circle,
.un-bottom-navigation .em_body_navigation.bg-secondary .item_link .btn_navLink .items_basket_circle,
.un-bottom-navigation .em_body_navigation.bg-blue .item_link .btn_navLink .items_basket_circle,
.un-bottom-navigation .em_body_navigation.bg-orange .item_link .btn_navLink .items_basket_circle,
.un-bottom-navigation .em_body_navigation.bg-yellow .item_link .btn_navLink .items_basket_circle,
.un-bottom-navigation .em_body_navigation.bg-green .item_link .btn_navLink .items_basket_circle,
.un-bottom-navigation .em_body_navigation.bg-black .item_link .btn_navLink .items_basket_circle {
    background-color: var(--white);
    color: var(--dark)
}

.un-bottom-navigation .em_body_navigation.bg-primary .item_link .btn_navLink .txt__tile,
.un-bottom-navigation .em_body_navigation.bg-secondary .item_link .btn_navLink .txt__tile,
.un-bottom-navigation .em_body_navigation.bg-blue .item_link .btn_navLink .txt__tile,
.un-bottom-navigation .em_body_navigation.bg-orange .item_link .btn_navLink .txt__tile,
.un-bottom-navigation .em_body_navigation.bg-yellow .item_link .btn_navLink .txt__tile,
.un-bottom-navigation .em_body_navigation.bg-green .item_link .btn_navLink .txt__tile,
.un-bottom-navigation .em_body_navigation.bg-black .item_link .btn_navLink .txt__tile {
    color: var(--white)
}

.un-bottom-navigation .em_body_navigation.bg-primary .item_link .btn_navLink .icon_current svg path,
.un-bottom-navigation .em_body_navigation.bg-primary .item_link .btn_navLink .icon_current svg circle,
.un-bottom-navigation .em_body_navigation.bg-secondary .item_link .btn_navLink .icon_current svg path,
.un-bottom-navigation .em_body_navigation.bg-secondary .item_link .btn_navLink .icon_current svg circle,
.un-bottom-navigation .em_body_navigation.bg-blue .item_link .btn_navLink .icon_current svg path,
.un-bottom-navigation .em_body_navigation.bg-blue .item_link .btn_navLink .icon_current svg circle,
.un-bottom-navigation .em_body_navigation.bg-orange .item_link .btn_navLink .icon_current svg path,
.un-bottom-navigation .em_body_navigation.bg-orange .item_link .btn_navLink .icon_current svg circle,
.un-bottom-navigation .em_body_navigation.bg-yellow .item_link .btn_navLink .icon_current svg path,
.un-bottom-navigation .em_body_navigation.bg-yellow .item_link .btn_navLink .icon_current svg circle,
.un-bottom-navigation .em_body_navigation.bg-green .item_link .btn_navLink .icon_current svg path,
.un-bottom-navigation .em_body_navigation.bg-green .item_link .btn_navLink .icon_current svg circle,
.un-bottom-navigation .em_body_navigation.bg-black .item_link .btn_navLink .icon_current svg path,
.un-bottom-navigation .em_body_navigation.bg-black .item_link .btn_navLink .icon_current svg circle {
    stroke: var(--white)
}

.un-bottom-navigation .em_body_navigation.bg-primary .item_link .btn_navLink .icon_active svg path,
.un-bottom-navigation .em_body_navigation.bg-primary .item_link .btn_navLink .icon_active svg circle,
.un-bottom-navigation .em_body_navigation.bg-secondary .item_link .btn_navLink .icon_active svg path,
.un-bottom-navigation .em_body_navigation.bg-secondary .item_link .btn_navLink .icon_active svg circle,
.un-bottom-navigation .em_body_navigation.bg-blue .item_link .btn_navLink .icon_active svg path,
.un-bottom-navigation .em_body_navigation.bg-blue .item_link .btn_navLink .icon_active svg circle,
.un-bottom-navigation .em_body_navigation.bg-orange .item_link .btn_navLink .icon_active svg path,
.un-bottom-navigation .em_body_navigation.bg-orange .item_link .btn_navLink .icon_active svg circle,
.un-bottom-navigation .em_body_navigation.bg-yellow .item_link .btn_navLink .icon_active svg path,
.un-bottom-navigation .em_body_navigation.bg-yellow .item_link .btn_navLink .icon_active svg circle,
.un-bottom-navigation .em_body_navigation.bg-green .item_link .btn_navLink .icon_active svg path,
.un-bottom-navigation .em_body_navigation.bg-green .item_link .btn_navLink .icon_active svg circle,
.un-bottom-navigation .em_body_navigation.bg-black .item_link .btn_navLink .icon_active svg path,
.un-bottom-navigation .em_body_navigation.bg-black .item_link .btn_navLink .icon_active svg circle {
    fill: var(--white);
    stroke: var(--white)
}

.un-bottom-navigation .em_body_navigation.bg-primary .item_link .btn_navLink i,
.un-bottom-navigation .em_body_navigation.bg-secondary .item_link .btn_navLink i,
.un-bottom-navigation .em_body_navigation.bg-blue .item_link .btn_navLink i,
.un-bottom-navigation .em_body_navigation.bg-orange .item_link .btn_navLink i,
.un-bottom-navigation .em_body_navigation.bg-yellow .item_link .btn_navLink i,
.un-bottom-navigation .em_body_navigation.bg-green .item_link .btn_navLink i,
.un-bottom-navigation .em_body_navigation.bg-black .item_link .btn_navLink i {
    color: var(--white)
}

.un-bottom-navigation .em_body_navigation.bg-primary .item_link .btn_navLink::after,
.un-bottom-navigation .em_body_navigation.bg-secondary .item_link .btn_navLink::after,
.un-bottom-navigation .em_body_navigation.bg-blue .item_link .btn_navLink::after,
.un-bottom-navigation .em_body_navigation.bg-orange .item_link .btn_navLink::after,
.un-bottom-navigation .em_body_navigation.bg-yellow .item_link .btn_navLink::after,
.un-bottom-navigation .em_body_navigation.bg-green .item_link .btn_navLink::after,
.un-bottom-navigation .em_body_navigation.bg-black .item_link .btn_navLink::after {
    background-color: var(--white)
}

.un-bottom-navigation.ouline_footer .em_body_navigation .item_link .btn_navLink .txt__tile {
    color: var(--secondary)
}

.un-bottom-navigation.ouline_footer .em_body_navigation .item_link .btn_navLink svg path {
    stroke: var(--secondary)
}

.un-bottom-navigation.ouline_footer .em_body_navigation .item_link .btn_navLink .icon_current {
    display: block !important
}

.un-bottom-navigation.ouline_footer .em_body_navigation .item_link .btn_navLink.active::after {
    opacity: 1
}

.un-bottom-navigation.ouline_footer .em_body_navigation .item_link .btn_navLink.active .txt__tile {
    color: var(--dark)
}

.un-bottom-navigation.ouline_footer .em_body_navigation .item_link .btn_navLink.active svg path {
    stroke: var(--dark)
}

.un-bottom-navigation.ouline_footer .em_body_navigation .item_link .btn_navLink.without_active svg path {
    stroke: var(--white)
}

.un-bottom-navigation.ouline_footer .em_body_navigation .item_link .btn_navLink.without_active::after {
    display: none
}

.un-bottom-navigation.filter-blur .em_body_navigation {
    background: rgba(255, 255, 255, .8);
    -webkit-backdrop-filter: saturate(1) blur(20px);
    -o-backdrop-filter: saturate(1) blur(20px);
    -ms-backdrop-filter: saturate(1) blur(20px);
    backdrop-filter: saturate(1) blur(20px)
}

.item-card-nft {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left
}

.item-card-nft .body-card {
    padding: 0 20px;
    position: absolute
}

.item-card-nft .big-image {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.item-card-nft .counterdown {
    position: absolute;
    top: 15px;
    left: 15px;
    padding: 8px 12px;
    border-radius: 50px;
    background-color: var(--white);
    font-size: 13px;
    font-weight: 500;
    color: var(--dark)
}

.item-card-nft .un-info-card {
    background-color: var(--white);
    border-radius: 15px;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 15px;
    width: calc(100% - 30px)
}

.item-card-nft .un-info-card .block-left h4 {
    font-size: 18px;
    font-weight: 500;
    color: var(--dark);
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 33vw
}

.item-card-nft .un-info-card .block-left .user {
    display: flex;
    align-items: center
}

.item-card-nft .un-info-card .block-left .user h5 {
    font-size: 13px;
    color: var(--secondary);
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 22vw
}

.item-card-nft .un-info-card .block-left .user .img-avatar {
    width: 25px;
    height: 25px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px
}

.item-card-nft .un-info-card .block-right {
    text-align: right
}

.item-card-nft .un-info-card .block-right h6 {
    font-size: 13px;
    color: var(--secondary);
    font-weight: 500;
    display: block;
    margin-bottom: 3px
}

.item-card-nft .un-info-card .block-right p {
    font-size: 15px;
    color: #3eca76;
    font-weight: 500;
    margin: 0
}

.item-card-nft .un-info-card .block-right p span {
    font-size: 13px;
    margin-right: 3px
}

.un-block-cards .swiper {
    padding: 0 25px
}

.un-block-cards .item-card-nft {
    width: 100%;
    height: 60vh
}

.un-block-cards .item-card-nft .big-image {
    border-radius: 15px
}

.item-card-gradual {
    border-radius: 15px;
    border: 1px solid var(--gray-200);
    background-color: var(--white)
}

.item-card-gradual .body-card {
    padding: 20px 20px 15px 20px;
    display: block;
    text-decoration: none !important
}

.item-card-gradual .body-card .cover-nft {
    position: relative
}

.item-card-gradual .body-card .cover-nft .img-cover {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover
}

.item-card-gradual .body-card .cover-nft .icon-type {
    position: absolute;
    width: 35px;
    height: 35px;
    border-radius: 10px;
    background-color: rgba(34, 32, 50, .3);
    font-size: 18px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    inset: 0;
    margin: 15px
}

.item-card-gradual .body-card .cover-nft .countdown-time {
    background-color: var(--white);
    border-radius: 50px;
    font-size: 13px;
    font-weight: 500;
    padding: 8px 12px;
    bottom: 15px;
    left: 15px;
    position: absolute;
    display: inline-block;
    color: var(--dark)
}

.item-card-gradual .body-card .title-card-nft {
    display: flex;
    justify-content: space-between;
    margin: 15px 0
}

.item-card-gradual .body-card .title-card-nft .side-one h2 {
    font-size: 16px;
    font-weight: 500;
    color: var(--dark);
    margin-bottom: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 40vw
}

.item-card-gradual .body-card .title-card-nft .side-one p {
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    color: var(--secondary)
}

.item-card-gradual .body-card .title-card-nft .side-other .no-sales {
    font-size: 13px;
    display: block;
    font-weight: 500;
    color: #3eca76
}

.item-card-gradual .creator-name {
    display: inline-flex;
    align-items: center
}

.item-card-gradual .creator-name .image-user {
    position: relative;
    margin-right: 10px
}

.item-card-gradual .creator-name .image-user .img-avatar {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%
}

.item-card-gradual .creator-name .image-user .icon {
    position: absolute;
    font-size: 16px;
    color: #395aff;
    top: 15px;
    right: -4px
}

.item-card-gradual .creator-name h3 {
    font-size: 13px;
    color: var(--dark);
    font-weight: 500;
    margin: 0
}

.item-card-gradual .head-card {
    padding: 15px 20px
}

.item-card-gradual .footer-card {
    border-top: 1px solid var(--gray-200);
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-content: center
}

.item-card-gradual .footer-card .starting-bad h4 {
    font-size: 16px;
    font-weight: 600;
    color: var(--dark);
    margin-bottom: 3px
}

.item-card-gradual .footer-card .starting-bad span {
    font-size: 13px;
    color: var(--secondary);
    font-weight: 400;
    display: block
}

.btn-like-click {
    display: inline-flex;
    align-items: center;
    align-items: center
}

.btn-like-click .count-likes {
    font-size: 12px;
    font-weight: 500;
    color: var(--secondary);
    margin-right: 5px;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    -ms-transition: .3s;
    transition: .3s
}

.btn-like-click .btnLike {
    position: relative;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center
}

.btn-like-click .btnLike input {
    position: absolute;
    font-size: 30px;
    opacity: 0;
    width: 70px;
    height: 30px
}

.btn-like-click .btnLike input:checked~i::before {
    content: "";
    color: #ff3838;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    -ms-transition: .3s;
    transition: .3s
}

.btn-like-click .btnLike input:checked~.count-likes {
    color: #ff3838
}

.btn-like-click .btnLike i {
    font-size: 20px;
    color: var(--secondary)
}

.btn-like-click .btnLike i::before {
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    -ms-transition: .3s;
    transition: .3s
}

.btn-like-click.shape-box input {
    width: 90px;
    height: 50px
}

.btn-like-click.shape-box input:checked~.icon-inside {
    background-color: rgba(255, 56, 56, .1);
    border-color: transparent
}

.btn-like-click.shape-box input:checked~.icon-inside i::before {
    content: "";
    color: #ff3838;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    -ms-transition: .3s;
    transition: .3s
}

.btn-like-click.shape-box input:checked~.count-likes {
    color: #ff3838
}

.btn-like-click.shape-box .count-likes {
    margin-right: 10px
}

.btn-like-click.shape-box .icon-inside {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--gray-200);
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    -ms-transition: .3s;
    transition: .3s
}

.btn-like-click.shape-box .icon-inside i {
    font-size: 19px;
    line-height: 0
}

.unSwiper-cards .content-cards-NFTs .swiper-slide {
    width: 78%;
    background: transparent
}

@media(min-width: 768px) {
    .unSwiper-cards .content-cards-NFTs .swiper-slide {
        width: 30% !important
    }
}

@media(min-width: 992px) {
    .unSwiper-cards .content-cards-NFTs .swiper-slide {
        width: 22% !important
    }
}

@media(max-width: 575.98px) {
    .unSwiper-cards .content-cards-NFTs .swiper-slide {
        width: 78% !important
    }
}

.unSwiper-cards .content-cards-NFTs .swiper-slide .item-card-gradual {
    width: 100%;
    margin-left: 20px;
    text-align: left
}

.unSwiper-cards .content-cards-NFTs .swiper-slide:last-child {
    margin-right: 20px
}

.discover-nft-random .content-NFTs-body {
    padding: 0 20px
}

.discover-nft-random .content-NFTs-body .item-card-nft {
    width: 100%;
    margin-bottom: 10px
}

.discover-nft-random .content-NFTs-body .item-card-nft .big-image {
    border-radius: 15px;
    height: 355px
}

.discover-nft-random .content-NFTs-body .item-card-nft .un-info-card {
    text-decoration: none !important
}

.discover-nft-random .content-NFTs-body .item-card-nft .btn-like-click {
    background-color: var(--white);
    position: absolute;
    padding: 4px 12px;
    border-radius: 50px;
    top: 15px;
    right: 15px
}

.discover-nft-random .content-NFTs-body .grid-item {
    display: flex;
    flex-direction: row;
    column-gap: 10px
}

.discover-nft-random .content-NFTs-body .grid-item .item-sm-card-NFTs {
    width: 50%
}

.item-sm-card-NFTs {
    display: block;
    position: relative;
    text-decoration: none !important;
    margin-bottom: 10px
}

.item-sm-card-NFTs .cover-image {
    position: relative;
    overflow: hidden;
    border-radius: 15px
}

.item-sm-card-NFTs .cover-image .big-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 15px
}

.item-sm-card-NFTs .cover-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    top: inherit;
    bottom: 0;
    height: 50%;
    background-image: linear-gradient(to top, #222032, rgba(34, 32, 50, 0))
}

.item-sm-card-NFTs .cover-image .content-text .icon-type {
    position: absolute;
    width: 35px;
    height: 35px;
    border-radius: 10px;
    background-color: rgba(34, 32, 50, .3);
    font-size: 18px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    inset: 0;
    margin: 15px
}

.item-sm-card-NFTs .cover-image .content-text .btn-like-click {
    background-color: var(--white);
    position: absolute;
    padding: 4px 12px;
    border-radius: 50px;
    top: 15px;
    right: 15px
}

.item-sm-card-NFTs .cover-image .user-text {
    position: absolute;
    bottom: 15px;
    left: 15px
}

.item-sm-card-NFTs .cover-image .user-text .user-avatar {
    margin-bottom: 5px
}

.item-sm-card-NFTs .cover-image .user-text .user-avatar .sm-user {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px
}

.item-sm-card-NFTs .cover-image .user-text .user-avatar span {
    font-size: 13px;
    font-weight: 500;
    color: #fff
}

.item-sm-card-NFTs .cover-image .user-text .number-eth span {
    color: #fff;
    font-size: 11px;
    font-weight: 500
}

.item-sm-card-NFTs .cover-image .user-text .number-eth span.main-price {
    font-size: 14px;
    margin-right: 3px
}

.discover-NFTs-gradual {
    padding: 0 20px
}

.discover-NFTs-gradual .item-card-gradual {
    margin-bottom: 15px
}

.collection-creatores .content-list-creatores {
    padding: 0 !important
}

.collection-creatores .content-list-creatores .nav-item:first-of-type .nav-link {
    padding-top: 0 !important
}

.collection-creatores .content-list-creatores .nav-item .nav-link {
    padding: 20px !important;
    display: block !important;
    text-decoration: none !important
}

.collection-creatores .content-list-creatores .nav-item .nav-link .collection-img {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
    margin-bottom: 18px
}

.collection-creatores .content-list-creatores .nav-item .nav-link .collection-img picture {
    width: 100%;
    max-width: 20%
}

.collection-creatores .content-list-creatores .nav-item .nav-link .collection-img .img-cot {
    width: 100%;
    height: 70px;
    border-radius: 10px;
    object-fit: cover
}

.collection-creatores .content-list-creatores .nav-item .nav-link .item-user-img {
    text-decoration: none !important
}

.collection-creatores .content-list-creatores .nav-item .nav-link .item-user-img .wrapper-image {
    position: relative
}

.collection-creatores .content-list-creatores .nav-item .nav-link .item-user-img .wrapper-image .icon {
    position: absolute;
    bottom: -10px;
    right: 9px;
    font-size: 17px
}

.un-details-collectibles .head .cover-main-img {
    position: relative;
    margin: 20px 20px 0 20px
}

.un-details-collectibles .head .cover-main-img .img-full {
    width: 100%;
    border-radius: 15px;
    object-fit: cover;
    height: 400px
}

.un-details-collectibles .head .cover-main-img .action-sticky {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    column-gap: 15px;
    flex-direction: row
}

.un-details-collectibles .head .cover-main-img .action-sticky .btn-share,
.un-details-collectibles .head .cover-main-img .action-sticky .btn-fullScreen {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    background-color: var(--white);
    color: var(--dark)
}

.un-details-collectibles .head .cover-main-img .item-category {
    position: absolute;
    left: 15px;
    top: 15px
}

.un-details-collectibles .head .title-card-text {
    border-bottom: 1px solid var(--gray-200);
    padding: 20px
}

.un-details-collectibles .head .title-card-text .text h1 {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark);
    margin: 0;
    margin-bottom: 5px
}

.un-details-collectibles .head .title-card-text .text p {
    font-size: 13px;
    font-weight: 500;
    color: var(--secondary);
    margin: 0
}

.un-details-collectibles .head .txt-price-coundown {
    border-bottom: 1px solid var(--gray-200);
    padding: 20px
}

.un-details-collectibles .head .txt-price-coundown .price h2 {
    font-size: 13px;
    color: var(--secondary);
    margin: 0;
    margin-bottom: 5px
}

.un-details-collectibles .head .txt-price-coundown .price p {
    font-size: 24px;
    font-weight: 600;
    color: #3eca76;
    margin: 0
}

.un-details-collectibles .head .txt-price-coundown .price p .dollar {
    font-size: 13px;
    font-weight: 500
}

.un-details-collectibles .head .txt-price-coundown .coundown h3 {
    font-size: 13px;
    color: var(--secondary);
    margin-bottom: 5px
}

.un-details-collectibles .head .txt-price-coundown .coundown span {
    font-size: 24px;
    font-weight: 600;
    color: var(--dark)
}

.un-details-collectibles .body {
    padding: 20px
}

.un-details-collectibles .body .description p {
    font-size: 15px;
    color: var(--secondary)
}

.un-details-collectibles .body .nav-users-profile .nav-item .nav-link {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px
}

.un-details-collectibles .body .nav-users-profile .nav-item .nav-link .item-user-img {
    display: flex;
    align-items: center;
    text-decoration: none !important;
    position: relative
}

.un-details-collectibles .body .nav-users-profile .nav-item .nav-link .item-user-img .wrapper-image {
    position: relative
}

.un-details-collectibles .body .nav-users-profile .nav-item .nav-link .item-user-img .wrapper-image .avt-img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 15px
}

.un-details-collectibles .body .nav-users-profile .nav-item .nav-link .item-user-img .wrapper-image .icon {
    position: absolute;
    bottom: -10px;
    right: 9px;
    font-size: 17px
}

.un-details-collectibles .body .nav-users-profile .nav-item .nav-link .item-user-img .txt-user h5 {
    font-size: 13px;
    color: var(--secondary);
    margin-bottom: 2px;
    font-weight: 400
}

.un-details-collectibles .body .nav-users-profile .nav-item .nav-link .item-user-img .txt-user p {
    font-size: 15px;
    font-weight: 500;
    color: var(--dark);
    margin: 0
}

.un-details-collectibles .body .content-custome-data {
    min-height: 200px
}

.un-details-collectibles .space-sticky-footer {
    height: 90px
}

.un-details-collectibles .footer {
    padding: 15px 20px 20px 20px;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, .8);
    -webkit-backdrop-filter: saturate(1) blur(20px);
    -o-backdrop-filter: saturate(1) blur(20px);
    -ms-backdrop-filter: saturate(1) blur(20px);
    backdrop-filter: saturate(1) blur(20px)
}

.un-details-collectibles .footer .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: env(safe-area-inset-bottom)
}

.un-details-collectibles .footer .links-item-pages {
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 15px
}

.un-details-collectibles .footer .links-item-pages .icon-box {
    text-decoration: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: var(--secondary);
    border: 1px solid var(--gray-200);
    background-color: var(--white)
}

.un-details-collectibles .footer .links-item-pages .icon-box.next {
    color: var(--dark)
}

.un-details-collectibles .footer .links-item-pages .icon-box.prev {
    background-color: var(--light);
    border-color: transparent
}

.un-details-collectibles .item-profile-creator {
    display: flex;
    align-items: center;
    text-decoration: none !important;
    position: relative
}

.un-details-collectibles .item-profile-creator .wrapper-image {
    position: relative
}

.un-details-collectibles .item-profile-creator .wrapper-image .avt-img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 15px
}

.un-details-collectibles .item-profile-creator .wrapper-image .icon {
    position: absolute;
    bottom: -10px;
    right: 9px;
    font-size: 17px
}

.un-details-collectibles .item-profile-creator .txt-user h5 {
    font-size: 13px;
    color: var(--secondary);
    margin-bottom: 2px;
    font-weight: 400
}

.un-details-collectibles .item-profile-creator .txt-user p {
    font-size: 15px;
    font-weight: 500;
    color: var(--dark);
    margin: 0
}

.btn-copy-address {
    display: inline-flex;
    align-items: center;
    padding: 0;
    position: relative;
    overflow: hidden
}

.btn-copy-address input {
    position: absolute;
    width: 150px;
    height: 50px;
    opacity: 0
}

.btn-copy-address input:checked~.icon-box {
    background-color: rgba(57, 90, 255, .1);
    color: #395aff;
    border-color: transparent
}

.btn-copy-address span {
    font-size: 13px;
    color: var(--secondary);
    margin-right: 10px;
    display: inline-block
}

.btn-copy-address .icon-box {
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    -ms-transition: .3s;
    transition: .3s;
    width: 35px;
    height: 35px;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #395aff;
    border: 1px solid var(--gray-200)
}

header.default {
    position: relative;
    min-height: 56px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white)
}

header.default .un-title-page h1 {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark);
    margin: 0
}

header.default .un-title-page.go-back {
    display: flex;
    align-items: center
}

header.default .un-title-page.go-back .icon {
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: var(--dark);
    background-color: var(--gray-200);
    border-radius: 50%;
    margin-right: 20px;
    text-decoration: none !important
}

header.default .un-title-page .user-publish {
    width: 24px;
    height: 24px;
    object-fit: cover
}

header.default .un-item-logo .logo-img {
    width: 56px;
    height: 18px;
    object-fit: contain
}

header.default .un-block-right {
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 20px
}

header.default .un-block-right .menu-sidebar .btn {
    padding: 0;
    width: 30px;
    height: 30px;
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center
}

header.default .un-block-right .menu-sidebar .btn svg {
    width: 19px
}

header.default .un-block-right .un-user-profile a {
    display: block;
    text-decoration: none !important
}

header.default .un-block-right .un-user-profile a .img-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover
}

header.default .un-block-right .un-notification {
    position: relative
}

header.default .un-block-right .un-notification a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    text-decoration: none !important;
    font-size: 22px;
    color: var(--dark)
}

header.default .un-block-right .un-notification a.active i::before {
    content: ""
}

header.default .un-block-right .un-notification .bull-activity {
    display: block;
    width: 5px;
    height: 5px;
    background-color: #ff3838;
    border-radius: 30px;
    position: absolute;
    top: 0;
    right: 0
}

header.default .un-block-right .btn-box-icon {
    width: 35px;
    height: 35px;
    font-size: 19px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0
}

header.default.border-b-gradient::before {
    content: "";
    position: absolute;
    height: 2px;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background-image: linear-gradient(to right, white, #d977a7, #8272e1, white)
}

header.default.heade-sticky {
    position: fixed;
    width: 100%;
    z-index: 99;
    background-color: var(--white)
}

header.default.header-light {
    background: rgba(34, 32, 50, .5);
    -webkit-backdrop-filter: saturate(1) blur(20px);
    -o-backdrop-filter: saturate(1) blur(20px);
    -ms-backdrop-filter: saturate(1) blur(20px);
    backdrop-filter: saturate(1) blur(20px)
}

header.default.header-light .un-block-right .menu-sidebar .btn {
    padding: 0;
    width: 30px;
    height: 30px;
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center
}

header.default.header-light .un-block-right .menu-sidebar .btn svg {
    width: 19px
}

header.default.header-light .un-block-right .menu-sidebar .btn svg rect {
    fill: #fff
}

.space-sticky {
    height: 56px
}

.un-item-logo .logo-img.dark-mode {
    display: none
}

.unList-creatores .un-title-default {
    margin-bottom: 5px
}

.unList-creatores .content-list-creatores {
    padding: 0 20px
}

.unList-creatores .content-list-creatores .nav-item .nav-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray-200);
    padding: 15px 0
}

.unList-creatores .content-list-creatores .nav-item .nav-link .item-user-img {
    display: flex;
    align-items: center
}

.unList-creatores .content-list-creatores .nav-item .nav-link .item-user-img .avt-img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 15px
}

.unList-creatores .content-list-creatores .nav-item .nav-link .item-user-img .txt-user h5 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 2px;
    color: var(--dark)
}

.unList-creatores .content-list-creatores .nav-item .nav-link .item-user-img .txt-user p {
    font-size: 13px;
    color: var(--secondary);
    font-weight: 500;
    margin: 0
}

.unList-creatores .content-list-creatores .nav-item:last-child .nav-link {
    border-bottom: 0
}

.unList-bestSeller .content-list-sellers {
    padding: 20px
}

.unList-bestSeller .content-list-sellers .item-user-seller {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    border: 1px solid var(--gray-200);
    padding: 0;
    margin-bottom: 15px
}

.unList-bestSeller .content-list-sellers .item-user-seller .item-user-img {
    display: flex;
    align-items: center;
    text-decoration: none !important;
    padding: 15px 20px
}

.unList-bestSeller .content-list-sellers .item-user-seller .item-user-img .avt-img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 15px
}

.unList-bestSeller .content-list-sellers .item-user-seller .item-user-img .txt-user h5 {
    font-size: 18px;
    font-weight: 500;
    color: var(--dark);
    margin-bottom: 2px
}

.unList-bestSeller .content-list-sellers .item-user-seller .item-user-img .txt-user h5 span {
    font-size: 13px;
    font-weight: 500;
    color: var(--secondary)
}

.unList-bestSeller .content-list-sellers .item-user-seller .item-user-img .txt-user p {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    color: var(--dark);
    display: flex;
    align-items: center
}

.unList-bestSeller .content-list-sellers .item-user-seller .item-user-img .txt-user p i {
    font-size: 15px;
    color: #3eca76;
    margin-left: 5px
}

.unList-bestSeller .content-list-sellers .item-user-seller .other-option .btn-box-check {
    padding: 15px 20px
}

.follow-sellers .content-list-creatores .nav-item .nav-link {
    border: 0;
    padding: 0;
    margin-bottom: 20px
}

.follow-sellers .content-list-creatores .nav-item .nav-link .item-user-img {
    text-decoration: none !important
}

.follow-sellers .content-list-creatores .nav-item .nav-link .item-user-img .wrapper-image {
    position: relative
}

.follow-sellers .content-list-creatores .nav-item .nav-link .item-user-img .wrapper-image .icon {
    position: absolute;
    bottom: -10px;
    right: 9px;
    font-size: 17px
}

.un-activity-page .content-activity .head {
    padding: 15px 20px
}

.un-activity-page .content-activity .head:first-of-type {
    padding-top: 0
}

.un-activity-page .content-activity .head .title {
    font-size: 13px;
    font-weight: 500;
    color: var(--secondary);
    text-transform: uppercase
}

.un-activity-page .content-activity .body {
    border-bottom: 1px solid var(--gray-200);
    padding-bottom: 10px;
    margin-bottom: 10px
}

.un-activity-page .content-activity .body:last-of-type {
    border-bottom: 0
}

.un-activity-page .content-activity .body .nav-item .nav-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px
}

.un-activity-page .content-activity .body .nav-item .nav-link .item-user-img {
    display: flex;
    align-items: center
}

.un-activity-page .content-activity .body .nav-item .nav-link .item-user-img .wrapper-image {
    position: relative
}

.un-activity-page .content-activity .body .nav-item .nav-link .item-user-img .wrapper-image .icon-status {
    width: 45px;
    height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    font-size: 20px;
    border-radius: 50%
}

.un-activity-page .content-activity .body .nav-item .nav-link .item-user-img .wrapper-image .avt-img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 15px
}

.un-activity-page .content-activity .body .nav-item .nav-link .item-user-img .wrapper-image .icon {
    position: absolute;
    bottom: -10px;
    right: 9px;
    font-size: 17px
}

.un-activity-page .content-activity .body .nav-item .nav-link .item-user-img .txt-user h5 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 3px;
    color: var(--dark)
}

.un-activity-page .content-activity .body .nav-item .nav-link .item-user-img .txt-user h5.text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 47vw
}

.un-activity-page .content-activity .body .nav-item .nav-link .item-user-img .txt-user p {
    font-size: 13px;
    color: var(--secondary);
    font-weight: 400;
    margin: 0
}

.un-activity-page .content-activity .body .nav-item .nav-link .other-option .img-activiy-sm {
    width: 55px;
    height: 55px;
    object-fit: cover;
    border-radius: 15px
}

.un-connect-wallet {
    padding: 0 20px
}

.un-connect-wallet .cover-image-wallet img {
    width: 100%;
    height: 100%;
    object-fit: contain
}

@media(min-width: 576px) {
    .un-connect-wallet .cover-image-wallet img {
        height: 240px
    }
}

.un-connect-wallet .nav .nav-item .nav-link {
    padding: 20px;
    border-radius: 15px;
    border: 1px solid var(--gray-200);
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    -webkit-transition: .25s;
    -moz-transition: .25s;
    -o-transition: .25s;
    -ms-transition: .25s;
    transition: .25s
}

.un-connect-wallet .nav .nav-item .nav-link::before {
    content: "";
    font-family: remixicon !important;
    font-size: 24px;
    color: var(--secondary);
    position: absolute;
    right: 20px
}

.un-connect-wallet .nav .nav-item .nav-link .icon-app {
    margin-right: 20px
}

.un-connect-wallet .nav .nav-item .nav-link .icon-app img {
    width: 35px;
    height: 35px;
    object-fit: contain
}

.un-connect-wallet .nav .nav-item .nav-link .text-name {
    font-size: 16px;
    font-weight: 500;
    color: var(--dark);
    margin: 0
}

.un-connect-wallet .nav .nav-item .nav-link:active,
.un-connect-wallet .nav .nav-item .nav-link :hover {
    background-color: var(--light)
}

.modal .modal-dialog {
    max-width: 100%
}

.modal .modal-dialog .modal-content {
    border-radius: 20px;
    border: 0;
    background-color: var(--white)
}

.modal .modal-dialog .modal-content .modal-header .title-modal {
    font-size: 18px;
    margin: 0;
    font-weight: 600;
    color: var(--dark)
}

.modal .modal-dialog .modal-content .modal-header .item-shared {
    display: flex;
    align-items: center
}

.modal .modal-dialog .modal-content .modal-header .item-shared .image-items .user-img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 15px
}

.modal .modal-dialog .modal-content .modal-header .item-shared .txt h1 {
    font-size: 14px;
    font-weight: 500;
    color: var(--dark);
    margin: 0;
    margin-bottom: 1px
}

.modal .modal-dialog .modal-content .modal-header .item-shared .txt p {
    font-size: 11px;
    color: var(--secondary);
    margin: 0
}

.modal .modal-dialog .modal-content .modal-header .icon-socialNetworks {
    display: inline-flex;
    flex-direction: row;
    column-gap: 15px
}

.modal .modal-dialog .modal-content .modal-header .icon-socialNetworks .btn-social {
    width: 38px;
    height: 38px;
    border-radius: 10px;
    border: 1px solid var(--gray-200);
    display: inline-flex;
    align-items: center;
    justify-content: center
}

.modal .modal-dialog .modal-content .modal-header .icon-socialNetworks .btn-social img {
    width: 18px;
    height: 18px
}

.modal .modal-dialog .modal-content .modal-body {
    background-color: var(--white);
    padding: 20px
}

.modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none
}

.modal .modal-dialog.height-full {
    height: 100%;
    max-height: 100%
}

.modal .modal-dialog.height-full .modal-content {
    max-height: 100%
}

.modal.defaultModal .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom-color: var(--gray-200)
}

.modal.transition-bottom.fade .modal-dialog {
    -webkit-transform: translate3d(0%, 0, 0);
    -moz-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
    -webkit-transition: transform .35s;
    -moz-transition: transform .35s;
    -o-transition: transform .35s;
    -ms-transition: transform .35s;
    transition: transform .35s;
    transform: translateY(100%) !important;
    -webkit-transform: translateY(100%) !important;
    -moz-transform: translateY(100%) !important;
    -o-transform: translateY(285px) !important;
    width: 100%
}

.modal.transition-bottom.show .modal-dialog {
    transform: translateY(0) !important;
    -webkit-transform: translateY(0) !important;
    -moz-transform: translateY(0) !important;
    -o-transform: translateY(0) !important;
    bottom: 0
}

.modal.screenFull .modal-dialog {
    margin: 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0
}

.modal.screenFull .modal-dialog .modal-content {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important
}

.modal.screenFull .modal-dialog .modal-content .modal-footer {
    border-radius: 0 !important
}

.modal.-inside {
    padding-bottom: env(safe-area-inset-bottom)
}

.modal.-inside .modal-dialog {
    padding: 0 20px;
    bottom: 20px
}

.modal.-inside.show .modal-dialog {
    padding: 0 20px;
    bottom: 20px
}

.modal.-inside.show .modal-dialog .modal-content {
    border-radius: 10px
}

.modal.-inside.show .modal-dialog .modal-content.rounded-15 {
    border-radius: 15px !important
}

.modal.-inside.show .modal-dialog .modal-footer {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px
}

.modal.modalCentered .modal-dialog {
    margin: 0 20px
}

.modal.modalCentered .modal-dialog .modal-header {
    padding: 25px
}

.modal.modalCentered .modal-dialog .close {
    position: absolute;
    right: 25px
}

.modal .modal-body .content-upload-item p {
    font-size: 14px;
    color: var(--secondary);
    margin: 0
}

.modal .modal-body .content-upload-item p span {
    color: var(--dark)
}

.modal .modal-body .content-upload-item .btn-create {
    min-width: 260px;
    height: 60px;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 500
}

.modal .modal-body .content-alert-actions h2 {
    font-size: 14px;
    color: var(--dark);
    margin: 0
}

.modal .modal-body .content-alert-actions .action-links .btn {
    min-width: 120px;
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center
}

.modal .modal-body .content-share-socials .un-navMenu-default .nav-item {
    margin-bottom: 0 !important
}

.modal .modal-body .content-share-socials .un-navMenu-default .nav-item .nav-link {
    padding: 10px 20px;
    border: 0;
    margin-bottom: 12px;
    width: 100%
}

.modal .modal-body .content-share-socials .un-navMenu-default .nav-item .nav-link .icon-svg {
    margin-right: 10px
}

.modal .modal-body .content-share-socials .un-navMenu-default .nav-item .nav-link .icon-svg img {
    width: 24px;
    height: 24px
}

.modal .modal-body .content-share-socials .un-navMenu-default .nav-item .nav-link.facebook {
    background-color: rgba(57, 90, 255, .1)
}

.modal .modal-body .content-share-socials .un-navMenu-default .nav-item .nav-link.facebook .link-title {
    color: #395aff
}

.modal .modal-body .content-share-socials .un-navMenu-default .nav-item .nav-link.twitter {
    background-color: rgba(29, 161, 242, .1)
}

.modal .modal-body .content-share-socials .un-navMenu-default .nav-item .nav-link.twitter .link-title {
    color: #1da1f2
}

.modal .modal-body .content-share-socials .un-navMenu-default .nav-item .nav-link.instrgrame {
    background-color: rgba(255, 84, 62, .1)
}

.modal .modal-body .content-share-socials .un-navMenu-default .nav-item .nav-link.instrgrame .link-title {
    color: #ff543e
}

.modal .modal-body .content-share-socials .un-navMenu-default .nav-item .nav-link.whatsapp {
    background-color: rgba(62, 202, 118, .1)
}

.modal .modal-body .content-share-socials .un-navMenu-default .nav-item .nav-link.whatsapp .link-title {
    color: #3eca76
}

.modal.-left .modal-dialog,
.modal.-right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 320px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -moz-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0)
}

.modal.-left .modal-dialog .modal-content,
.modal.-right .modal-dialog .modal-content {
    height: 100%;
    overflow-y: auto;
    border-radius: 0;
    border: 0;
    background-color: var(--white)
}

.modal.-left .modal-dialog .modal-content .modal-header .btnClose,
.modal.-right .modal-dialog .modal-content .modal-header .btnClose {
    position: absolute;
    z-index: 2;
    right: 20px;
    top: 30px
}

.modal.-left .modal-dialog.modal-dialog-scrollable,
.modal.-right .modal-dialog.modal-dialog-scrollable {
    max-height: 100% !important
}

.modal.-left .modal-dialog.modal-dialog-scrollable .modal-content,
.modal.-right .modal-dialog.modal-dialog-scrollable .modal-content {
    max-height: 100%
}

.modal.-right.fade .modal-dialog {
    right: -320px;
    -webkit-transition: all .35s ease;
    -moz-transition: all .35s ease;
    -o-transition: all .35s ease;
    transition: all .35s ease
}

.modal.-right.fade.show .modal-dialog {
    right: 0
}

.modal.-left.fade .modal-dialog {
    left: -320px;
    -webkit-transition: all .35s ease;
    -moz-transition: all .35s ease;
    -o-transition: all .35s ease;
    transition: all .35s ease
}

.modal.-left.fade.show .modal-dialog {
    left: 0
}

.modal.--fullScreen {
    opacity: 1 !important
}

.modal.--fullScreen .modal-dialog {
    width: 100%
}

.modal.--fullScreen.fade .modal-dialog {
    left: -100%
}

.modal.modal-collectibles .modal-header {
    border: 0
}

.modal.modal-collectibles .modal-header h1 {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark);
    margin: 0;
    margin-bottom: 5px
}

.modal.modal-collectibles .modal-header p {
    font-size: 13px;
    color: var(--secondary);
    font-weight: 500;
    margin: 0
}

.modal.modal-collectibles .modal-header .btnClose {
    top: 20px !important
}

.modal.modal-collectibles .content-custome-data {
    min-height: 100px
}

.modal.modal-collectibles .content-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: env(safe-area-inset-bottom)
}

.modal.modal-collectibles .links-item-pages {
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 15px
}

.modal.modal-collectibles .links-item-pages .icon-box {
    text-decoration: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: var(--secondary);
    border: 1px solid var(--gray-200);
    background-color: var(--white)
}

.modal.modal-collectibles .links-item-pages .icon-box.next {
    color: var(--dark)
}

.modal.modal-collectibles .links-item-pages .icon-box.prev {
    background-color: var(--light);
    border-color: transparent
}

.sidebarMenu {
    opacity: 1 !important
}

.sidebarMenu.-left .modal-dialog,
.sidebarMenu.-right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 320px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -moz-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0)
}

.sidebarMenu.-left .modal-dialog .modal-content,
.sidebarMenu.-right .modal-dialog .modal-content {
    height: 100%;
    overflow-y: auto;
    border-radius: 0;
    border: 0;
    background-color: var(--white)
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-header .btnClose,
.sidebarMenu.-right .modal-dialog .modal-content .modal-header .btnClose {
    position: absolute;
    z-index: 2;
    right: 20px;
    top: 30px
}

.sidebarMenu.-left .modal-dialog.modal-dialog-scrollable,
.sidebarMenu.-right .modal-dialog.modal-dialog-scrollable {
    max-height: 100% !important
}

.sidebarMenu.-left .modal-dialog.modal-dialog-scrollable .modal-content,
.sidebarMenu.-right .modal-dialog.modal-dialog-scrollable .modal-content {
    max-height: 100%
}

.sidebarMenu.-right.fade .modal-dialog {
    right: -320px;
    -webkit-transition: all .35s ease;
    -moz-transition: all .35s ease;
    -o-transition: all .35s ease;
    transition: all .35s ease
}

.sidebarMenu.-right.fade.show .modal-dialog {
    right: 0
}

.sidebarMenu.-left.fade .modal-dialog {
    left: -320px;
    -webkit-transition: all .35s ease;
    -moz-transition: all .35s ease;
    -o-transition: all .35s ease;
    transition: all .35s ease
}

.sidebarMenu.-left.fade.show .modal-dialog {
    left: 0
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-header {
    border-bottom: 0;
    padding: 30px 20px 20px 20px;
    border-radius: 0
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-header .un-user-profile {
    display: flex;
    align-items: center;
    margin-bottom: 15px
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-header .un-user-profile .image_user {
    margin-right: 15px
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-header .un-user-profile .image_user img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-header .un-user-profile .text-user h3 {
    font-size: 18px;
    font-weight: 600;
    color: var(--dark);
    margin-bottom: 2px
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-header .un-user-profile .text-user p {
    font-size: 13px;
    color: var(--secondary);
    font-weight: 500;
    margin-bottom: 0
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-header .cover-balance {
    position: relative;
    overflow: hidden;
    background-color: var(--dark);
    border-radius: 15px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-header .cover-balance::before {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='312' height='312' viewBox='0 0 312 312'%3E%3Cg id='Group_8526' data-name='Group 8526' transform='translate(71 107)'%3E%3Cg id='Ellipse_601' data-name='Ellipse 601' transform='translate(-8 -44)' fill='rgba(255,255,255,0)' stroke='%23fff' stroke-width='1.5' opacity='0.1'%3E%3Ccircle cx='93' cy='93' r='93' stroke='none'/%3E%3Ccircle cx='93' cy='93' r='92.25' fill='none'/%3E%3C/g%3E%3Cg id='Ellipse_602' data-name='Ellipse 602' transform='translate(-39 -75)' fill='rgba(255,255,255,0)' stroke='%23fff' stroke-width='1.5' opacity='0.1'%3E%3Ccircle cx='124' cy='124' r='124' stroke='none'/%3E%3Ccircle cx='124' cy='124' r='123.25' fill='none'/%3E%3C/g%3E%3Cg id='Ellipse_603' data-name='Ellipse 603' transform='translate(-71 -107)' fill='rgba(255,255,255,0)' stroke='%23fff' stroke-width='1.5' opacity='0.1'%3E%3Ccircle cx='156' cy='156' r='156' stroke='none'/%3E%3Ccircle cx='156' cy='156' r='155.25' fill='none'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-position: -330% 89%;
    position: absolute;
    z-index: 0;
    pointer-events: none
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-header .cover-balance .un-balance {
    position: relative
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-header .cover-balance .un-balance .content-balance .head-balance {
    margin-bottom: 3px;
    display: flex;
    align-items: center
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-header .cover-balance .un-balance .content-balance .head-balance h4 {
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    margin: 0
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-header .cover-balance .un-balance .content-balance .head-balance .link-addBalance {
    width: 22px;
    height: 22px;
    background-color: rgba(255, 255, 255, .2);
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 0;
    margin-left: 8px
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-header .cover-balance .un-balance .content-balance .no-balance {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    margin: 0
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-header .cover-balance .un-balance~.btn {
    position: relative
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-body {
    padding: 10px 20px
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-body .nav .nav-item .nav-link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
    background-color: var(--white);
    border-radius: 10px;
    margin-bottom: 8px;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    -ms-transition: .3s;
    transition: .3s
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-body .nav .nav-item .nav-link .badge-circle .doted_item {
    width: 5px;
    height: 5px;
    background-color: #ff3838;
    border-radius: 20px;
    display: block
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-body .nav .nav-item .nav-link .xs-badge {
    min-width: 20px;
    height: 20px;
    padding: 0 7px;
    border-radius: 6px;
    background-color: rgba(34, 32, 50, .1);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: 600;
    color: var(--dark)
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-body .nav .nav-item .nav-link .icon_current {
    position: absolute
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-body .nav .nav-item .nav-link .icon_active {
    position: absolute;
    opacity: 0
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-body .nav .nav-item .nav-link i {
    font-size: 20px;
    color: var(--secondary)
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-body .nav .nav-item .nav-link .title_link {
    color: var(--dark);
    font-size: 16px;
    padding-left: 30px;
    font-weight: 500
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-body .nav .nav-item .nav-link.active {
    background-color: var(--light)
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-body .nav .nav-item .nav-link.active .icon_current {
    opacity: 0
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-body .nav .nav-item .nav-link.active .icon_active {
    opacity: 1
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-body .nav .nav-item .nav-link.active .icon_active i {
    color: var(--dark)
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-body .nav .title__label {
    padding: 10px 20px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    color: var(--secondary);
    margin: 0
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-footer {
    border-top: 1px solid var(--gray-200);
    justify-content: inherit;
    align-items: center;
    padding: 20px;
    border-radius: 0
}

@supports(-webkit-touch-callout: none) {
    .sidebarMenu.-left .modal-dialog .modal-content .modal-footer {
        padding-bottom: env(safe-area-inset-bottom)
    }
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-footer .em_darkMode_menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-footer .em_darkMode_menu .text {
    margin: 0
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-footer .em_darkMode_menu .text h3 {
    font-size: 15px;
    color: var(--dark);
    margin-bottom: 0;
    padding-bottom: 3px
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-footer .em_darkMode_menu .text p {
    font-size: 13px;
    color: var(--secondary);
    margin-bottom: 0
}

.sidebarMenu.-guest .modal-dialog .modal-content .modal-header .welcome_em h2 {
    font-size: 18px;
    font-weight: 600;
    color: var(--dark)
}

.sidebarMenu.-guest .modal-dialog .modal-content .modal-header .welcome_em h2 span {
    color: #395aff
}

.sidebarMenu.-guest .modal-dialog .modal-content .modal-header .welcome_em p {
    font-size: 14px;
    color: var(--secondary);
    margin-bottom: 15px;
    width: 85%
}

.sidebarMenu.-guest .modal-dialog .modal-content .modal-header .welcome_em p a {
    color: var(--dark);
    text-decoration: none
}

.sidebarMenu.--fullScreen .modal-dialog {
    width: 100%
}

.sidebarMenu.--fullScreen.fade .modal-dialog {
    left: -100%
}

.btnClose {
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    color: var(--dark);
    background-color: var(--gray-200);
    border-radius: 50%;
    text-decoration: none !important
}

.status-connection {
    position: fixed;
    top: -30px;
    box-shadow: none !important;
    border-radius: 10px;
    border: 0;
    z-index: 999;
    width: auto;
    opacity: 0;
    pointer-events: none;
    -webkkit-transition: .35s cubic-bezier(0.46, 0.03, 0.52, 0.96);
    -moz-transition: .35s cubic-bezier(0.46, 0.03, 0.52, 0.96);
    -o-transition: .35s cubic-bezier(0.46, 0.03, 0.52, 0.96);
    -ms-transition: .35s cubic-bezier(0.46, 0.03, 0.52, 0.96);
    transition: .35s cubic-bezier(0.46, 0.03, 0.52, 0.96)
}

.status-connection .toast-body {
    display: flex;
    align-items: center;
    padding: 12px 20px
}

.status-connection .toast-body .icon-status {
    font-size: 16px;
    color: var(--white);
    margin-right: 10px;
    display: inline-flex
}

.status-connection .toast-body .msg-status {
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    margin: 0
}

.status-connection .btn-close {
    background-image: none !important;
    font-size: 18px;
    font-weight: 500;
    color: var(--white);
    opacity: .8;
    display: flex;
    align-items: center
}

.status-connection.offline {
    background-color: var(--dark);
    top: 10px;
    opacity: 1;
    pointer-events: all
}

.status-connection:not(.show) {
    opacity: 0;
    top: -10px;
    display: block;
    pointer-events: none
}

.modal-filter .title-modal {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark);
    margin: 0
}

.modal-filter .btn-clear {
    font-size: 14px;
    font-weight: 500;
    color: #ff3838;
    margin-right: 40px
}

.modal-filter .form-filter-checkbox .title-form {
    font-size: 16px;
    font-weight: 600;
    color: var(--dark);
    margin-bottom: 20px;
    margin-top: 30px
}

.modal-filter .form-filter-checkbox .nav-checkbox .nav-item {
    margin-bottom: 18px
}

.modal-filter .form-filter-checkbox .nav-checkbox .nav-item .item-checkbox-filter {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.modal-filter .form-filter-checkbox .nav-checkbox .nav-item .form-check {
    margin: 0;
    display: flex;
    align-items: center
}

.modal-filter .form-filter-checkbox .nav-checkbox .nav-item .form-check .form-check-input {
    width: 22px;
    height: 22px;
    border-radius: 6px;
    background-color: var(--white);
    border: 1px solid var(--gray-200);
    -webkit-transition: .25s;
    -moz-transition: .25s;
    -o-transition: .25s;
    -ms-transition: .25s;
    transition: .25s
}

.modal-filter .form-filter-checkbox .nav-checkbox .nav-item .form-check .form-check-input:checked[type=checkbox] {
    background-size: 18px;
    background-color: #395aff;
    border-color: #395aff
}

.modal-filter .form-filter-checkbox .nav-checkbox .nav-item .form-check .form-check-input:checked[type=checkbox]~.form-check-label {
    color: var(--dark)
}

.modal-filter .form-filter-checkbox .nav-checkbox .nav-item .form-check .form-check-label {
    font-size: 14px;
    color: var(--secondary);
    font-weight: 500;
    margin-left: 15px;
    margin-top: 5px;
    -webkit-transition: .25s;
    -moz-transition: .25s;
    -o-transition: .25s;
    -ms-transition: .25s;
    transition: .25s
}

.modal-filter .form-filter-checkbox .nav-checkbox .nav-item .number-category span {
    font-size: 14px;
    color: var(--secondary)
}

.modal-filter .form-filter-checkbox .nav-checkbox .nav-item:last-child {
    margin-bottom: 0
}

.modal-filter .slider-range-price {
    border-bottom: 1px solid var(--gray-200);
    margin-bottom: 30px
}

.modal-filter .btn-apply-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 165px;
    height: 55px;
    border-radius: 50px;
    background-color: #3eca76;
    padding: 10px 10px 10px 20px
}

.modal-filter .btn-apply-filter p {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin: 0
}

.modal-filter .btn-apply-filter p span {
    font-size: 11px
}

.modal-filter .btn-apply-filter .ico {
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #fff;
    background-color: rgba(255, 255, 255, .12);
    border-radius: 50%
}

.nav-checkbox .nav-item {
    margin-bottom: 18px
}

.nav-checkbox .nav-item .item-checkbox-filter {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.nav-checkbox .nav-item .form-check {
    margin: 0;
    display: flex;
    align-items: center
}

.nav-checkbox .nav-item .form-check .form-check-input {
    width: 22px;
    height: 22px;
    border-radius: 6px;
    background-color: var(--white);
    border: 1px solid var(--gray-200);
    -webkit-transition: .25s;
    -moz-transition: .25s;
    -o-transition: .25s;
    -ms-transition: .25s;
    transition: .25s
}

.nav-checkbox .nav-item .form-check .form-check-input:checked[type=checkbox] {
    background-size: 18px;
    background-color: #395aff;
    border-color: #395aff
}

.nav-checkbox .nav-item .form-check .form-check-input:checked[type=checkbox]~.form-check-label {
    color: var(--dark)
}

.nav-checkbox .nav-item .form-check .form-check-label {
    font-size: 14px;
    color: var(--secondary);
    font-weight: 500;
    margin-left: 15px;
    margin-top: 5px;
    -webkit-transition: .25s;
    -moz-transition: .25s;
    -o-transition: .25s;
    -ms-transition: .25s;
    transition: .25s
}

.nav-checkbox .nav-item .number-category span {
    font-size: 14px;
    color: var(--secondary)
}

.nav-checkbox .nav-item:last-child {
    margin-bottom: 0
}

.noUi-target {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    background: #fafafa;
    border-radius: 4px;
    border: 1px solid #d3d3d3;
    box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb
}

.noUi-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.noUi-base {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1
}

.noUi-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;
    z-index: 0;
    border-radius: 3px
}

.noUi-connect {
    will-change: transform;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -webkit-transform-style: preserve-3d;
    transform-origin: 0 0;
    transform-style: flat;
    background: #3fb8af
}

.noUi-origin {
    will-change: transform;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -webkit-transform-style: preserve-3d;
    transform-origin: 0 0;
    transform-style: flat
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
    left: 0;
    right: auto
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
    left: -17px;
    right: auto
}

.noUi-vertical {
    width: 18px
}

.noUi-vertical .noUi-origin {
    top: -100%;
    width: 0
}

.noUi-vertical .noUi-origin>.noUi-tooltip {
    -webkit-transform: translate(0, -18px);
    transform: translate(0, -18px);
    top: auto;
    right: 28px
}

.noUi-vertical .noUi-handle {
    width: 28px;
    height: 34px;
    right: -6px;
    bottom: -17px
}

.noUi-vertical .noUi-handle:before {
    width: 14px;
    height: 1px;
    left: 6px;
    top: 14px
}

.noUi-vertical .noUi-handle:after {
    width: 14px;
    height: 1px;
    left: 6px;
    top: 14px;
    top: 17px
}

.noUi-vertical .noUi-draggable {
    cursor: ns-resize
}

.noUi-vertical .noUi-tooltip {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    right: 120%
}

.noUi-horizontal {
    height: 18px
}

.noUi-horizontal .noUi-origin {
    height: 0
}

.noUi-horizontal .noUi-origin>.noUi-tooltip {
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0);
    left: auto;
    bottom: 10px
}

.noUi-horizontal .noUi-handle {
    width: 34px;
    height: 28px;
    right: -17px;
    top: -6px
}

.noUi-horizontal .noUi-tooltip {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50% !important;
    bottom: 120%
}

.noUi-handle {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    background: #fff;
    cursor: default;
    box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb
}

.noUi-handle:before {
    content: "";
    display: block;
    position: absolute;
    height: 14px;
    width: 1px;
    background: #e8e7e6;
    left: 14px;
    top: 6px
}

.noUi-handle:after {
    content: "";
    display: block;
    position: absolute;
    height: 14px;
    width: 1px;
    background: #e8e7e6;
    left: 14px;
    top: 6px;
    left: 17px
}

.noUi-touch-area {
    height: 100%;
    width: 100%
}

.noUi-state-tap .noUi-connect {
    -webkit-transition: transform .3s;
    transition: transform .3s
}

.noUi-state-tap .noUi-origin {
    -webkit-transition: transform .3s;
    transition: transform .3s
}

.noUi-state-drag * {
    cursor: inherit !important
}

.noUi-draggable {
    cursor: ew-resize
}

.noUi-active {
    box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb
}

[disabled] .noUi-connect {
    background: #b8b8b8
}

[disabled] .noUi-handle {
    cursor: not-allowed
}

[disabled].noUi-target {
    cursor: not-allowed
}

[disabled].noUi-handle {
    cursor: not-allowed
}

.noUi-pips {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    color: #999
}

.noUi-pips * {
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.noUi-value {
    position: absolute;
    white-space: nowrap;
    text-align: center
}

.noUi-value-sub {
    color: #ccc;
    font-size: 10px
}

.noUi-marker {
    position: absolute;
    background: #ccc
}

.noUi-marker-sub {
    background: #aaa
}

.noUi-marker-large {
    background: #aaa
}

.noUi-pips-horizontal {
    padding: 10px 0;
    height: 80px;
    top: 100%;
    left: 0;
    width: 100%
}

.noUi-value-horizontal {
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%)
}

.noUi-rtl .noUi-value-horizontal {
    -webkit-transform: translate(50%, 50%);
    transform: translate(50%, 50%)
}

.noUi-rtl .noUi-value-vertical {
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%)
}

.noUi-marker-horizontal.noUi-marker {
    margin-left: -1px;
    width: 2px;
    height: 5px
}

.noUi-marker-horizontal.noUi-marker-sub {
    height: 10px
}

.noUi-marker-horizontal.noUi-marker-large {
    height: 15px
}

.noUi-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%
}

.noUi-value-vertical {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding-left: 25px
}

.noUi-marker-vertical.noUi-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px
}

.noUi-marker-vertical.noUi-marker-sub {
    width: 10px
}

.noUi-marker-vertical.noUi-marker-large {
    width: 15px
}

.noUi-tooltip {
    display: block;
    position: absolute;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    background: #fff;
    color: #000;
    padding: 5px;
    text-align: center;
    white-space: nowrap
}

.un-title-default {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.un-title-default .text h2 {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark);
    margin: 0;
    margin-bottom: 3px
}

.un-title-default .text p {
    font-size: 13px;
    color: var(--secondary);
    font-weight: 400;
    margin: 0
}

.un-title-default .un-block-right .icon-back {
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: var(--dark);
    background-color: var(--gray-200);
    border-radius: 50%;
    text-decoration: none !important
}

.un-intro-card {
    padding: 20px
}

.un-intro-card .un-cc-description .cover-image {
    background-color: var(--bg-black);
    border-radius: 15px;
    height: 215px;
    width: 100%;
    position: relative;
    overflow: hidden
}

.un-intro-card .un-cc-description .cover-image img {
    width: 100%;
    object-fit: cover;
    height: 460px;
    position: absolute;
    top: -60px
}

.un-intro-card .un-cc-description .text-content {
    margin-top: 20px
}

.un-intro-card .un-cc-description .text-content h1 {
    font-size: 13px;
    font-weight: 500;
    color: #395aff;
    margin-bottom: 5px
}

.un-intro-card .un-cc-description .text-content h2 {
    font-size: 24px;
    font-weight: 600;
    color: var(--dark);
    margin-bottom: 5px
}

.un-intro-card .un-cc-description .text-content p {
    font-size: 13px;
    color: var(--secondary);
    margin: 0
}

.un-navMenu-default {
    padding: 10px 20px
}

.un-navMenu-default .nav .nav-item {
    margin-bottom: 12px
}

.un-navMenu-default .nav .nav-item .nav-link {
    padding: 12px 20px;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid var(--gray-200);
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    -ms-transition: .3s;
    transition: .3s
}

.un-navMenu-default .nav .nav-item .nav-link .item-content-link {
    display: flex;
    align-items: center
}

.un-navMenu-default .nav .nav-item .nav-link .item-content-link .icon {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    margin-right: 15px
}

.un-navMenu-default .nav .nav-item .nav-link .item-content-link .link-title {
    font-size: 16px;
    font-weight: 500;
    color: var(--dark);
    margin: 0
}

.un-navMenu-default .nav .nav-item .nav-link .other-cc {
    display: flex;
    align-items: center
}

.un-navMenu-default .nav .nav-item .nav-link .other-cc .badge-text {
    font-size: 12px;
    color: var(--secondary);
    display: block;
    margin-right: 5px
}

.un-navMenu-default .nav .nav-item .nav-link .other-cc .icon-arrow {
    font-size: 24px;
    color: var(--secondary);
    display: inline-flex
}

.un-navMenu-default .nav .nav-item .nav-link:hover,
.un-navMenu-default .nav .nav-item .nav-link:active {
    background-color: var(--light)
}

.un-navMenu-default .sub-title-pkg {
    margin: 13px 0 22px 0
}

.un-navMenu-default .sub-title-pkg h2 {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    color: var(--secondary);
    text-transform: uppercase
}

.un-navMenu-default.with-visit .nav .nav-item .nav-link.visited {
    background-color: rgba(34, 32, 50, .015)
}

.un-navMenu-default.with-visit .nav .nav-item .nav-link.visited .other-cc .icon-arrow i {
    color: #3eca76;
    font-size: 22px
}

.un-navMenu-default.with-visit .nav .nav-item .nav-link.visited .other-cc .icon-arrow i::before {
    content: ""
}

.un-navMenu-default.without-visit .nav .nav-item .nav-link.visited .other-cc .icon-arrow i {
    color: var(--secondary);
    font-size: 24px
}

.un-navMenu-default.without-visit .nav .nav-item .nav-link.visited .other-cc .icon-arrow i::before {
    content: ""
}

.un-navMenu-default.without-visit .nav .nav-item .nav-link:hover,
.un-navMenu-default.without-visit .nav .nav-item .nav-link:active {
    background-color: var(--light)
}

.un-navMenu-default.without-icon .nav-item .nav-link {
    padding: 10px 20px;
    height: 55px
}

.copyright-mark {
    text-align: center;
    padding: 20px
}

.copyright-mark .content .logo-gray {
    width: 56px;
    height: 18px;
    object-fit: contain;
    margin-bottom: 10px
}

.copyright-mark .content p {
    font-size: 12px;
    color: var(--secondary)
}

.main-search-header {
    background-color: var(--white)
}

.main-search-header .content-search {
    padding: 10px 20px 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    column-gap: 15px;
    width: 100%
}

.main-search-header .form-group {
    margin: 0;
    width: calc(100% - 60px)
}

.main-search-header .form-group input {
    background-color: var(--light) !important;
    height: 45px !important
}

.main-search-header .form-group input:active,
.main-search-header .form-group input:hover {
    background-color: var(--white) !important
}

.main-search-header .btn-box-filter {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    padding: 0;
    font-size: 20px;
    color: var(--dark);
    border: 1px solid var(--gray-200);
    display: flex;
    align-items: center;
    justify-content: center
}

.main-search-header.style-border {
    border-bottom: 1px solid var(--gray-200)
}

.main-search-header .nav-tab-search {
    justify-content: center;
    margin-top: 18px
}

.main-search-header .nav-tab-search .nav-item .nav-link {
    position: relative;
    font-size: 15px;
    font-weight: 500;
    color: var(--secondary);
    background-color: transparent;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 18px;
    margin: 0 15px;
    -webkit-transition: .25s;
    -moz-transition: .25s;
    -o-transition: .25s;
    -ms-transition: .25s;
    transition: .25s
}

.main-search-header .nav-tab-search .nav-item .nav-link::before {
    content: "";
    width: 15px;
    height: 2px;
    border-radius: 5px;
    background-color: var(--dark);
    position: absolute;
    bottom: 0;
    opacity: 0;
    -webkit-transition: .25s;
    -moz-transition: .25s;
    -o-transition: .25s;
    -ms-transition: .25s;
    transition: .25s
}

.main-search-header .nav-tab-search .nav-item .nav-link.active {
    color: var(--dark)
}

.main-search-header .nav-tab-search .nav-item .nav-link.active::before {
    opacity: 1
}

.main-search-header.sticky-tab {
    position: fixed;
    width: 100%
}

.categories-tag-search .content-tags {
    padding: 20px
}

.categories-tag-search .content-tags .btn-tag-filter {
    position: relative;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-right: 3px;
    margin-bottom: 6px
}

.categories-tag-search .content-tags .btn-tag-filter span {
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    color: var(--dark);
    background-color: var(--light);
    padding: 7px 14px;
    border-radius: 50px;
    -webkit-transition: .15s;
    -moz-transition: .15s;
    -o-transition: .15s;
    -ms-transition: .15s;
    transition: .15s;
    background-image: linear-gradient(to right, rgba(255, 121, 142, 0), rgba(85, 111, 255, 0))
}

.categories-tag-search .content-tags .btn-tag-filter input {
    font-size: 100px;
    width: 40px;
    height: 120px;
    position: absolute;
    opacity: 0
}

.categories-tag-search .content-tags .btn-tag-filter input:checked~span {
    background-image: linear-gradient(to right, #ff798e, #556fff);
    color: #fff;
    border-color: transparent
}

.categories-tag-search.solid-bg-white .content-tags {
    padding: 15px 20px
}

.categories-tag-search.solid-bg-white .content-tags.other-style .btn-tag-filter span {
    border: 1px solid var(--gray-200);
    background-color: var(--white)
}

.slider-range-price {
    padding-bottom: 30px
}

.slider-range-price .content-slider {
    position: relative
}

.slider-range-price .content-slider .header {
    margin-bottom: 20px
}

.slider-range-price .content-slider .header h2 {
    font-size: 16px;
    font-weight: 600;
    color: var(--dark);
    margin-bottom: 5px
}

.slider-range-price .content-slider .auto-price {
    display: flex;
    align-items: center
}

.slider-range-price .content-slider .auto-price .input-text-price input {
    border: 0;
    background-color: transparent;
    color: var(--secondary);
    font-size: 13px;
    font-weight: 400;
    width: 40px;
    opacity: 1;
    padding: 0;
    pointer-events: none
}

.slider-range-price .content-slider .auto-price .input-text-price span {
    color: var(--secondary);
    font-size: 13px;
    font-weight: 400
}

.slider-range-price .content-slider .auto-price .input-text-price.min input {
    width: 35px
}

.slider-range-price .content-slider .price-under-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    color: var(--dark);
    font-weight: 500;
    margin-top: 20px
}

.slider-range-price .noUi-horizontal {
    height: 0;
    background-color: transparent;
    border: 0
}

.slider-range-price .noUi-tooltip {
    position: absolute;
    min-width: 45px;
    height: 27px;
    border-radius: 6px;
    padding: 6px 9px;
    font-size: 11px;
    font-weight: 500;
    background-color: var(--dark);
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    top: -35px;
    left: -10px;
    opacity: 0;
    border: 0;
    box-shadow: 0;
    outline: none;
    -webkit-transition: .25s;
    -moz-transition: .25s;
    -o-transition: .25s;
    -ms-transition: .25s;
    transition: .25s
}

.slider-range-price .noUi-tooltip::before {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='8' viewBox='0 0 16 8'%3E%3Cpath id='Polygon_3' data-name='Polygon 3' d='M7.293.707a1,1,0,0,1,1.414,0l5.586,5.586A1,1,0,0,1,13.586,8H2.414a1,1,0,0,1-.707-1.707Z' transform='translate(16 8) rotate(180)' fill='%23272343'/%3E%3C/svg%3E%0A");
    position: absolute;
    background-size: 12px;
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
    bottom: -10px
}

.slider-range-price .noUi-base .noUi-connects {
    background-color: var(--gray-200);
    height: 10px;
    border-radius: 10px
}

.slider-range-price .noUi-base .noUi-connects .noUi-connect {
    background-color: #395aff
}

.slider-range-price .noUi-base .noUi-origin .noUi-handle {
    box-shadow: none !important;
    outline: none;
    background-color: transparent;
    border: 0;
    top: -10px
}

.slider-range-price .noUi-base .noUi-origin .noUi-handle .noUi-touch-area {
    box-shadow: none !important;
    border: 5px solid var(--white);
    border-radius: 50%;
    background-color: #395aff;
    width: 30px;
    height: 30px;
    -webkit-transition: .25s;
    -moz-transition: .25s;
    -o-transition: .25s;
    -ms-transition: .25s;
    transition: .25s
}

.slider-range-price .noUi-base .noUi-origin .noUi-handle::before,
.slider-range-price .noUi-base .noUi-origin .noUi-handle::after {
    display: none
}

.slider-range-price .noUi-base .noUi-origin .noUi-handle.noUi-active .noUi-touch-area {
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -o-transform: scale(1.4);
    -ms-transform: scale(1.4);
    transform: scale(1.4)
}

.slider-range-price .noUi-base .noUi-origin .noUi-handle.noUi-active .noUi-tooltip {
    opacity: 1;
    top: -40px
}

.nav-pilled-tab {
    background-color: #eef0f7;
    border-radius: 10px;
    padding: 4px;
    width: max-content
}

.nav-pilled-tab .nav-item .nav-link {
    background-color: transparent;
    color: var(--secondary);
    font-size: 13px;
    font-weight: 500;
    min-width: 65px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    -webkit-transition: .25s;
    -moz-transition: .25s;
    -o-transition: .25s;
    -ms-transition: .25s;
    transition: .25s
}

.nav-pilled-tab .nav-item .nav-link.active {
    background-color: var(--white);
    color: var(--dark)
}

.un-page-components .un-title-default {
    padding: 20px;
    background-color: var(--white)
}

.un-page-components .content-comp {
    padding: 20px 20px 50px 20px
}

.un-page-components .content-comp .text-alert {
    font-size: 13px
}

.un-page-components .content-comp .space-items {
    margin: 5px 0
}

.un-page-components .content-comp .box-buttons .btn {
    margin-bottom: 10px
}

.btn-sm-arrow {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    min-width: 165px;
    height: 55px;
    border-radius: 50px;
    padding: 10px 10px 10px 20px
}

.btn-sm-arrow p {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin: 0
}

.btn-sm-arrow p span {
    font-size: 11px
}

.btn-sm-arrow .ico {
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #fff;
    background-color: rgba(255, 255, 255, .12);
    border-radius: 50%
}

.btn-md-arrow {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    min-width: 190px;
    height: 60px;
    border-radius: 50px;
    padding: 10px 10px 10px 20px
}

.btn-md-arrow p {
    font-size: 15px;
    font-weight: 500;
    color: var(--white);
    margin: 0
}

.btn-md-arrow .ico {
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: var(--white);
    background-color: rgba(255, 255, 255, .12);
    border-radius: 50%
}

.un-brand-about {
    background-color: var(--light);
    border-top: 1px solid var(--gray-200);
    border-bottom: 1px solid var(--gray-200);
    padding: 25px;
    text-align: center
}

.un-brand-about .brand_img {
    width: 80px;
    height: auto;
    object-fit: contain;
    margin-bottom: 10px
}

.un-brand-about .brand_img.light-mode {
    display: inline-block
}

.un-brand-about .brand_img.dark-mode {
    display: none
}

.un-brand-about p {
    font-size: 12px;
    margin: 0;
    color: var(--secondary)
}

[data-theme=dark] .brand_img.light-mode {
    display: none
}

[data-theme=dark] .brand_img.dark-mode {
    display: inline-block
}

.about-section .descriptio {
    padding: 25px 20px
}

.about-section .descriptio h2 {
    font-size: 18px;
    font-weight: 600;
    color: var(--dark);
    margin: 0;
    margin-bottom: 8px
}

.about-section .descriptio p {
    font-size: 14px;
    color: var(--secondary);
    font-weight: 400;
    margin: 0
}

.social-networks-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    column-gap: 12px
}

.social-networks-footer .nav-link {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 10px
}

.social-networks-footer .nav-link.facebook {
    background-color: rgba(24, 119, 242, .1);
    color: #1877f2
}

.social-networks-footer .nav-link.instagram {
    background-color: rgba(250, 153, 5, .1);
    color: #fa9905
}

.social-networks-footer .nav-link.youtube {
    background-color: rgba(255, 64, 64, .1);
    color: #ff4040
}

.social-networks-footer .nav-link.twitter {
    background-color: rgba(3, 169, 244, .1);
    color: #03a9f4
}

.un-item-seller {
    text-decoration: none !important;
    display: flex;
    align-items: center
}

.un-item-seller .number {
    font-size: 13px;
    font-weight: 500;
    color: var(--gray-300);
    margin-right: 15px
}

.un-item-seller .media-profile {
    display: flex;
    align-items: center
}

.un-item-seller .media-profile .image-avatar {
    position: relative;
    margin: 0;
    margin-right: 15px
}

.un-item-seller .media-profile .image-avatar img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover
}

.un-item-seller .media-profile .image-avatar .icon-verify {
    position: absolute;
    font-size: 16px;
    color: #395aff;
    top: 30px;
    right: -4px
}

.un-item-seller .media-profile .text h3 {
    font-size: 15px;
    font-weight: 500;
    color: var(--dark);
    margin: 0;
    margin-bottom: 3px
}

.un-item-seller .media-profile .text p {
    font-size: 13px;
    font-weight: 500;
    color: var(--secondary);
    margin: 0
}

.un-section-seller .un-block-creators .swiper-slide {
    width: 55%;
    background: transparent
}

@media(min-width: 240px)and (max-width: 375px) {
    .un-section-seller .un-block-creators .swiper-slide {
        width: 100%
    }
}

@media(min-width: 300px)and (max-width: 375px) {
    .un-section-seller .un-block-creators .swiper-slide {
        width: 70%
    }
}

.un-section-seller .un-block-creators .un-item-seller {
    padding: 0 0 0 20px;
    width: 100%;
    text-align: left
}

.help-search-support {
    padding: 10px 20px 12px 20px;
    border-radius: 15px
}

.help-search-support .content {
    background-position: center;
    width: 100%;
    height: 100%;
    background-size: cover;
    padding: 30px 35px;
    border-radius: 15px
}

.help-search-support .head {
    text-align: center
}

.help-search-support .head h2 {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin: 0;
    margin-bottom: 3px
}

.help-search-support .head p {
    font-size: 12px;
    color: rgba(255, 255, 255, .8)
}

.help-search-support .form-group .form-control {
    height: 45px
}

.offline-section {
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    padding: 20px 50px;
    margin-top: 50px
}

.offline-section .content-offline .img-offline {
    margin-bottom: 30px;
    width: 175px;
    height: 115px;
    object-fit: contain
}

.offline-section .content-offline .display-text h2 {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark);
    margin-bottom: 10px
}

.offline-section .content-offline .display-text p {
    font-size: 14px;
    color: var(--secondary);
    margin-bottom: 30px
}

.un-blog-list .nav .nav-item .nav-link {
    display: block;
    text-decoration: none;
    padding: 0 20px;
    margin-bottom: 20px
}

.un-blog-list .nav .nav-item .nav-link .image-blog {
    display: flex;
    align-items: center
}

.un-blog-list .nav .nav-item .nav-link .image-blog img {
    width: 80px;
    height: 80px;
    border-radius: 15px;
    object-fit: cover;
    margin-right: 15px
}

.un-blog-list .nav .nav-item .nav-link .image-blog .text-blog h2 {
    font-size: 15px;
    font-weight: 500;
    color: var(--dark);
    margin: 0;
    margin-bottom: 8px
}

.un-blog-list .nav .nav-item .nav-link .image-blog .text-blog .others {
    display: flex;
    column-gap: 15px;
    flex-direction: row
}

.un-blog-list .nav .nav-item .nav-link .image-blog .text-blog .others .time,
.un-blog-list .nav .nav-item .nav-link .image-blog .text-blog .others .views {
    display: inline-flex;
    align-items: center;
    color: var(--secondary)
}

.un-blog-list .nav .nav-item .nav-link .image-blog .text-blog .others .time i,
.un-blog-list .nav .nav-item .nav-link .image-blog .text-blog .others .views i {
    font-size: 17px;
    margin-right: 5px
}

.un-blog-list .nav .nav-item .nav-link .image-blog .text-blog .others .time span,
.un-blog-list .nav .nav-item .nav-link .image-blog .text-blog .others .views span {
    font-size: 13px;
    font-weight: 500
}

.item-blog-list .nav-link {
    display: block;
    text-decoration: none;
    margin-bottom: 20px;
    padding: 0
}

.item-blog-list .nav-link .image-blog {
    display: flex;
    align-items: center
}

.item-blog-list .nav-link .image-blog img {
    width: 80px;
    height: 80px;
    border-radius: 15px;
    object-fit: cover;
    margin-right: 15px
}

.item-blog-list .nav-link .image-blog .text-blog h2 {
    font-size: 15px;
    font-weight: 500;
    color: var(--dark);
    margin: 0;
    margin-bottom: 8px
}

.item-blog-list .nav-link .image-blog .text-blog .others {
    display: flex;
    column-gap: 15px;
    flex-direction: row
}

.item-blog-list .nav-link .image-blog .text-blog .others .time,
.item-blog-list .nav-link .image-blog .text-blog .others .views {
    display: inline-flex;
    align-items: center;
    color: var(--secondary)
}

.item-blog-list .nav-link .image-blog .text-blog .others .time i,
.item-blog-list .nav-link .image-blog .text-blog .others .views i {
    font-size: 17px;
    margin-right: 5px
}

.item-blog-list .nav-link .image-blog .text-blog .others .time span,
.item-blog-list .nav-link .image-blog .text-blog .others .views span {
    font-size: 13px;
    font-weight: 500
}

.un-details-blog .head {
    padding: 0 20px
}

.un-details-blog .head .cover_img {
    position: relative
}

.un-details-blog .head .cover_img img {
    width: 100%;
    height: 250px;
    border-radius: 15px;
    object-fit: cover
}

.un-details-blog .body {
    padding: 15px 20px
}

.un-details-blog .body .title-blog {
    margin-bottom: 18px
}

.un-details-blog .body .title-blog .others {
    display: flex;
    column-gap: 15px;
    flex-direction: row;
    margin-bottom: 10px
}

.un-details-blog .body .title-blog .others .time,
.un-details-blog .body .title-blog .others .views {
    display: inline-flex;
    align-items: center;
    color: var(--secondary)
}

.un-details-blog .body .title-blog .others .time i,
.un-details-blog .body .title-blog .others .views i {
    font-size: 17px;
    margin-right: 5px
}

.un-details-blog .body .title-blog .others .time span,
.un-details-blog .body .title-blog .others .views span {
    font-size: 13px;
    font-weight: 500
}

.un-details-blog .body .title-blog h2 {
    font-size: 22px;
    font-weight: 600;
    color: var(--dark);
    margin: 0
}

.un-details-blog .body .description p {
    font-size: 15px;
    color: var(--secondary)
}

.un-details-blog .footer {
    background-color: var(--light);
    padding: 30px 20px
}

.un-details-blog .footer h4 {
    font-size: 20px;
    font-weight: 500
}

.bok-next-prev {
    display: flex;
    justify-content: center;
    align-items: center
}

.bok-next-prev a {
    text-decoration: none;
    display: flex;
    align-items: center
}

.bok-next-prev a .icon {
    width: 75px;
    height: 52px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    border: 1px solid var(--gray-200);
    font-size: 22px;
    position: relative
}

.bok-next-prev a span {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px
}

.bok-next-prev a.prev .icon {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    color: var(--secondary)
}

.bok-next-prev a.prev .icon::before {
    content: "";
    width: 1px;
    height: 25px;
    background-color: var(--gray-200);
    display: block;
    position: absolute;
    right: 0
}

.bok-next-prev a.prev span {
    color: var(--secondary);
    margin-right: 20px
}

.bok-next-prev a.next .icon {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: var(--dark)
}

.bok-next-prev a.next span {
    color: #395aff;
    margin-left: 20px
}

.bg-gradient-started {
    position: fixed !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #222032
}

.bg-gradient-started::before {
    content: "";
    /* background-image: url("../../images/other/bg-gradient.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 100%;
    position: fixed;
    opacity: .1;
    width: 100vw;
    height: 100%
}

.bg-gradient-started .bg-noise {
    position: fixed;
    /* background-image: url("../../images/other/bg-noise.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    opacity: .2;
    mix-blend-mode: darken
}

.un-info-template {
    position: relative
}

.un-info-template .main-img-nft {
    padding: 30px
}

.un-info-template .main-img-nft img {
    width: 100%;
    height: 100%;
    object-fit: contain
}

@media(min-width: 576px) {
    .un-info-template .main-img-nft img {
        height: 240px
    }
}

.un-info-template .block-numbers {
    display: flex;
    align-items: center;
    justify-content: space-evenly
}

.un-info-template .block-numbers .dividar-y {
    background-color: rgba(255, 255, 255, .15);
    height: 50px;
    width: 1px;
    display: inline-block
}

.un-info-template .block-numbers h2 {
    font-size: 35px;
    font-weight: 700;
    color: #fff;
    margin: 0
}

.un-info-template .block-numbers p {
    color: #dde1ec;
    font-size: 16px;
    font-weight: 400;
    margin: 0
}

.un-info-template::after {
    content: "";
    /* background-image: url("../../images/icons/shape_orange.png"); */
    position: absolute;
    background-repeat: no-repeat;
    background-size: 55px;
    width: 55px;
    height: 55px;
    display: block;
    bottom: 80px;
    left: 12px;
    -webkit-animation: floating-1 2s infinite ease-in-out;
    -moz-animation: floating-1 2s infinite ease-in-out;
    -o-animation: floating-1 2s infinite ease-in-out;
    -ms-animation: floating-1 2s infinite ease-in-out;
    animation: floating-1 2s infinite ease-in-out
}

.un__listPages_started {
    position: relative;
    padding: 15px 20px
}

.un__listPages_started .content {
    border: 1px solid rgba(255, 255, 255, .1);
    border-radius: 15px;
    padding: 20px;
    background: rgba(34, 32, 50, .01);
    -webkit-backdrop-filter: saturate(1) blur(10px);
    -o-backdrop-filter: saturate(1) blur(10px);
    -ms-backdrop-filter: saturate(1) blur(10px);
    backdrop-filter: saturate(1) blur(10px)
}

.un__listPages_started .content .title {
    padding: 10px 20px;
    text-align: center
}

.un__listPages_started .content .title h3 {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 10px;
    color: #fff
}

.un__listPages_started .content .title p {
    font-size: 13px;
    color: #b3b1c3;
    margin: 0
}

.un__listPages_started .content .title p span {
    color: #fff
}

.un__listPages_started .content .un-navMenu-default.style_glass .nav-item {
    width: 100%;
    margin-bottom: 0
}

.un__listPages_started .content .un-navMenu-default.style_glass .nav-item .nav-link {
    border: 0;
    background-color: transparent;
    padding: 17px 15px;
    border-radius: 15px
}

.un__listPages_started .content .un-navMenu-default.style_glass .nav-item .nav-link.visited {
    background-color: transparent !important
}

.un__listPages_started .content .un-navMenu-default.style_glass .nav-item .nav-link .icon img {
    width: 30px;
    height: 30px
}

.un__listPages_started .content .un-navMenu-default.style_glass .nav-item .nav-link .link-title {
    color: #fff
}

.un__listPages_started .content .un-navMenu-default.style_glass .nav-item .nav-link .other-cc .badge-text {
    color: #b3b1c3
}

.un__listPages_started .content .un-navMenu-default.style_glass .nav-item .nav-link .other-cc .xs-badge {
    min-width: 20px;
    height: 20px;
    padding: 0 7px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, .1);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: 600;
    color: #f4f5f7
}

.un__listPages_started .content .un-navMenu-default.style_glass .nav-item .nav-link .other-cc .icon-arrow {
    color: #fff
}

.un__listPages_started .content .un-navMenu-default.style_glass .nav-item .nav-link.background {
    background-color: rgba(255, 255, 255, .05) !important
}

.un__listPages_started::before {
    content: "";
    position: absolute;
    /* background-image: url("../../images/icons/shape_one.png"); */
    background-repeat: no-repeat;
    background-size: 53px;
    width: 53px;
    height: 53px;
    right: 0;
    top: -2px;
    -webkit-animation: floating-2 2.5s infinite ease-in-out;
    -moz-animation: floating-2 2.5s infinite ease-in-out;
    -o-animation: floating-2 2.5s infinite ease-in-out;
    -ms-animation: floating-2 2.5s infinite ease-in-out;
    animation: floating-2 2.5s infinite ease-in-out
}

.un_features_list {
    padding: 0 20px;
    position: relative
}

.un_features_list .content {
    padding: 30px;
    border: 1px solid rgba(255, 255, 255, .1);
    border-radius: 15px;
    background: rgba(34, 32, 50, .01)
}

.un_features_list .content ul {
    padding: 0;
    list-style: none;
    margin: 0
}

.un_features_list .content ul li {
    margin-bottom: 20px
}

.un_features_list .content ul li:last-child {
    margin-bottom: 0
}

.un_features_list .content ul li .icon {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    margin-bottom: 10px
}

.un_features_list .content ul li h4 {
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    margin: 0;
    margin-bottom: 4px
}

.un_features_list .content ul li p {
    font-size: 12px;
    font-weight: 500;
    color: #b3b1c3;
    margin: 0
}

.un_features_list::after {
    content: "";
    position: absolute;
    /* background-image: url("../../images/icons/shape_three.png"); */
    background-repeat: no-repeat;
    background-size: 70px;
    width: 70px;
    height: 70px;
    top: 62%;
    right: 60px;
    -webkit-animation: floating-2 2s infinite ease-in-out;
    -moz-animation: floating-2 2s infinite ease-in-out;
    -o-animation: floating-2 2s infinite ease-in-out;
    -ms-animation: floating-2 2s infinite ease-in-out;
    animation: floating-2 2s infinite ease-in-out
}

.bloack-get-started {
    padding: 15px 20px
}

.bloack-get-started .un-navMenu-default .nav-item {
    width: 100%;
    margin-bottom: 15px
}

.bloack-get-started .un-navMenu-default .nav-item .nav-link {
    border: 1px solid rgba(255, 255, 255, .1);
    border-radius: 15px
}

.bloack-get-started .un-navMenu-default .nav-item .nav-link .other-cc .badge-text {
    color: #b3b1c3
}

body {
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--white);
    min-height: 100vh;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark);
    -webkit-transition: .2s all;
    -moz-transition: .2s all;
    -o-transition: .2s all;
    -ms-transition: .2s all;
    transition: .2s all;
    -ms-overflow-style: none;
    scrollbar-width: none
}

body::-webkit-scrollbar {
    display: none
}

html {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important
}

#wrapper,
#content {
    width: 100%;
    height: 100%
}

button,
input,
textarea {
    outline: 0 !important;
    box-shadow: none !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none
}

.btn:hover {
    color: inherit
}

.btn.focus,
.btn:focus {
    box-shadow: none !important;
    outline: 0 !important
}

img {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none
}

::selection {
    background-color: var(--dark);
    color: vvar(--white)
}

i {
    display: inline-block
}

.swiper {
    width: 100%;
    height: 100%
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center
}

.dividar {
    border-bottom: 1px solid var(--gray-200)
}

.loader-page {
    position: fixed !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    z-index: 9999
}

.loader-page.hidden {
    animation: fadeOut 1s;
    animation-fill-mode: forwards
}

.spinner_flash {
    width: 40px;
    height: 40px;
    margin: 100px auto;
    background-color: var(--dark);
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    -moz-animation: sk-scaleout 1s infinite ease-in-out;
    -o-animation: sk-scaleout 1s infinite ease-in-out;
    -ms-animation: sk-scaleout 1s infinite ease-in-out;
    animation: sk-scaleout 1s infinite ease-in-out
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    display: none
}

.env-pb {
    padding-bottom: env(safe-area-inset-bottom)
}

.env-pt {
    padding-top: env(safe-area-inset-top)
}

.env-pr {
    padding-right: env(safe-area-inset-right)
}

.env-pl {
    padding-left: env(safe-area-inset-left)
}

@supports(-webkit-touch-callout: none) {
    .ios-env-pb {
        padding-bottom: env(safe-area-inset-bottom)
    }
}

@supports(-webkit-touch-callout: none) {
    .ios-env-pt {
        padding-top: env(safe-area-inset-top)
    }
}

@supports(-webkit-touch-callout: none) {
    .ios-env-pr {
        padding-right: env(safe-area-inset-right)
    }
}

@supports(-webkit-touch-callout: none) {
    .ios-env-pl {
        padding-left: env(safe-area-inset-left)
    }
}

.border {
    border: 1px solid var(--gray-200) !important
}

[data-theme=dark] {
    --white: #222032;
    --secondary: #807d96;
    --light: #2a283b;
    --dark: #dde1ec;
    --gray-200: #353246;
    --gray-300: #5b586e
}

[data-theme=dark] header.default .un-block-right .menu-sidebar .btn svg rect {
    fill: #fff
}

[data-theme=dark] header.default.border-b-gradient::before {
    background-image: linear-gradient(to right, #222032, #d977a7, #8272e1, #222032)
}

[data-theme=dark] .un-bottom-navigation.filter-blur .em_body_navigation {
    background: rgba(34, 32, 50, .8)
}

[data-theme=dark] .un-bottom-navigation .item_link .btn_navLink::after {
    background-color: #fff
}

[data-theme=dark] .un-bottom-navigation .item_link .btn_navLink.active .icon_active i {
    color: #fff
}

[data-theme=dark] .un-item-logo .logo-img.light-mode {
    display: none
}

[data-theme=dark] .un-item-logo .logo-img.dark-mode {
    display: block
}

[data-theme=dark] .sidebarMenu .modal-content .nav-link .xs-badge {
    background-color: rgba(255, 255, 255, .1) !important
}

[data-theme=dark] .un-navMenu-default .nav .nav-item .nav-link.visited {
    background-color: rgba(255, 255, 255, .015)
}

[data-theme=dark] .nav-pilled-tab {
    background-color: #2c293e
}

[data-theme=dark] .slider-range-price .noUi-tooltip::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='8' viewBox='0 0 16 8'%3E%3Cpath id='Polygon_3' data-name='Polygon 3' d='M7.293.707a1,1,0,0,1,1.414,0l5.586,5.586A1,1,0,0,1,13.586,8H2.414a1,1,0,0,1-.707-1.707Z' transform='translate(16 8) rotate(180)' fill='%237d7996'/%3E%3C/svg%3E%0A")
}

[data-theme=dark] .empty-items .empty-dark {
    display: inline-block
}

[data-theme=dark] .empty-items .empty-light {
    display: none
}

[data-theme=dark] .footer-pages-forms {
    background: rgba(34, 32, 50, .8)
}

[data-theme=dark] .un-details-collectibles .footer {
    background: rgba(34, 32, 50, .8)
}

[data-theme=dark] .sidebarMenu.-left .modal-dialog .modal-content .modal-header .cover-balance {
    background-color: #343247
}

[data-theme=dark] .text-dark {
    color: #fff !important
}

[data-theme=dark] .bg-white {
    background-color: #222032 !important
}

[data-theme=dark] .un-creator-ptofile .body .item-followers .users-text .img-user .link {
    color: #fff;
    background-color: #3e3c4b
}

#wrapper,
.modal {
    zoom: 75% !important;
    -ms-zoom: 75%;
    -webkit-zoom: 75%
}

.un-bottom-navigation {
    padding-top: 100px
}

.un-bottom-navigation .em_body_navigation {
    padding-bottom: 40px
}

.un-block-cards .item-card-nft {
    height: 80vh
}

.sidebarMenu.-left .modal-dialog .modal-content .modal-footer {
    padding-bottom: 40px
}

.modal.modal-collectibles .content-modal-footer {
    padding-bottom: 30px
}

.un-details-collectibles .footer {
    padding-bottom: 40px
}

.footer-pages-forms {
    padding-bottom: 40px
}

.status-connection {
    zoom: 75%;
    -ms-zoom: 75%;
    -webkit-zoom: 75%
}

.un-creator-ptofile .tab-creatore-profile .nav-pilled-tab .nav-item .nav-link {
    width: calc(33.3333333333vw - 12px)
}


.order_details h1{
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 10px !important
}

.order_details .card-body{
    padding: 20px !important;
    border: 0 !important;
    border-radius: 10px !important
}

.order_details .card{
    border: 0 !important;
    border-radius: 10px !important
}

.order_details .card-header{
    background: #FFF !important;
    padding: 15px 20px !important;
    font-size: 14px;
    font-weight: bold;
    border-radius: 10px !important
}