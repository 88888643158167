
.counter-item {
    text-align: center;
    margin-bottom: 30px
}

.counter-item .counter-number {
    font-size: 30px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.89);
}

.counter-item .counter-title {
    font-size: 16px;
    color: #3f9442ce;
}

.counter-item:hover .icon-element {
    -webkit-transform: translateY(-3px);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
    transform: translateY(-3px)
}


.icon-element {
    font-size: 25px;
    color: #050f19;
    width: 55px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    display: inline-block;
    position: relative;
    z-index: 1;
    background-color: #fff;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    -webkit-box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
    -moz-box-shadow: 0 1px 10px rgba(82, 85, 90, .1);
    box-shadow: 0 1px 10px rgba(82, 85, 90, .1)
}

@media (max-width: 500px) {
    .counter-item .counter-number {
        font-size: 20px !important;
    }
    .counter-item .counter-title {
        font-size: 13px !important;
    }
    .icon-element {
        font-size: 20px !important;
        width: 40px !important;
        height: 40px !important;
        line-height:40px !important;
    }
}
