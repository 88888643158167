.body {
    background-color: #050F19;
}

.body_2 {
    background-color: #ebebeb;
}

.body_3 {
    /* background: url('/public/images/bg_contact.jpg');
    background-repeat: no-repeat;
    background-size: contain; */
    /* background-color: black !important;
    background-blend-mode: normal !important; */
    background-color: #050F19;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.faq.style-3.style-4 .accordion-item .accordion-button {
    border-bottom: 0.1px solid !important;
    color: rgba(255, 255, 255, 0.603);
}

.faq.style-3.style-4 .accordion-item .accordion-button:not(.collapsed) {
    border: none !important;
}

.navbar-toggler {
    border: none !important;
    box-shadow: none !important;
}

.offcanvas {
    width: 300px !important;
}

.offcanvas-body ul li {
    padding-bottom: 15px !important;
}

.modal {
    height: 500px !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}


.error-page-area .error-box h1 {
    font-size: 200px;
    font-weight: 900;
    line-height: 140px;
    margin-bottom: 30px;
}

@media(max-width:767px) {
    .error-page-area .error-box h1 {
        font-size: 150px;
    }
}

.error-page-area .error-box h2 {
    font-weight: 900;
}

.error-page-area .error-box p {
    padding: 0 10%;
}

.error-page-area .error-box a.btn {
    margin-top: 10px;
}

select {
    box-shadow: none !important;
}

.css-1s2u09g-control,
.css-1pahdxg-control {
    height: 50px !important;
    min-height: 50px !important;
}

.input-range__slider {
    appearance: none;
    background: var(--color-main);
    border: 10px solid var(--color-blue4);
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.75rem;
    outline: none;
    position: absolute;
    top: 40%;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    width: 1rem;
}

.text-primary {
    color: var(--color-main) !important;
}

.input-range__slider:active {
    transform: scale(1.0);
}

.input-range__track--active {
    background-color: var(--color-main);
}

.loan_type_image {
    height: 170px !important;
}

.loan_info .form-control,
.loan_info select {
    padding: 15px;
}

.go2072408551 {
    font-size: 12px !important;
    font-weight: light !important;
    color: #000 !important;
}


.verify_modal {
    z-index: 0 !important;
}

.verify_modal .modal {
    height: 100% !important;
}

/* .verify_modal .modal-backdrop {
    display: block !important;
} */


input::placeholder {
    font-size: 14px !important;
    font-weight: 400 !important;
}

input,
select,
textarea {
    font-size: 14px !important;
    font-weight: 400 !important;
    border-radius: 10px !important;
}

.form-check-input:checked {
    background-color: var(--color-main) !important;
    border-color: var(--color-main) !important;
}

.form-check-input:focus {
    border-color: none !important;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(53, 253, 13, 0.25);
}



/* IMPORTANT START */

.important {
    background: #1F831F;
    box-shadow: 0px 5px 26px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
}

@media (max-width: 767px) {
    .important {
        text-align: center !important;
        margin: auto !important;
    }

    .align_Center {
        text-align: center !important;
        margin: auto !important;
    }
}

.important .card {
    background: #FFF;
    mix-blend-mode: normal;
    border-radius: 5px;
    height: 280px;
    color: #000 !important;
}

.important .card img {
    width: 50px !important;
}

/* IMPORTANT END */



/************* Steps Area ************/
.step-shape-left {
    width: 300px;
    left: 0;
    top: 0;
    z-index: -1;
}

.steps img {
    width: 450px !important;
}

.steps {
    color: #FFF;
}

.steps-left .steps-img {
    margin-top: 30px;
}

.steps-left .steps-img img {
    border-radius: 10px;
}

@media (max-width: 575.98px) {
    .steps-left .steps-title {
        padding-right: 0;
    }

    .steps-left .steps-desc {
        padding-right: 0;
    }
}

.steps-right {
    padding: 20px 0px;
    border-radius: 6px;
}

.steps-right ul li.steps-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.steps-right ul li.steps-item+li.steps-item {
    margin-top: 60px;
}

.steps-right ul li.steps-item+li.steps-item .icon-wrapper {
    position: relative;
}

.steps-right ul li.steps-item+li.steps-item .icon-wrapper::before {
    content: "";
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: -90px;
    width: 0;
    border-right: 2px dashed #ffffff;
    height: 90px;
    opacity: 0.1;
}

.steps-right ul li.steps-item+li.steps-item .steps-content {
    position: relative;
}

.steps-right ul li.steps-item+li.steps-item .steps-content::before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #ffffff;
    opacity: 0.2;
    top: -30px;
}

.steps-right ul li.steps-item+li.steps-item .steps-content::after {
    content: "";
    position: absolute;
    left: 0;
    top: -31px;
    width: 100px;
    height: 3px;
    background: linear-gradient(92.29deg, #36a136 -9.05%, #43b41f 103.12%);
}

.steps-right ul li.steps-item .icon-wrapper span {
    width: 42px;
    height: 42px;
    background-color: #0e1217;
    color: #FFF !important;
    font-size: 30px;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.steps-right ul li.steps-item .steps-content {
    margin-left: 20px;
    padding-right: 40px;
    position: relative;
}

.steps-right ul li.steps-item .steps-content .count-number {
    position: absolute;
    right: 0;
    top: -20px;
    font-size: 42px;
    font-weight: 700;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px;
    opacity: 0.25;
}

.steps-right ul li.steps-item .steps-content .content-txt {
    margin-top: 20px;
}

@media (max-width: 991.98px) {
    .teps-right {
        margin-top: 60px;
    }
}

@media (max-width: 575.98px) {

    .steps-right {
        padding: 60px 0px !important;
        border-radius: 6px;
    }

    .steps-right usteps-item .steps-content {
        padding-right: 0;
    }

    .steps-right ul li.steps-item .steps-content .count-number {
        display: none;
    }
}



.error_page .banner h1 {
    text-transform: uppercase;
    font-size: 50px !important;
}

@media (max-width:768px) {
    .error_page .banner h1 {
        font-size: 30px !important;
        margin-bottom: 40px !important;
    }

    .error_page .banner button {
        margin-bottom: 50px !important;
    }
}


/* DASHBOARD STYLE */

.verify_account {
    position: relative !important;
    z-index: 999999999 !important;
}

.btnClose {
    padding: 17px !important;
}

@media (max-width: 650px) {

    .sidebarMenu.-left .modal-dialog,
    .sidebarMenu.-right .modal-dialog {
        width: 360px !important;
    }
}

.modal-backdrop.show {
    display: none !important;
}

.modal-open {
    overflow: scroll !important;
}

.un-bottom-navigation .em_body_navigation {
    padding-bottom: 10px !important;
}

.delivery_details {
    height: 58vh !important;
    overflow-y: scroll !important;
}

.css-1e2bxag-MuiDataGrid-root {
    border: none !important;
}

.edit-btn {
    background: #ebebfb !important;
    color: #3A36DB !important;
    padding: 5px 17px;
    font-size: 13px;
    font-weight: 300 !important;
}

.delete-btn {
    background: #fff0f7 !important;
    color: #FF69B4 !important;
    padding: 5px 17px;
    font-size: 13px;
    font-weight: 300 !important;
}

.del-btn {
    padding: 5px !important;
    border-radius: 50% !important;
    background: #fff0f7 !important;
    color: #FF69B4 !important;
    height: 30px !important;
    width: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}


/* PLACE ORDER */

.place_order_container .payment input[type='radio'] {
    display: none;
}

.place_order_container .payment label {
    position: relative;
    color: #1F831F !important;
    border: 2px solid #1F831F !important;
    padding: 5px 50px !important;
    display: flex;
    align-items: center;
    border-radius: 6px !important;
    font-size: 15px;
    cursor: pointer !important;
}

@media (max-width: 612px) {
    .place_order_container .payment label {
        padding: 8px 8px !important;
        font-size: 13px;
        cursor: pointer !important;
    }
}

.place_order_container .payment label::before {
    content: '';
    height: 16px;
    width: 16px;
    border: 3px solid #1F831F !important;
    border-radius: 50%;
    margin-right: 9px;
}

.place_order_container .payment input[type='radio']:checked+label {
    background-color: #1F831F !important;
    color: #FFF !important;
}

.place_order_container .payment input[type='radio']:checked+label::before {
    height: 12px;
    width: 12px;
    border: 10px solid#FFF !important;
    background-color: #1F831F !important;
}

/* GOOGLE MAP */

.google_map {
    height: 80vh !important;
}

.google_map_2 {
    height: 85vh !important;
}

@media (max-width: 600px) {
    .google_map {
        height: 50vh !important;
    }
}


/* ANIMATION */

.animation_up_down {
    animation: zoom-in-zoom-out 2s ease-out infinite;
}

.illustration_1 {
    animation: illustration 2s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.4, 1.4);
    }

    100% {
        transform: scale(1, 1);
    }
}

.social_media_icon {
    border: 1px solid #00000081 !important;
    color: #000 !important;
}

.social_media_icon i {
    font-size: 17px !important;
}


.about-4-image {
    height: 640px;
    width: 518px;
    border-radius: 12px !important;
}

.about-4-image .about-4-image-content {
    position: absolute;
    bottom: 5%;
    background: #fff;
    width: 80%;
    left: 10%;
    border-radius: 8px;
    padding: 17px;
}

@media (max-width: 991px) {
    .about-4-image {
        display: none !important;
    }
}

.about-4-image .about-4-image-content p {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #000;
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .about-4-image .about-4-image-content p {
        font-size: 12px;
        line-height: 22px;
    }
}

.about-4-image .about-3-img-3 {
    width: 30px;
    position: absolute;
    bottom: -40px;
    left: 30px;
}

.about-4-image .about-3-img-6 {
    width: 30px;
    position: absolute;
    top: 16%;
    left: -40px;
}

@media (max-width: 767px) {
    .about-4-image .about-3-img-6 {
        left: 0;
    }
}

.about-4-image .about-3-img-5 {
    position: absolute;
    width: 30px;
    top: 34%;
    right: -40px;
}

@media (max-width: 767px) {
    .about-4-image .about-3-img-5 {
        right: 0;
    }
}


.section-padding {
    padding-top: 120px;
}

@media (max-width: 991px) {
    .section-padding {
        padding-top: 60px;
    }
}

.section-padding2 {
    padding: 120px 0 90px;
}

@media (max-width: 991px) {
    .section-padding2 {
        padding: 60px 0 30px;
    }
}


.heading4 {
    margin-bottom: 70px;
}

@media (max-width: 767px) {
    .heading4 {
        margin-bottom: 40px;
    }
}

.heading4.white-heading h1,
.heading4.white-heading h2,
.heading4.white-heading h3,
.heading4.white-heading h4,
.heading4.white-heading h5,
.heading4.white-heading h6,
.heading4.white-heading p,
.heading4.white-heading small {
    color: #fff !important;
}

.heading4 h2 {
    font-family: 'Canela Trial', sans-serif !important;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #F2F2F2;
}

@media (max-width: 767px) {
    .heading4 h2 {
        font-size: 32px;
        line-height: 42px;
    }
}

.heading4.inner-heading h2 {
    font-weight: 700;
}

.heading4 h3 {
    font-weight: 400;
    font-size: 42px;
    line-height: 54px;
    color: #FFF;
}

@media (max-width: 767px) {
    .heading4 h3 {
        font-size: 32px;
        line-height: 42px;
    }
}

.heading4 h4 {
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    color: #07060B;
}

@media (max-width: 767px) {
    .heading4 h4 {
        font-size: 24px;
    }
}

.heading4 p {
    font-size: 18px;
    line-height: 32px;
    color: #F2F2F2;
    margin-top: 20px;
}

@media (max-width: 767px) {
    .heading4 p {
        font-size: 16px;
        line-height: 26px;
    }
}

.heading4 small.overline {
    position: relative;
    margin-bottom: 15px;
    z-index: 2;
    display: inline-block;

    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    color: #FFF;
}

.heading4 small.overline:after {
    position: absolute;
    content: "";
    height: 2px;
    width: 50%;
    background: #FFF;
    left: 0;
    top: -10px;
}

.heading4.text-center small.overline:after {
    left: 25%;
}

.space30 {
    height: 30px;
}

@media (max-width: 767px) {
    .space30 {
        height: 15px;
    }
}

.space40 {
    height: 40px;
}

@media (max-width: 767px) {
    .space40 {
        height: 20px;
    }
}


.theme-btn-11 {
    background-color: var(--color-main) !important;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    display: inline-flex;
    border: none;
    align-items: center;
    color: #ffffff;
    transition: all 0.3s;
    transform: translateY(0);
    padding: 15px 20px;
}

.theme-btn-11 img {
    margin-left: 6px;
}

.theme-btn-11 span {
    display: inline-block;
    width: 20px;
    text-align: right;
    font-size: 18px;
    transform: rotate(-45deg);
    height: 15px;
    display: flex;
    justify-content: flex-end;
}

.theme-btn-11 span img {
    margin-left: 10px;
    transition: all 0.3s;
    width: 12px;
}

.theme-btn-11:hover {
    color: #ffffff;
    background: #706de2;
    transition: all 0.3s;
    transform: translateY(-3px);
}

/* SERVICES */


.heading {
    margin-bottom: 70px;
}

@media (max-width: 767px) {
    .heading {
        margin-bottom: 40px;
    }
}

.heading h2 {
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #FFF;
    font-family: 'Canela Trial', sans-serif !important;
}

@media (max-width: 767px) {
    .heading h2 {
        font-size: 28px;
        line-height: 38px;
    }
}

.heading h5 {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #FFF;
}

@media (max-width: 767px) {
    .heading h5 {
        font-size: 16px;
        line-height: 24px;
    }
}

.heading.white-heading h2,
.heading.white-heading h3,
.heading.white-heading h5 {
    color: #ffffff;
}

.heading h3 {
    font-size: 42px;
    font-weight: 400;
    line-height: 48px;
    color: #07060B;
    margin-bottom: 40px;
}

@media (max-width: 991px) {
    .heading h3 {
        font-size: 28px;
        line-height: 38px;
    }
}

.heading p {
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    color: #5C5B79;
}

@media (max-width: 991px) {
    .heading p {
        font-size: 70%;
        line-height: 26px;
    }
}

.heading small {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #F2F2F2;
    margin-bottom: 10px;
    display: inline-block;
    position: relative;
    z-index: 2;
}

.heading small:after {
    position: absolute;
    content: "";
    height: 1px;
    width: 60%;
    background: #F2F2F2;
    left: 0;
    top: -7px;
}

.heading.text-center small:after {
    left: 20%;
}


.single-service {
    background: #ffffff;
    border: 1px solid #E1E3E8;
    border-radius: 10px;
    padding: 24px 28px;
    margin-bottom: 30px;
    transition: all 0.3s;
    transform: translateY(0);
}

.single-service:hover {
    transition: all 0.3s;
    transform: translateY(-15px);
    border-color: transparent;
    box-shadow: 0 4px 60px rgba(0, 0, 0, 0.08);
}

.single-service h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #161540;
    font-family: 'Canela Trial', sans-serif !important;
    margin-bottom: 15px !important;
}

@media (max-width: 767px) {
    .single-service h3 {
        font-size: 18px;
        line-height: 26px;
    }
}

.single-service p {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #5C5B79;
    margin-bottom: 24px;
}

@media (max-width: 767px) {
    .single-service p {
        font-size: 16px;
        line-height: 24px;
    }
}

.service-icon {
    display: inline-block;
    background: #02ff1731;
    margin-bottom: 30px;
    border-radius: 50px;
    width: 70px;
    height: 70px;
    text-align: center;
    line-height: 64px;
}

@media (max-width: 767px) {
    .service-icon {
        width: 50px;
        height: 50px;
        line-height: 48px;
        margin-bottom: 20px;
    }
}

.service-icon img {
    width: 40px;
    height: 40px;
}

@media (max-width: 767px) {
    .service-icon img {
        height: 30px;
        width: 30px;
    }
}


.theme-btn-6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    align-items: center;
    gap: 5 !important;
    color: #161540;
    transition: all 0.3s;
}

@media (max-width: 767px) {
    .theme-btn-6 {
        font-size: 14px;
        line-height: 22px;
    }
}