/*======================================
       Banner Start
    ========================================*/

.banner_container .gradient_1 {
    position: absolute;
    left: 0 !important;
    top: 0 !important;
}

.banner_container .line {
    position: absolute;
    right: 300px !important;
    top: 120px !important;
}

.banner {
    margin-top: 50px !important;
}

.banner p {
    color: #e0e0e0 !important;
}

.banner .btn {
    padding: 12px 20px !important;
    font-weight: 400 !important;
    box-shadow: none !important;
    border-radius: 15px !important;
    font-size: 14px !important;
}

.banner a {
    color: #e0e0e0 !important;
}

.banner .people img {
    width: 110px;
}

.banner .people p {
    font-size: 12px;
}


/* .app-download-area {
        padding-top: 95px;
        padding-bottom: 50px;
    } */

.app-image {
    position: relative;
    z-index: 1;
    text-align: left;
}

.app-image img {
    user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
}

.app-image .main-image img:nth-child(2) {
    position: absolute;
    right: -100px;
    top: 0;
}

.app-image .main-mobile-image {
    display: none;
}

.app-image .circle-img {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-55%);
    transform: translateY(-55%);
    z-index: -1;
    right: 0;
    margin: 0 auto;
    width: 600px;
}

.app-image .circle-img img {
    -webkit-animation-name: rotateMe;
    animation-name: rotateMe;
    -webkit-animation-duration: 35s;
    animation-duration: 35s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.app-download-content {
    padding-left: 20px;
}

.app-download-content h2 {
    margin-bottom: 0;
    font-size: 40px;
    font-weight: 600;
}

@media (max-width: 768px) {
    .app-download-content h2 {
        font-size: 30px !important;
        margin-bottom: 20px;
    }
}

.bar {
    height: 5px;
    width: 90px;
    background: #ef6c0073;
    margin: 20px 0 25px;
    position: relative;
    border-radius: 30px;
}

.bar::before {
    content: "";
    position: absolute;
    left: 0;
    top: -2.7px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #ef6a00;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: MOVE-BG;
    animation-name: MOVE-BG;
}

.bar2 {
    height: 5px;
    width: 90px;
    background: #195644be;
    margin: 20px 0 25px;
    position: relative;
    border-radius: 30px;
}

.bar2::before {
    content: "";
    position: absolute;
    left: 0;
    top: -2.7px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #195644;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: MOVE-BG;
    animation-name: MOVE-BG;
}

.app-download-content p {
    margin-bottom: 0;
}

.app-download-content .btn-box {
    margin-top: 25px;
}


/*======================================
                 Banner  Ends
    ========================================*/